import { ComponentPropsWithRef } from 'react';
import styled from 'styled-components';

import { ReactComponent as DownloadSvg } from '~/shared/images/download.svg';
import ActionButton from '~/shared/components/FeedItemActionButton';
import { useDownloading } from '~/shared/hooks';
import { useTranslation } from 'react-i18next';
const downloadIcon = styled( DownloadSvg ).attrs( { viewBox: '-4.5 -4 25 25' } )`
  height: 17.5px;
  width: 17.5px;
`;
const DownloadButton = ( {
  fileId: mediaId,
  url,
  downloads,
  name,
  hideLabel,
  ...rest
}: ComponentPropsWithRef<'button'> & {
  fileId: number;
  url: string;
  downloads: number;
  name?:string;
  hideLabel?:boolean;
} ) => {

  const { t } = useTranslation();
  const { download, isLoading } = useDownloading( {
    url,
    mediaId,
    isModal: false,
    name
  } );

  return (
    <ActionButton
      disabled={isLoading}
      label={!hideLabel
        ? t( 'Post.Buttons.Download' )
        : ''}
      Icon={downloadIcon}
      title={t( 'Post.Buttons.Download' )}
      onClick={() => download( { downloads: downloads + 1 } )}
      {...rest}
    />
  );

};

export default DownloadButton;


