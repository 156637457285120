import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {Root} from './styled'

import { withHebrew } from '../../utils/language';
import { ButtonsWrapper, CancelButton, ConfirmButton } from './ButtonsWrapper';



const Message = styled.p`
  white-space:pre-line;
  text-align:center;
  font-family:inherit;
  margin:0;
`
type Props = {
  onCancel: () => void;
  onConfirm: () => void;
  text?: string;
  disabled?:boolean;
};

const ConfirmPopup = ({ onCancel, onConfirm, text, disabled }: Props) => {
  const {t} = useTranslation()
  return (
    <Root >
      <Message>{text||'are you sure?'}</Message>
      {/* <p>There is no way back!</p> */}
      <ButtonsWrapper>
        <CancelButton onClick={onCancel}>{t('Popups.Confirm.Cancel')}</CancelButton>
        <ConfirmButton onClick={onConfirm} disabled={disabled}>{t('Popups.Confirm.Confirm')}</ConfirmButton>
      </ButtonsWrapper>
    </Root>
  );
};

export default ConfirmPopup;
