import React, { ComponentPropsWithRef, MouseEventHandler } from 'react';
import styled from 'styled-components';

import { ReactComponent as FileSvg } from '~/shared/images/file-document.svg';
import { ReactComponent as ImageSvg } from '~/shared/images/file-image.svg';
import { ReactComponent as PdfSvg } from '~/shared/images/file-pdf.svg';
import { ReactComponent as VideoSvg } from '~/shared/images/file-video.svg';
import { ReactComponent as DownloadSvg } from '~/shared/images/download.svg';
import { ReactComponent as TrashSvg } from '~/shared/images/trash-with-circle.svg';
import { useDownloading } from '../hooks';


export const Root = styled.div`
  border-radius: 10px;
  position: relative;
  background: #FCFCFC;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items:center;
  height: 70px;
  min-width: 70px;
  max-width: 70px;
  position:relative;
  margin: .5rem;
  margin-top: 0;
  padding:5px;
`;

const Button = styled( Root )`
  
  position:absolute;
  height:100%;
  width:100%;
  opacity:0;
  top:0;
  left:0;
  cursor: pointer;
  background:#555;
  color:white;
  margin: 0;
  &:hover{
    opacity:1;
  }
`;
const TrashIcon = styled( TrashSvg )`
  position:relative;
  top:50%;
  transform:translateY(-50%);
  circle{
    display:none;
  }
  `;
const DownloadIcon = styled( DownloadSvg )`
  position:relative;
  top:50%;
  transform:translateY(-50%);
  path{
    fill: currentColor;
  }
`;
const Title = styled.span`
  text-transform: lowercase;
  display:grid;
  grid-template: 'title suffix';
  grid-template-columns:minmax(10px,1fr) fit-content;
  width:100%;
  height:1em;
  font-size: ${( { theme } ) => theme.typing.fontSizes.tiny};
  margin-block: auto 5px;
`;
const Name = styled.span`
  display: block;
  overflow:hidden;
  text-overflow: ellipsis;
  white-space:nowrap;
  max-width:50px;
`;
const Type = styled.span`
  display: block;
  width:fit-content;
`;

function getIcon ( type: string ) {

  const lowercase = type.toLowerCase();
  if ( lowercase.includes( 'pdf' ) ) {

    return PdfSvg;

  }
  if ( lowercase.includes( 'image' ) ) {

    return ImageSvg;

  }

  /*
   * if(lowercase.includes('audio') ){
   *         return AudioIcon
   *       }
   */
  if ( lowercase.includes( 'video' ) ) {

    return VideoSvg;

  }
  return FileSvg;

}
const FileCard = ( {
  type,
  name,
  withoutIcon,
  path = 'nopath',
  editing = false,
  onDelete,
  mediaId,
  ...rest
}: {
  type: string;
  name?: string;
  withoutIcon?: boolean;
  path?:string;
  clickable?:boolean;
  editing?:boolean;
  onDelete:( e:React.MouseEvent )=>void;
  mediaId:number;
} & ComponentPropsWithRef<'div'> ) => {

  const { download } = useDownloading( {
    url: path || 'nopath',
    mediaId,
    isModal: false,
    name
  } );
  const lastDot = name?.lastIndexOf( '.' );
  const title = name?.slice(
    0,
    lastDot
  );
  const suffix = name?.slice( lastDot );
  const Icon = getIcon( type );

  return (
    <Root
      title={name}
      { ...rest }
    >
      <Button
        as="button"
        type="button"
        onClick={( ( e ) => {

          if ( editing ) {

            return onDelete( e );

          }
          download( { downloads: 0 } );

        } )as MouseEventHandler}
      >
        {editing
          ? <TrashIcon />
          : <DownloadIcon/>
        }
        <Title>
          <Name>{title}</Name><Type>{suffix}</Type>
        </Title>
      </Button>

      {!withoutIcon && <Icon/>}
    </Root>
  );

};

export default FileCard;


