import { css } from 'styled-components';

export const fontSizes = {
  large: '18px',
  normal: '16px', // will not cause zoom on focus in inputs on ios
  small: '14px',
  smallMobile: '13px',
  smaller: '12px',
  tiny: '11px',
  tinier: '10px',
};

export const fontWeights = {
  bold: 800,
  semiBold: 600,
  medium: 500,
  normal: 400,
  small: 300,
};

export const breakPoints = {
  smallMobile: '400px',
  mobile: '700px',
  small: '962px',
  medium: '1232px',
  big: '1494px',
  xLarge: '1780px',
};

export const mediaRules = {
  untilSmall: `(max-width: ${breakPoints.small})`,
  untilSmallMobile: `(max-width: ${breakPoints.smallMobile})`,
  untilMobile: `(max-width: ${breakPoints.mobile})`,
  fromSmall: `(min-width: ${breakPoints.small})`,
  untilMedium: `(max-width: ${breakPoints.medium})`,
  medium: `(min-width: ${breakPoints.small}) and (max-width: ${breakPoints.medium})`,
  fromMedium: `(min-width: ${breakPoints.medium})`,
  mobile: `(max-width:1150px)`,
  fromMobile: `(min-width:1150px)`,
  untilBig: `(max-width: ${breakPoints.big})`,
  fromBig: `(min-width: ${breakPoints.big})`,
  untilXLarge: `(max-width: ${breakPoints.xLarge})`,
  fromXLarge: `(min-width: ${breakPoints.xLarge})`,
};
export const zIndexes = {
  aboveNormal: 0,
  topbar: 1,
  overOthers: 2,
  phoneMiddleButton: 3,
  popup: 4
};

export const textTinyBold = css`
  font-size: ${fontSizes.tiny};
  font-weight: ${fontWeights.bold};
`;
export const textSmallBold = css`
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.bold};
`;
export const textSmallerSemiBold = css`
  font-size: ${fontSizes.smaller};
  font-weight: ${fontWeights.semiBold};
  letter-spacing: -0.1px;
`;
export const textLargeBold = css`
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.bold};
`;
