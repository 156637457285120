import { ComponentPropsWithRef, useEffect } from 'react';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const SpinnerRoot = styled.svg`
position: relative;
height: 100%;
& circle {
    fill: none;
    stroke-width: 16;
    stroke-linecap: round;
    stroke-dasharray: 0, 0, 70, 194;
    stroke-dashoffset: 0;
    stroke-dashoffset: -264;
    transform-origin: center;
    transform: rotateZ(180deg);
    animation: spin .5s infinite linear;
    @keyframes spin {
      from {
        transform: rotateZ(0);
      }
      to {
        transform: rotateZ(360deg);
      }
    }
  }

  
`;

type SpinnerProps = ComponentPropsWithRef<'svg'> & {
  size?: number;
  stroke?: string;
};

const Spinner = ( {
  size = 20,
  stroke = 'fff',
  ...rest
}: SpinnerProps ) => (
  <SpinnerRoot viewBox="0 0 100 100" stroke={stroke} width={size} height={size} {...rest}>
    <circle cx="50" cy="50" r="42" transform="rotate(-90,50,50)" />
  </SpinnerRoot>
);

/**
 * useful to debug things the instance they load
 * @param props use data prop to log that data when the spinner is rendered
 * @returns
 */
export const DebugSpinner = ( props:any ) => {

  useEffect( () => {

    console.log(
      'data:',
      props.data
    );
    debugger;

  } );
  return <Spinner {...props}/>;

};

export const StandardSpinner = ( props:any ) => <Spinner size={props.size || 100} stroke={'#3ebad3'} {...props}/>;

/**
 * useful when you need the spinner to fill up the available area and be in the middle
 */
export const ContainedSpinner = ( { className, title, ...props }:SpinnerProps&{data?:string, title?:string} ) => <LoaderWrapper title={title} className={className}><Spinner size={100} stroke={'#3ebad3'} {...props} /></LoaderWrapper>;

export default Spinner;
