import styled from 'styled-components';

import { 
  Button as _Button
 } from '~/shared/components/styled';

export const Button = styled(_Button)`
 padding: 19px 33px;
 border-radius: 28px;

 font-size: 16px;
 font-weight: 800;
 text-transform: uppercase;
`
export const Section = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  position:relative;
  
`;
export const Column = styled.section`
  padding: 0 50px;
  display:flex;
  flex-direction:column;
`;

export const ButtonHolder = styled.div`
  display:flex;
  flex-direction:row;
  gap: 24px;
  @media ${ ({theme}) => theme.typing.mediaRules.untilSmall} {
    width: 100%;
    justify-content: center;
  }
`;
export const FullButton = styled(_Button)`
  height: 64px;
  border-radius: 28px;
  padding:10px 30px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.primary};
  font-size:${({ theme }) => theme.typing.fontSizes.normal};
  transition:.2s;
  :hover{
    box-shadow: 0 0px 20px 10px rgba(62,186,211,0.5);
  }
`;
export const EmptyButton = styled(FullButton)`
  padding:4px 30px;
  color: ${({ theme }) => theme.colors.primary};
  border: solid 2px ${({ theme }) => theme.colors.primary};
  /* background-color: ${({ theme }) => theme.colors.white}; */
  background-color: transparent;

`;

export const SectionTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-transform: uppercase;
  word-break:keep-all;
  margin:0 auto;
  color: ${({theme})=>theme.colors.primary};;
`;
export const Content = styled.p`
  font-size: 20px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: 1.6;
letter-spacing: normal;
color: #000000;
`;
