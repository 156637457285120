import {
  createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState
} from 'react';
import { useLocation } from 'react-router';
import useCurrentCommunity from './useCurrentCommunity';
interface UseFiltersI {
  filters: string;
  setFilters: Dispatch<SetStateAction<string>>;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;

  /**
   * ['cells', 'profile', communityId, search, filters]
   */
  wallDataQueryKey: [string, string, number, string, string];
}

const feedFiltersContext = createContext<UseFiltersI|undefined>( undefined );


export const FeedFiltersProvider = (
  { children }:{
    children:React.ReactNode
  }
) => {

  const { state } = useLocation<Partial<Omit<UseFiltersI, 'wallDataQueryKey'>>|undefined>();

  const { currentCommunity } = useCurrentCommunity();
  const [ search, setSearch ] = useState<string>( state?.search || '' );
  const [ filters, setFilters ] = useState<string>( state?.filters || '' );
  const wallDataQueryKey = useMemo(
    () => [
      'cells',
      'profile',
      ( currentCommunity?.id as number ),
      search,
      filters,
    ] as UseFiltersI['wallDataQueryKey'],
    [
      search,
      filters,
      currentCommunity?.id
    ]
  );
  useEffect(
    () => {


      if ( state?.filters ) {

        setFilters( state.filters );

      }

    },
    [ state ]
  );
  return <feedFiltersContext.Provider value={{
    filters,
    search,
    wallDataQueryKey,
    setSearch,
    setFilters
  }}>
    {children}
  </feedFiltersContext.Provider>;

};

export const useFeedFilters = (): UseFiltersI => {

  const context = useContext( feedFiltersContext );
  if ( context === undefined ) {

    throw new Error( 'useFeedFilters must be used within a FeedFiltersProvider' );

  }
  return context;

};
