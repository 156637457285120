import React from 'react';
import styled from 'styled-components';
import theme from '~/shared/theme';

type ButtonProps = {
  hollow?: boolean;
  borderWidth?: number;
  children: any;
  color?: ( keyof typeof theme.colors ) | string;
 } & React.ComponentPropsWithRef<'button'>;

function getColor ( color: string|undefined ) {

  return theme.colors[color as keyof typeof theme.colors] || color;

}
export const BaseButton = styled.button<ButtonProps>`
  padding: 11px 27px;
  border-radius: 12px;
  color: ${( { theme, hollow, color } ) => ( hollow
    ? getColor( color ) || theme.colors.textLight2
    : theme.colors.white )};
  background-color: ${( { theme, hollow, color } ) => ( hollow
    ? 'transparent'
    : color
      ? getColor( color )
      : theme.colors.primary
  )};
  ${( { theme } ) => theme.typing.textSmallBold};
  ${( { hollow, color, borderWidth } ) => borderWidth && hollow && `border: ${getColor( color )} ${borderWidth}px solid`}
`;

export const TextButton = styled( BaseButton ).attrs( { hollow: true } )<{color?:string, capitalize?:boolean}>`
  padding:0;
  width: fit-content;
  color: ${( { theme, color } ) => theme.colors[color as keyof typeof theme.colors]};

  background-color: transparent;
  ${( { theme } ) => theme.typing.textSmallBold};
  ${( { capitalize } ) => capitalize && `
    ::first-letter{
      text-transform: uppercase;
    }
  `};

`;

const Button = ( props: ButtonProps ) => <BaseButton {...props} />;

Button.defaultProps = {
  hollow: false,
};

export default Button;


