import React, { ComponentPropsWithoutRef } from 'react';

const FileInput = ({
  onFilesChange,
  ...rest
}: ComponentPropsWithoutRef<'input'> & { onFilesChange: (files: FileList) => void }) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) onFilesChange(e.target.files);
  };
  return <input multiple={false} value={undefined} type={'file'} 
    onChange={
      e=>{

        onChange(e)
        e.target.value = ''
      }
    } 
    {...rest} />;
};

export default FileInput;
