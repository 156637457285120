export interface PhonePrefix {
  name: string;
  he: string;
  label: string;
  flag: string;
  code: string;
  dial_code: string;
}

export const PHONE_PREFIXES : PhonePrefix[] = [
  {
    name: 'Afghanistan',
    label: 'Afghanistan',
    flag: '🇦🇫',
    code: 'AF',
    dial_code: '+93',
    he: 'אפגניסטן'
  },
  {
    name: 'Åland Islands',
    label: 'Åland Islands',
    flag: '🇦🇽',
    code: 'AX',
    dial_code: '+358',
    he: 'אולנד'
  },
  {
    name: 'Albania',
    label: 'Albania',
    flag: '🇦🇱',
    code: 'AL',
    dial_code: '+355',
    he: 'אלבניה'
  },
  {
    name: 'Algeria',
    label: 'Algeria',
    flag: '🇩🇿',
    code: 'DZ',
    dial_code: '+213',
    he: 'אלג׳יריה'
  },
  {
    name: 'American Samoa',
    label: 'American Samoa',
    flag: '🇦🇸',
    code: 'AS',
    dial_code: '+1684',
    he: 'סמואה'
  },
  {
    name: 'Andorra',
    label: 'Andorra',
    flag: '🇦🇩',
    code: 'AD',
    dial_code: '+376',
    he: 'אנדורה'
  },
  {
    name: 'Angola',
    label: 'Angola',
    flag: '🇦🇴',
    code: 'AO',
    dial_code: '+244',
    he: 'אנגולה'
  },
  {
    name: 'Anguilla',
    label: 'Anguilla',
    flag: '🇦🇮',
    code: 'AI',
    dial_code: '+1264',
    he: 'אנגווילה'
  },
  {
    name: 'Antarctica',
    label: 'Antarctica',
    flag: '🇦🇶',
    code: 'AQ',
    dial_code: '+672',
    he: 'אנטארקטיקה'
  },
  {
    name: 'Antigua and Barbuda',
    label: 'Antigua and Barbuda',
    flag: '🇦🇬',
    code: 'AG',
    dial_code: '+1268',
    he: 'אנטיגואה וברבודה'
  },
  {
    name: 'Argentina',
    label: 'Argentina',
    flag: '🇦🇷',
    code: 'AR',
    dial_code: '+54',
    he: 'ארגנטינה'
  },
  {
    name: 'Armenia',
    label: 'Armenia',
    flag: '🇦🇲',
    code: 'AM',
    dial_code: '+374',
    he: 'ארמניה'
  },
  {
    name: 'Aruba',
    label: 'Aruba',
    flag: '🇦🇼',
    code: 'AW',
    dial_code: '+297',
    he: 'ארובה'
  },
  {
    name: 'Australia',
    label: 'Australia',
    flag: '🇦🇺',
    code: 'AU',
    dial_code: '+61',
    he: 'אוסטרליה'
  },
  {
    name: 'Austria',
    label: 'Austria',
    flag: '🇦🇹',
    code: 'AT',
    dial_code: '+43',
    he: 'אוסטריה'
  },
  {
    name: 'Azerbaijan',
    label: 'Azerbaijan',
    flag: '🇦🇿',
    code: 'AZ',
    dial_code: '+994',
    he: 'אזרביג\'אן'
  },
  {
    name: 'Bahamas',
    label: 'Bahamas',
    flag: '🇧🇸',
    code: 'BS',
    dial_code: '+1242',
    he: 'איי הבהאמס'
  },
  {
    name: 'Bahrain',
    label: 'Bahrain',
    flag: '🇧🇭',
    code: 'BH',
    dial_code: '+973',
    he: 'בחריין'
  },
  {
    name: 'Bangladesh',
    label: 'Bangladesh',
    flag: '🇧🇩',
    code: 'BD',
    dial_code: '+880',
    he: 'בנגלדש'
  },
  {
    name: 'Barbados',
    label: 'Barbados',
    flag: '🇧🇧',
    code: 'BB',
    dial_code: '+1246',
    he: 'ברבדוס'
  },
  {
    name: 'Belarus',
    label: 'Belarus',
    flag: '🇧🇾',
    code: 'BY',
    dial_code: '+375',
    he: 'בלארוס'
  },
  {
    name: 'Belgium',
    label: 'Belgium',
    flag: '🇧🇪',
    code: 'BE',
    dial_code: '+32',
    he: 'בלגיה'
  },
  {
    name: 'Belize',
    label: 'Belize',
    flag: '🇧🇿',
    code: 'BZ',
    dial_code: '+501',
    he: 'בליז'
  },
  {
    name: 'Benin',
    label: 'Benin',
    flag: '🇧🇯',
    code: 'BJ',
    dial_code: '+229',
    he: 'בנין'
  },
  {
    name: 'Bermuda',
    label: 'Bermuda',
    flag: '🇧🇲',
    code: 'BM',
    dial_code: '+1441',
    he: 'ברמודה'
  },
  {
    name: 'Bhutan',
    label: 'Bhutan',
    flag: '🇧🇹',
    code: 'BT',
    dial_code: '+975',
    he: 'בהוטן'
  },
  {
    name: 'Bolivia, Plurinational State of bolivia',
    label: 'Bolivia, Plurinational State of bolivia',
    flag: '🇧🇴',
    code: 'BO',
    dial_code: '+591',
    he: 'בוליביה'
  },
  {
    name: 'Bosnia and Herzegovina',
    label: 'Bosnia and Herzegovina',
    flag: '🇧🇦',
    code: 'BA',
    dial_code: '+387',
    he: 'בוסניה והרצגובינה'
  },
  {
    name: 'Botswana',
    label: 'Botswana',
    flag: '🇧🇼',
    code: 'BW',
    dial_code: '+267',
    he: 'בוטסואנה'
  },
  {
    name: 'Bouvet Island',
    label: 'Bouvet Island',
    flag: '🇧🇻',
    code: 'BV',
    dial_code: '+47',
    he: 'האי בובה'
  },
  {
    name: 'Brazil',
    label: 'Brazil',
    flag: '🇧🇷',
    code: 'BR',
    dial_code: '+55',
    he: 'ברזיל'
  },
  {
    name: 'British Indian Ocean Territory',
    label: 'British Indian Ocean Territory',
    flag: '🇮🇴',
    code: 'IO',
    dial_code: '+246',
    he: 'הודו'
  },
  {
    name: 'Brunei Darussalam',
    label: 'Brunei Darussalam',
    flag: '🇧🇳',
    code: 'BN',
    dial_code: '+673',
    he: 'ברוניי'
  },
  {
    name: 'Bulgaria',
    label: 'Bulgaria',
    flag: '🇧🇬',
    code: 'BG',
    dial_code: '+359',
    he: 'בולגריה'
  },
  {
    name: 'Burkina Faso',
    label: 'Burkina Faso',
    flag: '🇧🇫',
    code: 'BF',
    dial_code: '+226',
    he: 'בורקינה פסו'
  },
  {
    name: 'Burundi',
    label: 'Burundi',
    flag: '🇧🇮',
    code: 'BI',
    dial_code: '+257',
    he: 'בורונדי'
  },
  {
    name: 'Cambodia',
    label: 'Cambodia',
    flag: '🇰🇭',
    code: 'KH',
    dial_code: '+855',
    he: 'קמבודיה'
  },
  {
    name: 'Cameroon',
    label: 'Cameroon',
    flag: '🇨🇲',
    code: 'CM',
    dial_code: '+237',
    he: 'קמרון'
  },
  {
    name: 'Canada',
    label: 'Canada',
    flag: '🇨🇦',
    code: 'CA',
    dial_code: '+1',
    he: 'קנדה'
  },
  {
    name: 'Cape Verde',
    label: 'Cape Verde',
    flag: '🇨🇻',
    code: 'CV',
    dial_code: '+238',
    he: 'קייפ ורדה'
  },
  {
    name: 'Cayman Islands',
    label: 'Cayman Islands',
    flag: '🇰🇾',
    code: 'KY',
    dial_code: '+345',
    he: 'איי קיימן'
  },
  {
    name: 'Central African Republic',
    label: 'Central African Republic',
    flag: '🇨🇫',
    code: 'CF',
    dial_code: '+236',
    he: 'רפובליקה מרכז אפריקנית'
  },
  {
    name: 'Chad',
    label: 'Chad',
    flag: '🇹🇩',
    code: 'TD',
    dial_code: '+235',
    he: 'צ\'אד'
  },
  {
    name: 'Chile',
    label: 'Chile',
    flag: '🇨🇱',
    code: 'CL',
    dial_code: '+56',
    he: 'צ\'ילה'
  },
  {
    name: 'China',
    label: 'China',
    flag: '🇨🇳',
    code: 'CN',
    dial_code: '+86',
    he: 'סין'
  },
  {
    name: 'Christmas Island',
    label: 'Christmas Island',
    flag: '🇨🇽',
    code: 'CX',
    dial_code: '+61',
    he: 'אי חג המולד'
  },
  {
    name: 'Cocos (Keeling) Islands',
    label: 'Cocos (Keeling) Islands',
    flag: '🇨🇨',
    code: 'CC',
    dial_code: '+61',
    he: 'איי קוקוס'
  },
  {
    name: 'Colombia',
    label: 'Colombia',
    flag: '🇨🇴',
    code: 'CO',
    dial_code: '+57',
    he: 'קולומביה'
  },
  {
    name: 'Comoros',
    label: 'Comoros',
    flag: '🇰🇲',
    code: 'KM',
    dial_code: '+269',
    he: 'קומורוס'
  },
  {
    name: 'Congo',
    label: 'Congo',
    flag: '🇨🇬',
    code: 'CG',
    dial_code: '+242',
    he: 'קונגו'
  },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    label: 'Congo, The Democratic Republic of the Congo',
    flag: '🇨🇩',
    code: 'CD',
    dial_code: '+243',
    he: 'הרפובליקה הדמוקרטית של קונגו'
  },
  {
    name: 'Cook Islands',
    label: 'Cook Islands',
    flag: '🇨🇰',
    code: 'CK',
    dial_code: '+682',
    he: 'איי קוק'
  },
  {
    name: 'Costa Rica',
    label: 'Costa Rica',
    flag: '🇨🇷',
    code: 'CR',
    dial_code: '+506',
    he: 'קוסטה ריקה'
  },
  {
    name: 'Cote d\'Ivoire',
    label: 'Cote d\'Ivoire',
    flag: '🇨🇮',
    code: 'CI',
    dial_code: '+225',
    he: 'חוף השנהב (קוט דיוואר)'
  },
  {
    name: 'Croatia',
    label: 'Croatia',
    flag: '🇭🇷',
    code: 'HR',
    dial_code: '+385',
    he: 'קרואטיה'
  },
  {
    name: 'Cuba',
    label: 'Cuba',
    flag: '🇨🇺',
    code: 'CU',
    dial_code: '+53',
    he: 'קובה'
  },
  {
    name: 'Cyprus',
    label: 'Cyprus',
    flag: '🇨🇾',
    code: 'CY',
    dial_code: '+357',
    he: 'קפריסין'
  },
  {
    name: 'Czech Republic',
    label: 'Czech Republic',
    flag: '🇨🇿',
    code: 'CZ',
    dial_code: '+420',
    he: 'צ\'כיה'
  },
  {
    name: 'Denmark',
    label: 'Denmark',
    flag: '🇩🇰',
    code: 'DK',
    dial_code: '+45',
    he: 'דנמרק'
  },
  {
    name: 'Djibouti',
    label: 'Djibouti',
    flag: '🇩🇯',
    code: 'DJ',
    dial_code: '+253',
    he: 'ג׳יבוטי'
  },
  {
    name: 'Dominica',
    label: 'Dominica',
    flag: '🇩🇲',
    code: 'DM',
    dial_code: '+1767',
    he: 'דומיניקה'
  },
  {
    name: 'Dominican Republic',
    label: 'Dominican Republic',
    flag: '🇩🇴',
    code: 'DO',
    dial_code: '+1849',
    he: 'הרפובליקה הדומיניקנית'
  },
  {
    name: 'Ecuador',
    label: 'Ecuador',
    flag: '🇪🇨',
    code: 'EC',
    dial_code: '+593',
    he: 'אקוואדור'
  },
  {
    name: 'Egypt',
    label: 'Egypt',
    flag: '🇪🇬',
    code: 'EG',
    dial_code: '+20',
    he: 'מצרים'
  },
  {
    name: 'El Salvador',
    label: 'El Salvador',
    flag: '🇸🇻',
    code: 'SV',
    dial_code: '+503',
    he: 'אל סלבדור'
  },
  {
    name: 'Equatorial Guinea',
    label: 'Equatorial Guinea',
    flag: '🇬🇶',
    code: 'GQ',
    dial_code: '+240',
    he: 'גינאה המשוונית'
  },
  {
    name: 'Eritrea',
    label: 'Eritrea',
    flag: '🇪🇷',
    code: 'ER',
    dial_code: '+291',
    he: 'אריתריאה'
  },
  {
    name: 'Estonia',
    label: 'Estonia',
    flag: '🇪🇪',
    code: 'EE',
    dial_code: '+372',
    he: 'אסטוניה'
  },
  {
    name: 'Eswatini',
    label: 'Eswatini',
    flag: '🇸🇿',
    code: 'SZ',
    dial_code: '+268',
    he: 'אסוואטיני'
  },
  {
    name: 'Ethiopia',
    label: 'Ethiopia',
    flag: '🇪🇹',
    code: 'ET',
    dial_code: '+251',
    he: 'אתיופיה'
  },
  {
    name: 'Falkland Islands (Malvinas)',
    label: 'Falkland Islands (Malvinas)',
    flag: '🇫🇰',
    code: 'FK',
    dial_code: '+500',
    he: 'איי פוקלנד (מלווינס)'
  },
  {
    name: 'Faroe Islands',
    label: 'Faroe Islands',
    flag: '🇫🇴',
    code: 'FO',
    dial_code: '+298',
    he: 'איי פארו'
  },
  {
    name: 'Fiji',
    label: 'Fiji',
    flag: '🇫🇯',
    code: 'FJ',
    dial_code: '+679',
    he: 'פיג\'י'
  },
  {
    name: 'Finland',
    label: 'Finland',
    flag: '🇫🇮',
    code: 'FI',
    dial_code: '+358',
    he: 'פינלנד'
  },
  {
    name: 'France',
    label: 'France',
    flag: '🇫🇷',
    code: 'FR',
    dial_code: '+33',
    he: 'צרפת'
  },
  {
    name: 'French Guiana',
    label: 'French Guiana',
    flag: '🇬🇫',
    code: 'GF',
    dial_code: '+594',
    he: 'גיאנה הצרפתית'
  },
  {
    name: 'French Polynesia',
    label: 'French Polynesia',
    flag: '🇵🇫',
    code: 'PF',
    dial_code: '+689',
    he: 'פולינזיה הצרפתית'
  },
  {
    name: 'French Southern Territories',
    label: 'French Southern Territories',
    flag: '🇹🇫',
    code: 'TF',
    dial_code: '+262',
    he: 'הארצות הדרומיות של צרפת'
  },
  {
    name: 'Gabon',
    label: 'Gabon',
    flag: '🇬🇦',
    code: 'GA',
    dial_code: '+241',
    he: 'גאבון'
  },
  {
    name: 'Gambia',
    label: 'Gambia',
    flag: '🇬🇲',
    code: 'GM',
    dial_code: '+220',
    he: 'גמביה'
  },
  {
    name: 'Georgia',
    label: 'Georgia',
    flag: '🇬🇪',
    code: 'GE',
    dial_code: '+995',
    he: 'גיאורגיה'
  },
  {
    name: 'Germany',
    label: 'Germany',
    flag: '🇩🇪',
    code: 'DE',
    dial_code: '+49',
    he: 'גרמניה'
  },
  {
    name: 'Ghana',
    label: 'Ghana',
    flag: '🇬🇭',
    code: 'GH',
    dial_code: '+233',
    he: 'גאנה'
  },
  {
    name: 'Gibraltar',
    label: 'Gibraltar',
    flag: '🇬🇮',
    code: 'GI',
    dial_code: '+350',
    he: 'גיברלטר'
  },
  {
    name: 'Greece',
    label: 'Greece',
    flag: '🇬🇷',
    code: 'GR',
    dial_code: '+30',
    he: 'יוון'
  },
  {
    name: 'Greenland',
    label: 'Greenland',
    flag: '🇬🇱',
    code: 'GL',
    dial_code: '+299',
    he: 'גרינלנד'
  },
  {
    name: 'Grenada',
    label: 'Grenada',
    flag: '🇬🇩',
    code: 'GD',
    dial_code: '+1473',
    he: 'גרנדה'
  },
  {
    name: 'Guadeloupe',
    label: 'Guadeloupe',
    flag: '🇬🇵',
    code: 'GP',
    dial_code: '+590',
    he: 'גוואדלופ'
  },
  {
    name: 'Guam',
    label: 'Guam',
    flag: '🇬🇺',
    code: 'GU',
    dial_code: '+1671',
    he: 'גואם'
  },
  {
    name: 'Guatemala',
    label: 'Guatemala',
    flag: '🇬🇹',
    code: 'GT',
    dial_code: '+502',
    he: 'גואטמלה'
  },
  {
    name: 'Guernsey',
    label: 'Guernsey',
    flag: '🇬🇬',
    code: 'GG',
    dial_code: '+44',
    he: 'גרנזי'
  },
  {
    name: 'Guinea',
    label: 'Guinea',
    flag: '🇬🇳',
    code: 'GN',
    dial_code: '+224',
    he: 'גינאה'
  },
  {
    name: 'Guinea-Bissau',
    label: 'Guinea-Bissau',
    flag: '🇬🇼',
    code: 'GW',
    dial_code: '+245',
    he: 'גינאה-ביסאו'
  },
  {
    name: 'Guyana',
    label: 'Guyana',
    flag: '🇬🇾',
    code: 'GY',
    dial_code: '+592',
    he: 'גויאנה'
  },
  {
    name: 'Haiti',
    label: 'Haiti',
    flag: '🇭🇹',
    code: 'HT',
    dial_code: '+509',
    he: 'האיטי'
  },
  {
    name: 'Heard Island and Mcdonald Islands',
    label: 'Heard Island and Mcdonald Islands',
    flag: '🇭🇲',
    code: 'HM',
    dial_code: '+672',
    he: 'האי הרד ואיי מקדונלד'
  },
  {
    name: 'Holy See (Vatican City State)',
    label: 'Holy See (Vatican City State)',
    flag: '🇻🇦',
    code: 'VA',
    dial_code: '+379',
    he: 'ותיקן'
  },
  {
    name: 'Honduras',
    label: 'Honduras',
    flag: '🇭🇳',
    code: 'HN',
    dial_code: '+504',
    he: 'הונדורס'
  },
  {
    name: 'Hong Kong',
    label: 'Hong Kong',
    flag: '🇭🇰',
    code: 'HK',
    dial_code: '+852',
    he: 'הונג קונג'
  },
  {
    name: 'Hungary',
    label: 'Hungary',
    flag: '🇭🇺',
    code: 'HU',
    dial_code: '+36',
    he: 'הונגריה'
  },
  {
    name: 'Iceland',
    label: 'Iceland',
    flag: '🇮🇸',
    code: 'IS',
    dial_code: '+354',
    he: 'איסלנד'
  },
  {
    name: 'India',
    label: 'India',
    flag: '🇮🇳',
    code: 'IN',
    dial_code: '+91',
    he: 'הודו'
  },
  {
    name: 'Indonesia',
    label: 'Indonesia',
    flag: '🇮🇩',
    code: 'ID',
    dial_code: '+62',
    he: 'אינדונזיה'
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    label: 'Iran, Islamic Republic of Persian Gulf',
    flag: '🇮🇷',
    code: 'IR',
    dial_code: '+98',
    he: 'איראן'
  },
  {
    name: 'Iraq',
    label: 'Iraq',
    flag: '🇮🇶',
    code: 'IQ',
    dial_code: '+964',
    he: 'עיראק'
  },
  {
    name: 'Ireland',
    label: 'Ireland',
    flag: '🇮🇪',
    code: 'IE',
    dial_code: '+353',
    he: 'אירלנד'
  },
  {
    name: 'Isle of Man',
    label: 'Isle of Man',
    flag: '🇮🇲',
    code: 'IM',
    dial_code: '+44',
    he: 'האי מאן'
  },
  {
    name: 'Israel',
    label: 'Israel',
    flag: '🇮🇱',
    code: 'IL',
    dial_code: '+972',
    he: 'ישראל'
  },
  {
    name: 'Italy',
    label: 'Italy',
    flag: '🇮🇹',
    code: 'IT',
    dial_code: '+39',
    he: 'איטליה'
  },
  {
    name: 'Jamaica',
    label: 'Jamaica',
    flag: '🇯🇲',
    code: 'JM',
    dial_code: '+1876',
    he: 'ג\'מאיקה'
  },
  {
    name: 'Japan',
    label: 'Japan',
    flag: '🇯🇵',
    code: 'JP',
    dial_code: '+81',
    he: 'יפן'
  },
  {
    name: 'Jersey',
    label: 'Jersey',
    flag: '🇯🇪',
    code: 'JE',
    dial_code: '+44',
    he: 'ג׳רזי'
  },
  {
    name: 'Jordan',
    label: 'Jordan',
    flag: '🇯🇴',
    code: 'JO',
    dial_code: '+962',
    he: 'ירדן'
  },
  {
    name: 'Kazakhstan',
    label: 'Kazakhstan',
    flag: '🇰🇿',
    code: 'KZ',
    dial_code: '+7',
    he: 'קזחסטאן'
  },
  {
    name: 'Kenya',
    label: 'Kenya',
    flag: '🇰🇪',
    code: 'KE',
    dial_code: '+254',
    he: 'קניה'
  },
  {
    name: 'Kiribati',
    label: 'Kiribati',
    flag: '🇰🇮',
    code: 'KI',
    dial_code: '+686',
    he: 'קיריבטי'
  },
  {
    name: 'Korea, Democratic People\'s Republic of Korea',
    label: 'Korea, Democratic People\'s Republic of Korea',
    flag: '🇰🇵',
    code: 'KP',
    dial_code: '+850',
    he: 'צפון קוריאה'
  },
  {
    name: 'Korea, Republic of South Korea',
    label: 'Korea, Republic of South Korea',
    flag: '🇰🇷',
    code: 'KR',
    dial_code: '+82',
    he: 'דרום קוריאה'
  },
  {
    name: 'Kosovo',
    label: 'Kosovo',
    flag: '🇽🇰',
    code: 'XK',
    dial_code: '+383',
    he: 'קוסובו'
  },
  {
    name: 'Kuwait',
    label: 'Kuwait',
    flag: '🇰🇼',
    code: 'KW',
    dial_code: '+965',
    he: 'כווית'
  },
  {
    name: 'Kyrgyzstan',
    label: 'Kyrgyzstan',
    flag: '🇰🇬',
    code: 'KG',
    dial_code: '+996',
    he: 'קירגיסטאן'
  },
  {
    name: 'Laos',
    label: 'Laos',
    flag: '🇱🇦',
    code: 'LA',
    dial_code: '+856',
    he: 'לאוס, רפובליקה דמוקרטית עממית'
  },
  {
    name: 'Latvia',
    label: 'Latvia',
    flag: '🇱🇻',
    code: 'LV',
    dial_code: '+371',
    he: 'לטביה'
  },
  {
    name: 'Lebanon',
    label: 'Lebanon',
    flag: '🇱🇧',
    code: 'LB',
    dial_code: '+961',
    he: 'לבנון'
  },
  {
    name: 'Lesotho',
    label: 'Lesotho',
    flag: '🇱🇸',
    code: 'LS',
    dial_code: '+266',
    he: 'לסוטו'
  },
  {
    name: 'Liberia',
    label: 'Liberia',
    flag: '🇱🇷',
    code: 'LR',
    dial_code: '+231',
    he: 'ליבריה'
  },
  {
    name: 'Libya',
    label: 'Libya',
    flag: '🇱🇾',
    code: 'LY',
    dial_code: '+218',
    he: 'לוב'
  },
  {
    name: 'Liechtenstein',
    label: 'Liechtenstein',
    flag: '🇱🇮',
    code: 'LI',
    dial_code: '+423',
    he: 'ליכטנשטיין'
  },
  {
    name: 'Lithuania',
    label: 'Lithuania',
    flag: '🇱🇹',
    code: 'LT',
    dial_code: '+370',
    he: 'ליטא'
  },
  {
    name: 'Luxembourg',
    label: 'Luxembourg',
    flag: '🇱🇺',
    code: 'LU',
    dial_code: '+352',
    he: 'לוקסמבורג'
  },
  {
    name: 'Macao',
    label: 'Macao',
    flag: '🇲🇴',
    code: 'MO',
    dial_code: '+853',
    he: 'מקאו'
  },
  {
    name: 'Macedonia',
    label: 'Macedonia',
    flag: '🇲🇰',
    code: 'MK',
    dial_code: '+389',
    he: 'מקדוניה'
  },
  {
    name: 'Madagascar',
    label: 'Madagascar',
    flag: '🇲🇬',
    code: 'MG',
    dial_code: '+261',
    he: 'מדגסקר'
  },
  {
    name: 'Malawi',
    label: 'Malawi',
    flag: '🇲🇼',
    code: 'MW',
    dial_code: '+265',
    he: 'מלאווי'
  },
  {
    name: 'Malaysia',
    label: 'Malaysia',
    flag: '🇲🇾',
    code: 'MY',
    dial_code: '+60',
    he: 'מלזיה'
  },
  {
    name: 'Maldives',
    label: 'Maldives',
    flag: '🇲🇻',
    code: 'MV',
    dial_code: '+960',
    he: 'מלדיביים'
  },
  {
    name: 'Mali',
    label: 'Mali',
    flag: '🇲🇱',
    code: 'ML',
    dial_code: '+223',
    he: 'מאלי'
  },
  {
    name: 'Malta',
    label: 'Malta',
    flag: '🇲🇹',
    code: 'MT',
    dial_code: '+356',
    he: 'מלטה'
  },
  {
    name: 'Marshall Islands',
    label: 'Marshall Islands',
    flag: '🇲🇭',
    code: 'MH',
    dial_code: '+692',
    he: 'איי מרשל'
  },
  {
    name: 'Martinique',
    label: 'Martinique',
    flag: '🇲🇶',
    code: 'MQ',
    dial_code: '+596',
    he: 'מרטיניק'
  },
  {
    name: 'Mauritania',
    label: 'Mauritania',
    flag: '🇲🇷',
    code: 'MR',
    dial_code: '+222',
    he: 'מאוריטניה'
  },
  {
    name: 'Mauritius',
    label: 'Mauritius',
    flag: '🇲🇺',
    code: 'MU',
    dial_code: '+230',
    he: 'מאוריציוס'
  },
  {
    name: 'Mayotte',
    label: 'Mayotte',
    flag: '🇾🇹',
    code: 'YT',
    dial_code: '+262',
    he: 'מיוט'
  },
  {
    name: 'Mexico',
    label: 'Mexico',
    flag: '🇲🇽',
    code: 'MX',
    dial_code: '+52',
    he: 'מקסיקו'
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    label: 'Micronesia, Federated States of Micronesia',
    flag: '🇫🇲',
    code: 'FM',
    dial_code: '+691',
    he: 'מיקרונזיה'
  },
  {
    name: 'Moldova',
    label: 'Moldova',
    flag: '🇲🇩',
    code: 'MD',
    dial_code: '+373',
    he: 'מולדובה'
  },
  {
    name: 'Monaco',
    label: 'Monaco',
    flag: '🇲🇨',
    code: 'MC',
    dial_code: '+377',
    he: 'מונקו'
  },
  {
    name: 'Mongolia',
    label: 'Mongolia',
    flag: '🇲🇳',
    code: 'MN',
    dial_code: '+976',
    he: 'מונגוליה'
  },
  {
    name: 'Montenegro',
    label: 'Montenegro',
    flag: '🇲🇪',
    code: 'ME',
    dial_code: '+382',
    he: 'מונטנגרו'
  },
  {
    name: 'Montserrat',
    label: 'Montserrat',
    flag: '🇲🇸',
    code: 'MS',
    dial_code: '+1664',
    he: 'מונטסראט'
  },
  {
    name: 'Morocco',
    label: 'Morocco',
    flag: '🇲🇦',
    code: 'MA',
    dial_code: '+212',
    he: 'מרוקו'
  },
  {
    name: 'Mozambique',
    label: 'Mozambique',
    flag: '🇲🇿',
    code: 'MZ',
    dial_code: '+258',
    he: 'מוזמביק'
  },
  {
    name: 'Myanmar',
    label: 'Myanmar',
    flag: '🇲🇲',
    code: 'MM',
    dial_code: '+95',
    he: 'מיאנמר'
  },
  {
    name: 'Namibia',
    label: 'Namibia',
    flag: '🇳🇦',
    code: 'NA',
    dial_code: '+264',
    he: 'נמיביה'
  },
  {
    name: 'Nauru',
    label: 'Nauru',
    flag: '🇳🇷',
    code: 'NR',
    dial_code: '+674',
    he: 'נאורו'
  },
  {
    name: 'Nepal',
    label: 'Nepal',
    flag: '🇳🇵',
    code: 'NP',
    dial_code: '+977',
    he: 'נפאל'
  },
  {
    name: 'Netherlands',
    label: 'Netherlands',
    flag: '🇳🇱',
    code: 'NL',
    dial_code: '+31',
    he: 'הולנד'
  },
  {
    name: 'Netherlands Antilles',
    label: 'Netherlands Antilles',
    flag: '',
    code: 'AN',
    dial_code: '+599',
    he: 'האנטילים ההולנדיים'
  },
  {
    name: 'New Caledonia',
    label: 'New Caledonia',
    flag: '🇳🇨',
    code: 'NC',
    dial_code: '+687',
    he: 'קלדוניה החדשה'
  },
  {
    name: 'New Zealand',
    label: 'New Zealand',
    flag: '🇳🇿',
    code: 'NZ',
    dial_code: '+64',
    he: 'ניו זילנד'
  },
  {
    name: 'Nicaragua',
    label: 'Nicaragua',
    flag: '🇳🇮',
    code: 'NI',
    dial_code: '+505',
    he: 'ניקרגואה'
  },
  {
    name: 'Niger',
    label: 'Niger',
    flag: '🇳🇪',
    code: 'NE',
    dial_code: '+227',
    he: 'ניז\'ר'
  },
  {
    name: 'Nigeria',
    label: 'Nigeria',
    flag: '🇳🇬',
    code: 'NG',
    dial_code: '+234',
    he: 'ניגריה'
  },
  {
    name: 'Niue',
    label: 'Niue',
    flag: '🇳🇺',
    code: 'NU',
    dial_code: '+683',
    he: 'ניואה'
  },
  {
    name: 'Norfolk Island',
    label: 'Norfolk Island',
    flag: '🇳🇫',
    code: 'NF',
    dial_code: '+672',
    he: 'אי נורפולק'
  },
  {
    name: 'Northern Mariana Islands',
    label: 'Northern Mariana Islands',
    flag: '🇲🇵',
    code: 'MP',
    dial_code: '+1670',
    he: 'איי צפון מריאנה'
  },
  {
    name: 'Norway',
    label: 'Norway',
    flag: '🇳🇴',
    code: 'NO',
    dial_code: '+47',
    he: 'נורבגיה'
  },
  {
    name: 'Oman',
    label: 'Oman',
    flag: '🇴🇲',
    code: 'OM',
    dial_code: '+968',
    he: 'עומאן'
  },
  {
    name: 'Pakistan',
    label: 'Pakistan',
    flag: '🇵🇰',
    code: 'PK',
    dial_code: '+92',
    he: 'פקיסטאן'
  },
  {
    name: 'Palau',
    label: 'Palau',
    flag: '🇵🇼',
    code: 'PW',
    dial_code: '+680',
    he: 'פלאו'
  },
  {
    name: 'Palestinian Territory, Occupied',
    label: 'Palestinian Territory, Occupied',
    flag: '🇵🇸',
    code: 'PS',
    dial_code: '+970',
    he: 'שטים פלסטיניים'
  },
  {
    name: 'Panama',
    label: 'Panama',
    flag: '🇵🇦',
    code: 'PA',
    dial_code: '+507',
    he: 'פנמה'
  },
  {
    name: 'Papua New Guinea',
    label: 'Papua New Guinea',
    flag: '🇵🇬',
    code: 'PG',
    dial_code: '+675',
    he: 'פפואה ניו גיני'
  },
  {
    name: 'Paraguay',
    label: 'Paraguay',
    flag: '🇵🇾',
    code: 'PY',
    dial_code: '+595',
    he: 'פרגוואי'
  },
  {
    name: 'Peru',
    label: 'Peru',
    flag: '🇵🇪',
    code: 'PE',
    dial_code: '+51',
    he: 'פרו'
  },
  {
    name: 'Philippines',
    label: 'Philippines',
    flag: '🇵🇭',
    code: 'PH',
    dial_code: '+63',
    he: 'פיליפינים'
  },
  {
    name: 'Pitcairn',
    label: 'Pitcairn',
    flag: '🇵🇳',
    code: 'PN',
    dial_code: '+64',
    he: 'פיטקיירן'
  },
  {
    name: 'Poland',
    label: 'Poland',
    flag: '🇵🇱',
    code: 'PL',
    dial_code: '+48',
    he: 'פולין'
  },
  {
    name: 'Portugal',
    label: 'Portugal',
    flag: '🇵🇹',
    code: 'PT',
    dial_code: '+351',
    he: 'פורטוגל'
  },
  {
    name: 'Puerto Rico',
    label: 'Puerto Rico',
    flag: '🇵🇷',
    code: 'PR',
    dial_code: '+1939',
    he: 'פורטו ריקו'
  },
  {
    name: 'Qatar',
    label: 'Qatar',
    flag: '🇶🇦',
    code: 'QA',
    dial_code: '+974',
    he: 'קטר'
  },
  {
    name: 'Romania',
    label: 'Romania',
    flag: '🇷🇴',
    code: 'RO',
    dial_code: '+40',
    he: 'רומניה'
  },
  {
    name: 'Russia',
    label: 'Russia',
    flag: '🇷🇺',
    code: 'RU',
    dial_code: '+7',
    he: 'רוסיה'
  },
  {
    name: 'Rwanda',
    label: 'Rwanda',
    flag: '🇷🇼',
    code: 'RW',
    dial_code: '+250',
    he: 'רואנדה'
  },
  {
    name: 'Reunion',
    label: 'Reunion',
    flag: '🇷🇪',
    code: 'RE',
    dial_code: '+262',
    he: 'ראוניון'
  },
  {
    name: 'Saint Barthelemy',
    label: 'Saint Barthelemy',
    flag: '🇧🇱',
    code: 'BL',
    dial_code: '+590',
    he: 'סן ברתלמי'
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    label: 'Saint Helena, Ascension and Tristan Da Cunha',
    flag: '🇸🇭',
    code: 'SH',
    dial_code: '+290',
    he: 'סנט הלנה'
  },
  {
    name: 'Saint Kitts and Nevis',
    label: 'Saint Kitts and Nevis',
    flag: '🇰🇳',
    code: 'KN',
    dial_code: '+1869',
    he: 'סנט קיטס ונוויס'
  },
  {
    name: 'Saint Lucia',
    label: 'Saint Lucia',
    flag: '🇱🇨',
    code: 'LC',
    dial_code: '+1758',
    he: 'סנט לוסיה'
  },
  {
    name: 'Saint Martin',
    label: 'Saint Martin',
    flag: '🇲🇫',
    code: 'MF',
    dial_code: '+590',
    he: 'סנט מרטין'
  },
  {
    name: 'Saint Pierre and Miquelon',
    label: 'Saint Pierre and Miquelon',
    flag: '🇵🇲',
    code: 'PM',
    dial_code: '+508',
    he: 'סנט פייר ומיקלון'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    label: 'Saint Vincent and the Grenadines',
    flag: '🇻🇨',
    code: 'VC',
    dial_code: '+1784',
    he: 'סנט וינסנט והגרנדינים'
  },
  {
    name: 'Samoa',
    label: 'Samoa',
    flag: '🇼🇸',
    code: 'WS',
    dial_code: '+685',
    he: 'סמואה'
  },
  {
    name: 'San Marino',
    label: 'San Marino',
    flag: '🇸🇲',
    code: 'SM',
    dial_code: '+378',
    he: 'סן מרינו'
  },
  {
    name: 'Sao Tome and Principe',
    label: 'Sao Tome and Principe',
    flag: '🇸🇹',
    code: 'ST',
    dial_code: '+239',
    he: 'סאו תומה ופרינציפה'
  },
  {
    name: 'Saudi Arabia',
    label: 'Saudi Arabia',
    flag: '🇸🇦',
    code: 'SA',
    dial_code: '+966',
    he: 'ערב הסעודית'
  },
  {
    name: 'Senegal',
    label: 'Senegal',
    flag: '🇸🇳',
    code: 'SN',
    dial_code: '+221',
    he: 'סנגל'
  },
  {
    name: 'Serbia',
    label: 'Serbia',
    flag: '🇷🇸',
    code: 'RS',
    dial_code: '+381',
    he: 'סרביה'
  },
  {
    name: 'Seychelles',
    label: 'Seychelles',
    flag: '🇸🇨',
    code: 'SC',
    dial_code: '+248',
    he: 'איי סיישל'
  },
  {
    name: 'Sierra Leone',
    label: 'Sierra Leone',
    flag: '🇸🇱',
    code: 'SL',
    dial_code: '+232',
    he: 'סיירה ליאונה'
  },
  {
    name: 'Singapore',
    label: 'Singapore',
    flag: '🇸🇬',
    code: 'SG',
    dial_code: '+65',
    he: 'סינגפור'
  },
  {
    name: 'Slovakia',
    label: 'Slovakia',
    flag: '🇸🇰',
    code: 'SK',
    dial_code: '+421',
    he: 'סלובקיה'
  },
  {
    name: 'Slovenia',
    label: 'Slovenia',
    flag: '🇸🇮',
    code: 'SI',
    dial_code: '+386',
    he: 'סלובניה'
  },
  {
    name: 'Solomon Islands',
    label: 'Solomon Islands',
    flag: '🇸🇧',
    code: 'SB',
    dial_code: '+677',
    he: 'איי שלמה'
  },
  {
    name: 'Somalia',
    label: 'Somalia',
    flag: '🇸🇴',
    code: 'SO',
    dial_code: '+252',
    he: 'סומליה'
  },
  {
    name: 'South Africa',
    label: 'South Africa',
    flag: '🇿🇦',
    code: 'ZA',
    dial_code: '+27',
    he: 'דרום אפריקה'
  },
  {
    name: 'South Sudan',
    label: 'South Sudan',
    flag: '🇸🇸',
    code: 'SS',
    dial_code: '+211',
    he: 'דרום סודאן'
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    label: 'South Georgia and the South Sandwich Islands',
    flag: '🇬🇸',
    code: 'GS',
    dial_code: '+500',
    he: 'איי ג׳ורג׳יה הדרומית ואיי סנדוויץ׳ הדרומיים'
  },
  {
    name: 'Spain',
    label: 'Spain',
    flag: '🇪🇸',
    code: 'ES',
    dial_code: '+34',
    he: 'ספרד'
  },
  {
    name: 'Sri Lanka',
    label: 'Sri Lanka',
    flag: '🇱🇰',
    code: 'LK',
    dial_code: '+94',
    he: 'סרי לנקה'
  },
  {
    name: 'Sudan',
    label: 'Sudan',
    flag: '🇸🇩',
    code: 'SD',
    dial_code: '+249',
    he: 'סודאן'
  },
  {
    name: 'Suriname',
    label: 'Surilabel',
    flag: '🇸🇷',
    code: 'SR',
    dial_code: '+597',
    he: 'סורינם'
  },
  {
    name: 'Svalbard and Jan Mayen',
    label: 'Svalbard and Jan Mayen',
    flag: '🇸🇯',
    code: 'SJ',
    dial_code: '+47',
    he: 'סוואלברד'
  },
  {
    name: 'Eswatini',
    label: 'Eswatini',
    flag: '🇸🇿',
    code: 'SZ',
    dial_code: '+268',
    he: 'אסוואטיני'
  },
  {
    name: 'Sweden',
    label: 'Sweden',
    flag: '🇸🇪',
    code: 'SE',
    dial_code: '+46',
    he: 'שבדיה'
  },
  {
    name: 'Switzerland',
    label: 'Switzerland',
    flag: '🇨🇭',
    code: 'CH',
    dial_code: '+41',
    he: 'שווייץ'
  },
  {
    name: 'Syrian Arab Republic',
    label: 'Syrian Arab Republic',
    flag: '🇸🇾',
    code: 'SY',
    dial_code: '+963',
    he: 'סוריה'
  },
  {
    name: 'Taiwan',
    label: 'Taiwan',
    flag: '🇹🇼',
    code: 'TW',
    dial_code: '+886',
    he: 'טייואן'
  },
  {
    name: 'Tajikistan',
    label: 'Tajikistan',
    flag: '🇹🇯',
    code: 'TJ',
    dial_code: '+992',
    he: 'טג\'קיסטאן'
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    label: 'Tanzania, United Republic of Tanzania',
    flag: '🇹🇿',
    code: 'TZ',
    dial_code: '+255',
    he: 'טנזניה'
  },
  {
    name: 'Thailand',
    label: 'Thailand',
    flag: '🇹🇭',
    code: 'TH',
    dial_code: '+66',
    he: 'תאילנד'
  },
  {
    name: 'Timor-Leste',
    label: 'Timor-Leste',
    flag: '🇹🇱',
    code: 'TL',
    dial_code: '+670',
    he: 'מזרח טימור'
  },
  {
    name: 'Togo',
    label: 'Togo',
    flag: '🇹🇬',
    code: 'TG',
    dial_code: '+228',
    he: 'טוגו'
  },
  {
    name: 'Tokelau',
    label: 'Tokelau',
    flag: '🇹🇰',
    code: 'TK',
    dial_code: '+690',
    he: 'טוקלאו'
  },
  {
    name: 'Tonga',
    label: 'Tonga',
    flag: '🇹🇴',
    code: 'TO',
    dial_code: '+676',
    he: 'טונגה'
  },
  {
    name: 'Trinidad and Tobago',
    label: 'Trinidad and Tobago',
    flag: '🇹🇹',
    code: 'TT',
    dial_code: '+1868',
    he: 'טרינידד וטובגו'
  },
  {
    name: 'Tunisia',
    label: 'Tunisia',
    flag: '🇹🇳',
    code: 'TN',
    dial_code: '+216',
    he: 'תוניסיה'
  },
  {
    name: 'Turkey',
    label: 'Turkey',
    flag: '🇹🇷',
    code: 'TR',
    dial_code: '+90',
    he: 'תורכיה'
  },
  {
    name: 'Turkmenistan',
    label: 'Turkmenistan',
    flag: '🇹🇲',
    code: 'TM',
    dial_code: '+993',
    he: 'תורכמניסטאן'
  },
  {
    name: 'Turks and Caicos Islands',
    label: 'Turks and Caicos Islands',
    flag: '🇹🇨',
    code: 'TC',
    dial_code: '+1649',
    he: 'איי טורקס וקאיקוס'
  },
  {
    name: 'Tuvalu',
    label: 'Tuvalu',
    flag: '🇹🇻',
    code: 'TV',
    dial_code: '+688',
    he: 'טובלו'
  },
  {
    name: 'Uganda',
    label: 'Uganda',
    flag: '🇺🇬',
    code: 'UG',
    dial_code: '+256',
    he: 'אוגנדה'
  },
  {
    name: 'Ukraine',
    label: 'Ukraine',
    flag: '🇺🇦',
    code: 'UA',
    dial_code: '+380',
    he: 'אוקראינה'
  },
  {
    name: 'United Arab Emirates',
    label: 'United Arab Emirates',
    flag: '🇦🇪',
    code: 'AE',
    dial_code: '+971',
    he: 'אמירויות ערביות מאוחדות'
  },
  {
    name: 'United Kingdom',
    label: 'United Kingdom',
    flag: '🇬🇧',
    code: 'GB',
    dial_code: '+44',
    he: 'בריטניה'
  },
  {
    name: 'United States',
    label: 'United States',
    flag: '🇺🇸',
    code: 'US',
    dial_code: '+1',
    he: 'ארצות הברית'
  },
  {
    name: 'Uruguay',
    label: 'Uruguay',
    flag: '🇺🇾',
    code: 'UY',
    dial_code: '+598',
    he: 'אורוגואי'
  },
  {
    name: 'Uzbekistan',
    label: 'Uzbekistan',
    flag: '🇺🇿',
    code: 'UZ',
    dial_code: '+998',
    he: 'אוזבקיסטאן'
  },
  {
    name: 'Vanuatu',
    label: 'Vanuatu',
    flag: '🇻🇺',
    code: 'VU',
    dial_code: '+678',
    he: 'ונואטו'
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    label: 'Venezuela, Bolivarian Republic of Venezuela',
    flag: '🇻🇪',
    code: 'VE',
    dial_code: '+58',
    he: 'ונצואלה'
  },
  {
    name: 'Vietnam',
    label: 'Vietnam',
    flag: '🇻🇳',
    code: 'VN',
    dial_code: '+84',
    he: 'וויטנאם'
  },
  {
    name: 'Virgin Islands, British',
    label: 'Virgin Islands, British',
    flag: '🇻🇬',
    code: 'VG',
    dial_code: '+1284',
    he: 'איי הבתולה הבריטיים'
  },
  {
    name: 'Virgin Islands, U.S.',
    label: 'Virgin Islands, U.S.',
    flag: '🇻🇮',
    code: 'VI',
    dial_code: '+1340',
    he: 'איי הבתולה של ארה״ב'
  },
  {
    name: 'Wallis and Futuna',
    label: 'Wallis and Futuna',
    flag: '🇼🇫',
    code: 'WF',
    dial_code: '+681',
    he: 'ואליס ופוטונה'
  },
  {
    name: 'Yemen',
    label: 'Yemen',
    flag: '🇾🇪',
    code: 'YE',
    dial_code: '+967',
    he: 'תימן'
  },
  {
    name: 'Zambia',
    label: 'Zambia',
    flag: '🇿🇲',
    code: 'ZM',
    dial_code: '+260',
    he: 'זמביה'
  },
  {
    name: 'Zimbabwe',
    label: 'Zimbabwe',
    flag: '🇿🇼',
    code: 'ZW',
    dial_code: '+263',
    he: 'זימבאבווה'
  }
];
