import { ReactChild, KeyboardEvent, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { BaseButton } from '~/shared/components/Button';
import { StandardSpinner } from '~/shared/components/Spinner';
import { Shelf } from '~/shared/components/styled';

const ButtonBox = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-flow: column-reverse;
  height: 60px;
`;

const ButtonInsideBox = styled.div`
  display: grid;
  grid-template-columns: 122px 1fr 122px;
  grid-template-areas: "prev x next";

  .language-he & {
    direction: rtl;
  }
`;

const MiddlePart = styled( Shelf )`
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`;
export const preventEnter = ( event: KeyboardEvent ) => {

  if (event.key === 'Enter') {

    event.preventDefault();
    // E.stopPropagation()

  }

};
export const Input = styled.input`
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  border: unset;
  border-bottom: 2px solid #D8D8D8;
  width: 100%;
  line-height: 54px;
  height: 50px;
  transition: .2s;

  :hover {
    border-color: #b3b3b3;
  }

  :focus {
    background: #eee;
    outline: unset;
    padding: 0 10px;
    border-bottom-color: ${( { theme } ) => theme.colors.primary};
    border-bottom-width: 4px;
  }

  .language-he & {
    direction: rtl;
  }
`;

export const Button = styled( BaseButton )`
  white-space: pre;

  ::first-letter {
    text-transform: uppercase;
  }

  &[disabled] {
    -webkit-filter: none;
    filter: none;
    background-color: rgba(93, 121, 223, 0.3);
  }
  
  :not(&[disabled]) {
    color: #ffffff;
    background-color: #5D79DF;
    :hover,
    :focus {
      background-color: #4153C6;
    }
  }
`;
const NextButton = styled( Button )`
  grid-area: next;

  &[disabled] {
    background: #c7cbd5;
    cursor: pointer;
  }
`;
const PrevButton = styled( Button )`
  background: white;
  grid-area: prev;
  width: fit-content;
  color: ${( { theme } ) => theme.colors.textMedium};
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    white-space: nowrap;
  }
`;
const RightArrow = styled.span.attrs( () => ( { children: '➜' } ) )`
  display: inline-block;

  .language-he & {
    transform: scaleX(-1);
  }
`;
const LeftArrow = styled( RightArrow )`
  transform: scaleX(-1);

  .language-he & {
    transform: unset;
  }
`;

type ButtonsProps = {
  next?: React.MouseEventHandler<HTMLButtonElement>;
  prev? (): void;
  nextActive: boolean;
  prevActive: boolean;
  isSubmit?: boolean;
  children?: ReactChild;
  style?: CSSProperties;
  isLoading?: boolean;
};

export const Buttons = ( {
  next,
  prev,
  nextActive,
  prevActive,
  isSubmit: submit,
  children,
  style,
  isLoading
}: ButtonsProps ) => {

  const { t } = useTranslation();
  return <ButtonBox
    style={style}
  >
    <ButtonInsideBox>
      {prev
        && <PrevButton
          onClick={prev}
          type="button"
          disabled={!prevActive}
        >
          <LeftArrow/>
          {`      ${t( 'Actions.back' )}`}
        </PrevButton>
      }
      <MiddlePart
        style={{gridArea: 'x' }}>
        {children}
      </MiddlePart>
      <NextButton
        onClick={next}
        type={submit ? 'submit' : 'button'}
        disabled={!nextActive}
      >
        {t( `Actions.${submit ? 'finish' : 'next'}` )}
        {'    '}
        {isLoading
          ? <StandardSpinner size={20}/>
          : <RightArrow/>}
      </NextButton>
    </ButtonInsideBox>
  </ButtonBox>;

};
