import { useState, useMemo } from 'react';

import { useMyUserQuery } from './useMyUserQuery';

import { isHub, isUser } from '~/chat/utils';
import { ChatUser } from '~/chat/types';
import { Hub } from '~/shared/services/api';

const normalizeString = ( str: string ) => str.trim().toLowerCase();

const isStringIncludesSearch = ( str: string, search: string ) => normalizeString( str ).includes( search );

const isMe = ( item: ChatUser | Hub, myId?: number ) => isUser( item ) && item.network_id === myId;

const isSearchedItem = ( item: ChatUser | Hub, search: string ) => {

  const name = isUser( item )
    ? item.full_name
    : item.chat_group.group_title;
  const isSearchedRole =
    isUser( item ) && item.current_role && isStringIncludesSearch(
      normalizeString( item.current_role ),
      search
    );
  const isSearchedName = isUser( item ) && isStringIncludesSearch(
    normalizeString( name ),
    search
  );
  const isSearchedMember =
    isHub( item ) &&
    !!item.chat_group.members.find( ( { full_name } ) => isStringIncludesSearch(
      normalizeString( full_name ),
      search
    ) );
  const isSearchGroup = isHub( item ) && isStringIncludesSearch(
    normalizeString( item.chat_group.group_title ),
    search
  );
  return isSearchedRole || isSearchedName || isSearchedMember || isSearchGroup;

};

function useChatListFiltering<T extends ChatUser | Hub> (
  chats: T[]
): {
  filter: string;
  setFilter: ( value: string ) => void;
  filteredChats: T[];
} {

  const { user } = useMyUserQuery();
  const [ filter, setFilter ] = useState( '' );
  const normalizedFilter = normalizeString( filter );
  const filteredItems = useMemo(
    () => ( normalizedFilter.length
      ? chats.filter( ( item ) => (
        !isMe(
          item,
          user?.network_id
        ) && isSearchedItem(
          item,
          normalizedFilter
        )
      ) )
      : chats.filter( ( item ) => !isMe(
        item,
        user?.network_id
      ) ) ),
    [
      chats,
      normalizedFilter,
      user
    ]
  );
  return {
    setFilter,
    filteredChats: filteredItems,
    filter,
  };

}

export default useChatListFiltering;
