export { default as ibm } from './ibm_color.webp';
export { default as allianz } from './allianz_color.webp';
export { default as cisco } from './cisco_color.webp';
export { default as siemens } from './siemens_color.webp';
export { default as atos } from './atos.png';
export { default as portland } from './portland.png';
export { default as cotPng } from './thumb.png';
export { default as aes } from './aes.webp';

export { ReactComponent as cot } from './cot.svg';
export { ReactComponent as metafinanceSvg } from './metafinanz_color.svg';
export { LinkedinSvg as Linkedin } from '../../../powerupLanding/images/index';
export { ReactComponent as munich } from './munich-security-conference.svg';
export { ReactComponent as mhi } from './MHI.svg';
