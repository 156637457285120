import styled from 'styled-components';
import Typed from 'react-typed';

import 'react-typed/dist/animatedCursor.css';

import {
  Section as _Section,
  Column,
  Content as _Content,
  ButtonHolder,
  EmptyButton,
  SectionTitle
} from './styled';
import {
  teamSvg, cooperationSvg, missionSvg, introBackground
} from '../images';
import { Link } from 'react-router-dom';
const Root = styled( Column )`
  padding-top: 150px;
  padding-bottom: 72px;
  background-color:white;
  background-repeat:no-repeat;
  background-size: 115% auto;
  background-position: bottom;
  background-image: url(${introBackground});
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    padding:0;
    padding-top:50px;
    width:100%;
  }
`;
const SubColumn = styled( Column )`
  justify-content:center;
  position:relative;


  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    padding: 0 ;
    width:100%;
    align-items:center;
    &.title{
      align-items:flex-start;
      padding: 0 50px;
      @media (max-width:550px) {
        padding: 0 20px;
      }
    }
  }

`;
const Title = styled( SectionTitle )`
  color:black;
  padding-top:110px;
`;
const H1 = styled.h1`
  font-size: 60px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: black;
  text-transform: capitalize;
  word-break:keep-all;
  margin:0;
  min-width: 370px;
  span{
    color:${( { theme } ) => theme.colors.primary};
  }
  .typed-cursor{
    color:gray;
    font-weight:400;
  }
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    min-width: unset;
    max-width: calc( 100% - 40px);
    font-size: 58px;
  }
  @media (max-width:550px) {
    font-size: 48px;
  }
`;
const Content = styled( _Content )`
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    width:90%;
  }
`;

const Section = styled( _Section )`
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    flex-direction:column;
  }
`;
const IconSection = styled( _Section )`
  gap: 196px;
  justify-content: center;
  padding: 72px 0;
  @media ${( { theme } ) => theme.typing.mediaRules.untilBig} {
    justify-content: space-around;
    gap: unset;
  }
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    flex-direction:column;
    padding: 20px 0;
    gap: 40px;
  }
`;
const Item = styled( Column )`
  height:172px;
  gap:30px;
  align-items:center;
  text-align:center;
  svg{
    min-height:120px;
  }
`;
const ItemTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  color:black;

`;

const titles = [
  ' employees',
  ' groups',
  ' customers',
];
const paragraph =
`Chimu is a members-only data-driven ML platform for knowledge capture.
Our focus is boosting interaction with your participants by igniting engagement and empower productive collaboration in a secure environment.
`;
const items = [
  {
    name: 'Enterprises teams',
    icon: teamSvg
  },
  {
    name: 'Consortium between companies',
    icon: cooperationSvg
  },
  {
    name: 'Corporate Outbound',
    icon: missionSvg
  },
];

/*
 * const imageWidth = window.innerWidth > 778
 *   ? (window.innerWidth / 2) - 100
 *   : window.innerWidth;
 */

export function Introduction () {
  return <Root id="intro">
    <Section >
      <SubColumn className={'title'}>
        <H1>
        Innovative way to team-up <br/>
          <Typed
            backDelay={2000}
            strings={titles}
            backSpeed={50}
            typeSpeed={50}
            smartBackspace={true}
            loop={true}
          />
        </H1>
        <Content> {paragraph} </Content>
        <ButtonHolder>
          <EmptyButton
            as={Link}
            to={'/auth'}
            style={{lineHeight: '54px', textDecoration: 'unset'}}
          >
            Join Our Community ➜
          </EmptyButton>
        </ButtonHolder>
      </SubColumn>
      <SubColumn>
      </SubColumn>
    </Section>;
    <Title>
      We make a difference in
    </Title>
    <IconSection>
      {items.map( ( item ) => (
        <Item
          key={item.name} >
          <item.icon/>
          <ItemTitle>
            {item.name}
          </ItemTitle>
        </Item>
      ) )}
    </IconSection>
  </Root>;

}
