import { ComponentPropsWithRef } from 'react';
import { useTranslation } from 'react-i18next';

import ActionButton from '~/shared/components/FeedItemActionButton';
import { ReactComponent as CommentSvg } from '~/shared/images/comment.svg';

const CommentButton = ( {
  onClick,
  ...rest
}: ComponentPropsWithRef<'button'> & {
  postId?: number;
} ) => {

  const { t } = useTranslation();
  return <ActionButton label={t( 'Post.Buttons.Comment' )} Icon={CommentSvg} onClick={onClick} {...rest} />;

};

export default CommentButton;
