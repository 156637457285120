import {
  RefObject, useEffect, useRef, useState
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Container,
} from './styled';
import { motion, useAnimation, Variants } from 'framer-motion';
import { Shelf } from '~/shared/components/styled';
import { BaseButton } from '~/shared/components/Button';
import theme from '~/shared/theme';

const CodeInputWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  overflow-y: auto;
  direction:ltr;
  padding: 10px 5px;
  border-radius: 10px;
  margin-bottom: 10px;
`;

export const mainItemBackground = 'rgb(222 226 235)';

export const CodeInput = styled( motion.input )`
  border-radius: 13px;
  max-width: 55px;
  max-height: 200px;
  height: 50px;
  width: 45px;
  text-align: center;
  margin: 4px;
  color: rgb(179, 171, 167);
  font-size: 32px;
  border: none;
  background-color: ${mainItemBackground};
  outline: none;
  box-sizing: border-box;
  moz-appearance: textfield;
  direction: ltr;
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    width: 35px ;
    padding: 0 ;
    margin:2px ;
  }

`;

const ButtonsWrapper = styled( Shelf )`
  justify-content: center;
`;
const Button = styled( BaseButton )`
  padding: 0.4rem 1.4rem;
  font-size: 1rem;
  ::first-letter{
    text-transform: uppercase;
  }
  :not(:first-of-type){
    margin-left: 0.75rem;
  }
`;
const variants :Variants = {
  wrong: {
    // Border:'red 2px solid'
    x: [
      0,
      10,
      0,
      10,
      0
    ],
    // Background:'lightcoral',
    transition: {
      repeat: 1,
      duration: 0.2
    },
  },
  initial: {
    background: 'none'
  }
};
const inputVariants :Variants = {
  wrong: {
    backgroundColor: theme.colors.pea,
    transition: { duration: 0.1 }
  },
  initial: {
    // backgroundColor: 'initial',

  }
};

function removeSpaces ( value:string ) {

  return value.replaceAll(
    ' ',
    ''
  );

}

const size = 6;
const emptyCode = '      ';


function CodeBoxButtons (
  {
    onResend,
    onSubmit,
    submitButtonRef,
    disableResendButton,
    disableSubmitButton
  }: {
    onResend: () => Promise<boolean | undefined>;
    submitButtonRef: RefObject<HTMLButtonElement>;
    onSubmit: ( ) => Promise<void>;
    disableResendButton?: boolean;
    disableSubmitButton?: boolean;
  }
) {

  const { t } = useTranslation();
  return <ButtonsWrapper >
    <Button
      hollow
      color="primary"
      type="button"
      disabled={disableResendButton}
      borderWidth={2}
      onClick={onResend}
    >
      {t( 'Login.Code.resend' )}
    </Button>
    {/* <Button */}
    {/*   color="primary" */}
    {/*   ref={submitButtonRef} */}
    {/*   onClick={onSubmit} */}
    {/*   disabled={disableSubmitButton} */}
    {/*   // Disabled={code.length != 6} */}
    {/*   type={'button'} */}
    {/* > */}
    {/*   {t( 'Actions.submit' )} */}

    {/* </Button> */}
  </ButtonsWrapper>;

}

export interface CodeBoxProps {

  /**
   * on Error, onSubmit must throw the error forward in order for the codebox to work as intended
   * @param code
   */
  onSubmit( code: string ): Promise<any>;
  sendCodeToMail: () => Promise<boolean | undefined>;
  ButtonComponent?:typeof CodeBoxButtons;
  InputComponent?: typeof CodeInput;
  className?: string;
}

export function CodeBox ( {
  onSubmit,
  sendCodeToMail,
  ButtonComponent = CodeBoxButtons,
  className,
  InputComponent = CodeInput
}:CodeBoxProps ) {

  const [ code, setCode ] = useState( emptyCode );
  const [ isSubmitting, setSubmitting ] = useState( false );
  const submitButtonRef = useRef<HTMLButtonElement>( null );
  const inputRef = useRef<( HTMLInputElement|null )[]>( [] );
  useEffect( () => {
    const handleKeyUp = ( e: KeyboardEvent ) =>
      (e.key === 'Enter') && submitButtonRef.current?.click();
    window.document.addEventListener('keyup', handleKeyUp);
    return () => window.document.removeEventListener('keyup', handleKeyUp)
  }, [submitButtonRef]);
  const animationControls = useAnimation();
  async function handleSubmit ( code:string ) {
    setSubmitting( true );
    submitButtonRef.current?.focus();
    await onSubmit(code).catch(() => {
      animationControls.start( 'wrong' );
      setSubmitting( false );
      setCode( emptyCode );
      inputRef.current[0]?.focus();
    });
  }
  const onChange = ( password:string, forceSubmit?:boolean ) => {
    animationControls.start( 'initial' );
    setCode( password );
    const enteredLastDigit = removeSpaces( code ).length === size - 1 &&
      removeSpaces( password ).length === size;
    if (enteredLastDigit || forceSubmit)
      handleSubmit(password);
  }
  function setCodeChar ( i: number, value: string ) {
    if (i < 0)
      return;
    const before = code.slice(0, i);
    const after = code.slice(i + 1);
    onChange( `${before}${value.replace(code[i], '') || ' '}${after}`);
  }
  function selectInput ( input:HTMLInputElement|null ) {
    if ( input ) {
      const hasText = input?.value.trim().length > 0;
      input.setSelectionRange( 0, hasText ? 1 : 0);
      input.focus();
    }
  }
  const nextInput = ( i: number ) => selectInput(inputRef.current[i + 1]);
  const prevInput = ( i: number ) => selectInput( inputRef.current[i - 1] );
  function handlePastedValue ( inputIndex: number, text: string ) {
    let value = code.slice(0, inputIndex);
    for (let i = 0; i < size - inputIndex && i < text.length; i++) {
      const char = text[i];
      value += char;
      nextInput( i + inputIndex );
    }
    const isFullSize = value.length === size;
    while (value.length < size)
      value += ' ';
    onChange(value, isFullSize);
  }
  return <Container className={className}>
    <CodeInputWrapper
      data-testid="codebox"
      as={motion.div}
      animate={animationControls}
      variants={variants}
    >
      {( new Array( size ).fill( '' ) )
      .map((unused, i) =>
        <InputComponent
          variants={inputVariants}
          ref={(ref) => {
            inputRef.current[i] = ref
          }}
          autoFocus={i === 0}
          value={code[i]}
          key={`code${i}`}
          inputMode="numeric"
          onChange={({target: {value }}) => {
            // handles pasting from clipboard in android mobile, which triggers a change event instead of paste
            if (value.length > 1)
              return handlePastedValue(i, value.trim());
            setCodeChar(i, value);
            nextInput(i);
          }}
          onKeyDown={( e ) => {
            // e.preventDefault()
            const { key } = e;
            if (key === 'ArrowRight') {
              nextInput(i);
            } else if (key === 'ArrowLeft') {
              prevInput( i );
            } else if (['Delete', 'Backspace'].includes(key)) {
              if (code[i] !== ' ')
                return setCodeChar(i, ' ');
              setCodeChar(i - 1, ' ');
              prevInput(i);
            }
          }}
          onClick={() => setCodeChar(i, ' ')}
          onPaste={( e ) => {
            e.preventDefault();
            const { clipboardData } = e;
            const text = clipboardData.getData( 'text' );
            handlePastedValue(i, text);
          }}
        /> )}
    </CodeInputWrapper>
    <ButtonComponent
      onResend={sendCodeToMail}
      submitButtonRef={submitButtonRef}
      onSubmit={() => handleSubmit( code )}
      disableResendButton={isSubmitting}
      disableSubmitButton={isSubmitting || code.includes(' ') || code.length < 6}
    />
  </Container>;
}

