import React from 'react';
import styled, {css} from 'styled-components'

import { Column, SectionTitle, Button as _Button, Section as _Section } from "./styled";
import { whatYouGetBackground, chimuTotem  } from "../images";
import { 
  groupSvg,
  ideaSvg,
  searchSvg,
  shareSvg,
  shieldSvg,
  systemSvg,
  tickSvg,
  waveSvg,
} from "../images/icons";

const iconsStyles=css`
  grid-area:icon;
  rect{
    fill:${({theme})=>theme.colors.primary};;
  }
`

const Root = styled(Column)`
  position:relative;
  width:100%;
  gap: 20px;
  align-items:center;
  padding-top: 305px ;
  padding-bottom: 50px ;
  background-size:100%;
  background-repeat: no-repeat;
  background-color:${({theme})=>theme.colors.white};
  background-image: url(${whatYouGetBackground});
  @media ${ ({theme}) => theme.typing.mediaRules.untilSmall} {
    padding:0;
    padding-top: 105px ;
  }
` 
const Headline = styled.h2`
  color:black;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.72;
`
const Section = styled(_Section)`
  position:relative;
  justify-content:space-around;
  height:600px;
  
  background-repeat:no-repeat;
  background-blend-mode:saturation;
  object-fit:contain;
  height:600px;
  background-size: 500px;
  background-position:center 100px;
  background-image:url(${chimuTotem});
  gap:360px;
  @media ${ ({theme}) => theme.typing.mediaRules.untilMedium} {
    gap:unset;
    /* height: 780px; */
    height:unset;
    background-image:unset;
  }
  @media ${ ({theme}) => theme.typing.mediaRules.untilSmall} {
    flex-direction:column;
  }
`
const BenefitList = styled(Column)`
  align-items:flex-start;  
  gap:26px;
  border-radius: 20px;
  padding: 15px;
  height: min-content;
  @media ${ ({theme}) => theme.typing.mediaRules.untilBig} {
    background: rgba(245,245,245,.7);
  }
  @media ${ ({theme}) => theme.typing.mediaRules.untilSmall} {
    width:fit-content;
    max-width:95%;
    background:unset;
  }
`
const BenefitContainer = styled.article`
  display:grid;
  width:400px;
  grid-template-areas:
  "icon title"
  "icon content"
  "nothing content"
  ;
  grid-template-rows: 24px repeat( 2, fit-content );
  grid-template-columns: 55px 1fr;
  grid-row-gap: 10px;
  grid-column-gap: 25px;
  @media ${ ({theme}) => theme.typing.mediaRules.untilSmall} {
    width:100%;
    background: rgba(255,255,255,.9);
    border-radius: 15px;
    padding: 5px;
    grid-column-gap: 15px;
  }

`
const BenefitTitle = styled.h4`
  color:black;
  margin:0;
  font-size: 20px;
  font-weight: bold;
  grid-area: title;
`
const BenefitContent = styled.p`
  font-size: 16px;
  line-height: 1.38;
  margin:0;
  grid-area:  content;
  color:${({theme})=>theme.colors.textMedium};
  @media ${ ({theme}) => theme.typing.mediaRules.untilSmall} {
    font-weight:bold;
  }
`
const GroupIcon = styled(groupSvg)`
  ${iconsStyles}
`
const IdeaIcon = styled(ideaSvg)`
  ${iconsStyles}
`
const SearchIcon = styled(searchSvg)`
  ${iconsStyles}
`
const ShareIcon = styled(shareSvg)`
  ${iconsStyles}
`
const ShieldIcon = styled(shieldSvg)`
  ${iconsStyles}
`
const SystemIcon = styled(systemSvg)`
  ${iconsStyles}
`
const TickIcon = styled(tickSvg)`
  ${iconsStyles}
`
const WaveIcon = styled(waveSvg)`
  ${iconsStyles}
`


const title = 'what you get'
const headline = 'Main Features'


type Benefit={
  title: string;
  content: string;
  icon: typeof shareSvg;
}
const rightColumn: Benefit[] = [
  {
    title:'Community networking',
    content:'Share information in the comfort of a closed and secured environment',
    icon:ShareIcon
  },
  {
    title:'Discover members',
    content:'Discover members and engage without "cold call" barriers',
    icon:SearchIcon
  },
  {
    title:'Accessibility & security',
    content:'Single data store for data integrity, accessibility & security',
    icon:ShieldIcon
  },
  {
    title:'Networking opportunities',
    content:'Mutually benefit with easily communicated networking opportunities',
    icon:WaveIcon
  },
]
const leftColumn: Benefit[] = [
  {
    title:'Know your communities',
    content:'Enhance community brand and member loyalty with white label app',
    icon:GroupIcon
  },
  {
    title:'Monitor community activities',
    content:'Reduce management overhead with workflows and assistant aids',
    icon:SystemIcon
  },
  {
    title:'Communities insights',
    content:'Promote community value with advanced insights',
    icon:IdeaIcon
  },
  {
    title:'Enabling data management',
    content:'Reduce hassle with an all in one community platform enabling chats, events, networking engagement, data management and payments',
    icon:TickIcon
  },
]

const BenefitLine = ( props:Benefit ) => {
  return <BenefitContainer key={props.title}>
    <props.icon />
    <BenefitTitle>
      {props.title}
    </BenefitTitle>
    <BenefitContent>
      {props.content}
    </BenefitContent>
  </BenefitContainer>
}


export function WhatYouGet() {
  return <Root id='whatget' >
    <SectionTitle> {title} </SectionTitle>
    <Headline> {headline} </Headline>
    <Section>
      <BenefitList>
        {leftColumn.map(BenefitLine)}
      </BenefitList>
      <BenefitList>
        {rightColumn.map(BenefitLine)}
      </BenefitList>
    </Section>
  </Root>
}
