import styled from 'styled-components';
import { withHebrew } from '~/shared/utils/language';

export const CloseButton = styled.button<{ rtl: boolean }>`
  position: absolute;
  top: 33px;
  ${( { rtl } ) => ( rtl
    ? 'left: 33px'
    : 'right: 33px' )}
`;

export const EditIconButton = styled.button<{ isEdit?: boolean }>`
  position: absolute;
  top: -5px;
  right: 33px;
  font-size: 24px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${( { theme } ) => theme.colors.primary};
  color: ${( { theme } ) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:${( { theme } ) => theme.typing.zIndexes.overOthers};
  ${( { isEdit } ) => ( isEdit
    ? ''
    : `-moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1); ` )}
  &:focus {
    outline: none;
  }
`;

export const NameWrapper = styled.div<{ isHebrew?: boolean }>`
  text-align: center;
  margin-bottom: 11px;
  display: flex;
  gap: 5px;
  ${( { isHebrew } ) => isHebrew &&
    `
    direction: rtl;
    `};
  @media (max-width: 560px) {
    flex-direction: column;
  }
`;

export const Name = styled.span`
  color: ${( { theme } ) => theme.colors.textDark3};
  font-weight: ${( { theme } ) => theme.typing.fontWeights.bold};
  font-size: 20px;
`;

export const Status = styled.span`
  color: ${( { theme } ) => theme.colors.textDark3};
  font-size: 20px;
  @media (min-width: 560px) {
    &::before {
      content: '| ';
    }
  }
`;

export const CommunityName = styled.span`
  color: ${( { theme } ) => theme.colors.textDark3};
  font-weight: ${( { theme } ) => theme.typing.fontWeights.semiBold};
  font-size: 16px;
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: inherit;  
  width:80vw;
  max-width: 100%;
`;

export const ContentDescription = styled.p`
  white-space: pre-line;
  width: 100%;
  margin: 9px 0 0;
  line-height: 21px;
  color: ${( { theme } ) => theme.colors.textDark4};
  font-size: ${( { theme } ) => theme.typing.fontSizes.normal};
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${( { theme } ) => theme.typing.fontWeights.normal};
`;

export const HorizontalLine = styled.hr`
  width: 100%;
  height: 1px;
  margin: 16px 34px 15px 0;
  border: solid 1px rgba(112, 132, 154, 0.09);
`;

export const Content = styled.div`
  overflow: auto;
  padding: 20px 35px 0;
  @media (max-width: 560px) {
    padding: 20px 0px 0;
  }
`;

export const WithToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    flex-direction: column;
  }
`;

export const HeaderContent = styled.div<{ rtl: boolean }>`
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: inherit;
  position: relative;
  top: 62px;
  ${( { rtl } ) => withHebrew( rtl )}
  direction: ltr;
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    top: 30px;
  }
`;

export const BodyTopContent = styled.div`
  margin-top: 110px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    margin-top: 70px;
  }
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const InfoBox = styled.div<{ rtl: boolean }>`
  flex: 1;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 3px 7px 26px 0 rgba(112, 132, 154, 0.12);
  border-radius: 10px;
  ${( { rtl } ) => withHebrew( rtl )} ;
`;

export const ListInfoBox = styled.div<{ rtl: boolean }>`
  flex: 1;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 3px 7px 26px 0 rgba(112, 132, 154, 0.12);
  border-radius: 10px;
  ${( { rtl } ) => withHebrew( rtl )};
`;

export const ContentInfoBox = styled.div`
  margin: 35px 35px 20px;
  font-family: inherit;
`;

export const ContentTitle = styled.div`
  color: ${( { theme } ) => theme.colors.textMedium2};
  font-weight: ${( { theme } ) => theme.typing.fontWeights.medium};
  font-size: ${( { theme } ) => theme.typing.fontSizes.small};
  margin-bottom: 5px;
  font-family: inherit;
`;

export const ListItemContainer = styled.div`
  height: 28px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: inherit;
`;

export const ListItemText = styled.p`
  margin: 9px 0 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #0e2441;
  font-family: inherit;
`;

export const WorkExperienceContainer = styled.div`
  padding: 0;
  margin: 0;
  padding-top: 9px;
`;

export const WorkExperienceEmployer = styled.div`
  font-weight: ${( { theme } ) => theme.typing.fontWeights.medium};
  color: ${( { theme } ) => theme.colors.WorkExperienceEmployer};
  font-size: ${( { theme } ) => theme.typing.fontSizes.normal};
  margin-top: 3px;
  margin-bottom: 3px;
`;

export const WorkExperienceRole = styled.div`
  font-weight: ${( { theme } ) => theme.typing.fontWeights.normal};
  color: ${( { theme } ) => theme.colors.WorkExperienceRole};
  font-size: ${( { theme } ) => theme.typing.fontSizes.small};
  margin-bottom: 15px;
`;

export const WorkExperienceYear = styled.div`
  font-weight: ${( { theme } ) => theme.typing.fontWeights.medium};
  color: ${( { theme } ) => theme.colors.WorkExperienceYears};
  font-size: ${( { theme } ) => theme.typing.fontSizes.small};
`;
