import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toCanvas } from 'qrcode';
import styled from 'styled-components';
import { useLocation } from 'react-router';

import { simpleAuthPath } from './simpleAuthPath';
import topCorner from './images/top-corner.svg';
import bottomCorner from './images/bottom-corner.svg';
import {
  Greeting, Root as _Root
} from '../Auth/styled';

import theme from '~/shared/theme';
import { config } from '~/shared/config';
import { logo } from '~/configurables/logo';
import { PoweredByLogo } from '~/configurables/Landing/TemplateLanding/components/PoweredByLogo';
import { useSimpleCommunityData } from './useSimpleCommunityData';

export const Root = styled( _Root )`
  background-color: white;
  background-position: 0px 0px, 100% 100%;
  padding-top: 30px;
  background-size: min(95%, 450px), min(95%, 450px);
  background-image: url(${topCorner}), url(${bottomCorner});
`;
export const Name = styled.span`
  color: ${( { theme } ) => theme.colors.primary};
`;

const QRContaier = styled.div`
  height: auto;
  border-radius: 15px;
  border: 3px solid ${( { theme } ) => theme.colors.primary};
  width: 350px;
  position: relative;
  max-width: 80vw;
  ::after{
    content: "";
    background: white;
    height: calc(100% + 6px);
    width: 50%;
    display: block;
    top: -3px;
    left: 25%;
    position: absolute;
  }
  ::before{
    content: "";
    background: white;
    height: 50%;
    width: calc(100% + 6px);
    display: block;
    top: 25%;
    left: -3px;
    position: absolute;
  }
  canvas{
    width: 100% !important;
    height: 100% !important;
    border-radius: inherit;
    z-index: 1;
    position: relative;
  }
`;
const LogoContainer = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  position: absolute;
  background: white;
  border: 5px solid ${( { theme } ) => theme.colors.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;
const Img = styled.img`
  object-fit: contain;
`;

export const PoweredBy = styled( PoweredByLogo )`
  position: absolute;
  bottom: 30px;
  img{ 
    filter: unset;
  }
  div{
    path{ 
      fill: ${( { theme } ) => theme.colors.primary};

    }
    span{
    color: black;
    font-size: 20px;
  }}
`;

function QR ( { text = 'sample text' }:{text:string} ) {

  return <canvas
    ref={( ref ) => {

      toCanvas(
        ref,
        text,
        {
          color: {
            dark: theme.colors.secondary,
            light: '#ffffff',
          },
          errorCorrectionLevel: 'H',
          margin: 3
        }
      );

    } } />;

}

export function QRPage () {

  const { t, i18n } = useTranslation();

  const { search } = useLocation<{community_id:string}>();
  const params = useMemo(
    () => {

      const params = new URLSearchParams( search );
      return [ params.get( 'community_id' ),
        params.get( 'community_name' ) ];

    },
    [ search ]
  );
  const [ first, last ] = t( 'Login.simple' ).split( '{eventName}' );

  const { data } = useSimpleCommunityData( Number( params[0] ) );

  if ( !params[0] ) {

    return <Root className={`language-${i18n.language}`}>

      <Greeting>
        Community unspecified
      </Greeting>
    </Root>;

  }
  const [ communityId, communityName ] = params;
  const simpleLink = `${config.baseUrl + simpleAuthPath}?community_id=${communityId}`
  return <Root className={`language-${i18n.language}`}>

    <Greeting
      style={{
        marginTop: '15vh',
        whiteSpace: 'pre-line'
      }}
    >
      {communityName && <>
        {first} <Name>
          {communityName}
        </Name> {last}</>
      } <br/>

    </Greeting>

    <QRContaier>
      <QR text={simpleLink}/>
      <LogoContainer>
        <Img
          src={data?.img || logo}
          width={45 * Math.sqrt( 2 )}
          height={45 * Math.sqrt( 2 )}
        />
      </LogoContainer>
    </QRContaier>
    <PoweredBy/>
  </Root>;

}
