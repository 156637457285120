import React, { ComponentPropsWithoutRef, forwardRef } from 'react';
import styled from 'styled-components';

const TextArea = styled.textarea`
  border: none;
  resize: none;
  height: 46px;
  width: 100%;
  font-family: inherit;
  padding: 12px 15px;
  border-radius: 25px;
  color: ${({ theme }) => theme.colors.textDark};
  font-weight: ${({ theme }) => theme.typing.fontWeights.normal};
  font-size: ${({ theme }) => theme.typing.fontSizes.small};
  border: solid 2px ${({ theme }) => theme.colors.borderLight};
  @media ${({ theme }) => theme.typing.mediaRules.mobile} {
  font-size: ${({ theme }) => theme.typing.fontSizes.tinier};
  height: 100%;
  width: 100%;
  padding: 6px 7.5px;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: ${({ theme }) => theme.colors.backgroundLight2};
  }
`;

const MiniTextArea = (props: ComponentPropsWithoutRef<'textarea'>, ref: any) => <TextArea ref={ref} {...props} />;

export default forwardRef(MiniTextArea);
