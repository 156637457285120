import React, {
  useState, useEffect, useRef, Suspense
} from 'react';
import styled from 'styled-components';
import { lazily } from 'react-lazily';

// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { ContainedSpinner } from './Spinner';
import _DownloadButton from './DownloadButton';
import { useQuery } from 'react-query';
import { fetchMediaContent } from '../services/api/api';
import { config } from '../config';
import { removeChimuSerial } from '../utils';
import { ReactComponent as CloseSvg } from '~/shared/images/close.svg';
import { useModal } from '../hooks';
import { Scrollbar } from './styled';

import { ReactComponent as BlockedSvg } from '~/app/components/images/blocked.svg';

// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';

const { Document, Page } = lazily( () => import( /* WebpackChunkName: "pdf" */'react-pdf/dist/esm/entry.webpack5' ) );


const LoadFailedIcon = styled( BlockedSvg )`
  margin: auto;
  display: block;
  width: 150px;
  height: 100%;
  ellipse{
    fill: #666;
    stroke: #666;
  }
`;

const Root = styled.div<{scrollable:boolean, height?:number}>`
  position: relative;
  width: 100%;
  height: ${( { height } ) => ( height
    ? `${height}px`
    : '100%' )};
  display: flex;
  justify-content: center;
  background: #eee;
  border-radius: 20px;
  *{
    transition:.1s;
  }
  .react-pdf__Document{
    ${( { scrollable } ) => scrollable && `
      overflow-y: auto;
      overflow-x: auto;
      ${Scrollbar}
    `}
    @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    ${( { scrollable } ) => scrollable && `
      // overflow-y: auto;
      overflow-x: auto ;
    `}
      
    }
  }
  .react-pdf__message{
    position: relative;
    width: 100%;
    height: 100%;      
  }
`;
export const Controls = styled.nav`
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  color:white;
  background:${( { theme } ) => theme.colors.secondary};
  padding:5px;
  opacity: .2;
  transition: .1s;
  display: flex;
  height: 30px;
  border-radius: 5px;
  gap: 8px;
  direction: ltr;
  ${Root}:hover &{
    opacity: 1;
    
  }
`;
export const ActionButton = styled.button`
  color: white;
  /* padding: 0 2px; */
  transition: .2s;
  font-size: 20px;
  line-height: 20px;
  transition:inherit;
  &[title="zoom"]{
    margin-top: -4px; 
  }
  :hover{
    transform: scale(1.3);
    /* filter: brightness(1.1); */
  }
  :disabled{
    color:${( { theme } ) => theme.colors.borderLight};
  }
`;
const ExpandButton = styled( ActionButton )`
  border: 2px solid white;
  width: 18px;
  height: 18px;
  position: relative;
  ::after{
    content: "";
    position: absolute;
    top: 3px;
    left: -2px;
    background:${( { theme } ) => theme.colors.secondary};
    width: calc(100% + 4px);
    height: 8px;
  }
  ::before{
    content: "";
    position: absolute;
    top: -2px;
    left: 3px;
    background:${( { theme } ) => theme.colors.secondary};
    width:     8px;
    height: calc(100% + 4px);
  }
  :disabled{
    border-color:${( { theme } ) => theme.colors.borderLight};
  }
`;
const DownloadButton = styled( _DownloadButton )`
  padding-left: unset;
  min-width: 20px;
  color: white;
  path{
    fill: white;
  }
`;
const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  word-break: break-word;
  text-align: center;
  padding: 2rem;
`;
interface Props extends React.ComponentPropsWithoutRef<'div'> {
  url: string;
  fileName?: string;

  /**
   * required for download button to be present
   */
  mediaId?: number;
  controls?:boolean;
  dimension?:'height'|'width';
  disableExpand?:boolean;
  fixedHeight?:number;
}


export function PDFviewer ( {
  url,
  controls = false,
  dimension = 'width',
  mediaId,
  fileName,
  fixedHeight,
  disableExpand = false,
  ...rest
}:Props ) {

  const { setModal } = useModal();
  const isHostwinds = config.baseImageUrl.includes( 'hostwinds' );
  const [ isError, setError ] = useState( false );
  const hourAndHalf = 1.5 * 60 * 60 * 1000;
  const { data, isLoading } = useQuery(
    `pdfFile ${mediaId}`,
    async () => {

      const base64 = await fetchMediaContent( mediaId as number );
      return { data: atob( base64 ) };

    },
    {
      enabled: !!mediaId && isHostwinds,
      onError () {

        setError( true );

      },
      staleTime: hourAndHalf
    }
  );

  const ref = useRef< HTMLDivElement>( null );
  const [ [ height, width ], setSizes ] = useState<[number, number|undefined]>( [ 400,
    300 ] );
  const [ totalLength, setTotalLength ] = useState( 1 );
  const [ page, setPage ] = useState( 1 );
  const [ scale, setScale ] = useState( 1 );
  useEffect(
    () => {

      if ( ref.current ) {

        const { clientHeight, clientWidth } = ref.current;

        /*
         * if(clientHeight>500){
         *   setSizes([ clientHeight, undefined ])
         */

        /*
         * }
         * else{
         * }
         */
        setSizes( [ clientHeight,
          clientWidth ] );

      }

    },
    [ ref.current ]
  );
  function next () {

    page !== totalLength && setPage( page + 1 );

  }
  function back () {

    page !== 1 && setPage( page - 1 );

  }
  return <Root {...rest} ref={ref}
    scrollable={controls}
    height={fixedHeight}
  >
    <Suspense fallback={<ContainedSpinner/>}>
      <Document
        file={isHostwinds
          ? data
          : url
        }

        onLoadError={( error ) => {

          console.error( error );
          setError( true );

        }}
        onLoadSuccess={( e ) => {

          setError( false );
          /* eslint-disable-next-line  no-underscore-dangle */
          setTotalLength( e.numPages );

        }}
        noData={isLoading
          ? <ContainedSpinner/>
          : <LoadFailedIcon/>
        }
        loading={<ContainedSpinner/>}
        error={<ErrorMessage>

          <CloseSvg
            height={50}
            width={50}
            style={{
              margin: '10px auto'
            }}
          />
              file: <br/>
          <b>{removeChimuSerial( fileName )}</b> <br/>
              failed to load

        </ErrorMessage>}
      >
        <Page
          pageNumber={page}
          scale={scale}
          height={height}
          width={width}

        />
      </Document>
    </Suspense>
    {controls && !isError && <Controls>
      <ActionButton // Prev
        onClick={back}
      >
          ◀
      </ActionButton>
      {page}/{totalLength}
      {mediaId && <DownloadButton
        url={url}
        downloads={0}
        fileId={mediaId}
        name={fileName}
        hideLabel
      />}
      <ActionButton title={'zoom'} // zoomout
        onClick={() => {

          scale > 0 && setScale( scale - 0.3 );

        }}>
          -
      </ActionButton>
      <ActionButton title={'zoom'} // zoomin
        onClick={() => {

          setScale( scale + 0.3 );

        }}>
          +
      </ActionButton>
      {!disableExpand && <ExpandButton
        onClick={() => {

          setModal( {
            content: <div
              style={{
                width: '80vw',
                height: '80vh',
                padding: 10
              }}
            >
              <PDFviewer
                disableExpand
                url={url}
                controls={ controls}
                dimension={ dimension}
                mediaId={ mediaId}
                fileName={ fileName}
                { ...rest}
              />
            </div>,
            // withCustomContainer: true,

          } );

        }}
      />}
      <ActionButton // Next
        onClick={next}
      >
          ▶
      </ActionButton>
    </Controls>}

  </Root>;

}
