import { useEffect } from 'react';
import { useMenu } from './useMenu';

/**
 *  Used to perform an effect when a click happens outside of set element
 * @param {React.RefObject<HTMLElement>} elemRef: ref with the element that is the limit
 * @param {( e:Event ) => void} handler : effect to fire when outside click occurs and {@link condition} is true
 * @param {any[]} condition : if false the effect will not fire. required to prevent ensure it's only fired when relevant,
 *
 *  and prevent hard to debug errors when multiple elements on screen use this hook.
 *
 * if using to close a menu, it is usually the state that makes the menu open
 */
function useOutsideClick (
  elemRef: React.RefObject<HTMLElement>,
  handler: ( e:Event ) => void,
  condition: boolean,
  itemIsInMenu?:boolean
): void {

  const { menuContainer } = useMenu();
  useEffect(
    () => {

      if ( condition ) {

        const listener = ( evt: Event ): void => {

          const clickNotInElement = !elemRef.current?.contains( evt.target as Node );
          const clickNotInMenu = itemIsInMenu || !menuContainer()?.contains( evt.target as Node ); // if a click is in a menu, unlikely it's an outside click
          if ( clickNotInElement && clickNotInMenu ) {


            handler( evt );

          }

        };
        document.addEventListener(
          'mousedown',
          listener
        );
        document.addEventListener(
          'touchstart',
          listener
        );
        return () => {

          document.removeEventListener(
            'mousedown',
            listener
          );
          document.removeEventListener(
            'touchstart',
            listener
          );

        };

      }

    },
    [
      elemRef,
      menuContainer,
      condition
    ]
  );

}

export default useOutsideClick;
