import { Suspense } from 'react';
import { StandardSpinner } from '~/shared/components/Spinner';
import Auth, { Props } from './AuthPage';

const AuthPage = ( props:Omit<Props, 'mobile'> ) => {
  return (
    <Suspense fallback={<StandardSpinner />}>
      <Auth {...props}/>
    </Suspense>
  );
};

export default AuthPage;
