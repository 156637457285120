import React, {
  useContext,
  useEffect,
  useState
} from 'react';
import _Modal, { ModalProps } from 'styled-react-modal';
import styled from 'styled-components';

import CloseButton from '~/shared/components/CloseButton';
import { useModal } from '~/shared/hooks';
import { Scrollbar } from './styled';
import ErrorBoundary from './ErrorBoundary';
import { ContainedSpinner } from './Spinner';

export const ModalTitle = styled.header`
  height: 3.56rem;
  padding: 1.125rem;
  font-family: inherit;
  background: #F5F5F5;
  color: ${( { theme } ) => theme.colors.textDark};
  ${( { theme } ) => theme.typing.textSmallBold};
`;

const Body = styled.div`
  padding: 30px;
  inset-inline-end: 24px;
  font-family: inherit;
  display: flex;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  background: #F9F9F9;
  ${Scrollbar}

  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    padding: 15px;
    width: 100%;
  }
`;
const OpaqueSpinner = styled( ContainedSpinner )`
  z-index:${( { theme } ) => theme.typing.zIndexes.popup};  
  background: rgb( 50%, 50%, 50%);
  position: absolute;
  top:0;
  left: -25%;
  width: 150%;
`;

type CoreModalProps = {
  maxWidth?: string | number;
  modalBackground?: string;
  ready:boolean;
} & ModalProps& React.ComponentPropsWithoutRef<'div'>;

const ModalContainer = styled( _Modal.styled`` )<CoreModalProps >`
  max-width: ${( { maxWidth } ) => ( typeof maxWidth === 'number'
    ? `${maxWidth}px`
    : maxWidth )};
  /* width: 100%;  *//* if you change this, test to see if small popups ( media delete, upload) still look ok */
  max-height: 90vh;
  margin: 20px;
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: ${( { ready } ) => ( ready
    ? '10px'
    : 'unset' )};
  overflow: ${( { ready } ) => ( ready
    ? 'hidden'
    : 'shown' )};
  /* overflow: hidden; */
  font-family: 'Montserrat';
  isolation: isolate;
  background-color: ${( { theme, modalBackground } ) => modalBackground ?? theme.colors.white
};
  &.language-he {
    font-family: Arimo;
    direction: rtl;
    font-stretch: normal;
  }
`;
type ModalReadiness = {
  setReady( value:boolean ):void
}
const modalReadinessContext = React.createContext<ModalReadiness>( {
  setReady () {},
} );

/**
 * must be used inside a container that can fit it properly, or in the popup uses the default modal container ( test before commiting)
 */
export const WithModalReadiness = ( { isReady, children }:{isReady:any, children:React.ReactNode} ) => {

  const { setReady } = useContext( modalReadinessContext );
  useEffect(
    () => {

      setReady( !!isReady );
      return () => setReady( true );

    },
    [ !!isReady ]
  );
  // return <OpaqueSpinner/>
  return isReady
    ? <>{children}</>
    : <OpaqueSpinner/>;

};

/**
 *
 *
 * @requires: must be child of
 * - RecaptchaProvider
 * - ModalContext provider
 * language prop is used instead of useTranslation hook because the hook causes issues in ssr
 */
export default function Modal ( { language = 'en' }:{language?:string} ) {

  const { modal, closeModal, previousModal } = useModal();
  const [ ready, setReady ] = useState( true );

  const closeFunc = () => {

    modal?.onClose?.();
    closeModal();

  };
  return (
    <ModalContainer
      ready={ready}
      className={`language-${language}`}
      isOpen={!!modal}
      maxWidth={modal?.maxWidth || 'unset'}
      modalBackground={modal?.modalBackground}
      onEscapeKeydown={
        modal?.onClose ||
        function close () {

          closeModal();

        }
      }
      onBackgroundClick={!ready
        ? () => {

          closeModal();
          setReady( true );

        }
        : undefined}
    >
      <modalReadinessContext.Provider value={{
        setReady
      }} >
        {!modal?.hideCloseButton && ready && <CloseButton
          id="modal-close-button"
          onClick={modal?.previousOnClose
            ? previousModal
            : ( modal?.onClose
              ? closeFunc
              : closeModal )
          }
        />}
        <ErrorBoundary nameToLog={modal
          ? modal?.title
          : undefined}>
          {modal?.title && <ModalTitle>{modal.title}</ModalTitle>}
          {modal?.withCustomContainer
            ? (
              React.cloneElement(
                modal?.content,
                { onClose: closeModal }
              )
            )
            : (
              <Body id={'modal-body'}>
                {modal?.content
                  ? React.cloneElement(
                    modal?.content,
                    { onClose: closeModal }
                  )
                  : null}
              </Body>
            )}
        </ErrorBoundary>
      </modalReadinessContext.Provider>
    </ModalContainer>
  );

}
