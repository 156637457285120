// tabs
export { ReactComponent as DataCenterSvg } from './pie-chart.svg';
export { ReactComponent as MyChallengesSvg } from './my-challenges.svg';
export { ReactComponent as CommunitySvg } from './community.svg';
export { ReactComponent as ChatSvg } from './chat.svg';
export { ReactComponent as Home } from './path.svg';
export { ReactComponent as SearchSvg } from './search.svg';
export { ReactComponent as PlusBoxSvg } from './plus_in_box.svg';
export { ReactComponent as ThreeDots } from './menudots.svg';

// media
export { ReactComponent as ImageSvg } from './image.svg';
export { ReactComponent as PhotoSvg } from './framed-image.svg';
export { ReactComponent as SmallDocSvg } from './document.svg';
export { ReactComponent as ImageTypeSvg } from './image-type-icon.svg';
export { ReactComponent as VideoSvg } from './video-icon.svg';
export { ReactComponent as FileSvg } from './file-text.svg';
export { ReactComponent as PdfSvg } from './pdf-icon.svg';
export { ReactComponent as AudioSvg } from './audio-icon.svg';
export { ReactComponent as FolderSvg } from './folder.svg';
export { ReactComponent as NewFolderSvg } from './new-folder.svg';
export { ReactComponent as MoveFolderSvg } from './move-folder.svg';
export { ReactComponent as TagSvg } from './tag.svg';
export { ReactComponent as LayoutBlocksSvg } from './layout-blocks.svg';
export { ReactComponent as LayoutLinesSvg } from './layout-lines.svg';
export { ReactComponent as RestoreMediaSvg } from './restore-media.svg';
export { ReactComponent as UploadSvg } from './file-upload.svg';
export { ReactComponent as CloudUploadSvg } from './upload.svg';
export { ReactComponent as PrivacySVG } from './privacySVG.svg';
export { ReactComponent as BluePrivacySVG } from './privacySVG_blue.svg';
export { ReactComponent as UploadCloudSVG } from './upload-cloud.svg';
export { default as PrivacyPNG } from './privacyPNG.png';

// buttons
export { ReactComponent as TrashSvg } from './trash.svg';
export { ReactComponent as WarningSvg } from './warning.svg';
export { ReactComponent as NotifificationsSVG } from './notifications.svg';
export { ReactComponent as ConsoleSvg } from './console.svg';
export { ReactComponent as InfoSvg } from './info-circle.svg';
export { ReactComponent as SignOutSvg } from './sign-out.svg';
export { ReactComponent as TranslateSvg } from './language.svg';
export { ReactComponent as AnnouncementSvg } from './announcement.svg';
export { ReactComponent as PlusButtonSvg } from './plus-event.svg';
export { ReactComponent as CommentSvg } from './comment.svg';
export { ReactComponent as DownloadSvg } from './download.svg';
export { ReactComponent as FilterSvg } from './filter.svg';
export { ReactComponent as CloseSvg } from './close.svg';
export { ReactComponent as SavedSvg } from './saved.svg';
export { ReactComponent as UnsavedSvg } from './unsaved.svg';
export { ReactComponent as EyeSvg } from './eye.svg';
export { ReactComponent as FiltersSvg } from './filters.svg';
export { ReactComponent as TabBarAdd } from './tab-bar-add.svg';
export { ReactComponent as CalendarIconSVG } from './calendar-icon.svg';
export { ReactComponent as RefreshSvg } from './refresh.svg';

// decortaions:
export { ReactComponent as YellowBadge } from './yellow-badge.svg';
export { ReactComponent as Handshake } from './handshake.svg';
export { ReactComponent as GroupIcon } from './group-challenge.svg';
export { ReactComponent as ClockRunning } from './clock.svg';
export { ReactComponent as ClockFinished } from './clock2.svg';
export { ReactComponent as RatingIcon } from './rating-icon.svg';
export { ReactComponent as RankingProgressLine } from './ranking-progress-line.svg';
export { ReactComponent as StarCircle } from './star-circle.svg';
export { ReactComponent as PhoneBottomBarSVG } from './phone-bottom-bar.svg';
export { ReactComponent as chimuLogoSvg } from './chimuLogo.svg';
export { ReactComponent as GoldRewardCoin } from './Givaway.svg';
export { ReactComponent as BlueRewardCoin } from './Redeem.svg';
export { ReactComponent as CoinStack } from './Coins-Stack.svg';
export { default as chimuLogo } from './chimu-logo.png';
export { default as SelectGroupIcon } from './group-11.png';
export { ReactComponent as GiveawaySingleDigit } from './Giveaways-Digit.svg';
export { ReactComponent as GiveawayDoubleDigit } from './Givaway-Double-digits.svg';
export { ReactComponent as GiveawayTripleDigit } from './Giveaway-Triple.svg';
export { ReactComponent as RedeemSingleDigit } from './Redeem-Digit.svg';
export { ReactComponent as RedeemDoubleDigit } from './Redeem-Double-Digits.svg';
export { ReactComponent as RedeemTripleDigit } from './Redeem-Triple-Digits.svg';

// background images:
export { default as chimuLogoBG } from './chimuLogo.svg';
export { default as loginMobileBG } from './login-bg-small.jpg';
export { default as loginWebBG } from './login-bg-big.jpg';
export { default as filterImg } from './filter_img.png';
export { default as createPostImg } from './create_new_post_img.png';

// Phone bottom buttons:
export { ReactComponent as CommunityTabBarIcon } from './tab_bar_community.svg';
export { ReactComponent as DCTabBarIcon } from './tab_bar_datacenter.svg';

export { ReactComponent as UndoSvg } from './undo-arrow-left.svg';

export { logo } from './logo';

import * as CS from './challenge_icons';
export const challengeSvgs = CS;
