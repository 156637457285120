import replace from 'lodash/replace';
import moment from 'moment';


// moment

export const getHoursAndMinutes = ( date: string, is24 = true ): string => moment
.utc( date )
.local()
.format( `${is24
  ? 'HH'
  : 'hh'}:mm ${is24
  ? ''
  : 'A'}` );

export const getDayAndMonth = ( date: string ): string => moment.utc( date ).local()
.format( 'DD-MM' );

export const getMonth = ( date: string, translate:( word:string )=>string ): string => translate( 'Calendar.Months' ).split( ',' )[moment.utc( date ).local()
.format( 'M' ) as unknown as number - 1];

export const getFullDate = ( date: string ): string => moment.utc( date ).local()
.format( 'YYYY-MMM-DD' );

/**
 * used for displaying dates that didn't come from BE, so should not be treated as utc
 * @param {Date} date
 * @returns string representation of a date as-is ( no utc translation)
 */
export function getDateStringFromLocalDate ( date:Date ):string {

  return moment( date ).local()
  .format( 'DD/MMM/YYYY' );

}

export const getFullLocalDateTime = ( date: string ): string => moment.utc( date ).local()
.format( 'YYYY-MM-DD HH:mm:ss' );

export const getPrettyLocaleDate = ( date: string, locale:string ) => moment
.utc( date )
.local()
.locale( locale )
.format( 'MMMM Do, HH:mm' );

export const getTimeFromNow = ( date: string ) => moment.utc( date ).local()
.fromNow();

export const patchDateStringForSafari = ( dateStr: string ): string => replace(
  dateStr,
  /-/g,
  '/'
);

export const createDateFromBackendString = ( dateStr: string ): Date => new Date( patchDateStringForSafari( dateStr ) );

export const midnight = ( date:Date ):Date => new Date( date.toDateString() );

export function getTimeZone ():string {

  /* eslint-disable-next-line new-cap*/
  return Intl.DateTimeFormat().resolvedOptions().timeZone;

}
export function isInPast ( date:Date ):boolean {

  const now = Date.now();
  return now > Number( date );

}
export function getDateAsBackendString ( date:Date ) {

  return moment
  .utc( date )
  .format( 'YYYY-MM-DD HH:mm' );

}
export function isSameDay ( start:Date, end:Date ):boolean {

  return moment( start ).isSame(
    end,
    'day'
  );

}
