import { useTranslation } from 'react-i18next';

import {
  Root,
  Header,
  Message,
  BlockedIcon
} from './styled';

export function AccountBlockedPage () {

  const { t } = useTranslation();
  return <Root>
    <BlockedIcon/>
    <Header>
      {t( 'Recovery.blocked.title' )}
    </Header>
    <Message>
      {t( 'Recovery.blocked.text' )}
    </Message>
    <span>
      {t( 'Recovery.blocked.email' )}
      <a
        style={{ color: 'rgb(62, 186, 211)' }}
        href="mailto:info@chimu.io?subject=Issues with registration">
        {t( 'Contact Support' )}
      </a>
    </span>
  </Root>;

}
