import {
  useState, createContext, useContext, useCallback, ReactElement
} from 'react';
import { ModalProvider as StyledModalProvider } from 'styled-react-modal';

export type Modal = {
  title?: string;
  maxWidth?: string | number;
  content: ReactElement;

  /**
   * should the content has a container of it's own,
   *
   * thus making it unneccesary to add the default one
   */
  withCustomContainer?: boolean;
  modalBackground?: string;

  /**
   * should the close button open the previous modal instead of closing everything
   */
  previousOnClose?:boolean;

  /**
   * should the close button appear
   */
  hideCloseButton?: boolean;
  onClose?:()=>void
} | null;
interface UseModal {
  modal: Modal;
  setModal ( options: Modal ):void;
  setModal ( content: ReactElement, options:Omit<Modal, 'content'> ):void;
  closeModal: () => void;
  previousModal: () => void;

}

// export for use in test only
export const modalContext = createContext<UseModal|undefined>( undefined );


function isModalOptions ( modalOrContent:Modal|ReactElement ):modalOrContent is Modal {

  return modalOrContent === null || !!( ( modalOrContent as Modal )?.content );

}

export const ModalProvider = ( { children }:{children:React.ReactNode} ) => {

  // const [modal, setModal] = useState<Modal>(null);
  const [ modals, setModals ] = useState<Modal[]>( [] );
  const modal = modals[modals.length - 1];

  function setModal( modal:Modal ):void
  function setModal( content: ReactElement, options?:Omit<Modal, 'content'> ):void
  function setModal ( modalOrContent:Modal|ReactElement, options?:Omit<Modal, 'content'> ) {

    let newModal:Modal = null;
    if ( isModalOptions( modalOrContent ) ) {

      newModal = modalOrContent;

    } else {

      newModal = {
        content: modalOrContent,
        ...options
      };

    }
    setModals( [ ...modals,
      newModal ] );

  }
  const closeModal = useCallback(
    () => setModals( [] ),
    [ setModals ]
  );
  function previousModal () {

    setModals( modals.slice(
      0,
      modals.length - 1
    ) );

  }
  return <StyledModalProvider>
    <modalContext.Provider value={{
      closeModal,
      modal,
      previousModal,
      setModal
    }}>
      {children}
    </modalContext.Provider>

  </StyledModalProvider>;

};
export const useModal = (): UseModal => {

  const data = useContext( modalContext );
  if ( data === undefined ) {

    throw new Error( 'useModal must be used within a ModalProvider' );

  }
  return data;

};


export default useModal;
