import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { StageProps } from './JoinCommunity';
import { sendCodeToNewUser, firstTimeLogin, login} from '~/shared/services/api/api';
import { useRecaptcha } from '~/shared/hooks/useRecaptcha';
import { PHONE_PREFIXES } from '~/shared/utils/countryData';
import { useMutation } from 'react-query';
import { CommunityValues } from './types';
import { ContainedSpinner } from '~/shared/components/Spinner';
import { RegisterCodeBox } from './components/RegisterCodeBox';
import { useLocation } from 'react-router';

export interface IRegistrationUrlParams {
  email: string,
  community_id: string,
}

export const Registration = ( {
  setValues, next
}:StageProps<CommunityValues> ) => {
  const location = useLocation<IRegistrationUrlParams>()
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email') as string
  const { executeRecaptcha, ready } = useRecaptcha();
  const [ sendCodeToMail, {isLoading} ] =
    useMutation<boolean>(async () => {
      try {
        const captcha = ready && await executeRecaptcha( 'submit' );
        if (!captcha) {
          setTimeout(() => sendCodeToMail(), 1000)
          return false
        } else {
          await sendCodeToNewUser( email, captcha );
          return true
        }
      } catch (err) {
        toast.error( 'Could not send, please check your email' );
        console.error( err );
        return false
      }
    });
  useEffect(() => {
    if (!email) window.location.href = '/auth'
    else if (ready) setTimeout(() => sendCodeToMail(), 2000)
  }, [ready])
  const onSubmit = async ( code:string ) => {
    try {
      const captcha = ready && (await executeRecaptcha( 'submit' ));
      if (!captcha)
        throw new Error( 'could not get captcha' );
      const {token, communities, country, name, phone_number: phone, role} =
        await firstTimeLogin(email, code, captcha);
      await login(token);
      const firstSpace = name.indexOf(' ');
      const location = PHONE_PREFIXES.find(
        (land) => land.name.toLowerCase() === country.toLowerCase());
      const prefix = location?.dial_code || '';
      setValues((prevValues ) => ({
        ...prevValues,
        firstName: name.slice(0, firstSpace),
        lastName: name.slice(firstSpace + 1),
        phone: phone?.replace( prefix, '') || '',
        role,
        location: location?.name || '',
        prefix,
        communities,
      }));
      next();
    } catch ( err ) {
      toast.error( 'Wrong code!' );
      console.error( err );
      throw err;
    }
  };

  if (!ready)
    return <ContainedSpinner data-cause="recaptcha loading"/>;

  return <RegisterCodeBox
    askTerms={true}
    style={{margin: '0 auto', fontSize: 12}}
    backToEmail={() => {
      window.location.href = '/auth'
    }}
    email={email}
    isLoading={isLoading}
    sendCodeToMail={sendCodeToMail}
    onSubmit={onSubmit}
  />;
};
