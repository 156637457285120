
import styled from 'styled-components';

import { Input } from './styled';

import {
  AnswerForOnboaringQuestion,
  joinCommunityAnswer,
  QuestionType
} from '~/shared/services/api';
import {
  PollOptionButton,
  PollOptionCountFill,
  PollOptionsContainer,
  PollOptionTextLeft
} from '~/feed/components/Feed/FeedItem/items/PollItem/styled';
import { useRTL } from '~/shared/hooks';

const AnswerText = styled( PollOptionTextLeft )`
  text-align: start;
  white-space: pre-wrap;
`;

export type AnswerType = ( number|undefined ) | number[] | string

export interface QuestionFieldProps {
  type:QuestionType;
  options:AnswerForOnboaringQuestion[];
  selected?: AnswerType;
  handleChange( value:number|string ):void;
  optional:boolean;
  className?:string;
  prefferedLang:'en'|'he'
}
interface ChoiceProps extends QuestionFieldProps {
  type: 'predefined_multi_select'|'predefined_one_selection';
  options:AnswerForOnboaringQuestion[];
  selected?:number[]|number;
  handleChange( value:number ):void;
}
interface TextualProps extends QuestionFieldProps{
  type: 'open';
  options:[];
  selected:string;
  handleChange( value:string ):void;
}

const TextField = ( {
  handleChange,
  selected,
}:TextualProps ) => (
  <Input placeholder="answer here" value={( selected as string )} onChange={( e ) => {

    handleChange( e.target.value );

  }}/>
);

function parseOptionCaption ( stats :joinCommunityAnswer['stats'] ):string {

  if ( !stats || stats.occurrences === 0 ) {

    return 'You are the first to select this option in the community!';

  }

  const {
    percentage,
    occurrences
  } = stats;
  let figure = '';
  if ( percentage >= 15 ) {

    figure = `${percentage}% of`;

  } else {

    figure = `${occurrences} other`;

  }

  return `${figure} members also picked this option`;

}
function MultiSelectQuestion ( {
  options,
  selected,
  handleChange,
  type,
  prefferedLang,
  className

}:ChoiceProps ) {

  const rtl = useRTL();

  return <PollOptionsContainer
    className={className}
  >
    {options?.map( ( { id, stats, ...languages } ) => {

      const isMulti = type !== 'predefined_one_selection';
      const isSelected = selected !== undefined
        ? isMulti
          ? ( selected as number[] ).includes( id )
          : selected === id
        : false;
      const text = languages[prefferedLang];
      return (
        <PollOptionButton
          type="button"
          rtl={rtl}
          key={id}
          isActive={isSelected}
          onClick={() => {

            handleChange( id );

          }}
        >
          <PollOptionCountFill rtl={rtl}
            count={ isSelected
              ? 100
              : 0
            }
          />
          <AnswerText>
            {isSelected
              ? stats
                ? `${parseOptionCaption( stats )}`
                : text
              : text
            }
            {}
          </AnswerText>

        </PollOptionButton>
      );

    } )}
  </PollOptionsContainer>;

}

export function QuestionField (
  props:QuestionFieldProps & { type:QuestionType }
) {

  switch ( props.type ) {

    case 'open':
      return <div>
        <TextField { ...props as TextualProps } />
      </div>;
    default:
      return <MultiSelectQuestion {...props as ChoiceProps} />;
      // return <MultiChoiceField { ...props as MultiChoiceProps } />;

  }

}
