import {
  useEffect, useRef, useState
} from 'react';
import styled from 'styled-components';
import { Redirect, useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useRecaptcha } from '~/shared/hooks/useRecaptcha';
import { login, logOut, sendPasswordToMail } from '~/shared/services/api/api';
import { LoginCodeBox } from './LoginCodeBox';
import { logo } from '~/shared/images';
import Spinner, { ContainedSpinner } from '~/shared/components/Spinner';
import {
  Card,
  CardContent,
  CardTitle,
  ChimuLogo,
  Container,
  Greeting,
  LoaderWrapper,
  LogoImage,
  Root,
  VerifyButton
} from './styled';
import { MailInput } from '~/shared/components/formControls/MailInput';
import { joinWizardPath } from '~/join-community/JoinCommunity';

const ToastCard = styled.div`
  position: relative;
  display:flex;
  flex-direction:column;
  align-items:center;
  text-align: center;
  font-weight: 600;
  padding: 20px 20px;
  color:white;
  font-size: 20px;
  width: calc( 100% + 14px);
  min-height:100px;
  white-space:pre-line;
  background:${( { theme } ) => theme.colors.secondary};
  &.language-he{
    direction: rtl;
    font-family: Arimo;
  }

`;

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const LanguageButton = styled.button`
  border:none;
  font-size:16px;
  padding:5px;
  font-weight:600;
  text-decoration:underline;
  font-family:Montserrat;
  &.en{
    font-family:Arimo;
  }
`;

const translationBaseEmail = 'Login.Email.';
export function EmailBox ( {
  sentEmail, prevEmail, sendCodeToMail, onFirstTime, clearPrevious
}: {
  /**
   * is the request being processed at the moment
   */
  sentEmail: boolean;
  prevEmail?:string;
  clearPrevious():void;
  sendCodeToMail: ( email:string ) => Promise<boolean>;
  onFirstTime?:()=>void;
} ) {
  const {t } = useTranslation();
  const buttonRef = useRef<HTMLButtonElement>( null );
  const [ email, setEmail ] = useState( prevEmail || '' );
  useEffect( () => {
    const handleKeyUp = (event: KeyboardEvent) =>
      (event.key === 'Enter') && buttonRef.current?.click()
    window.document.addEventListener('keyup', handleKeyUp);
    return () => window.document.removeEventListener('keyup', handleKeyUp);
  }, [buttonRef])
  useEffect(clearPrevious, []);
  const isValidEmail = (email: string) => (/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(email)
  return (
    <Card>
      <CardContent>
        <CardTitle>
          {t( `${translationBaseEmail}text` )}
        </CardTitle>
        <Container>
          <MailInput
            email={email}
            setEmail={setEmail}
            disabled={sentEmail}
            autoCapitalize="off"
          />
          <VerifyButton
            ref={buttonRef}
            type="button"
            disabled={!email || sentEmail || !isValidEmail(email)}
            onClick={() => sendCodeToMail(email)}
          >
            {t( `${translationBaseEmail}send` )}
          </VerifyButton>
        </Container>
      </CardContent>
    </Card>
  );
}

const translationBase = 'Login.';
export type Props = {
  refetchToken():void;
  validToken: boolean;
};

export default function AuthPage ( {
  refetchToken,
  validToken,
}: Props ) {
  const { t, i18n } = useTranslation();
  const [ email, setEmail ] = useState<string>();
  const [ sentEmail, setSentEmail ] = useState( false );
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (validToken) logOut()
  }, [])
  const {ready, executeRecaptcha } = useRecaptcha();
  if (!ready)
    return <ContainedSpinner data-cause="recaptcha"/>;
  const sendCodeToMail = async ( newEmail:string ): Promise<boolean> => {
    try {
      setSentEmail(true);
      const captcha = await executeRecaptcha('submit');
      if (!captcha) {
        console.error('could not get captcha')
        return await sendCodeToMail(newEmail)
      }
      const isSent = await sendPasswordToMail( {email: newEmail, captcha});
      if (isSent) {
        setEmail(newEmail);
        return true;
      } else {
        toast(<Redirect to={`${joinWizardPath}?email=${newEmail}`}/>, {})
        return false
      }
    } catch (err) {
      toast(
        <ToastCard>
          <ChimuLogo src={logo}/>
          {t( 'Login.Error.message' )}
        </ToastCard>,
        {
          toastId: 'error',
          autoClose: 5000,
          type: toast.TYPE.INFO,
          position: toast.POSITION.TOP_CENTER
        }
      );
      setSentEmail( false );
      console.error( err );
      return false;
    }
  };
  const onSubmit = async ( code:string ) => {
    try {
      const captcha = await executeRecaptcha( 'submit' );
      if ( !captcha )
        throw new Error( 'could not get captcha' );
      await login({email: email as string, code, captcha});
      refetchToken();
      history.push( location );
    } catch ( err ) {
      console.error( err );
      throw err;
    }
  };
  if (!validToken) {
    return (
      <Root className={`language-${i18n.language}`}>
        <Link to="/">
          <LogoImage
            src={logo}
            className="enter"
            onAnimationEnd={(event) =>
              event.currentTarget.classList.remove( 'enter' )}
          />
        </Link>
        <Greeting>
          {t(`${translationBase}welcome`)}!
        </Greeting>
        {sentEmail && email
          ? <LoginCodeBox
            email={email}
            backToEmail={() => setSentEmail(false)}
            sendCodeToMail={() => sendCodeToMail(email)}
            onSubmit={onSubmit}
          />
          : <EmailBox
            clearPrevious={() => setEmail(undefined)}
            sentEmail={sentEmail}
            prevEmail={email}
            sendCodeToMail={sendCodeToMail}
          />
        }
      </Root>
    );

  }
  return (
    <LoaderWrapper>
      <Spinner size={100} stroke={'#3ebad3'} />
      <Redirect to={{ pathname: '/feed',
        search: location.search }} />
    </LoaderWrapper>
  );
}
