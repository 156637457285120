/*
 * Just a placeholder, will be replaced by NormalModuleReplacementPlugin at ~/webpack.config.ts:37
 */

// @ts-ignore
import { logos } from './__PROJECT__';

export interface ILogo {
  landingLogo:string;
  logo:string;
  icon:string;
}
export const { logo, landingLogo, icon } = logos as ILogo;

