import { ComponentPropsWithoutRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  Button as _Button,
  preventEnter,
} from '~/join-community/styled';

const Box = styled.div`
  position: absolute;
  min-width: 440px;
  width: 80%;
  bottom: 200px;
  display: flex;
  flex-direction: column;
  font-family: inherit;
  direction: inherit;
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    min-width: unset;
  }
`;
const DefaultButton = styled( _Button )`
  width: 100%;
`;
const Label = styled.label`
  margin-bottom:10px;
  font-size: 12px;
  line-height: 1.31;
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${( { theme } ) => theme.colors.secondary};
  font-family: inherit;
  direction: inherit;
`;
const Checkbox = styled.input.attrs( () => ( { type: 'checkbox' } ) )`
  :focus {
    background: lighten(${( { theme } ) => theme.colors.primary}, 20%);
  }
`;
const Link = styled.a`
  color: ${( { theme } ) => theme.colors.primary};
  font-family: inherit;
  direction: inherit;
`;
const termsUrl =
  'https://uploads-ssl.webflow.com/5c25e9e2fd28a701daef38c1/5c35f5ad1c5d6040e65b5f98_Firetribe-%20Updated%20Terms%20and%20Conditions%20(GY271218).pdf';

type Props = {
  valid: boolean;
  accept(): void;
  acceptText?: string;
  CustomButton?: ( props:ComponentPropsWithoutRef<'button'> )=>JSX.Element
} & ComponentPropsWithoutRef<'div'>;

export const AcceptBox = ( {
  valid,
  accept,
  acceptText,
  CustomButton,
  tabIndex,
  ...rest
}: Props ) => {

  const { t } = useTranslation();
  const [ read, setRead ] = useState( false );
  const Button = CustomButton || DefaultButton;

  return (
    <Box {...rest}>
      <Label>
        <Checkbox
          tabIndex={tabIndex}
          // type='checkbox'
          checked={read}
          onChange={() => setRead( !read )}
          onKeyDown={preventEnter}
        />
        <span>
          {t( 'Create community.i accept' )}{' '}
          <Link
            href={termsUrl}
            target="_blank"
            tabIndex={0}
          >
            {t( 'Create community.terms' )}
          </Link>
        </span>
      </Label>
      <Button
        tabIndex={tabIndex && tabIndex + 1}
        type={'button'}
        disabled={!( read && valid )}
        onClick={accept}
      >
        {acceptText || t( 'Login.Email.send' )}
      </Button>
    </Box>
  );

};
