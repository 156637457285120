import { useTranslation } from 'react-i18next';

import { useMyUserQuery } from './useMyUserQuery';
import useFeedMutation from './useFeedMutation';
import useModal from './useModal';
import { useFeedFilters } from './useFilters';
import { queryCache } from '~/app/components/queryCache';
import { api, FeedCell } from '~/shared/services/api';
import ConfirmPopup from '~/shared/components/popups/ConfirmPopup';

const itemTypeMapper = {
  post: api.deletePost,
  video: api.deleteVideo,
  article: api.deleteArticle,
  media: api.deleteMedia,
  poll: api.deletePoll,
  event: api.deleteEvent,
  adminArticle: api.deleteAdminArticle,
  reward: api.deleteReward,
  announcement: api.deleteUpdate
};

type Props = {
  itemId: number;
  itemType: keyof typeof itemTypeMapper;
  managerId?: number;
  onSuccess?():void
};

const useFeedItemDeleting = ( {
  itemId,
  itemType,
  managerId,
  onSuccess
}: Props ): {
  deleteItem: () => void;
  isLoading: boolean;
  isAuthor: boolean;
  data?: string;
  error?: Error;
} => {

  const { user } = useMyUserQuery();
  const { setModal, closeModal } = useModal();
  const { wallDataQueryKey } = useFeedFilters();
  const [ deleteFeedItem, data ] = useFeedMutation( {
    fn: () => itemTypeMapper[itemType]?.( itemId ),
    config: {
      onMutate: () => {

        const savedData = queryCache.getQueryData<Array<{ cells: FeedCell[] }>>( wallDataQueryKey );
        const idx = savedData?.findIndex( ( { cells } ) => cells.find( ( cell ) => cell.id === itemId && cell.type === itemType ) );
        if ( !idx ) {

          throw new Error( 'Item does not exist' );

        }
        const updatedCells = savedData?.[idx as number].cells.filter( ( cell ) => cell.id !== itemId && cell.type === itemType );
        savedData![idx as number].cells = updatedCells as FeedCell[];
        queryCache.setQueryData(
          wallDataQueryKey,
          {
            ...savedData,
          }
        );

      },
      onSuccess
    },
  } );
  const { t } = useTranslation();
  const deleteItem = () => setModal( {
    withCustomContainer: true,
    content: (
      <ConfirmPopup
        text={t( 'Popups.Confirm.Text' )}
        onCancel={closeModal}
        onConfirm={() => {

          deleteFeedItem();
          closeModal();

        }}
      />
    ),
    maxWidth: 400,
    title: t( 'Popups.Confirm.Title' ),
  } );

  return {
    deleteItem,
    isAuthor: user?.network_id === managerId,
    ...data,
  };

};

export default useFeedItemDeleting;
