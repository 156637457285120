import { ComponentPropsWithRef } from 'react';
import styled from 'styled-components';

import ActionButton from '~/shared/components/FeedItemActionButton';
import { ReactComponent as HeartSvg } from '~/shared/images/heart.svg';
import { api } from '~/shared/services/api';
import { useFeedMutation } from '~/shared/hooks';
import { useTranslation } from 'react-i18next';
import { queryCache } from '~/app/components/queryCache';

const LikeIcon = styled( HeartSvg )`
  & path {
    fill: ${( { theme } ) => theme.colors.textLight};
  }
`;

const LikeButton = ( {
  groupDomain,
  isLiked,
  postId,
  like_count,
  ...rest
}: ComponentPropsWithRef<'button'> & {
  groupDomain: string;
  isLiked: boolean;
  postId: number;
  like_count: number;
} ) => {

  const { t } = useTranslation();
  const [ likePost, { isLoading } ] = useFeedMutation( {
    fn: ( { liked }: { liked: boolean; like_count: number } ) => {

      queryCache.invalidateQueries( [ 'post',
        postId ] );
      return api.likePost( {
        group_domain: groupDomain,
        like: liked,
      } );

    },
    optimisticOptions: {
      feedItemType: 'post',
      feedItemId: postId,
    },
  } );
  return (
    <ActionButton
      label={t( 'Post.Buttons.Like' )}
      Icon={LikeIcon}
      isActive={isLiked}
      disabled={isLoading}
      onClick={() => likePost( { liked: !isLiked,
        like_count: !isLiked
          ? like_count + 1
          : like_count - 1 } )}
      {...rest}
    />
  );

};

export default LikeButton;
