import React, { ComponentPropsWithoutRef } from 'react';
import styled, { css } from 'styled-components';

import { InputLabel } from '~/shared/components/styled';

const Root = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  border-bottom: 1px solid rgba(112, 132, 154, 0.31);
  padding-bottom: 12px;
  width: 100%;
  font-family: inherit;

  :focus-within {
    border-color: ${( { theme } ) => theme.colors.primary};
  }
`;

const inputStyles = css`
  border: none;
  width: 100%;
  outline: none;
  font-weight: ${( { theme } ) => theme.typing.fontWeights.normal};
  color: ${( { theme } ) => theme.colors.textDark};
  font-size: ${( { theme } ) => theme.typing.fontSizes.small};
  padding-top: 8px;
  font-family: inherit;
`;

const Input = styled.input`
  ${inputStyles}
  background: inherit;
`;

interface Props extends ComponentPropsWithoutRef<'input'> {
  label?: string;
  rtl?: boolean;
  rootStyles?: React.CSSProperties;
}

const TextInput = ( {
  label,

  className,
  children,
  rootStyles,
  ...rest
}: Props ) => (
  <Root className={className} style={rootStyles}>
    {label && <InputLabel>{label}</InputLabel>}
    <Input {...rest} />
    {children}
  </Root>
);
export default TextInput;
