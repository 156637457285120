import { ComponentPropsWithoutRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CheckBox from './CheckBox';
import { BaseButton } from './styled';

import { Button as _Button, Scrollbar } from '~/shared/components/styled';

const OptionsButton = styled( BaseButton )<{ open: boolean }>`
  width: 10px;
  height: 10px;
  border-bottom: 2px solid ${( { theme } ) => theme.colors.textDark};
  border-right: 2px solid ${( { theme } ) => theme.colors.textDark};
  transform: rotate(${( { open } ) => ( open
    ? -135
    : 45 )}deg);
  /* margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px; */
  /* vertical-align:${( { open } ) => ( open
    ? 'middle'
    : '' )}; */
  margin: 10px;
  vertical-align: middle;
  transition: 0.2s;
`;
export const Selection = styled.section<{ open: boolean }>`
  > * {
    border-bottom: solid 1px rgba(112, 132, 154, 0.3);
    padding: 17px 0;
    font-family: inherit;
  }
  font-family: inherit;
  height: fit-content;
  max-height: ${( { open } ) => ( open
    ? '355px'
    : 0 )};
  transition: 0.2s;
  overflow-y: auto ${Scrollbar};
  overflow-x: hidden;
`;
const Header = styled.header<{ open: boolean }>`
  font-family: inherit;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  min-width: fit-content;
  width: ${( { open } ) => ( open
    ? '100%'
    : '0' )};
  transition: 0.2s;
`;

const Heading = styled.h3<{ active?: boolean }>`
  /* &::first-letter{
} */
  text-transform: capitalize;

  font-family: inherit;
  /* font-weight:bold; */
  margin: 0;
  color: ${( { theme, active } ) => ( active
    ? theme.colors.primary
    : theme.colors.textDark )};
  /* ${( { theme } ) => theme.typing.fontSizes.normal}; */
  font-size: 19px;
`;

const Buttons = styled.span<{ open: boolean }>`
  font-weight: 200;
  font-size: 15px;
  border: none;
  display: flex;
  overflow: hidden;
  transition: 0.1s;
  width: ${( { open } ) => ( open
    ? '55%'
    : '0' )};
  justify-content: flex-end;
  /* width:100%; */
  padding: 2px 0;
  button {
    align-self: bottom;
    margin: 0 3px;
  }
`;
const Button = styled( _Button ).attrs( { hollow: true } )`
  font-size: ${( { theme } ) => theme.typing.fontSizes.smaller};
  padding: 11px 0px;
  padding: 0;
  font-weight: 100;
  [disabled] {
    color: ${( { theme } ) => theme.colors.textDark};
    color: ${( { theme } ) => theme.colors.textLight2};
  }
`;

export interface CheckBoxData<valueType=string|number> {
  label: string;
  value?: valueType;
  checked?: boolean;
}

interface IProps<valueType> extends ComponentPropsWithoutRef<'div'>{
  label: string;
  list: CheckBoxData<valueType>[];
  isSingleChoice?:boolean;
  select: ( value: valueType[] ) => void;
}

function CheckBoxGroup<valueType extends string|number = string> ( {
  label: groupLabel,
  list,
  select,
  isSingleChoice = false,
  ...rest
}: IProps<valueType> ) {

  const [ isOpen, setOpen ] = useState( groupLabel.length === 0 );
  const { t } = useTranslation();
  function onCheck ( i: number, isChecked: boolean ) {

    list[i].checked = isChecked;
    const selection = list
    .filter( ( { checked }, index ) => ( isSingleChoice
      ? ( index === i && isChecked )
      : checked ) )
    .map( ( { value, label } ) => value || label );
    select( selection as valueType[] );

  }
  function selectAll () {

    const selection = list.map( ( { value, label } ) => value || label );
    select( selection as valueType[] );

  }
  function clear () {

    select( [] );

  }
  return (
    <section style={{ fontFamily: 'inherit' }} {...rest}>
      {groupLabel.length > 0 && <Header open={isOpen}>
        <Heading
          onClick={() => {

            setOpen( !isOpen );

          }}
          active={list.some( ( { checked } ) => checked )}
        >
          {groupLabel}
        </Heading>
        <Buttons
          open={isOpen}
          onClick={( e ) => {

            e.stopPropagation();

          }}
        >
          {!isSingleChoice && <><Button
            type={'button'}
            disabled={list.every( ( { checked } ) => checked )}
            onClick={selectAll}
          >
            {t( 'Community.Filter.Select All' )}
          </Button>
          <span
            style={{
              position: 'relative',
              top: 3
            }}
          >
          |
          </span>
          </>
          }
          <Button onClick={clear}>{t( 'Community.Filter.Clear' )}</Button>
        </Buttons>
        <OptionsButton
          type={'button'}
          open={isOpen}
          onClick={() => {

            setOpen( !isOpen );

          }}
        />
      </Header>}
      {/* <h3 style={{fontFamily:'inherit'}} onClick={()=>{setOpen(!isOpen)}}>
      {title}
      <OptionsButton open={isOpen} />
    </h3> */}
      <Selection open={isOpen}>
        {list.map( ( { label, checked, value }, i ) => (
          <CheckBox
            key={label + value}
            checked={checked}
            label={label}
            onCheck={( isChecked ) => {

              onCheck(
                i,
                isChecked
              );

            }}
          />
        ) )}
      </Selection>
    </section>
  );

}
export default CheckBoxGroup;
