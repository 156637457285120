import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
// Enables the i18next backend
.use( Backend )
// Enable automatic language detection
.use( LanguageDetector )
// Enables the hook initialization module
.use( initReactI18next )
.init( {
  // Standard language used
  fallbackLng: 'en',
  debug: false,
  // Detects and caches a cookie from the language provided
  detection: {
    order: [
      'queryString',
      'cookie',
      'navigator'
    ],
    cache: [ 'cookie' ]
  },
  interpolation: {
    escapeValue: false
  },
  // fallbackNS:'en',
  lng: 'en',
  react: {
    wait: true,
    useSuspense: true
  },
} );


export default i18n;
export const t = i18n.t.bind( i18n );
