import { Suspense } from 'react';
import { StandardSpinner } from '~/shared/components/Spinner';
import { RouteChildrenProps } from 'react-router-dom';
import { Landing } from '.';
const LandinPage = ( props:RouteChildrenProps ) => <Suspense
  fallback={<StandardSpinner />}
>
  <Landing />
</Suspense>;

export default LandinPage;
