import * as React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import InteractiveButton from '~/shared/components/JoinCommunityButton';

// const items = ['alpha','beta','charlie','delta','echo','foxtrot','golf','hotel','india','juliett','lima']

const Root = styled.div`
  width: 100%;
  min-height: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow:hidden;
  `
const Item = styled(motion.span)<{selected:boolean}>`
  height: 40px;
  width:fit-content;
  border-radius: 20px;
  padding: 10px 20px;
  background:${({selected,theme})=>selected ? theme.colors.white : theme.colors.textLight2};
  color:${({selected,theme})=>selected ? theme.colors.primary : 'white'};
  outline: ${({selected,theme})=>selected && `2px solid ${theme.colors.primary}` };
  cursor: grab !important;
  font-weight:600;
  /* letter-spacing:1px; */
`
const ArrowButton = styled.button`
    top: calc(50% - 20px);
    position: absolute;
    border-radius: 30px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    z-index:${ ({theme}) => theme.typing.zIndexes.overOthers};;
    color: white;
    background: #3ebad3;
    &.next {
      right: 10px;
    }
    &.prev {
      left: 10px;
      transform: scale(-1);
    }
    @media ${ ({theme}) => theme.typing.mediaRules.untilSmall} {
      border-radius: unset;
      top:unset;
      height :100%;
      width: 20px;
      &.next {
        right: 0px;
      }
      &.prev {
        left: 0px;
        transform: scaleX(-1);
      }
    }
`
const AcceptButton = styled(InteractiveButton)`
  border-radius: 20px;
  margin-left: auto;
  width: fit-content;
  height: 40px;
  background: ${({theme})=>theme.colors.primary};
  text-transform: uppercase;
`
const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
    transition:{
      duration:.3,
      delay:.1
    }
  },
  exit: (direction: number) => {
    return {
      // zIndex: 1,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
      transition:{
        duration:.1
      }
    };
  }
};

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */

const swipeConfidenceThreshold = 10000;

const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

type Props = {
  items?:string[];
  onAccept?:(item:string)=>void;
  selected:string;
}

export const SwipeSelector = ({ items=[], selected, onAccept }:Props) => {
  const {t} = useTranslation()
  const [[page, direction], setPage] = useState([items.indexOf(selected), 0]);

  // We only have 3 items, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 items.
  const imageIndex = wrap(0, items.length, page);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  const displayedItem = items[imageIndex];
  return (<>
    <Root >
      <AnimatePresence initial={false} custom={direction}>
        <Item
          key={page}
          // src={items[imageIndex]}
          selected={displayedItem === selected}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 }
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);
            
            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } 
            else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}

        >
          {displayedItem}

        </Item>
      </AnimatePresence>
      <ArrowButton className="next" onClick={() => paginate(1)}>
        {"‣"}
      </ArrowButton>
      <ArrowButton className="prev" onClick={() => paginate(-1)}>
        {"‣"}
      </ArrowButton>
    </Root>
    <AcceptButton 
      onClick={() => onAccept?.(displayedItem)}
      disabled={selected === displayedItem }
      title={t('Actions.update')}
      />
  </>);
};
