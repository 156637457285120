import styled from 'styled-components';
import {
  Link, LinkProps, useLocation
} from 'react-router-dom';
import { Suspense } from 'react';

import { ChimuLogoSvg, Linkedin } from './chimuLanding/images';
import { Introduction } from './chimuLanding/components/Introduction';
import { LogoCarousel } from './chimuLanding/components/LogoCarousel';
import { WhyChimu } from './chimuLanding/components/WhyChimu';
import { GetStarted } from './chimuLanding/components/GetStarted';
import { HowWeDoIt } from './chimuLanding/components/HowWeDoIt';
import { WhatYouGet } from './chimuLanding/components/WhatYouGet';
import { ContactForm } from './chimuLanding/components/ContactForm';

import { Button as _Button, Scrollbar } from '~/shared/components/styled';
import { StandardSpinner } from '~/shared/components/Spinner';

const Root = styled.div`
  --backgroundDark : ${( { theme } ) => theme.colors.landingBackgroundDark};
  max-height: 100vh;
  width: 100%;
  overflow-x:hidden;
  overflow-y:auto;
  margin-top:100px;
  direction:ltr;
  font-family:'Montserrat';
  ${Scrollbar}
`;
const Topbar = styled.nav`
  position:absolute;
  top:0;
  height: 100px;
  padding: 26px 265px;
  background:white;
  color:black;
  width:100%;
  display:flex;
  flex-direction:row;
  align-items:center;
  gap:51px;
  z-index:${( { theme } ) => theme.typing.zIndexes.topbar};
  @media ${( { theme } ) => theme.typing.mediaRules.untilMedium} {
    padding: 26px 160px;
  }
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    padding: 26px 130px;
  }
`;
const ButtonContainer = styled.div`
  position: absolute;
  right: 10px;
  display: flex;
  align-items:center;
  gap: 42px;
  z-index:${( { theme } ) => theme.typing.zIndexes.overOthers};
  top: 19px;
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    top: 30px;
    right: 30px;
  
  }
`;
const FullButton = styled( _Button )`
  height: 64px;
  border-radius: 28px;
  padding:10px 30px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${( { theme } ) => theme.colors.white};
  background-color: ${( { theme } ) => theme.colors.primary};
  font-size:${( { theme } ) => theme.typing.fontSizes.normal};
  transition:.2s;
  :hover{
    box-shadow: 0 0px 20px 10px rgba(62,186,211,0.5);
  }
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    display:none;
  }
`;
const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${( { theme } ) => theme.colors.white};
`;

const ChimuLogo = styled( ChimuLogoSvg ).attrs( () => ( { viewBox: '0 0 95 68' } ) )`
  position:absolute;
  left:100px;
  cursor:pointer;
  &.small *{
    fill:rgba(255, 255, 255, 0.8);
  }
  @media ${( { theme } ) => theme.typing.mediaRules.untilMedium} {
   left:20px;
    &.small {
      left:10px;
    }
  }
`;
const Tab = styled( Link )`
  color:inherit;
  text-decoration:none;
  font-weight:500;
  white-space:nowrap;
  font-size:${( { theme } ) => theme.typing.fontSizes.normal};
  :hover{
    color:${( { theme } ) => theme.colors.primary};
  }
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
   ${Topbar}>&{
    display: none;
   }
  }
`;
const Footer = styled.footer`
  height: 100px;
  position: relative;
  background: var(--backgroundDark);
  width:100%;
  display:flex;
  flex-direction: row;
  align-items: center;
  gap: 36px;
  padding: 26px 250px;
  color:white;
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    padding: 26px 100px;
    height: 120px;
    align-items: self-end;
  }
`;

const LinkedinButton = styled.a`
  display: flex;
  gap: 14px;
  font-weight:500;
  align-items: center;
  position: absolute;
  color: white;
  right: 150px;
  text-decoration:none;
  transition:.2s;
  :hover{
    color:${( { theme } ) => theme.colors.primary};
  }
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    right: 30px;
    top: 10px;
  }
`;
const LinkedinIcon = styled( Linkedin )`
  path{
    fill: var(--backgroundDark);
  }
  path:first-of-type{
    fill: white;
    transition: .2s;
    ${LinkedinButton}:hover &{
      fill: ${( { theme } ) => theme.colors.primary};;
      
    }
  }
`;
export function scrollTo ( id:string ) {

  document.getElementById( id )?.scrollIntoView( { behavior: 'smooth' } );

}
const tabs :LinkProps[] = [
  {
    to: 'whyus',
    children: 'Why us',
    title: 'Why us',
    onClick: scrollTo.bind(
      null,
      'whyus'
    )
  },
  {
    to: 'howdo',
    children: 'How we do it',
    title: 'How we do it',
    onClick: scrollTo.bind(
      null,
      'howdo'
    )
  },
  {
    to: 'whatget',
    children: 'What you get',
    title: 'What you get',
    onClick: scrollTo.bind(
      null,
      'whatget'
    )
  },

  /*
   * {
   *   to:'/resources',
   *   children:'Resources',
   *   title:'Resources',
   *   onClick:scrollTo.bind(null,'resources')
   * },
   */
];

function LinkedIn () {

  return <LinkedinButton
    href="https://www.linkedin.com/company/chimu-io/about/"
    target="_blank"
    rel="noopener"
  >
    Follow us on
    <LinkedinIcon
      height={24}
      width={24}
    />
  </LinkedinButton>;

}

export default function Landing () {

  const { pathname } = useLocation();
  return (
    <Root>
      <Suspense
        fallback={<StandardSpinner/>}
      >
        <ButtonContainer>
          <Tab
            to="/auth"
            children="Sign in/ Register"
          />
          <FullButton
            onClick={() => {

              scrollTo( 'contact' );

            }}
          >
          Schedule a Demo
          </FullButton>
        </ButtonContainer>
        <Topbar>
          <ChimuLogo

            onClick={() => {

              scrollTo( 'intro' );

            }}
          />
          {tabs.map( ( tab ) => <Tab {...tab} to={`${pathname}#${tab.to}`} key={tab.title} /> )}
        </Topbar>
        <Main>
          <Introduction/>
          <LogoCarousel/>
          <WhyChimu/>
          <GetStarted/>
          <HowWeDoIt/>
          <WhatYouGet/>
          <ContactForm/>
        </Main>
        <Footer >
          <ChimuLogo className={'small'} height="40px"/>
        © 2021 Chimu.io All rights reserved.

          <LinkedIn />
        </Footer>
      </Suspense>
    </Root>
  );


}

