import {
  useMemo
} from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Formik, FormikErrors, Form } from 'formik';

import {
  simpleLogin, ISimpleLoginPayload
} from '~/shared/services/api/api';
import { logo } from '~/shared/images';
import { icon } from '~/configurables/logo';

import { ContainedSpinner } from '~/shared/components/Spinner';
import { VerifyButton } from '../Auth/styled';
import { Root } from './QrPage';
import { useRecaptcha } from '~/shared/hooks/useRecaptcha';
import { AcceptBox } from '~/shared/components/formControls/TermsBox';
import { TextInput } from '~/shared/components';
import { useSimpleCommunityData } from './useSimpleCommunityData';
import { MemberAlreadyButton } from './MemberAlreadyButton';
import { Logo, Title, Powered } from './styled';
import { Link } from 'react-router-dom';

const StyledForm = styled( Form )`
  display: grid;
  height: 100%;
  grid-template-rows: 1rem repeat(3,32px) auto;
  grid-row-gap: 20px;
  padding: 20px 40px;
  @media (max-height: 505px) {
    padding: 0 40px;
  }
`;
const Input = styled( TextInput )`
  padding-bottom: 5px;
  input{
    font-size: 0.8rem;
    ::placeholder{
      color: ${( { theme } ) => theme.colors.secondary};;
    }
  }
`;
const AcceptButton = styled( VerifyButton )`
  max-width: 160px;
  margin: auto;
`;
const TermsBox = styled( AcceptBox )`
  width: 100%;
  position: relative;
  bottom: unset;
  margin-top: 130px;
  span,a {
    font-size: 10px;
  }
`;


type FormValues = Omit<ISimpleLoginPayload, 'communityId'|'captcha'>
export type Props = {
  refetchToken():void;
  validToken: boolean;
};

export function SimpleAuth ( {
  refetchToken,
  validToken
}: Props ) {

  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { search } = useLocation<{community_id:string}>();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const { ready, executeRecaptcha } = useRecaptcha();
  const communityId = params.get( 'community_id' );
  const { data } = useSimpleCommunityData( Number( communityId ) );

  if ( !ready ) {

    return <ContainedSpinner/>;

  }
  if ( !communityId ) {

    return (
      <Root className={`language-${i18n.language}`}>
        <Logo
          src={logo}
          className="enter"
          onAnimationEnd={( event ) => {

            event.currentTarget.classList.remove( 'enter' );

          }}
          style={{
            marginTop: '5vh'
          }}
        />
        <Title>
          No community selected
        </Title>
        <br/>
        <Link to="/"
          style={{
            color: '#ccc',
          }}
        >
        here by mistake? go back
        </Link>
      </Root>
    );

  }
  if ( validToken ) {

    return <MemberAlreadyButton
      executeRecaptcha={executeRecaptcha}
      communityId={communityId}
    />;

  }
  return (
    <Root
      className={`language-${i18n.language}`}
    >
      <Logo
        src={data?.img || icon}

      />
      <Formik<FormValues>
        initialValues={{
          email: '',
          firstName: '',
          lastName: ''
        }}
        onSubmit={async ( values ) => {

          try {

            const captcha = await executeRecaptcha( 'submit' );
            if ( !captcha ) {

              throw new Error( 'could not get captcha' );

            }
            await simpleLogin( {
              ...values,
              captcha,
              communityId: Number( communityId as string )
            } ).catch( ( e ) => {

              throw e;

            } );
            await refetchToken();
            history.push( '/' );

          } catch ( err ) {

            console.error( err );
            throw err;

          }

        }}
        initialErrors={{
          email: '',
          firstName: '',
          lastName: ''
        }}
        validate={( values ) => {

          const errors :FormikErrors<FormValues> = {};
          Object.entries( values ).forEach( ( [ field, value ] ) => {

            if ( !value ) {

              errors[field as keyof FormValues] = 'required';

            }

          } );
          return errors;

        }}
      >
        {( {
          values: { email, firstName, lastName, },
          handleChange,
          isValid,
          handleSubmit
        } ) => (
          <StyledForm>
            <Title>
              {t( 'simpleAuth' )}
            </Title>
            <Input
              value={firstName}
              name="firstName"
              placeholder={t( 'Labels.First Name' )}
              onChange={handleChange}
              required
              autoFocus
            />
            <Input
              value={lastName}
              name="lastName"
              placeholder={t( 'Labels.Last Name' )}
              onChange={handleChange}
              required
            />
            <Input
              value={email}
              name="email"
              placeholder={t( 'Labels.Email' )}
              onChange={handleChange}
              required
              autoCapitalize="off"
              autoCorrect="off"
            />
            <TermsBox
              valid={isValid}
              accept={handleSubmit}
              CustomButton={AcceptButton}
              style={{ marginTop: 'auto' }}
              acceptText={t( 'Actions.continue' )}
            />
          </StyledForm>
        )}
      </Formik>
      <Powered />
    </Root>
  );

}
