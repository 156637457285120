import { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { ReactQueryCacheProvider } from 'react-query';
import { ModalProvider } from '~/shared/hooks/useModal';
import theme from '~/shared/theme';
import { queryCache } from './queryCache';


export function AppContextProviders ( { children }: { children?: ReactNode; } ) {

  return <ThemeProvider theme={theme}>
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ModalProvider>
        {children}
      </ModalProvider>
    </ReactQueryCacheProvider>
  </ThemeProvider>;

}
