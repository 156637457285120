import {
  useMutation, MutationFunction, MutationConfig, MutationResultPair
} from 'react-query';

import { queryCache } from '~/app/components/queryCache';
import useModal from './useModal';
import { useFeedFilters } from './useFilters';
import { feedCellMapping } from '~/shared/services/api';

interface OptimisticOptions {
  feedItemId: number;
  feedItemType: keyof typeof feedCellMapping;
}

const replaceItemInArray = ( newItem: any, idx: number, oldArray: Array<any> ) => [
  ...oldArray.slice(
    0,
    idx
  ),
  newItem,
  ...oldArray.slice( idx + 1 ),
];

const mutationCallback = <T = any>( options: OptimisticOptions, key: Array<string|number|boolean|undefined> ) => ( values: T ) => {

  const savedFeedData = queryCache.getQueryData<Array<{ cells: Array<{ type: string; data: { id: number } }> }>>( key );
  let itemIdx: number | undefined;
  const parentIdx = savedFeedData?.findIndex( ( { cells } ) => {

    const idx = cells.findIndex( ( { type, data } ) => type === options.feedItemType && data.id === options.feedItemId );
    if ( idx > -1 ) {

      itemIdx = idx;
      return true;

    }

  } );
  if ( savedFeedData && ( itemIdx as number ) > -1 ) {

    const oldCells = savedFeedData[parentIdx as number].cells;
    const oldItem = oldCells[itemIdx as number];
    const newItem = {
      ...oldItem,
      data: {
        ...oldItem.data,
        ...values,
      },
    };
    const newPageData = {
      ...savedFeedData[parentIdx as number],
      cells: replaceItemInArray(
        newItem,
itemIdx as number,
oldCells
      ),
    };
    const newData = replaceItemInArray(
      newPageData,
parentIdx as number,
savedFeedData
    );
    queryCache.setQueryData(
      key,
      newData
    );
    // rollback to old data if mutation failed
    return () => queryCache.setQueryData(
      key,
      savedFeedData
    );

  }

};

export type Props<TResult, TVariables, TError, TSnapshot> = {
  fn: MutationFunction<TResult, TVariables>;
  config?: MutationConfig<TResult, TError, TVariables, TSnapshot>;
  isModal?: boolean;

  /**
   * pass options to optimistically update feed wall data
   * in order for this to work you need to pass data that is needed to be merged with feed wall item data as an argument to fn
   */
  optimisticOptions?: OptimisticOptions;
};

export default function useFeedMutation<TResult = any, TError = any, TVariables = any, TSnapshot = any> ( {
  fn,
  config = {},
  isModal,
  optimisticOptions,
}: Props<TResult, TVariables, TError, TSnapshot> ): MutationResultPair<TResult, TError, TVariables, TSnapshot> {

  const { closeModal } = useModal();
  const { wallDataQueryKey } = useFeedFilters();
  return useMutation<TResult, TError, TVariables, TSnapshot>(
    fn,
    {
      ...config,
      onMutate: optimisticOptions
        ? ( mutationCallback(
          optimisticOptions,
          wallDataQueryKey
        ) as ( variables: TVariables ) => any )
        : undefined,
      onSuccess: ( ...args ) => {

        queryCache.invalidateQueries( wallDataQueryKey );
        if ( isModal ) {

          closeModal();

        }
        config.onSuccess?.( ...args );

      },
    }
  );

}
