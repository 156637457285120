import { useQuery } from 'react-query';

import { api } from '~/shared/services/api';
import { UserProfile } from '~/shared/services/api/types';

export const useMyUserProfileQuery = (userId: number): { userProfile?: UserProfile; error: unknown; isLoading: boolean } => {  
  const profilyKey = ['profile', String(userId)];
  const { data: userProfile, isLoading, error } = useQuery(profilyKey, () => api.fetchUserById(userId));

  return {
    userProfile,
    error,
    isLoading,
  };
};

export default useMyUserProfileQuery;