import { ComponentPropsWithRef } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FileSvg } from '~/shared/images/file-text.svg';
import { ReactComponent as AudioSvg } from '~/shared/images/audio-icon.svg';
import { ReactComponent as DownloadSvg } from '~/shared/images/download.svg';
import { separateNameAndSuffix, removeChimuSerial, } from '~/shared/utils';

const Root = styled.div`
  position:relative;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  direction: ltr;
  /* padding-right: 30px; */
  display: flex;
  align-items:center;
  cursor: pointer;
  border: solid 2px ${( { theme } ) => theme.colors.borderLight};
  background:  ${( { theme } ) => theme.colors.backgroundLight};
  font-weight: ${( { theme } ) => theme.typing.fontWeights.semiBold};
  color: ${( { theme } ) => theme.colors.textMedium2};
  word-break:break-all;
`;

const Name = styled.span`
  font-weight: ${( { theme } ) => theme.typing.fontWeights.semiBold};
  color: ${( { theme } ) => theme.colors.textMedium2};
  overflow-wrap: break-word;
  white-space: nowrap;
  max-width: 90%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const iconStyles = css`
  min-width: 30px;
  height:23px;
  /* height: 35px; */
  margin: 0 10px;
`;

const DefaultIcon = styled( FileSvg )`
  ${iconStyles}
`;

const DownloadIcon = styled( DownloadSvg )`
  margin: 10px;
  ${Root}:hover & path{
    fill:${( { theme } ) => theme.colors.primary};
  }
`;

const AudioIcon = styled( AudioSvg )`
  ${iconStyles}
`;
type Props = {
  type: string;
  name: string;
  withoutIcon?: boolean;
  path?: string;
  clickable: boolean;
} & ComponentPropsWithRef<'div'>;

const NotMediaFileInfo = ( {
  type,
  name,
  className,
  withoutIcon,
  clickable = false,
  ...rest
}: Props ) => {

  const { t } = useTranslation();
  const { filename, suffix } = separateNameAndSuffix( name );
  const indexOfQuestionMark = suffix.indexOf( '?' );
  return (
    <Root
      className={`not-media-content ${className ?? ''}`}
      title={t( 'Post.Buttons.Download' )}
      onClick={clickable
        ? rest.onClick
        : undefined
      }
      {...rest}
    >
      {withoutIcon
        ? null
        : type.toLowerCase() === 'audio'
          ? (
            <AudioIcon />
          )
          : (
            <DefaultIcon />
          )}
      <div
        style={{
          width: 'calc(100% - 90px)',
          display: 'flex'
        }}
      >

        <Name>
          {removeChimuSerial( filename )}
        </Name>

        <span style={{ wordBreak: 'keep-all' }}>.{suffix.slice(
          0,
          indexOfQuestionMark >= 0
            ? indexOfQuestionMark
            : undefined
        )}</span>

      </div>
      {clickable && <DownloadIcon/>}
    </Root>
  );

};

export default NotMediaFileInfo;
