import { useTranslation } from 'react-i18next';


import {
  Root,
  Header,
  Message,
  BlockedIcon
} from './styled';

export function RecoveryFailedPage () {

  const { t } = useTranslation();
  return <Root>
    <BlockedIcon/>
    <Header>
      {t( 'Recovery.failed.title' )}
    </Header>
    <Message>
      {t( 'Recovery.failed.text' )}
      <a
        style={{ color: 'rgb(62, 186, 211)' }}
        href="mailto:info@chimu.io?subject=Issues with account recovery">
        {t( 'Contact Support' )}
      </a>
    </Message>
  </Root>;

}
