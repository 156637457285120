import { toast, ToastOptions, ToastContent } from 'react-toastify';
import entries from 'lodash/entries';
import keys from 'lodash/keys';
import { MediaType } from '~/shared/services/api';
import { t } from '~/i18n';
import { PlatformSpecificSetting } from '../hooks/useUserSettings';

export * from './date';
export * from './files';

export function getCookie ( name: string ): string | undefined {

  return document.cookie
  ?.split( '; ' )
  ?.find( ( row ) => row.startsWith( name ) )
  ?.split( '=' )?.[1];

}
export function setCookie ( name: string, value:string /* Add other params */ ):void {

  const cookieString = `${name}=${value}; path=/`;
  document.cookie = cookieString;

}

/*
 * Export function (image: string): string {
 *   return image?.startsWith('http') ? image : `${config.baseImageUrl}/${image}`;
 * }
 */

type Media = keyof typeof MediaType;
export const types: Array<Media> = [
  'Photo',
  'Video',
  'Audio',
  'PDF'
];

export const getMediaTypeString = ( type: number ): Media => types[type];

export function errorMessage ( message: ToastContent, options?: ToastOptions ) {

  return toast.error(
    message,
    {
      position: toast.POSITION.BOTTOM_LEFT,
      ...options,
    }
  );

}
export function successMessage ( message: Parameters<typeof toast.error>[0] ): void {

  toast.success(
    message,
    {
      position: toast.POSITION.BOTTOM_LEFT,
    }
  );

}

// Added because formik has a bug which does not allow errors to be thrown out of submit
export function onSafeSubmit<T = any> (
  onSubmit: ( args: T ) => void,
  onError?: () => void
) {

  return async ( args: T ): Promise<void> => {

    try {

      await onSubmit( args );

    } catch ( error ) {

      onError?.();
      errorMessage( ( error as any ).message );

    }

  };

}

export const getMessageType = (
  messageTypes: { [key: string]: number } = {},
  type: number
): Media | undefined => entries( messageTypes ).find( ( item ) => item[1] === type )?.[0] as Media;

export const getMessageNumberType = (
  messageTypes: { [key: string]: number } = {},
  type: string
): number => {

  const typeIdx = keys( messageTypes ).findIndex( ( key ) => {

    if ( type.toLowerCase().startsWith( 'image' ) && key === 'Photo' ) {

      return true;

    }
    if ( type === 'application' && key === 'PDF' ) {

      return true;

    }
    return key.toLowerCase() === type.toLowerCase();

  } );
  return typeIdx > -1
    ? typeIdx + 1
    : -1;

};

export const shuffleArray = ( arr: any[] ) => {

  for ( let i = arr.length - 1; i > 0; i-- ) {

    const j = Math.floor( Math.random() * ( i + 1 ) );
    [ arr[i], arr[j] ] = [ arr[j],
      arr[i] ];

  }
  return arr;

};

// Export const LINK_REGEX = /^(?:https?:\/\/)?(((?:www\.)?(?!www)(?:[\w_-]*\.)+[A-z]{2,})|((localhost|((\d{1,3}\.){3}\d{1,3})):[0-6]?[0-9]{1,4}))(?:\/[\w_-]+?)*(?:\.\w+)?(?:\?\w+=\w*(?:&\w+=\w*)*)?\/?$/gi;
export const LINK_REGEX = /^(?:https?:\/\/)?(((?:www\.)?(?!www)(?:[\w_-]*\.)+[A-z]{2,})|((localhost|((\d{1,3}\.){3}\d{1,3})):[0-6]?[0-9]{1,4}))((?:\/[\w_\-]+)*(\/[\w_,\-]+\.\w+)|(?:\/[\w_\-]+)*)\/?(?:\?\w+=[\w_\-\.%]*(?:&\w+=[\w_\-\.%]*)*)?(#\w+)?\/?$/gi;
export const LOOSE_LINK_REGEX = /(?:^|\s)(?:https?:\/\/)?(((?:www\.)?(?!www)(?:[\w_-]*\.)+[A-z]{2,})|((localhost|((\d{1,3}\.){3}\d{1,3})):[0-6]?[0-9]{1,4}))((?:\/[\w_\-]+)*(\/[\w_,\-]+\.\w+)|(?:\/[\w_\-]+)*)*\/?(?:\?\w+=[\w_\-\.%]*(?:&\w+=[\w_\-\.%]*)*)?(#\w+)?\/?/gi;
//                               Start/space protocol                        host-------------------------------------------------------> port------------>   path------------------------------------------------> query----------------------------------> hash
export const isValidUrl = ( _link?: string | number | readonly string[] ): boolean => {

  if ( typeof _link !== 'string' ) {

    return false;

  }
  const link = String( _link );
  return Boolean( link.match( LINK_REGEX ) );

};
export const toAbsoluteLink = ( link: string ): string => {

  if ( !isValidUrl( link ) ) {

    return link;

  } else if ( link.startsWith( 'http://' ) || link.startsWith( 'https://' ) ) {

    return link;

  }
  return `http://${link}`;

};

export const getIsWindows = () => window.navigator?.appVersion?.includes( 'Win' ) ||
  window.navigator?.platform?.includes( 'Win' );

export function playSound ( sound:string, setting:PlatformSpecificSetting|undefined ) {

  const isSoundAllowed = setting?.web;
  if ( !isSoundAllowed ) {

    return;

  }
  const audio = new Audio( sound );
  audio.play();

}
export function getElementScreenTop ( element:HTMLElement ) {

  if ( !element ) {

    return 0;

  }
  let elem: HTMLElement|null = element;
  let location = 0;
  if ( elem.offsetParent ) {

    do {

      location += elem.offsetTop;
      elem = ( elem.offsetParent as HTMLElement );

    } while ( elem );

  }
  return location >= 0
    ? location
    : 0;

}
export function getElementScreenLeft ( element:HTMLElement ) {

  if ( !element ) {

    return 0;

  }
  let elem: HTMLElement|null = element;
  let location = 0;
  if ( elem.offsetParent ) {

    do {

      location += elem.offsetLeft;
      elem = ( elem.offsetParent as HTMLElement );

    } while ( elem );

  }
  return location >= 0
    ? location
    : 0;

}

/**
 *
 * @param {number[]} cur
 * @param {number[]} next
 * @returns whether the arrays have the same numbers in them
 */
export const equalsIgnoreOrder = ( cur:number[], next:number[] ) => {

  if ( cur.length !== next.length ) {

    return false;

  }
  const uniqueValues = new Set( [ ...cur,
    ...next ] );
  for ( const value of uniqueValues ) {

    const aCount = cur.filter( ( e ) => e === value ).length;
    const bCount = next.filter( ( e ) => e === value ).length;
    if ( aCount !== bCount ) {

      return false;

    }

  }
  return true;

};
export function checkVisible ( elm:HTMLElement ) {

  const rect = elm.getBoundingClientRect();
  const viewHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight
  );
  return !( rect.bottom < 0 || rect.top - viewHeight >= 0 );

}
export function copyToClipboard ( url: string, message?:string ) {

  /* eslint-disable-next-line  no-param-reassign */
  message ||= t( 'clipboard' );
  navigator.clipboard.writeText( url );
  message && toast.info( message );

}
