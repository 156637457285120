import React, { ComponentPropsWithRef, MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as ImageSvg } from '~/shared/images/image.svg';
import { ReactComponent as VideoSvg } from '~/shared/images/video.svg';
import NotMediaFileInfo from '~/shared/components/NotMediaFileInfo';
import { FullWidthFeedItemContent } from '~/shared/components/styled';
import { MediaType } from '~/shared/services/api';
import { SecureVideo, SecureImg } from './SecureImg';
import { useDownloading, useModal } from '../hooks';
import { PDFviewer as _PDFviewer } from './PDFPreview';
import { isPDF } from '../utils';
import { ReactComponent as LinkSvg } from '~/shared/images/media-link-icon.svg';
import { ViewImagePopup } from './popups/ViewImagePopup';

const Root = styled.div`
  display: flex;
  justify-content: center;
  margin: unset;
  height:300px;
  background: transparent;
`;

const Image = styled( SecureImg )`
  width: 100%;
  cursor: pointer;
  border-radius: 40px;
  object-fit: cover;
  height: 300px;
`;
const Video = styled( SecureVideo )`
  width: 100%;
  max-height: 300px;
`;
const LinkIcon = styled( LinkSvg )`
  `;
const iconStyles = css`
  margin-right: 10px;
  width: 24px;
  height: 24px;
  background-color: ${( { theme } ) => theme.colors.white};
  border-radius: 0.3px;
`;

const ImageIcon = styled( ImageSvg )`
  ${iconStyles}
`;

const VideoIcon = styled( VideoSvg )`
  ${iconStyles}
`;

const ContentWrapper = styled( FullWidthFeedItemContent )`
  min-height: 30px;
  margin: 0;
`;

const MediaLink = styled.a`
  margin-top: 20px;
  display: flex;
`;
const PDFviewer = styled( _PDFviewer )`
  margin: auto 0;
`;

export interface File {
  name: string;
  url: string;
  preview?: string;
}

type Media = keyof typeof MediaType;

const MediaFileContent = ( {
  url,
  name,
  Icon,
  type = 'Photo',
  className,
  onClick,
  cover,
  mediaId
}: {
  url: string;
  name?: string;
  Icon?: React.FunctionComponent;
  type?: Media;
  cover?:string;
  mediaId:number;
} & ComponentPropsWithRef<'div'> ) => {

  const isVideo = type === 'Video';

  return (
    <ContentWrapper onClick={onClick} className={className}>
      {type === 'Photo' && (
        <>
          <Image
            src={url}
            mediaId={mediaId}
          />
        </>
      )}
      {isVideo && (
        <Video controls onClick={( e ) => e.stopPropagation()} src={url} poster={cover} />
      )}
    </ContentWrapper>
  );

};

type MediaContentProps = {
  url: string;
  type: Media;
  name: string;
  className?: string;
  onClick?: React.ComponentPropsWithoutRef<'div'>['onClick']|'download';
  rootClassName?: string;
  notMediaClassName?: string;
  withoutIcon?: boolean;

  /**
   * needed for download or for loading pdfs
   */
  mediaId:number;
  cover?:string;
  disableDownloadApiCall?:boolean;
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'onClick'>;

function getOnClickAction ( onClick : MediaContentProps['onClick'], download:()=>void ):( ( e:any )=>void )|undefined {

  if ( onClick === 'download' ) {

    return () => download();

  }
  return onClick;

}

const PostMediaContent = ( {
  url,
  type,
  name,
  className: mediaClassName,
  onClick,
  rootClassName,
  notMediaClassName,
  withoutIcon,
  mediaId,
  cover,
  disableDownloadApiCall,
  style,
  ...rest
}: MediaContentProps ) => {

  const { setModal } = useModal();
  const fileName = name || '';
  const lastSlash = fileName.lastIndexOf( '/' );
  const nameWithSuffix = fileName.slice( lastSlash + 1 );
  const { download } = useDownloading( {
    url,
    mediaId,
    isModal: false,
    name,
    noApiCall: disableDownloadApiCall
  } );
  const isMediaPDF = isPDF( fileName );
  const onClickAction = getOnClickAction(
    onClick,
    download
  );
  const openImagePopup :MouseEventHandler<HTMLDivElement> = ( e ) => {

    e.preventDefault();
    e.stopPropagation();
    setModal( {
      previousOnClose: true,
      content: <ViewImagePopup
        src={url}
        alt={name}
        mediaId={mediaId}
      />,

    } );

  };

  return (
    <Root className={rootClassName} style={style}>
      {(
        type.toLowerCase() === 'audio' ||
        ( type.toLowerCase() === 'pdf' && !isMediaPDF )
      ) &&
       (
         <NotMediaFileInfo
           type={type}
           name={name || nameWithSuffix}
           clickable={true}
           className={`${mediaClassName || ''} ${notMediaClassName || ''}`}
           withoutIcon={withoutIcon}
           onClick={onClickAction}
           path={url}
         />
       )}
      {
        type === 'MediaLink' && <MediaLink
          href={url}
          target="_blank"
        >
          <LinkIcon
            height={20}
            width={35}
          />
          {url}
        </MediaLink>
      }
      {isMediaPDF && (
        <PDFviewer
          mediaId={mediaId}
          fileName={name}
          url={url}
          controls
          dimension="height"
          fixedHeight={300}
        />
      )}
      {type === 'Photo' && (
        <MediaFileContent
          type={type}
          mediaId={mediaId}
          url={url}
          name={nameWithSuffix}
          Icon={withoutIcon
            ? undefined
            : ImageIcon}
          className={mediaClassName}
          onClick={onClickAction || openImagePopup}
        />
      )}
      {type === 'Video' && (
        <MediaFileContent
          type={type}
          url={url}
          mediaId={mediaId}
          name={nameWithSuffix}
          Icon={withoutIcon
            ? undefined
            : VideoIcon}
          className={mediaClassName}
          onClick={onClickAction}
          cover={cover}
        />
      )}
    </Root>
  );

};

export default PostMediaContent;
