import styled from 'styled-components';

import FileCard from './FileCard';
import { Scrollbar } from '~/shared/components/styled';
import { FileData } from '~/shared/services/api';
import { types } from '~/shared/utils';
import { ReactNode } from 'react';

const Gallery = styled.section`
  display:flex;
  /* max-width:90%; */
  /* height:max-content; */
  min-height:75px;
  max-height:75px;
  
  overflow-x:auto;
  flex-wrap:wrap;
  ${Scrollbar}
`;

export type GalleryProps = {
  files: Pick<FileData, 'file' | 'id' | 'type' | 'media_path' | 'media_type'|'name'>[];
  editing: boolean;
  removeFile: void | ( ( fileId: string | number ) => void );
  children?: ReactNode;
  className?: string;
};


function FileGallery ( {
  files,
  editing,
  removeFile,
  className,
  children
}:GalleryProps ) {

  return <Gallery className={className}>
    {children}
    {files.map( ( {
      file, id, type, media_path, media_type, name
    }, i ) => (
      <>
        <FileCard
          type={type || types[media_type as number]}
          key={`${id}-${i}`}
          mediaId={id as number}
          path={media_path}
          name={name || file?.name || media_path}
          editing={editing}
          onDelete={( e ) => {

            e.stopPropagation();
            removeFile && removeFile( id );

          } } />
      </>
    ) )}
  </Gallery>;

}
export default FileGallery;
