import { useTranslation } from 'react-i18next';
import { useRTL } from '~/shared/hooks';

import {
  InfoBox,
  ContentInfoBox,
  ContentTitle,
  ContentDescription,
  HorizontalLine,
  WithToggleContainer,
} from './styles';
import Toggle from '~/shared/components/Toggle';

interface Props {
  email: string;
  phone: string;
  setPhone: ( value: string ) => void;
  hidePhone: boolean;
  hideEmail: boolean;
  setHidePhone: ( bol: boolean ) => void;
  setHideEmail: ( bol: boolean ) => void;
  isEdit: boolean;
}

const ContactInformation = ( {
  email,
  phone,
  setPhone,
  hidePhone,
  hideEmail,
  setHidePhone,
  setHideEmail,
  isEdit
}: Props ) => {

  const { t } = useTranslation();
  const rtl = useRTL();

  return (
    ( isEdit || !( hideEmail && hidePhone ) )
      ? <InfoBox rtl={rtl}>
        <ContentInfoBox>
          {( isEdit || ( !isEdit && !hideEmail ) ) && <>
            <ContentTitle>{t( 'Popups.Update profile.Email.Title' )}</ContentTitle>
            <WithToggleContainer>
              {hideEmail
                ? <ContentDescription>
                  { t( 'Popups.Update profile.Hidden' ) }
                </ContentDescription>
                : <ContentDescription as="a" href={`mailto:${email}`}>
                  { email }
                </ContentDescription>}
              {isEdit && <Toggle
                on={t( 'Popups.Update profile.Hide' )}
                off={t( 'Popups.Update profile.Show' )}
                className={'email-toggle'}
                checked={hideEmail}
                onChange={( ) => setHideEmail( !hideEmail )} />}
            </WithToggleContainer>
          </>}
          {phone && <>
            {email && phone && ( isEdit || ( !isEdit && !hidePhone ) ) && <HorizontalLine />}
            {( isEdit || ( !isEdit && !hidePhone ) ) &&
          <>
            <ContentTitle>
              {t( 'Popups.Update profile.Phone.Title' )}
            </ContentTitle>
            <WithToggleContainer>
              {hidePhone
                ? <ContentDescription>
                  { t( 'Popups.Update profile.Hidden' ) }
                </ContentDescription>
                : isEdit
                  ? <input
                    value={phone}
                    inputMode="numeric"
                    onChange={( { target: { value } } ) => setPhone( value )}
                  />
                  : <ContentDescription
                    as="a"
                    href={`tel:${phone}`}
                    style={{
                      direction: 'ltr',
                      width: 'max-content',
                    }}
                  >
                    { phone }
                  </ContentDescription>}
              {isEdit && <Toggle
                on={t( 'Popups.Update profile.Hide' )}
                off={t( 'Popups.Update profile.Show' )}
                className={'phone-toggle'}
                checked={hidePhone}
                onChange={( ) => setHidePhone( !hidePhone )} />}
            </WithToggleContainer></>}
          </>}
        </ContentInfoBox>
      </InfoBox>
      : <></>
  );

};

export default ContactInformation;
