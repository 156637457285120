import styled from 'styled-components';
import { LogoImage } from '../Auth/styled';
import { PoweredBy } from './QrPage';

export const Title = styled.h2`
  font-size: 1rem;
  color: ${( { theme } ) => theme.colors.secondary};
  text-align: start;
  margin:unset;
`;
export const Powered = styled( PoweredBy )`
  position: relative;
  bottom: unset;
  margin-bottom: 30px;

`;
export const Logo = styled( LogoImage )`
  object-fit: cover;
  height: 60px;
  margin-top: 10px;
  @media (max-height: 505px) {
    min-height: 50px;
    height: 50px;
    object-fit: contain;
  }
`;
