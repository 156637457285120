import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import {
  Validate,
  FeedCell,
  feedCellMapping,
  FeedPagination,
  User,
  UserProfile,
  Poll,
  PollOption,
  Reward,
  Sponsor,
  CommunityMasterData,
  PostCreationPayload,
  NetworkOpportunity,
  LikePayload,
  CommunityEvent,
  AllEvents,
  CommentPayload,
  Comment,
  GroupMessages,
  MediaResponse,
  PostUpdatePayload,
  EventPayload,
  CreateFilterPayload,
  FetchMembersPayload,
  UserChats,
  Message,
  ChatMessagePayload,
  ChatGroupCreatePayload,
  ChatGroup,
  SuggestedCommunity,
  NotificationsPagination,
  MessageUser,
  Hub,
  onBoradingCommunityPayload,
  MediaPagination,
  MediaFolders,
  DataCenterFolder,
  DataCenterMedia,
  VerboseFeedItem,
  MediaSearchPagination,
  MediaMessages,
  FeedItemDetails,
  ChallengesData,
  UserRanking,
  Challenge,
  NotificationPopupI,
  Community,
  DashboardData,
  MapData,
  UserMapData,
  SystemArticlesData,
  SystemArticle,
  ManagerUpdate,
  ManagerUpdateCreationPayload,
  OnboardingPayload,
  OnboardingQuestion,
  CommunitySuggestion,
  OnboardingQuestionType,
  ScrappedArticle,
  OnboardingScheme,
  OnboardingQuestionsPayload,
  OnboardingSchemePayload,
  UserRegistationPayload,
  UserRegistationResponse,
  CommunityCreationPayload,
  ExpertisePayload,
  InterestPayload,
  Invitation,
  CommunityTopic,
  ExpertiseItem,
  InterestItem,
  CompleteCommunitySettings,
  CommunitySettingsPayload,
  ArticleSource,
  UserAdminProfile,
  UserCommunity,
  PollMembers,
  MediaMembers,
  MediaMembersPayload,
  InviteNewMemberPayload,
  EventMembers,
  PostMembers,
  Filters,
  RewardsData,
  RewardPayload,
  UserRef,
  ClaimRedeemData,
  UpdatedUserProfile,
  CommunityCreationResponse,
  QuestionForOnboarding,
  SchemeLine, BioOptions, IBioResponse
} from './types';

import { config } from '~/shared/config';
import { OnboardingData } from '~/join-community/types';
import {
  Article, CommunityMemberItem, FeedCellType, Media, OnboardingAnswer, Video
} from '.';
import { errorMessage, getTimeZone } from '~/shared/utils';
import { UserSettingsI } from '~/shared/hooks/useUserSettings';
import { queryCache } from '~/app/components/queryCache';
import { AnswerType } from '~/join-community/Fields';

const { clientId } = config;

const http = axios.create( {
  baseURL: config.baseApiUrl,
  withCredentials: true
} );

export function isAxios ( e: AxiosError | any ): e is AxiosError {

  return !!e?.isAxiosError;

}
export const fetchApi = async <T = any>(
  url: string,
  requestConfig?: AxiosRequestConfig
  // @ts-ignore
): Promise<T | never> => {

  try {

    const res = await http(
      url,
      requestConfig
    );

    if ( res.data?.success ) {

      return res.data.data;

    }
    // throw new Error(JSON.stringify(res.data.message) + ' (' + res.data.error_code + ')');

  } catch ( e ) {

    if ( isAxios( e ) ) {

      if ( e.response?.status === 403 ) {

        // debugger
        window.location.href = config.loginUrl;

      }
      if ( e.response?.status === 406 ) {

        // debugger
        throw e;

      }
      throw e;

    }
    console.error( e );
    throw new Error( `error at ${url}: ${JSON.stringify(
      e,
      null,
      2
    )}` );

  }

};

export const getConfig = async (): Promise<{ rewards:boolean, challenges:boolean }> => fetchApi(
  '/user/app/modules/',
  {
    method: 'GET'
  }
).catch( console.error );

export const logOut = async () => fetchApi(
  '/oauth/logout/',
  {
    method: 'POST',
    data: `client_id=${clientId}`
  }
).catch( console.error );

/*
 * document.cookie = `trustnet_auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;${domain}`;
 * window.location.href = config.loginUrl;
 */


const handleBlockedLogin = () => {
  window.location.href = `${config.loginUrl}/blocked`;
}

export const sendPasswordToMail = async ( payload: {
  email: string;
  captcha: string;
} ): Promise<boolean> => {
  type EmailResponseData = {
    success:boolean;
    error_code: number;
    data: {
      message:{
        message: string;
        blocked: boolean;
      }
    }
  }
  const res = await axios.post<EmailResponseData>(`${config.baseApiUrl}/user/send_code_to_email/`, {...payload})
  .catch( ( error ) => {
    if (isAxios( error ) && error.response?.data?.message?.blocked)
      handleBlockedLogin();
    return error;
  });
  return (!!res?.data?.success)
};

const redirect_uri = config.baseUrl;

export async function getAuthToken (
  email: string,
  password: string,
  captcha: string
) :Promise<string> {


  type LoginResponseData = {
    success:boolean;
    data: {
      user: {
        token: string;
      },
      message: string;
      blocked: boolean;
    }
  }
  const res = await axios( {
    method: 'post',
    url: `${config.baseApiUrl}/user/login/`,
    data: {
      email,
      password,
      captcha,
    },
    // withCredentials:true
  } )
  .catch( ( error ) => {

    if ( isAxios( error ) && error.response?.data.data.message.blocked ) {
      console.error(error)
      handleBlockedLogin();
    }
    throw ( error as Error );

  } );
  const { data, success }:LoginResponseData = res.data;
  if ( success ) {

    return data.user.token;

  }
  throw new Error( res.data );

}


export const getAuthCode = async ( token:string ) => {

  const res = await axios( {
    method: 'GET',
    url: `${config.baseApiUrl}/oauth/authorize/`,
    params: {
      client_id: clientId,
      response_type: 'code',
      redirect_uri,
      // redirect_uri:'https://dev.chimu.io',
      scope: 'read write'
    },
    withCredentials: true,
    headers: {
      Authorization: `Token ${token}`,
    }
  } );
  const redirectURl = res.request?.responseURL;
  return redirectURl;

};

export async function loginWithAuthCode ( redirectURl: string ) {

  const index = ( redirectURl as string ).indexOf( 'code=' );
  const code = redirectURl.slice( index + 5 );
  const res2 = await axios( {
    method: 'POST',
    url: `${config.baseApiUrl}/oauth/token/`,
    withCredentials: true,
    data: `grant_type=authorization_code&code=${code}&client_id=${clientId}&redirect_uri=${redirect_uri}`
  } );

  return res2;

}

export async function login (
  tokenOrLogin:{
    email: string,
    code: string,
    captcha: string
  }| string
) {

  const token = typeof tokenOrLogin === 'string'
    ? tokenOrLogin
    : await getAuthToken(
      tokenOrLogin.email as string,
      tokenOrLogin.code,
      tokenOrLogin.captcha
    );

  const redirectUrl = await getAuthCode( token as string );

  const res = await loginWithAuthCode( redirectUrl );
}
export interface ISimpleLoginPayload {
  email: string;
  firstName: string;
  lastName: string;
  communityId: number;
  captcha:string;
}

export async function getAuthTokenSimple ( {
  communityId, email, firstName, lastName
}:ISimpleLoginPayload ) :Promise<string> {


  type LoginResponseData = {
    success:boolean;
    data: {
      user: {
        token: string;
      },
      message: string;
      blocked: boolean;
    }
  }
  const res = await axios( {
    method: 'post',
    url: `${config.baseApiUrl}/user/login/`,
    data: {
      no_onboard: true,
      email,
      first_name: firstName,
      last_name: lastName,
      community_id: communityId,
    }
  } )
  .catch( ( error ) => {

    if ( isAxios( error ) && error.response?.data.data.message.blocked ) {

      handleBlockedLogin();

    }
    throw ( error as Error );

  } );
  const { data, success }:LoginResponseData = res.data;
  if ( success ) {

    return data.user.token;

  }
  throw new Error( res.data );

}

export async function simpleLogin (
  payload:ISimpleLoginPayload
) {

  const token = await getAuthTokenSimple( payload );

  const redirectUrl = await getAuthCode( token as string );

  await loginWithAuthCode( redirectUrl );

}

/*
 * export const authenticate = async () => {
 *   const res = await axios({
 *     method: 'POST',
 * url: `${config.baseApiUrl}/oauth/token`,
 *     withCredentials:true,
 *     data:URLSearchParams()
 *   });
 *   return res
 * }
 */


async function goToApp ( token: string ) {

  const href = `${config.baseApiUrl.replace(
    'api.',
    ''
  )}/auth?token=Token ${token}`;
  // const href = `http://localhost:3000/auth?token=Token ${token}`
  const link = document.createElement( 'a' );
  link.href = href;
  link.click();
  return true;

}

/**
 * used to check that the browser has the proper authentication to access the site
 * @param mobile when used for mobile login
 * @returns
 */
export const validateToken = async ( mobile?: boolean ): Promise<boolean> => {

  try {

    const { is_valid } = await fetchApi(
      '/user/auth/token/validate/',
      {
        method: 'POST',
      }
    );
    if ( is_valid && mobile ) {

      goToApp( http.defaults.headers.Authorization );

    }
    return is_valid;

  } catch ( e ) {

    /*
     *     console.error(e);
     *     if (window.location.href.includes('localhost')) {
     *       alert(
     *         `hey, server may be down.
     * please give it another try.
     * if you keep seeing this message check with Filip`
     *       );
     *     }
     */
    return false;

  }

};
export const fetchSecrets = (
  app: string,
  token_type: string
): Promise<{ key: string; secret: string }> => fetchApi(
  '/user/auth/secrets/',
  {
    params: {
      app,
      token_type,
    },
  }
);
export const fetchMasterData = (): Promise<CommunityMasterData> => fetchApi( '/user/community/master_data/' );

export const fetchTags = (
  type: 'post' | 'hub' | 'event' | 'media'
): Promise<{ id: number; name: string }[]> => {

  const typeIds = {
    post: 0,
    hub: 1,
    event: 2,
    media: 3,
  };
  return fetchApi(
    `/user/tags/type/${typeIds[type]}/`,
    { method: 'GET' }
  );

};

export const contactUs = ( data: {
  email: string;
  captcha: string;
  full_name: string;
  organization: string;
  message: string;
} ) => axios( {
  method: 'post',
  url: `${config.baseApiUrl}/mailing/forms/join-us/`,
  data,
} );

// settings

export const fetchUserSettings = (
  userNetworkId: number
): Promise<UserSettingsI> => fetchApi(
  `/user/${userNetworkId}/profile/config/`,
  {
    method: 'GET',
  }
);
export const updateUserSettings = (
  userNetworkId: number,
  data:UserSettingsI
): Promise<UserSettingsI> => fetchApi(
  `/user/${userNetworkId}/profile/config/`,
  {
    method: 'PUT',
    data
  }
);

export const fetchCommunitySettings = (
  communityId: number
): Promise<CompleteCommunitySettings> => fetchApi(
  `/admin_api/community/${communityId}/`,
  {
    method: 'GET',
  }
);
export const fetchBasicCommunityInfo = (
  communityId: number
) => fetchApi<{
  id:number;
  logo:string;
  img:string;
}>(
  `/user/community/${communityId}/basics/`,
  {
    method: 'GET',
  }
);

export const fetchArticleSources = (): Promise<ArticleSource[]> => fetchApi(
  '/admin_api/community/sources/',
  {
    method: 'GET',
  }
);

export const fetchUserAdminProfile = (
  networkId: number
): Promise<UserAdminProfile> => fetchApi(
  `/admin_api/profile/users/${networkId}/`,
  {
    method: 'GET',
  }
);
interface Scheme {
  id: 5,
  languages: {
    description:string;
    en: SchemeLine[],
    he: SchemeLine[]
  }
}
export const fetchScheme = (
  communityId: number
) => fetchApi<Scheme>(
  `/user/community/${communityId}/onboarding/scheme/`,
  {
    method: 'GET',
  }
);

export async function updateCommunityScheme (
  communityId: number,
  data: OnboardingSchemePayload
) {

  return fetchApi(
    `${config.baseApiUrl}/user/community/${communityId}/onboarding/scheme/`,
    { method: 'POST',
      data, }
  );

}

export const updateCommunitySettings = (
  communityId: number,
  data: CommunitySettingsPayload
): Promise<CompleteCommunitySettings> => fetchApi(
  `/admin_api/community/${communityId}/`,
  {
    method: 'PATCH',
    data,
  }
);


// onBoarding

export const sendCodeToNewUser = async ( email: string, captcha:string ) => axios( {
  method: 'post',
  url: `${config.baseApiUrl}/admin_api/user/send_code_to_new_user/`,
  data: {
    email,
    captcha
  },
} );

export type FirstLoginReturn = {
  token: string;
  communities: CommunitySuggestion[],
  role: string;
  name: string;
  phone_number: string,
  country: string,
}
export async function firstTimeLogin (
  email: string,
  password: string,
  captcha: string
) :Promise<FirstLoginReturn > {

  const res = await axios( {
    method: 'post',
    url: `${config.baseApiUrl}/admin_api/user/login_first_time/`,
    data: {
      email,
      password,
      captcha,
    },
  } );

  if ( res.data?.success ) {

    const {
      user,
      communities,
      role,
      name,
      phone_number,
      country,
    } = res.data.data;
    return {
      token: user.token as string,
      communities: communities as CommunitySuggestion[],
      role: role || '',
      name: name || '',
      phone_number: phone_number || '',
      country: country || '',
    };

  }
  throw new Error( res.data );

}
export const onBoardUserFirstTime = async (
  userId: number,
  data: OnboardingData,
  locale: 'en'|'he'
) => {

  const res = await http( {
    method: 'post',
    url: `${config.baseApiUrl}/user/${userId}/on_board/`,
    headers: {
      language: locale
    },
    data: {
      ...data,
      id: userId
    },
  } );

  if ( res.data?.success ) {

    return res.data.data as {invited_by_manager:boolean};

  }
  throw new Error( res.data );

};


export function sendSingleAnswer (
  userId:number,
  questionId:number,
  answer:AnswerType
) {
  return fetchApi<{question:OnboardingQuestion, next:OnboardingQuestion|null}>(
    `user/${userId}/on_boarding/questions/${questionId}/`,
    {
      method: 'POST',
      data: { answer }
    }
  );
}

/**
 * Using questionId=0 and adding community_id to payload just gets the first onboarding question
 */
export function getFirstQuestion (
  userId:number,
  communityId:number,
) {

  return fetchApi<QuestionForOnboarding>(
    `user/${userId}/on_boarding/questions/0/`,
    {
      method: 'POST',
      data: {
        community_id: communityId,
        answer: null
      }
    }
  );

}
// createCommunity

export const createCommunityToken = (): Promise<{
  token: string;
  is_valid: boolean;
}> => fetchApi(
  '/user/community/validation/token/',
  {
    method: 'POST',
    withCredentials: true
  }
);

export const validateCommunityToken = async (
  token: string
): Promise<{ is_valid: boolean; token: string }> => {

  const res = await axios( {
    url: `${config.baseApiUrl}/user/community/validation/token/`,
    method: 'get',
    params: { token },
  } );
  if ( res.data?.success ) {

    return res.data.data;

  }
  throw new Error( res.data );

};
export const fetchOnboradingQuestionTypes = async (): Promise<
  OnboardingQuestionType[]
> => {

  const res = await http( {
    url: `${config.baseApiUrl}/user/community/onboarding/questions/types/`,
    method: 'get',
  } );
  if ( res.data?.success ) {

    return res.data.data;

  }
  throw new Error( res.data );

};

export const fetchCommunityTopics = () => fetchApi<CommunityTopic[]>(
  'user/community/topics/',
  {
    method: 'GET',
  }
);

export const fetchCommunityOnboradingQuestions = async (
  communityId: number
): Promise<OnboardingQuestion[]> => {

  const res = await http( {
    url: `${config.baseApiUrl}/user/community/${communityId}/onboarding/questions/`,
    method: 'get',
  } );
  if ( res.data?.success ) {

    return res.data.data;

  }
  throw new Error( res.data );

};

interface UserCommunityData{
  user_answers:OnboardingAnswer[],
  user_data:{
    position:string;
    full_name:string;
    location:string;
    phone:string|null;
    image:string;
  }
}
export async function fetchUserCommunityData (
  communityId: number
) {

  return fetchApi<UserCommunityData>(
    `/admin_api/community/${communityId}/get_user_community_data/`,
    {
      method: 'GET'
    }
  );

}

type DefaultImages = {
    avatars: string[];
    events: string[];
  };

/**
 *
 * @returns {DefaultImages} src links for default images
 */
export async function fetchDefaultImages () {


  const { avatars, events } = await fetchApi<DefaultImages>(
    '/user/media/defaults/',
    {
      method: 'GET'
    }
  );
  return { avatars,
    events };

}


export const fetchOnboradingArticles = async (
  community_id: number,
  page: number
): Promise<{ articles: ScrappedArticle[]; page: number }> => {

  const res = await http( {
    url: `${config.baseApiUrl}/user/community/${community_id}/onboarding/articles/`,
    method: 'get',
    params: {
      page,
    },
  } );
  if ( res.data?.success ) {

    res.data.data.page = page + 1;
    return res.data.data;

  }
  throw new Error( res.data );

};

export const registerUser = (
  data: UserRegistationPayload & { captcha: string }
): Promise<UserRegistationResponse> => fetchApi(
  `${config.baseApiUrl}/user/register/`,
  {
    method: 'POST',
    data,
  }
);

export const createCommunity = (
  data: CommunityCreationPayload
) => fetchApi<CommunityCreationResponse>(
  '/user/community/',
  {
    method: 'POST',
    data,
  }
);


export const createCommunityOnboradingQuestions = async (
  communityId: number,
  data: OnboardingQuestionsPayload
): Promise<OnboardingQuestion[]> => fetchApi(
  `${config.baseApiUrl}/user/community/${communityId}/onboarding/questions/`,
  {
    method: 'POST',
    data,
  }
);

export const createCommunityScheme = async (
  communityId: number,
  data: OnboardingSchemePayload
): Promise<any> => {

  const res = await http( {
    url: `${config.baseApiUrl}/user/community/${communityId}/onboarding/scheme/`,
    method: 'POST',
    data,
  } );
  if ( res.data?.success ) {

    return res.data.data;

  }
  throw new Error( res.data );

};

export const postOnboradingArticle = async (
  community_id: number,
  document_id: string
): Promise<any> => {

  const res = await http( {
    url: `${config.baseApiUrl}/user/community/${community_id}/onboarding/articles/`,
    method: 'POST',
    data: {
      id: document_id,
    },
  } );
  if ( res.data?.success ) {

    return res.data.data;

  }
  throw new Error( res.data );

};

export const inviteMembers = async (
  community_id: number,
  invitees: Invitation[]
): Promise<any> => {

  const res = await http( {
    url: `${config.baseApiUrl}/user/community/${community_id}/onboarding/invitations/`,
    method: 'POST',
    data: {
      invitees,
    },
  } );
  if ( res.data?.success ) {

    return res.data.data;

  }
  throw new Error( res.data );

};

// feed //post
export const fetchFeedPagination = (
  search?: string,
  lastDate?: string,
  filter?: string
): Promise<{ cells: FeedCell[]; last_cell_date: string }> => fetchApi<FeedPagination>(
  '/wall_data/',
  {
    params: {
      last_date: lastDate || undefined,
      filter: search || undefined,
      mobile: false,
      limit: 20,
      types: filter || undefined,
      api: 2,
    },
  }
)
.then( ( data ) => {

  const cells = data.cells.map( ( { type, id } ) => {

    const cellType = feedCellMapping[type];
    const currentItems = data[cellType];
    const cellData = Array.isArray( currentItems )
      ? currentItems.find( ( item ) => item?.id === id )
      : currentItems;
    return {
      data: cellData,
      type,
    };

  } ) as FeedCell[];
  return { cells,
    last_cell_date: data.last_cell_date };

} )
.then( ( data ) => ( {
  ...data,
  cells: data.cells.filter( ( cell: FeedCell ) => cell.type && cell.data ),
} ) );

/**
 * fetches a single feed object
 * @param objectId
 * @param type
 * @returns
 */
export type FeedItem = NetworkOpportunity|
Poll|
Challenge|
Media|
Video|
Article|
CommunityEvent|
CommunityMemberItem

export function fetchFeedObject (
  objectId: number,
  type:FeedCellType
):Promise<FeedItem> {

  return fetchApi<FeedItem>(
    `/user/wall_data/objects/${objectId}/`,
    {
      params: {
        type
      },
    }
  );

}
interface GetPostData{
  id: number;
  created: string;
  image: string;
  description: string;
  title: string;
  url: string;
  is_video: false,
  group_domain: string;
  chat_group_id: number;
  like_count: number;
  comments_count: number;
  view_count: number;
  manager: {

      image: string;
      name: string;

  }
}

/**
 *
 * @deprecated returns a non-standard post format, use {@link getPostVerbose}
 * @param postId
 * @returns
 *
 */
export const getPost = ( postId: number ): Promise<GetPostData> => fetchApi(
  `/user/posts/${postId}/`,
  {
    method: 'GET',
  }
);

export const createPost = (
  post: PostCreationPayload
): Promise<NetworkOpportunity> => fetchApi(
  '/user/posts/create/',
  {
    method: 'POST',
    data: post,
  }
);

export const updatePost = (
  payload: Partial<PostUpdatePayload>
): Promise<string> => fetchApi(
  '/user/posts/update/',
  {
    method: 'PATCH',
    data: payload,
  }
);

export const deletePost = ( postId: number ): Promise<string> => fetchApi(
  `/user/posts/${postId}/`,
  {
    method: 'DELETE',
  }
);

export const likePost = ( likeData: LikePayload ): Promise<string> => fetchApi(
  '/user/posts/like/',
  {
    method: 'POST',
    data: likeData,
  }
);

export const viewPost = ( viewData: { group_domain: string } ): Promise<string> => fetchApi(
  '/user/posts/view/',
  {
    method: 'POST',
    data: viewData,
  }
);

const savePost = (
  userId: number,
  details: FeedItemDetails
): Promise<string> => fetchApi(
  `/user/${userId}/saved_objects/`,
  {
    method: 'POST',
    data: {
      object_id: details.feedItemId,
      object_type: details.feedItemType,
    },
  }
);

const unsavePost = (
  userId: number,
  details: FeedItemDetails
): Promise<string> => fetchApi(
  `/user/${userId}/saved_objects/`,
  {
    method: 'DELETE',
    params: {
      object_id: details.feedItemId,
      object_type: details.feedItemType,
    },
  }
);

export function bookMarkPost (

  /**
   * is the action a save or a delete
   */
  save: boolean,
  userId: number,
  details: FeedItemDetails
) {

  if ( save ) {

    return savePost(
      userId,
      details
    );

  }
  return unsavePost(
    userId,
    details
  );

}


export const postCTR = ( postId: number ): Promise<any> => fetchApi(
  `/user/posts/${postId}/ctr/`,
  {
    method: 'POST',
  }
);

export const getPostVerbose = ( postId: number ): Promise<NetworkOpportunity> => fetchApi(
  `user/posts/${postId}/verbose/`,
  {
    method: 'GET',
  }
);

export const fetchPostMembers = (
  communityId: number,
  postId: number,
  requestConfig:{
    excludeCreator?:boolean,
    onlyIds?:boolean
  } = {}
): Promise<PostMembers> => {

  const { onlyIds = true, excludeCreator = true } = requestConfig;
  return fetchApi(
    `/user/community/${communityId}/posts/${postId}/members`,
    {
      method: 'GET',
      params: {
        only_ids: onlyIds,
        exclude_creator: excludeCreator
      },
    }
  );

};

export const getPostComments = (
  group_domain: string,
  parent_id?: number,
  page?: number,
  mobile?: boolean
): Promise<GroupMessages & { page: number }> => fetchApi(
  '/user/messages/group/',
  {
    params: {
      target_id: group_domain,
      page,
      parent_id,
      mobile
    },
  }
).then( ( data ) => ( { page: page || 1,
  ...data } ) );

export const updatePostComment = (
  messageId: number,
  payload:Pick<CommentPayload, 'plaintext'|'ranges'|'text'|'type'>
) => fetchApi<Comment>(
  `/user/messages/group/${messageId}/`,
  {
    method: 'PATCH',
    data: payload
  }
);
export const deletePostComment = (
  messageId: number,
) => fetchApi(
  `/user/messages/group/${messageId}/`,
  {
    method: 'DELETE'
  }
);

export const getPollComments = (
  pollId: number,
  parent?: number,
  page?: number
) => fetchApi(
  `/user/polls/${pollId}/messages/`,
  {
    params: { target_id: pollId,
      page,
      parent },
  }
)
.then( ( data ) => ( {
  page: page || 1,
  ...data
} ) );

export const updatePollComment = (
  pollId: number,
  messageId: number,
  payload:Pick<CommentPayload, 'plaintext'|'ranges'|'text'|'type'>
) => fetchApi<Comment>(
  `/user/polls/${pollId}/messages/${messageId}/`,
  {
    method: 'PATCH',
    data: payload
  }
);
export const deletePollComment = (
  pollId: number,
  messageId: number,
) => fetchApi(
  `/user/polls/${pollId}/messages/${messageId}/`,
  {
    method: 'DELETE',
  }
);

export const getRewardComments = (
  rewardId: number,
  parent?: number,
  page?: number
) => fetchApi(
  `/rewards/user/rewards/${rewardId}/messages/`,
  {
    params: { target_id: rewardId,
      page,
      parent },
  }
).then( ( data ) => ( {
  page: page || 1,
  ...data
} ) );

export const addPostComment = ( commentData: CommentPayload ): Promise<Comment> => fetchApi(
  '/user/posts/comment/',
  {
    method: 'POST',
    data: commentData,
  }
);

export const addPollComment = (
  pollId: number,
  commentData: CommentPayload
): Promise<Comment> => fetchApi(
  `/user/polls/${pollId}/messages/`,
  {
    method: 'POST',
    data: commentData,
  }
);

export const addRewardComment = (
  rewardId: number,
  commentData: CommentPayload
): Promise<Comment> => fetchApi(
  `/rewards/user/rewards/${rewardId}/messages/`,
  {
    method: 'POST',
    data: commentData,
  }
);

// notifications
export const fetchNotificationsPagination = (
  id: number,
  olderThan?: string
): Promise<NotificationsPagination> => fetchApi<NotificationsPagination>(
  `/user/${id}/notifications/`,
  {
    params: {
      older_than: olderThan || undefined,
    },
  }
).then( ( data ) => data );

export const makeNotificationSeen = ( notificationId: string ) => fetchApi( `user/notifications/${notificationId}/clicked/` );

export const makeAllNotificationsSeen = ( userId: number ) => fetchApi(
  `user/${userId}/notifications/reset/all/`,
  {
    method: 'POST',
  }
);

export const getUnseenNotifications = ( userId: number ): Promise<{unseen_count:number}> => fetchApi(
  `user/${userId}/notifications/status/`,
  {
    method: 'GET',
  }
);

export const updateFCMToken = ( registration_id: string ) => fetchApi(
  'user/update_FCM_token/',
  {
    method: 'POST',
    data: {
      type: 'web',
      registration_id,
    },
  }
);

export const getNotificationPopup = (
  userId: number,
  documentId: string
): Promise<NotificationPopupI> => fetchApi( `user/${userId}/notifications/popup/${documentId}/` );

// user
export const fetchUser = (): Promise<User> => fetchApi( '/user/' );

export const fetchUserById = (
  id: number,
  partialcv?: boolean
): Promise<UserProfile> => fetchApi(
  `/user/${id}/profile/`,
  {
    params: {
      partialcv,
    },
  }
);

export const updateUserCV = (
  id: number,
  partialcv?: boolean
): Promise<UserProfile> => fetchApi(
  `/user/${id}/profile/`,
  {
    method: 'PATCH',
    params: {
      partialcv,
    },
  }
);

export const updateUserById = (
  id: number,
  user: UpdatedUserProfile
): Promise<UserProfile> => fetchApi(
  `/user/${id}/profile/`,
  {
    method: 'PATCH',
    data: {
      ...user,
    },
  }
);

export const fetchUserRanking = ( userNetworkId: number ) => fetchApi<UserRanking>( `/user/${userNetworkId}/ranking/` );

export const sendVerificationCode = ( userId: number ) => fetchApi<string>(
  `/user/${userId}/verify/`,
  { method: 'POST' }
);

export const verifyUserCode = ( userId: number, code:number ) => fetchApi<Validate>(
  `/user/${userId}/verify/`,
  {
    method: 'GET',
    params: { code }
  }
);


// media //data center //datacenter

export const uploadMedia = (
  payload: FormData,
  onProgress?: ( progressEvent: { loaded: number; total: number } ) => void
): Promise<MediaResponse> => fetchApi(
  '/user/media/upload/',
  {
    method: 'POST',
    data: payload,
    onUploadProgress: onProgress,
  }
).catch( ( err ) => {

  // errorMessage( 'unsupported file format' );
  if ( err?.response.status === 406 ) {

    console.log( payload.values().next().value );
    errorMessage( `Unsupported file format: "${( payload.values().next().value as File ).name}"`, );

  }

} );

export const fetchMediaPagination = ( props: {
  communityId: number;
  type?: number;
  page: number;
  tag_ids?: string;
  parent?: number;
} ): Promise<MediaPagination & { page: number }> => {

  const {
    communityId, type = 0, page = 1, tag_ids, parent
  } = props;
  return fetchApi<MediaPagination>(
    `/user/community/${communityId}/media/type/${type}/`,
    {
      method: 'GET',
      params: {
        parent: Number( parent ) > 0
          ? parent
          : undefined,
        page,
        tag_ids,
        folder: false,
        deleted: parent === -1,
      },
    }
  ).then( ( data ) => ( { page,
    ...data } ) );

};
export const fetchSingleMedia = (
  media_id: number | string
): Promise<DataCenterMedia> => fetchApi(
  `/user/media/${media_id}/`,
  { method: 'GET' }
);

/**
 * Fetches just the file content for a media item.
 * Used for hostwinds where there are CORS issues with fetching pdf files,
 * and for downloading files on mobile.
 * @param mediaId
 * @returns string of base64 format
 */
export const fetchMediaContent = async (
  mediaId: number | string
) => (
  await fetchApi<{content:string}>(
    `user/media/${mediaId}/content/`,
    { method: 'GET' }
  )
).content;
export const searchMedia = ( {
  community_id,
  search: filter,
  page = 1,
}: {
  community_id: number;
  search: string;
  page?: number;
} ): Promise<MediaSearchPagination> => fetchApi(
  `/user/community/${community_id}/media/search/`,
  {
    method: 'GET',
    params: { filter,
      page },
  }
);

export const fetchFolders = (
  communityId: number,
  parent?: number,
  tags?: string
): Promise<DataCenterFolder[]> => fetchApi(
  `/user/community/${communityId}/media/type/0/`,
  {
    method: 'GET',
    params: {
      folder: 'true',
      parent,
      tag_ids: tags || '',
    },
  }
).then( ( data: MediaFolders ) => data.community_media );

export const downloadMedia = ( id: number ): Promise<string> => fetchApi(
  `/user/media/${id}/download/`,
  { method: 'POST' }
);

/**
 *
 * @param payload
 * Media types:
 *   Photo = 0
 *   Video = 1
 *   Audio = 2
 *   PDF = 3
 *   URL = use {@link createMediaURL }
 * folder => use {@link createFolder }
 * @returns
 */

export const createMedia = ( { path, type, ...payload }: MediaResponse &{
  parent_id: number;
  community_id: number;
} ): Promise<{
  success: boolean;
  data: any;
}> => fetchApi(
  '/user/media/create/',
  {
    method: 'POST',
    data: {
      ...payload,
      link: path,
      media_type: type,
      is_folder: false
    },
  }
).then( ( res ) => res.data );

export const createMediaLink = ( payload: {
  parent_id: number;
  description: string;
  name: string;
  url: string;
  community_id: number;
} ): Promise<{
  success: boolean;
  data: any;
}> => fetchApi(
  '/user/media/create/',
  {
    method: 'POST',
    data: { ...payload,
      is_folder: false,
      media_type: 4 },
  }
).then( ( res ) => res.data );

export const updateMediaLink = ( { mediaId, ...payload }: {
  mediaId:number;
  description: string;
  name: string;
  url: string;
} ) => fetchApi(
  `/user/media/${mediaId}/`,
  {
    method: 'PATCH',
    data: payload,
  }
).then( ( res ) => res.data );

export const createFolder = ( payload: {
  parent_id: number;
  link: string;
  community_id: number;
} ): Promise<string> => fetchApi(
  '/user/media/create/',
  {
    method: 'POST',
    data: { ...payload,
      media_type: 0,
      is_folder: true },
  }
).then( ( res ) => res.data );

export const tagMedia = ( payload: {
  tag_ids: number[];
  reference_ids: number[];
} ): Promise<void> => fetchApi(
  '/user/tags/media/',
  {
    method: 'POST',
    data: payload,
  }
);

export const moveMedia = ( payload: {
  parent_id: number;
  media_ids: number[];
} ): Promise<string> => fetchApi(
  '/user/media/move/',
  {
    method: 'PATCH',
    data: payload,
  }
);

export const restoreMedia = (
  media_ids: number | string,
  to: number
): Promise<string> => (

  /**
   * accepts id or comma delimited string of ids
   *
   */
  fetchApi(
    '/user/media/',
    {
      method: 'PATCH',
      params: { media_ids,
        to },
    }
  )
);
export const deleteMedia = ( media_ids: number | string ): Promise<string> => (

  /**
   * accepts id or comma delimited string of ids
   *
   */
  fetchApi(
    '/user/media/',
    {
      method: 'DELETE',
      params: { media_ids },
    }
  )
);

export const fetchMediaViewingMembers = (
  communityId: number,
  mediaId: number,
  requestConfig:{
    excludeCreator?:boolean,
    onlyIds?:boolean
  } = {}
): Promise<MediaMembers> => {

  const { onlyIds = true, excludeCreator = true } = requestConfig;

  return fetchApi(
    `/user/community/${communityId}/media/${mediaId}/members/`,
    {
      method: 'GET',
      params: {
        only_ids: onlyIds,
        exclude_creator: excludeCreator
      },

    }
  );

};

export const updateMediaViewingMembers = ( communityId: number, mediaId: number, payload:MediaMembersPayload ): Promise<MediaMembers> => fetchApi(
  `/user/community/${communityId}/media/${mediaId}/members/`,
  {
    method: 'PUT',
    data: payload
  }
);

export const deleteVideo = ( videoId: number ): Promise<string> => fetchApi(
  `/user/video/${videoId}/`,
  {
    method: 'DELETE',
  }
);

export const fetchMediaComments = ( {
  media_id,
  page,
  parent,
}: {
  media_id: number | string;
  page?: number;
  parent?: number;
} ): Promise<MediaMessages> => ( // TODO
  fetchApi(
    `/user/media/${media_id}/messages/`,
    {
      method: 'GET',
      params: { page,
        parent },
    }
  ).then( ( data ) => ( { page,
    ...data } ) )
);

export const createMediaComment = (
  payload: CommentPayload
): Promise<Comment> => {

  const media_id = payload.target_id;
  return fetchApi(
    `/user/media/${media_id}/messages/`,
    {
      method: 'POST',
      data: payload,
    }
  );

};

export const updateMediaComment = (
  mediaId: number,
  messageId: number,
  payload:Pick<CommentPayload, 'plaintext'|'ranges'|'text'|'type'>
) => fetchApi<Comment>(
  `/user/media/${mediaId}/messages/${messageId}/`,
  {
    method: 'PATCH',
    data: payload
  }
);
export const deleteMediaComment = (
  mediaId: number,
  messageId: number,
) => fetchApi(
  `/user/media/${mediaId}/messages/${messageId}/`,
  {
    method: 'DELETE',
  }
);

export const fetchURLData = ( url: string ): Promise<any> => fetchApi(
  '/user/generic/link-preview/',
  {
    method: 'GET',
    params: { url },
  }
);

// article
export const deleteArticle = ( articleId: number ): Promise<string> => fetchApi(
  `/user/articles/${articleId}/`,
  {
    method: 'DELETE',
  }
);

export const deleteAdminArticle = ( articleId: number ): Promise<string> => fetchApi(
  `/admin_api/articles/${articleId}/`,
  {
    method: 'DELETE',
  }
);

export const rate = ( type: string, id: number, rate: number ): Promise<string> => fetchApi(
  `/user/${type}/${id}/rate/`,
  {
    method: 'POST',
    data: { rate },
  }
);

export const removeRate = ( type: string, id: number ): Promise<string> => fetchApi(
  `/user/${type}/${id}/rate/`,
  { method: 'DELETE' }
);

export type CreatePollPayload = Pick<Poll, 'title'|'multiselect'> & {
  options: Array<Pick<PollOption, 'text'>>;
  members: number[];
  public: boolean;
};

// poll
export const createPoll = (
  poll: CreatePollPayload
): Promise<Poll> => fetchApi(
  '/user/polls/',
  {
    method: 'POST',
    data: poll,
  }
);

export const updatePoll = (
  pollId: number,
  payload: Partial<Poll>
): Promise<Poll> => fetchApi(
  `/user/polls/${pollId}/`,
  {
    method: 'PATCH',
    data: payload,
  }
);

export const deletePoll = ( pollId: number ): Promise<string> => fetchApi(
  `/user/polls/${pollId}/`,
  {
    method: 'DELETE',
  }
);

export const voteOnPoll = ( pollId: number, optionId: number ): Promise<Poll> => fetchApi(
  `/user/polls/${pollId}/vote/`,
  {
    method: 'POST',
    data: { option_id: optionId },
  }
);

export const removeVoteFromPoll = (
  pollId: number,
  optionId: number
): Promise<Poll> => fetchApi(
  `/user/polls/${pollId}/vote/${optionId}/`,
  { method: 'DELETE' }
);

export const fetchPollItem = ( pollId: number ): Promise<Poll> => fetchApi(
  `/user/polls/${pollId}/`,
  {
    method: 'GET',
  }
);

export const fetchPollMembers = (
  communityId: number,
  pollId: number,
  requestConfig?:{
    excludeCreator?:boolean,
    onlyIds?:boolean
  }
): Promise<PollMembers> => {

  const { onlyIds = true, excludeCreator = true } = requestConfig || { onlyIds: true,
    excludeCreator: true };
  return fetchApi(
    `/user/community/${communityId}/polls/${pollId}/members/`,
    {
      method: 'GET',
      params: {
        only_ids: onlyIds,
        exclude_creator: excludeCreator
      }
    }
  );

};

// challenge
const makeChallengeApi = () => {

  const challengeBaseUrl = '/user/challenges';
  const makeChallengeRequest = ( action: string ) => ( challengeId: number ) => fetchApi(
    `${challengeBaseUrl}/${challengeId}/${action}/`,
    {
      method: 'POST',
    }
  );

  return {
    accept: makeChallengeRequest( 'accept' ),
    complete: makeChallengeRequest( 'complete' ),
    tryAgain: makeChallengeRequest( 'try_again' ),
  };

};

export const challenge = makeChallengeApi();

export const fetchMyChallenges = (
  userId: number,
  pageNumber?: number,
  type?: 'all' | 'personal' | 'group'
): Promise<ChallengesData & { page: number }> => fetchApi(
  `/user/${userId}/challenges/`,
  {
    method: 'GET',
    params: {
      page: pageNumber,
      type,
    },
  }
).then( ( data ) => ( { ...data,
  page: pageNumber } ) );

export const fetchChallengesByDate = async (
  userId: number,
  date: Date
) => {

  const day = date.getDate();
  const month = String( date.getMonth() + 1 );
  const year = date.getFullYear();

  return ( await fetchApi<ChallengesData>(
    `/user/${userId}/challenges/`,
    {
      method: 'GET',
      params: {
        day: `${year}-${month.length === 2
          ? month
          : `0${month}`}-${day}`,
        tz: getTimeZone()
      },
    }
  ) ).data;

};

export const fetchSingleChallenge = (
  challengeId: number,
  userId: number
): Promise<Challenge> => fetchApi( `/user/${userId}/challenges/${challengeId}/` );

export const acceptChallenge = (
  challengeId: number,
  userId: number
): Promise<Challenge> => fetchApi(
  `/user/${userId}/challenges/${challengeId}/accept/`,
  {
    method: 'POST',
  }
);

export const completeChallenge = (
  challengeId: number,
  userId: number
): Promise<Challenge> => fetchApi(
  `/user/${userId}/challenges/${challengeId}/complete/`,
  {
    method: 'POST',
  }
);

export const declineChallenge = (
  challengeId: number,
  userId: number
): Promise<Challenge> => fetchApi(
  `/user/${userId}/challenges/${challengeId}/decline/`,
  {
    method: 'POST',
  }
);

export const challengeTaskSignup = (
  challengeId: number,
  taskId: number,
  userId: number
): Promise<Challenge> => fetchApi(
  `/user/${userId}/challenges/${challengeId}/tasks/${taskId}/signup/`,
  {
    method: 'POST',
  }
);

export const challengeTaskWithdraw = (
  challengeId: number,
  taskId: number,
  userId: number
): Promise<Challenge> => fetchApi(
  `/user/${userId}/challenges/${challengeId}/tasks/${taskId}/withdraw/`,
  {
    method: 'DELETE',
  }
);

export const challengeTaskComplete = (
  challengeId: number,
  taskId: number,
  userId: number
): Promise<Challenge> => fetchApi(
  `/user/${userId}/challenges/${challengeId}/tasks/${taskId}/complete/`,
  {
    method: 'POST',
  }
);

export const changeChallengeTaskStatus = (
  challengeId: number,
  taskId: number,
  userId: number,
  status: string
): Promise<Challenge> => fetchApi(
  `/user/${userId}/challenges/${challengeId}/tasks/${taskId}/status/`,
  {
    params: {
      status,
    },
    method: 'PATCH',
  }
);

export const getChallengeDates = (
  communityId: number
) => fetchApi<string[]>(
  `user/community/${communityId}/challenges/start_dates/`,
  {
    method: 'GET',
    params: {
      tz: getTimeZone()
    }
  }
);

// rewards

export const deleteReward = ( rewardId: number ): Promise<string> => fetchApi(
  `/rewards/user/${rewardId}/`,
  {
    method: 'DELETE',
  }
);

export const fetchRewardRecivers = ( userId:number, rewardId: number ): Promise<UserRef[]> => fetchApi(
  `/rewards/user/${userId}/points/rewards/${rewardId}/receivers/`,
  {
    method: 'GET',
  }
).then( ( res ) => {

  const temp = [] as UserRef[];
  res.map( ( reciver:Reward ) => temp.push( reciver.user ) );
  return temp;

} );

export const fetchRewards = (
  userId: number,
  pageNumber?: number,
  type?: 'all' | 'personal' | 'claimed'
): Promise<RewardsData & { page: number }> => fetchApi(
  `/rewards/user/${userId}/points/rewards/`,
  {
    method: 'GET',
    params: {
      page: pageNumber,
      type,
    },
  }
).then( ( data ) => ( { ...data,
  page: pageNumber } ) );

export const sendReward = ( rewardId: number, payload: RewardPayload ): Promise<Reward> => fetchApi(
  `/rewards/user/${rewardId}/points/rewards/`,
  {
    method: 'POST',
    data: payload
  }
);

export const fetchClaimedRewardsHistory = (
  userId: number,
  pageNumber?: number,
): Promise<ClaimRedeemData & { page: number }> => fetchApi(
  `/rewards/user/${userId}/points/rewards/claimed/`,
  {
    method: 'GET',
    params: {
      page: pageNumber
    },
  }
).then( ( data ) => ( { ...data,
  page: pageNumber } ) );

export const fetchRedeemableRewards = (
  userId: number,
  pageNumber?: number,
): Promise<ClaimRedeemData & { page: number }> => fetchApi(
  `/rewards/user/${userId}/points/rewards/redeem/`,
  {
    method: 'GET',
    params: {
      page: pageNumber
    },

  }
).then( ( data ) => ( { ...data,
  page: pageNumber } ) );

export const redeemReward = ( userId: number, rewardId: number ): Promise<string> => fetchApi(
  `rewards/user/${userId}/points/rewards/redeem/${rewardId}/`,
  {
    method: 'POST'
  }
);

// sponsors
export const fetchCommunitySponsors = ( communityId: number ): Promise<Array<Sponsor>> => fetchApi( `/user/community/${communityId}/sponsors/` );

// event
export const fetchAllEvents = (): Promise<AllEvents> => fetchApi( '/user/events' );

export const fetchEvent = ( eventId: number ): Promise<CommunityEvent> => fetchApi( `/user/events/${eventId}/` );

export const fetchEventsByDate = (
  communityId: number,
  date: Date
): Promise<CommunityEvent[]> => {

  const day = date.getDate();
  const month = String( date.getMonth() + 1 );
  const year = date.getFullYear();
  return fetchApi(
    `/user/community/${communityId}/events/`,
    {
      params: {
        day: `${year}-${month.length === 2
          ? month
          : `0${month}`}-${day}`,
        tz: getTimeZone()
      },
    }
  );

};

export const fetchEventDates = ( communityId?: number ) => fetchApi<string[]>(
  `/user/community/${communityId}/events/start_dates/`,
  {
    params: {
      tz: getTimeZone()
    }

  }
);

export const createEvent = ( event: EventPayload ) => fetchApi<CommunityEvent>(
  '/user/events/',
  {
    method: 'POST',
    data: event,
  }
);

export const updateEvent = (
  eventId: number,
  payload: Partial<EventPayload>
): Promise<CommunityEvent> => fetchApi(
  `/user/events/${eventId}/`,
  {
    method: 'PUT',
    data: payload,
  }
);

export const attendEvent = ( eventId: number ): Promise<string> => fetchApi(
  `/user/events/${eventId}/register/`,
  {
    method: 'POST',
    data: { partner: false },
  }
);

export const leaveEvent = ( eventId: number ): Promise<string> => fetchApi(
  `/user/events/${eventId}/register/`,
  {
    method: 'DELETE',
  }
);
export function changeEventAttendance ( eventId: number, attend:boolean ) {

  if ( attend ) {

    return attendEvent( eventId );

  }
  return leaveEvent( eventId );

}
export const deleteEvent = ( eventId: number ): Promise<string> => fetchApi(
  `/user/events/${eventId}/`,
  {
    method: 'DELETE',
  }
);

/**
 * used to fetch a post by its id
 *
 * @param feedItemId
 * @returns
 */
export const getVerboseFeedItem = (
  feedItemId: number
): Promise<VerboseFeedItem> => fetchApi(
  `user/posts/${feedItemId}/verbose/`,
  {
    method: 'GET',
  }
);

export const fetchEventMembers = ( communityId: number, eventId: number ): Promise<EventMembers> => fetchApi(
  `/user/community/${communityId}/events/${eventId}/members/`,
  {
    method: 'GET',
    params: {
      only_ids: true,
      exclude_creator: true
    }
  }
);

// updates announcements
export const fetchManagerUpdates = (
  communityId?: number
): Promise<Array<ManagerUpdate>> => fetchApi(
  `user/community/${communityId}/pins/`,
  {
    method: 'GET',
  }
);

export const createUpdate = (
  update: ManagerUpdateCreationPayload,
  communityId?: number
): Promise<ManagerUpdate> => fetchApi(
  `/admin_api/community/${communityId}/pins/`,
  {
    method: 'POST',
    data: update,
  }
);

/**
 *
 * @param updateId
 * @param pinState true to pin, false to unpin
 * @returns
 */
export const pinUpdate = async (
  updateId: number,
  data:ManagerUpdate&{pinned:boolean}
) => {

  const user = queryCache.getQueryData<User>( 'user' );
  if ( user ) {

    return fetchApi(
      `/admin_api/community/${user.current_community.id}/pins/${updateId}/`,
      {
        method: 'PUT',
        data
      }
    );

  }

};
export const deleteUpdate = async (
  updateId: number
) => {

  const user = queryCache.getQueryData<User>( 'user' );
  if ( user ) {

    return fetchApi(
      `/admin_api/community/${user.current_community.id}/pins/${updateId}/`,
      {
        method: 'DELETE',
      }
    );

  }

};

// manager
export const fetchDashboard = ( id: number ): Promise<DashboardData> => fetchApi(
  `admin_api/community/${id}/dashboard/`,
  {
    method: 'GET',
  }
);

export const fetchRepository = ( param: {
  newer_than?: string;
  sources?: string;
  size?: number;
  older_than?: string;
  term?: string;
  tags?: string;
} ): Promise<object> => fetchApi(
  'admin_api/articles/suggest/',
  {
    method: 'GET',
    params: { ...param },
  }
);

export interface RepoParams {
  search?: string;
  tags?: string;
  sources?: string;
}

export const fetchRepoPagination = (
  lastDate: string|undefined,
  {
    search,
    tags,
    sources,
  }: RepoParams
): Promise<{ systemData: SystemArticlesData; last_cell_date: string }> => fetchApi<any>(
  'user/articles/suggest/',
  {
    params: {
      older_than: lastDate || undefined,
      term: search || undefined,
      size: 20,
      tags: tags || undefined,
      sources: sources || undefined,
    },
  }
).then( ( data ) => {

  const cells = data.articles;
  let last_cell_date = '';
  if ( cells.length ) {

    last_cell_date = cells[cells.length - 1].published_at;

  }
  return { systemData: data,
    last_cell_date };

} );

export const createSystemPost = (
  communityId: number,
  postId: string | number,
  users:number[],
  share_as_system_user?: boolean
): Promise<SystemArticle> => fetchApi(
  `admin_api/articles/community/${communityId}/post/${postId}/`,
  {
    method: 'POST',
    params: { suggested: true },
    data: {
      name: new Date().getTime(),
      as_system_user: share_as_system_user
        ? share_as_system_user
        : false,
      users
    },
  }
);

export const fetchMap = ( id: number ): Promise<MapData> => fetchApi(
  `admin_api/community/${id}/insights/map/`,
  {
    method: 'GET',
  }
);

export const fetchMapUserData = (
  id: number,
  userId: number
): Promise<UserMapData> => fetchApi(
  `admin_api/community/${id}/insights/users/${userId}/`,
  {
    method: 'GET',
  }
);

// community
export const fetchCommunityMasterData = (
  communityId: number
): Promise<CommunityMasterData> => fetchApi<CommunityMasterData>(
  `/user/community/${communityId}/master_data/`
).then( ( data ) => {

  data.organizations = data.organizations.sort();
  data.countries = data.countries.sort();
  return data;

} );

/**
 * when called without params.pages, will not be paginated
 */
export const fetchCommunityMembers = (
  communityId: number,
  params?: FetchMembersPayload
) => fetchApi<{
    members: Community['members'];
    total_count: number;
    members_basic?: Array<any>;
    page?: number;
  }>(
    `v2/user/community/${communityId}/members/`,
    {
      params,
    }
  );

export const fetchCommunityFilters = (
  userId: number,
  communityId: number
): Promise<Filters> => fetchApi(
  `/user/community/${communityId}/filters/`,
  {
    method: 'GET',
    params: { user_id: userId },
  }
);

export const changeCurrentCommunity = (
  communityId: number
): Promise<{ id: number }> => fetchApi(
  `/user/community/${communityId}/set_current_community/`,
  {
    method: 'POST',
  }
);

export const fetchSuggestedCommunities = (): Promise<SuggestedCommunity[]> => fetchApi( '/user/suggested_communities/' );

/**
 *
 * @param suggested
 * @param page if undefined, will return all (no pagination)
 */
export const fetchUserCommunities = ( suggested:boolean, page?:number ) => fetchApi<{
  count: number;
  has_more_pages: boolean;
  communities: UserCommunity[]
}>(
  '/user/community/',
  {
    params: {
      suggested,
      page,
    }
  }
).then( ( data ) => ( { page,
  ...data } ) );

export const createCommunityUserRequest = (
  communityId: number
): Promise<onBoradingCommunityPayload> => fetchApi(
  `/user/community/${communityId}/create_community_user/`,
  {
    method: 'POST',
  }
);

/**
 *
 * @param userId must be the user's network id on the community you are trying to join, not the current community, which is received from `createCommunityUserRequest` api
 * @param language
 * @param data
 * @returns
 */
export const onBoardUserToCommunity = (
  userId: number,
  language: string,
  data: OnboardingPayload
) => fetchApi<{
  message:string;
  invited_by_manager:boolean;
}>(
  `/user/${userId}/on_board/`,
  {
    method: 'POST',
    headers: { language },
    data,
  }
);

export const mailToCommunityManagerForPermission = (
  communityId: number
): Promise<any> => fetchApi(
  `/user/community/${communityId}/request_to_join/`,
  {
    method: 'POST',
  }
);

export const createFilter = (
  userId: number,
  payload: CreateFilterPayload
): Promise<any> => fetchApi(
  `/user/${userId}/filters/definitions/`,
  {
    method: 'POST',
    data: payload,
  }
);


export const inviteNewMember = (
  communityId: number,
  payload:InviteNewMemberPayload,
): Promise<any> => fetchApi(
  `user/community/${communityId}/members/invitations/`,
  {
    method: 'POST',
    data: payload,
  }
);

// chat //messages

export const fetchChatUsers = (): Promise<UserChats> => fetchApi(
  '/user/messages/list/',
  {
    params: {
      mobile: true,
    },
  }
);
export const fetchChatUser = ( userId: number ): Promise<MessageUser> => fetchApi( `user/${userId}/profile/chat_profile/` );

export const createChatGroup = (
  payload: ChatGroupCreatePayload
): Promise<ChatGroup> => fetchApi(
  '/user/hubs/create/',
  {
    method: 'POST',
    data: payload,
  }
);
export const fetchChatHub = ( hubId: number ): Promise<Hub> => fetchApi( `user/hubs/${hubId}/` );

export const updateChatHubName = ( hubId: number, value:string ): Promise<Hub> => fetchApi(
  `user/hubs/${hubId}/`,
  {
    method: 'PATCH',
    params: {
      users: false
    },
    data: { group_title: value }
  }
);


export const updateChatHubUsers = (
  hubId: number,
  users: Array<number>
): Promise<Hub> => fetchApi(
  `user/hubs/${hubId}/`,
  {
    method: 'PATCH',
    params: { users: true },
    data: { users },
  }
);
export const fetchPrivateChatMessages = (
  userId: number,
  page = 1
): Promise<GroupMessages & { page: number }> => fetchApi(
  '/user/messages/private/',
  {
    params: {
      target_id: userId,
      page,
    },
  }
).then( ( data ) => ( { page,
  ...data } ) );

export const fetchPrivateChatMessagesUpTo = (
  messageId: number
): Promise<GroupMessages> => fetchApi( `/user/messages/private/${messageId}/` );

export const fetchGroupChatMessagesUpTo = (
  messageId: number
): Promise<GroupMessages> => fetchApi( `/user/messages/private/${messageId}/` );

export const sendChatMessage = (
  message: ChatMessagePayload
): Promise<Message> => fetchApi(
  '/user/messages/send_message/',
  {
    data: message,
    method: 'POST',
  }
);

export const updateChatMessage = (
  messageId:number,
  message: Pick<ChatMessagePayload, 'plaintext'|'ranges'|'type'|'text'>,
  target:'private'|'group'
): Promise<Message> => fetchApi(
  `user/messages/${target}/${messageId}/`,
  {
    data: message,
    method: 'PATCH',
  }
);

export const deleteChatMessage = (
  messageId:number,
  target:'private'|'group'
): Promise<Message> => fetchApi(
  `user/messages/${target}/${messageId}/`,
  {
    method: 'DELETE',
  }
);

export const fetchItemMembers = {
  post: fetchPostMembers,
  media: fetchMediaViewingMembers,
  poll: fetchPollMembers,
  event: fetchEventMembers,
  video: () => {

    throw new Error( 'limited access not availabe for video items' );

  },
  article: () => {

    throw new Error( 'limited access not availabe for article items' );

  },
  reward: () => {

    throw new Error( 'limited access not availabe for reward items' );

  }
};


export const getBioOptions = (
  userId: number,
): Promise<BioOptions> => fetchApi( `openai/user/${userId}/bio/`).then(data => data.options)

export const updateBioOptions = (
  userId: number,
  bio: string,
  removeCookies = false,
): Promise<IBioResponse> => fetchApi( `openai/user/${userId}/bio/`, {
  method: 'PATCH',
  data: {bio, remove_cookies: removeCookies}
})
