import styled, { css } from 'styled-components';

import _Avatar from '~/shared/components/Avatar';
import { default as DateBanner } from '~/shared/images/date-banner.svg';
import { ReactComponent as AttachSvg } from '~/shared/images/attach.svg';
import { withHebrew } from '~/shared/utils/language';

export const Scrollbar = css`
  /* firefox > 64 */
  scrollbar-color: ${( { theme } ) => theme.colors.primary} #eaedf1;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #eaedf1;
    border-radius: 5px;
    :hover {
      background: #eaedf1;
    }
  }
  ::-webkit-scrollbar-thumb {
    background: #7dd0ca;
    border-radius: 5px;
    :hover {
      background: #4ab6af;
    }
  }
`;


export const Box = styled.div<{ rtl?: boolean }>`
  width: 100%;
  background-color: ${( { theme } ) => theme.colors.white};
  border-radius: 10px;
  box-shadow: 0 2px 22px 0 rgba(10, 33, 56, 0.1);
  box-sizing: border-box;
  /* margin-top: 20px; */
  overflow: hidden;
  ${( { rtl } ) => withHebrew( rtl )};
`;

export const Shelf = styled.div`
  /* height:100%; */
  font-family: inherit;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
`;

const DotSpan = styled.span`
  font-size: 19px;
  display: inline-flex;
  padding: 0 8px;
  font-weight: 900;
`;

export const Dot = ( { className }: { className?: string } ) => (
  <DotSpan className={className}>&#183;</DotSpan>
);

export const Line = styled.div`
  border-color: 2px;
  min-height: 1px;
  width: 100%;
  background-color: ${( { theme } ) => theme.colors.divider};
`;

export const PageHeader = styled.h3`
  padding: 20px 26px;
  font-family: inherit;
  margin: 0;
  border-bottom: solid 2px;
  border-color: ${( { theme } ) => theme.colors.borderLight};
  position: relative;
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    font-size: 15px;
  }
`;

export const BaseButton = styled.button<{ rtl?: boolean }>`
  border: none;
  outline: none;
  gap: 10px;
  background-color: transparent;
  font-family: inherit;
  &:disabled {
    cursor: default;
  }
  ${( { rtl } ) => withHebrew( rtl )}
`;

export const Button = styled( BaseButton )`
  cursor:pointer;
  ${( { theme } ) => theme.typing.textSmallBold};
  font-family: inherit;
`;

export const PostFooterWrapper = styled.footer`
  display: flex;
  margin-top: 15px;
  justify-content: space-around;
`;


export const InputLabel = styled.label`
  ${( { theme } ) => theme.typing.textTinyBold}
  text-transform: uppercase;
  color: ${( { theme } ) => theme.colors.textLight2};
  font-family: inherit;
`;

export const FeedItemBarRoot = styled.div`
  display: flex;
  padding: 15px 0;
  justify-content: space-around;
  position: relative;
  font-family: inherit;
`;

export const feedItemInteractiveLabel = css`
  font-size: ${( { theme } ) => theme.typing.fontSizes.small};
  color: ${( { theme } ) => theme.colors.textLight};
  text-transform: uppercase;
  &:not(:disabled):hover {
    color: ${( { theme } ) => theme.colors.primary};
  }
  font-family: inherit;
  position: relative;
`;

export const commentItemInteractiveLabel = css`
  font-size: ${( { theme } ) => theme.typing.fontSizes.smaller};
  letter-spacing: 0.48px;
  color: ${( { theme } ) => theme.colors.textLight};
  text-transform: uppercase;
  &:not(:disabled):hover {
    color: ${( { theme } ) => theme.colors.primary};
  }
  font-family: inherit;
`;

export const feedActionStyles = css`
  padding: 5px;
  font-family: inherit;
  padding-left: 30px;
  position: relative;
  ${feedItemInteractiveLabel}
  &.active {
    color: ${( { theme } ) => theme.colors.primary};
    g,
    path {
      fill: ${( { theme } ) => theme.colors.primary};
    }
  }
  &:not(:disabled):hover {
    g,
    path {
      fill: ${( { theme } ) => theme.colors.primary};
    }
  }
  & svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const commentActionStyles = css`
  font-family: inherit;
  position: relative;
  ${commentItemInteractiveLabel}
  &.active {
    color: ${( { theme } ) => theme.colors.primary};
    g,
    path {
      fill: ${( { theme } ) => theme.colors.primary};
    }
  }
  &:not(:disabled):hover {
    g,
    path {
      fill: ${( { theme } ) => theme.colors.primary};
    }
  }
  & svg {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const FullWidthFeedItemContent = styled.div`
  width: calc(100% + 40px);
  margin: 0 -20px;
  position: relative;
`;

export const InfoPopupDescriptionHeader = styled( InputLabel )`
  margin-bottom: 14px;
  cursor: inherit;
  font-family: inherit;
`;

export const CommunityName = styled.span`
  font-weight: ${( { theme } ) => theme.typing.fontWeights.bold};
  text-align: center;
  font-size: ${( { theme } ) => theme.typing.fontSizes.normal};
`;


export const PostHeaderRoot = styled.div`
  display: flex;
  max-width: 90%;
  font-family: inherit;
`;

export const PostHeading = styled.span`
  color: ${( { theme } ) => theme.colors.textDark2};
  ${( { theme } ) => theme.typing.textLargeBold};
  font-family: inherit;
  @media ${( { theme } ) => theme.typing.mediaRules.untilMedium} {
    font-size: ${( { theme } ) => theme.typing.fontSizes.small};
  }
`;

export const PostHeaderTextWrapper = styled.div<{ isHebrew: boolean }>`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: space-between;
  ${( { isHebrew } ) => isHebrew && 'padding-right:10px;'};
  font-family: inherit;
`;

export const PostHeaderTextLine = styled.div`
  display: flex;
  align-items: center;
  font-family: inherit;
  & svg {
    margin-right: 5px;
  }
`;

export const PostHeaderInfoCaption = styled.span`
  color: ${( { theme } ) => theme.colors.textMedium};
  font-weight: ${( { theme } ) => theme.typing.fontWeights.bold};
  text-transform: uppercase;
  font-size: ${( { theme } ) => theme.typing.fontSizes.small};
  font-weight: 700;
  font-family: inherit;
  @media ${( { theme } ) => theme.typing.mediaRules.untilMedium} {
    font-size: ${( { theme } ) => theme.typing.fontSizes.smaller};
  }
`;

export const DateBannerWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 20px;
  width: 76px;
  height: 60px;
  display: flex;
  flex-direction: column;
  font-family: inherit;
  background-image: url(${DateBanner});
`;

export const PostHeaderAvatar = styled( _Avatar ).attrs( { borderWidth: 0,
  size: 40, } )`
  font-family: inherit;
`;
/* eslint-disable-next-line object-property-newline */
export const AttachIcon = styled( AttachSvg ).attrs( { width: 11, height: 22, viewBox: '4 0 14 20' } )`
  width: 11px;
  height: 22px;
  transform: scaleY(1.2);
  position: relative;
  top: -3px;  
  & g {
    fill: ${( { theme } ) => theme.colors.primary};
  }
`;

export const ListItemWrapper = styled.div`
  display: flex;
  min-height: 90px;
  width: 100%;
  border-bottom: 1px solid ${( { theme } ) => theme.colors.divider};
  /* justify-content: space-between; */
  font-family: inherit;
  align-items: center;
  :hover {
    background: ${( { theme } ) => theme.colors.hoverLight};
  }
`;

export const ListItemMainInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: inherit;
`;

export const ListItemInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: stretch;
  justify-content: space-between;
  font-family: inherit;
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    max-width: 200px;
  }
`;

export const ListItemName = styled.span`
  font-weight: ${( { theme } ) => theme.typing.fontWeights.semiBold};
  font-family: inherit;
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ListItemInfoText = styled.span`
  font-size: ${( { theme } ) => theme.typing.fontSizes.small};
  font-weight: ${( { theme } ) => theme.typing.fontWeights.normal};
  max-width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: inherit;
`;

export const FloatingMenu = styled( Box )`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index:${( { theme } ) => theme.typing.zIndexes.popup};
  overflow: auto;
  font-family: inherit;
`;

export const Notice = styled.p`
  text-align: center;
  color: ${( { theme } ) => theme.colors.textMedium};
  font-size: large;
  font-weight: 600;
`;

export const Column = styled.section`
  display: flex;
  flex-direction: column;
`;
