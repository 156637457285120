import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BaseButton as Button } from '~/shared/components/Button';


import { ReactComponent as SuccessSvg } from '~/shared/images/tick-success-blue.svg';
import { Root, Header, Message } from './styled';

const SuccessIcon = styled( SuccessSvg )`
  circle{
    fill: ${( { theme } ) => theme.colors.primary};
    stroke: ${( { theme } ) => theme.colors.primary};
  }
`;


export function RecoverySuccessPage () {

  const { t } = useTranslation();
  return <Root>
    <SuccessIcon/>
    <Header>
      {t( 'Recovery.success.title' )}
    </Header>
    <Message>
      {t( 'Recovery.success.text' )}
    </Message>
    <Button
      as={Link}
      to="/auth"
      style={{
        textDecoration: 'none'
      }}
    >
      {t( 'Recovery.success.next' )}
    </Button>
  </Root>;

}
