import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import _ImageUploader from './ImageUploader';
import { rankPercentHelper } from './rankPercentHelper';
import GeneralInformation from './GeneralInformation';
import ContactInformation from './ContactInformation';
import List from './List';
import ListSelector from './ListSelector';
import {
  Root as _Root,
  HeaderContent,
  BodyTopContent,
  CommunityName,
  Status,
  Name,
  NameWrapper,
  EditIconButton
} from './styles';

import { errorMessage } from '~/shared/utils';
import { useFilesUploading, useRTL } from '~/shared/hooks';
import { queryCache } from '~/app/components/queryCache';
import {
  Avatar as _Avatar,
  AvatarRank as _AvatarRank,
} from '~/shared/components';
import {
  api,
  UserProfile,
  Expertise,
  Interest,
  User,
  UpdatedUserProfile,
} from '~/shared/services/api';
import DefaultUserProfileImage from '~/shared/images/default-user-profile-image.jpg';
import { ContainedSpinner } from '~/shared/components/Spinner';
import { isHebrew } from '~/shared/utils/language';
import { Scrollbar } from '~/shared/components/styled';
import { Formik } from 'formik';
import { updateUserById } from '~/shared/services/api/api';

const Root = styled( _Root )`
  margin-top: 20px;
  /* width: 100%; */
  overflow-y: auto;
  overflow-x: hidden ${Scrollbar};
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    padding: 0 10px;
    height: 100%;
  }
`;

const Avatar = styled( _Avatar ).attrs( {
  size: 170,
  mobileSize: 120,
  borderColor: '#fff',
  borderWidth: 5,
} )`
  box-shadow: 3px 7px 26px 0 rgba(112, 132, 154, 0.12);
`;

const ImageUploader = styled( _ImageUploader ).attrs( {
  size: 170,
  mobileSize: 120,
  borderColor: '#fff',
  borderWidth: 5,
} )`
  box-shadow: 3px 7px 26px 0 rgba(112, 132, 154, 0.12);
`;

const AvatarRank = styled( _AvatarRank ).attrs( {
  paddingLeft: 45,
  paddingBottom: 6,
  containerSize: 48,
  size: 27,
  backgroundColor: '#fff',
  borderWidth: 5,
  borderColor: '#ccc',
  highlightBorderColor: '#0d3750',
} )`
  color: #fff;
  font-family: inherit;
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    margin-bottom: -4px;
    /* &>span{
    } */
  }
`;

const HeaderContainer = styled.div<{ isUser: boolean }>`
  height: 144px;
  background-color: ${( { theme, isUser } ) => ( isUser
    ? theme.colors.backgroundDark
    : '#bfc329' )};
  display: flex;
  flex-direction: row;
  position: relative;
  font-family: inherit;
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    height: 94px;
  }
`;
const transltaionBase = 'Popups.Update profile.';

function RenderList( props: {
  list: Expertise[];
  isEdit: boolean;
  setList: ( list: Expertise[] ) => void;
  baseName: string;
} ): JSX.Element;
function RenderList( props: {
  list: Interest[];
  isEdit: boolean;
  setList: ( list: Interest[] ) => void;
  baseName: string;
} ): JSX.Element;
function RenderList ( props: {
  list: ( Expertise | Interest )[];
  isEdit: boolean;
  setList: ( list: ( Expertise | Interest )[] ) => void;
  baseName: string;
} ) {

  const { t } = useTranslation();
  const {
    list, setList, isEdit, baseName
  } = props;
  return (
    ( !!list?.length || isEdit ) &&
    ( isEdit
      ? (
        <ListSelector
          listType={baseName}
          header={t( `${transltaionBase + baseName}.Title` )}
          onChange={setList}
          items={list.map( ( exp ) => exp.id )}
        />
      )
      : (
        <List
          header={t( `${transltaionBase + baseName}.Title` )}
          items={list}
        />
      ) )
  );

}
const imageExtensions = [
  'png',
  'jpg',
  'jpeg',
  'svg'
];
export const isFileImage = ( type: string ): boolean => {

  const [ fileType, fileExt ] = type.split( '/' );
  return fileType === 'image' && imageExtensions.includes( fileExt );

};

type Props = {
  userNetworkId: number;
  onClose?: () => void;
  isUser: boolean;
};

const ProfilePopup = ( {
  userNetworkId, isUser
}: Props ) => {

  const { t, i18n } = useTranslation();
  const { upload } = useFilesUploading( 'user-icon/' );
  const profileKey = [ 'profile',
    String( userNetworkId ) ];

  const { data: fetchedUser } = useQuery(
    profileKey,
    () => api.fetchUserById( userNetworkId ),
    {

      /*
       * cacheTime: isUser ? Infinity : 0, // do we want cache on this?
       * initialStale: true,
       */
      onError ( e ) {

        errorMessage( ( ( e as unknown ) as Error ).message );

      },
    }
  );

  const [ updateProfile, { isLoading: isUpdating, } ] = useMutation(
    ( updatedUser:UpdatedUserProfile ) => updateUserById(
      userNetworkId,
      updatedUser
    ).then( ( updatedProfile ) => {

      // can't be done in onSuccess, as the queryCache needs to be updated before isUpdating is false
      const {
        full_name,
        image,
        phone_number,
        address,
        current_role,

      } = updatedProfile;
      queryCache.setQueryData<User>(
        'user',
        ( user ) => ( {
          ...user as User,
          full_name,
          image,
          phone_number,
          address,
          current_role,
        } )
      );
      queryCache.setQueryData(
        profileKey,
        updatedProfile
      );

    } ),
    {


    }
  );

  const [ isEdit, setIsEdit ] = useState( false );

  const community_name = fetchedUser?.community_name;

  const rankBorderProps = rankPercentHelper(
    fetchedUser?.rank && fetchedUser?.rank
      ? ( fetchedUser?.rank?.all_points as number )
      : 0
  );

  const [ blobObj, setBlobObj ] = useState<any>( null );
  useEffect(
    () => () => {

      blobObj && URL.revokeObjectURL( blobObj.url );

    },
    [ blobObj ]
  );

  const rtl = useRTL();
  if ( ( fetchedUser === undefined ) || isUpdating ) {

    return <Root>
      <ContainedSpinner/>
    </Root>;

  }
  return (
    <Formik
      initialValues={{
        ...fetchedUser as UserProfile,
        image: ''
      }}
      onSubmit={async ( { image, ...values } ) => {

        let selectedImage = fetchedUser.image;
        if ( image !== '' ) {

          const mediaResponse = await upload( [ blobObj ] );

          selectedImage = mediaResponse[0].path;
          setBlobObj( null );

        }
        const updatedUserData: UpdatedUserProfile = {
          id: userNetworkId,
          image: selectedImage,
          full_name: values.full_name,
          current_role: values.current_role,
          community_name: community_name || '',
          rank: values.rank,
          cv: values.cv,
          address: values.address,
          email: values.email,
          phone_number: values.phone_number,
          expertises: values.expertises.map( ( e ) => e.id ),
          work_experience: values.work_experience,
          interests: values.interests.map( ( e ) => e.id ),
          profile_config: values.profile_config,
        };
        await updateProfile( updatedUserData );

      }}
    >
      {( {
        values, setFieldValue, handleSubmit, dirty, resetForm
      } ) => {

        const {
          interests,
          expertises,
          email,
          phone_number: phone,
          current_role: currentRole,
          full_name: name,
          rank,
          image
        } = values;
        const onImageChange = ( evt: React.ChangeEvent<HTMLInputElement> ) => {

          const file = evt.target?.files?.[0];
          if ( file ) {

            if ( !isFileImage( file.type ) ) {

              const error = 'Unsupported file type';
              errorMessage( ( ( error as unknown ) as Error ).message );
              throw new Error( 'Unsupported file type' );

            }
            const bloble = URL.createObjectURL( file );
            setFieldValue(
              'image',
              bloble
            );
            setBlobObj( {
              file,
              url: bloble,
              type: 'Photo',
            } );

          }

        };
        async function onEditButtonClick () {

          if ( dirty ) {

            handleSubmit();

          } else {

            resetForm();

          }
          setIsEdit( !isEdit );

        }
        return <Root>
          <HeaderContainer isUser={isUser}>
            <HeaderContent rtl={rtl}>
              { (
                isEdit
                  ? <ImageUploader
                    imgSrc={ image || (
                      fetchedUser.image
                        ? fetchedUser.image
                        : DefaultUserProfileImage
                    )}
                    onChange={onImageChange}
                    accept="image/jpeg,image/png,image/gif,image/jpg"
                  />
                  : <>
                    <Avatar
                      imgSrc={ image || (
                        fetchedUser.image
                          ? fetchedUser.image
                          : DefaultUserProfileImage
                      )}
                    />
                    {rank && (
                      <AvatarRank
                        hideProgress={true}
                        rankBorderProps={rankBorderProps}
                        points={( rank && rank && rank.all_points ) || 0}
                        imgSrc={
                          rank && rank.image
                            ? rank && rank && rank.image
                            : undefined
                        }
                        rank={rank}
                      />
                    )}
                  </>
              )}
              {isUser && (
                <EditIconButton
                  isEdit={isEdit}
                  onClick={onEditButtonClick}
                >
                  {isEdit
                    ? <>&#x2714;</>
                    : <>&#x270E;</>}
                </EditIconButton>
              )}
            </HeaderContent>
          </HeaderContainer>

          <BodyTopContent>
            <NameWrapper isHebrew={isHebrew( i18n )}>
              <Name>{name}</Name>
              {currentRole && <Status> {currentRole}</Status>}
            </NameWrapper>
            <CommunityName>
              {community_name} {t( `${transltaionBase}Community` )}
            </CommunityName>
          </BodyTopContent>
          <GeneralInformation
            isEdit={isEdit}
            currentRole={currentRole}
            setCurrentRole={( value ) => setFieldValue(
              'current_role',
              value
            )}
            name={name}
            setName={( value ) => setFieldValue(
              'full_name',
              value
            )}
            about={values.cv}
            setAbout={( value ) => setFieldValue(
              'cv',
              value
            )}
            city={values.address.city}
            setCity={( value ) => setFieldValue(
              'address.city',
              value
            )}
            country={values.address.country}
            setCountry={( value ) => setFieldValue(
              'address.country',
              value
            )}
            TEXTAREA_PLACEHOLDER={t( 'Popups.Placeholders.Type here' )}
          />
          <ContactInformation
            email={email}
            phone={phone}
            hidePhone={values.profile_config.hide_phone_number}
            setPhone={( value ) => setFieldValue(
              'phone_number',
              value
            )}
            setHidePhone={( value ) => setFieldValue(
              'profile_config.hide_phone_number',
              value
            )}
            hideEmail={values.profile_config.hide_email}
            setHideEmail={( value ) => setFieldValue(
              'profile_config.hide_email',
              value
            )}
            isEdit={isEdit} />

          <RenderList list={expertises}
            setList={( value ) => {

              setFieldValue(
                'expertises',
                value
              );

            }}

            isEdit={isEdit}
            baseName="Expertise"
          />
          <RenderList list={interests}
            setList={( value ) => {

              setFieldValue(
                'interests',
                value
              );

            }}
            isEdit={isEdit}
            baseName="Interests"
          />
        </Root>;

      }}
    </Formik>
  );

};

export default ProfilePopup;

