import { ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Avatar } from '~/shared/components';
import { useRTL } from '~/shared/hooks';


import { ReactComponent as UploadSvg } from '~/shared/images/file-upload.svg';
import { withHebrew } from '~/shared/utils/language';


type ImageProps = { imgSrc?: string };

const UploadInput = styled.input`
  display: none;
`;

export const Label = styled.label<( {rtl:boolean} )>`
  position: absolute;
  bottom: 18px;
  left: calc(50% + 80px);
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  ${( { rtl } ) => withHebrew( rtl )}
  :focus-visible{
    outline-color: blue;
    outline-style: solid;
    outline-width: 2px;
  }
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    bottom: 10px;
    left: calc(50% + 50px);
    font-size: ${( { theme } ) => theme.typing.fontSizes.smaller};
  }
`;
const UploadIcon = styled( UploadSvg )`
  path{
    fill:black;
  }
`;
type UploaderProps = {
  borderWidth: number;
  size: number;
  borderColor?: string
  UploadLabel?( props:ComponentPropsWithoutRef<'label'> & {rtl:boolean} ):JSX.Element;
  Icon?: typeof UploadIcon;
 };
const ImageUploader = (
  {
    onChange,
    imgSrc,
    tabIndex,
    UploadLabel = Label,
    Icon = UploadIcon,
    ...props
  }: UploaderProps & ImageProps & ComponentPropsWithoutRef<'div'> & ComponentPropsWithoutRef<'input'>
) => {

  const { t } = useTranslation();
  const rtl = useRTL();

  return (
    <>
      {imgSrc && <Avatar
        tabIndex={-1}
        imgSrc={imgSrc}
        {...props}
        title={imgSrc}
      />
      }
      <UploadLabel rtl={rtl} tabIndex={tabIndex}>
        <Icon />
        {t( 'Popups.Update profile.Image.Title' )}
        <UploadInput type="file" onChange={onChange} {...props} />
      </UploadLabel>
    </>
  );

};

export default ImageUploader;
