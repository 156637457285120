import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import _Avatar from './Avatar';
import { Line as _Line, Button } from './styled';
import ClampLines from './ClampLines';

import { useRTL } from '~/shared/hooks';
import { Box } from '~/shared/components/styled';
import { withHebrew } from '~/shared/utils/language';
import { chimuLogo } from '~/shared/images';
import { ReactComponent as SearchSvg } from '~/shared/images/search.svg';
import { ReactComponent as GenerateLinkIcon } from '~/shared/images/generate-link-icon.svg';

const Root = styled.div<{ rtl: boolean }>`
  ${( { rtl } ) => withHebrew( rtl )};
  position: relative;
  max-width: 300px;
  width: 100%;
  border: 1px solid rgb(62, 186, 211);
  display: flex;
  flex-direction: column;
  height: 363px;
  font-family: Montserrat, sans-serif;
  border-radius: 10px;
  box-shadow: 0 17px 25px 0 rgb(10 33 56 / 12%);
  overflow: hidden;
  @media (max-width: 580px) {
    overflow-x: hidden;
    width: 80vw;
  }
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmallMobile} {
    width: 72vw;
    overflow-x: hidden;
    height: 100%;
  }
`;

const Header = styled.div`
  height: 69px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 25px;
  margin-left: 20px;
  padding-top: 10px;
  @media (max-width: 400px) {
    margin-right: 0px;
    margin-left: 0px;
    justify-content: flex-start;
  }
`;

const IconTitle = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 5px;
  padding-left: 5px;
`;

const TitleDate = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 2px;
`;

const Avatar = styled.div.attrs( ( { theme } ) => ( {
  borderWidth: 3,
  borderColor: theme.colors.secondary,
  size: 40,
  width: '46px',
  height: '46px',
} ) )``;

const TitleLabel = styled.span`
  font-family: inherit;
  font-weight: ${( { theme } ) => theme.typing.fontWeights.bold};
  text-align: center;
  font-size: ${( { theme } ) => theme.typing.fontSizes.smallMobile};
  line-height: 1.36;
  color: ${( { theme } ) => theme.colors.textDark6};
  text-align: start;
`;

const Line = styled( _Line )`
  width: 100%;
  align-self: center;
`;

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Montserrat, sans-serif;
  overflow-y: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 500;
`;

const Description = styled( ClampLines )`
  height: 69px;
  width: 95%;
  padding: 5px 15px;
  margin-top: 20px;
  text-align: center;
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmallMobile} {
    display: none;
  }
`;

const InputBox = styled( Box )<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: visible;
  position: relative;
  height: 60px;
  max-width: 250px;
  align-self: center;
  border-radius: 10px;
  box-shadow: 0 2px 22px 0 rgb(10 33 56 / 10%);
  margin-top: 20px;
`;

const Input = styled.input`
  border: none;
  flex: 1;
  color: ${( { theme } ) => theme.colors.textDark};
  opacity: 0.5;
  font-size: ${( { theme } ) => theme.typing.fontSizes.small};
  background-color: inherit;
  padding: 5px;
  font-family: inherit;
  max-width: 100%;
`;

export const SearchIcon = styled( SearchSvg )`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
`;

export const InputWrapper = styled.div<{ rtl: boolean }>`
  position: relative;
  padding: 16px;
  display: flex;
  width: 100%;
  ${( { rtl } ) => `
    ${withHebrew( rtl )}
    padding-${rtl
    ? 'right'
    : 'left'}: 50px;`}
  ${SearchIcon} {
    ${( { rtl } ) => ( rtl
    ? 'right'
    : 'left' )}: 20px;
  }
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    width: 100%;
  }
`;

const PublishButton = styled( Button )`
  width: 100%;
  max-width: 150px;
  align-self: center;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat, sans-serif;
  text-align: center;
  color: ${( { theme } ) => theme.colors.white};
  background-color: ${( { theme } ) => theme.colors.primary};
  text-transform: uppercase;
  font-size: ${( { theme } ) => theme.typing.fontSizes.small};
  font-weight: 700;
  padding: 4px 7px;
  border-radius: 6px;
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmallMobile} {
    margin-bottom: 20px;
  }
`;

const Error = styled.div`
  color: ${( { theme } ) => theme.colors.lightRed};
  font-size: ${( { theme } ) => theme.typing.fontSizes.small};
  width: 200px;
  align-self: center;
  margin-top: 20px;
`;

const CreateArticleCard = ( articleURL: any ) => {

  const { hasError, setHasError, onFetchData } = articleURL;
  const rtl = useRTL();
  const { t, i18n } = useTranslation();
  const [ search, setSearch ] = useState( '' );
  const [ searchUrl, setSearchUrl ] = useState( false );
  const buttonRef = useRef<HTMLButtonElement>( null );

  useEffect(
    () => {

      const handleKeyUp = ( event: KeyboardEvent ) => {

        if ( event.key === 'Enter' ) {

          buttonRef.current?.click();

        }

      };

      const input = document.getElementById( 'link-input' );
      input?.addEventListener(
        'keyup',
        handleKeyUp
      );

      return () => {

        const input = document.getElementById( 'link-input' );
        input?.removeEventListener(
          'keyup',
          handleKeyUp
        );

      };

    },
    [ buttonRef ]
  );

  return (
    <Root rtl={rtl}>
      <Header>
        <IconTitle>
          <Avatar>
            <GenerateLinkIcon />
          </ Avatar>
          <TitleDate>
            <TitleLabel>{t( 'Popups.New post.Create article' )}</TitleLabel>
          </TitleDate>
        </IconTitle>
      </Header>
      <Line />
      <ArticleContainer>
        <InputBox rtl={rtl} isActive={!searchUrl}>
          <InputWrapper rtl={rtl}>
            <SearchIcon />
            <Input
              id="link-input"
              value={search}
              onChange={( evt ) => {

                if ( hasError ) {

                  setHasError( false );

                }
                setSearch( evt.target.value );

              }}
              placeholder={t( 'Popups.New post.URL placeholder' )}
            />
          </InputWrapper>
        </InputBox>
        <PublishButton ref={buttonRef} onClick={() => onFetchData( search )}>
          {t( 'Popups.New post.Get URL article' )}
        </PublishButton>
        {hasError && <Error>{t( 'Popups.New post.Error' )}</Error>}
        {!hasError && <Description>{t( 'Popups.New post.Add url' )}</Description>}
      </ArticleContainer>
    </Root>
  );

};

export default CreateArticleCard;
