import styled, { css } from 'styled-components';

import {
  cot,
  metafinanceSvg,
  munich,
  mhi,
} from '../images/sponsors';

const globalStyle = css`
  max-width: 100%;
  margin: auto;
  max-height:65px;
  /* filter: grayscale(); */
  /* *{
    fill: rgb( 198, 198, 198 )
  } */

`;

export const MunichSvg = styled( munich ).attrs( () => ( { viewBox: '0 0 1478.1262 712.125' } ) )`
  ${globalStyle}
`;
export const MhiSvg = styled( mhi ).attrs( () => ( { viewBox: '0 -0.605 670 124' } ) )`
  ${globalStyle}
 
`;
export const CoT = styled( cot )`
  ${globalStyle}

`;
export const MFsvg = styled( metafinanceSvg )`
  ${globalStyle}
  
`;
