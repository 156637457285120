import { QueryConfig, useQuery } from 'react-query';
import { api } from '~/shared/services/api';
import useCurrentCommunity from './useCurrentCommunity';

const useCommunityConfig = (): Partial<{ rewards:boolean, challenges:boolean }> => {

  const { currentCommunity } = useCurrentCommunity();
  const { data } = useQuery(
    [ 'communityConfig',
      currentCommunity?.id ],
    api.getConfig,
    {
      cacheTime: Infinity,
      enabled: !!currentCommunity
    }
  );

  return { ...data };

};

export default useCommunityConfig;
