import { useTranslation } from 'react-i18next';
import { QueryConfig, useQuery } from 'react-query';
import { queryCache } from '~/app/components/queryCache';

import { api } from '~/shared/services/api';
import { User } from '~/shared/services/api/types';

export const useMyUserQuery = ( queryConfig?: QueryConfig<User, unknown> ) => {

  const { i18n } = useTranslation();
  const {
    data: user, error, isLoading, refetch, ...rest
  } = useQuery<User>(
    'user',
    api.fetchUser,
    {
      ...queryConfig,
      onSuccess ( user ) {

        const current_community = user?.current_community;
        i18n.changeLanguage( current_community?.preferred_locale );

      }
    }
  );
  function updateCurrentCommunity ( updatedCommunity:User['current_community'] ) {

    queryCache.setQueryData(
      'user',
      () => {

        const newUser :User = {
          ...user!,
          current_community: updatedCommunity
        };
        return newUser;

      }
    );

  }
  return {
    user,
    error,
    isLoading,
    refetch,
    updateCurrentCommunity,
    ...rest,
  };

};

export default useMyUserQuery;
