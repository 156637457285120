import { fetchRepoPagination } from '~/shared/services/api/api';
import { useInfiniteQuery } from 'react-query';
import { SystemArticlesData } from '~/shared/services/api';

const useRepo = (
  {
    tags,
    search,
    sources
  }:{
    search: string|undefined,
    tags: string|undefined,
    sources: string|undefined
  }
) => useInfiniteQuery<{
    systemData: SystemArticlesData;
    last_cell_date: string;
  }>(
    [
      'articles',
      search,
      tags,
      sources
    ],
    async ( ...args ) => {

      const lastDate = args[args.length - 1];
      return fetchRepoPagination(
        lastDate,
        {
          search,
          tags,
          sources
        }
      );

    }
    ,
    {
      getFetchMore: ( lastData ) => lastData.last_cell_date,
      refetchOnWindowFocus: false,
      keepPreviousData: true
    }
  );

export default useRepo;
