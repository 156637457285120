import React, { useState, ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

const Root = styled.section<{width?:string}>`
  overflow:hidden;
  width:100%;
  position:relative;
`
const Runner = styled.section<{duration:number; grayscale:boolean}>`
  position:absolute;
  left:0;
  display:grid;
  width:calc( 200% - 60px);
  grid-column-gap:30px;
  /* grid-template-rows: 100px; */
  /* grid-auto-columns: 1fr; */
  grid-auto-flow: column;
  animation: marquee linear infinite;
  animation-duration: ${({duration})=>duration}s ;
  transform: translateX(0%);
  padding:0 15px;
  img{
    ${({grayscale})=>grayscale?'filter: saturate(0);':''}
  }
  :hover{
    animation-play-state:paused;
  }

  @keyframes marquee {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-50%);
  }
}
@media ${ ({theme}) => theme.typing.mediaRules.untilSmall} {
  width:calc( 350% - 60px);
  animation-duration: 14s;  
}
@media (max-width:450px) {
  width:calc( 800% - 60px);
}
`

interface Props extends React.ComponentPropsWithoutRef<'section'>{
  duration?:number;
  grayscale?:boolean
}

export const Marquee = ({children, duration=8,grayscale=false, ...props}:Props) =>{
  return <Root {...props}>
    <Runner
      duration={duration}
      grayscale={grayscale}
    >
      {children}
      {children}
    </Runner>
  </Root>
}