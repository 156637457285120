import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    
    .grecaptcha-badge { visibility: hidden; }
    :lang(heb) {
      font-family: Arimo;
    }
    body, html {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      font-family: Montserrat;
      .language-he{
        font-family: Arimo;
        direction:rtl;
        font-stretch:normal;
      }
    }

    #app {
      width: 100%;
      height: 100%;
    }

    * {
      /* font-family: Montserrat; */
      box-sizing: border-box;
    }

    button {
      background-color: unset;
      border: none;
      padding: 0;
    }

    button:not(:disabled):hover {
      cursor: pointer;
    }

    *:disabled {
      filter: grayscale();
      cursor: default;
    }

    textarea::placeholder {
      color: rgba(10, 33, 56, 0.5);  
    }

    input::placeholder {
      color: rgba(10, 33, 56, 0.5);  
    }
    .language-he{
      direction:rtl;
      font-family:Arimo;
    }
`;

export default GlobalStyle;
