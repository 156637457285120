import styled from 'styled-components';
import { motion, Variants } from 'framer-motion';

const Container = styled.div`
  position: relative;
  width: 45px;
  height: 24px;
  transition: .2s;
  height: 15px;
  width: 36px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  background: ${( { theme } ) => theme.colors.backgroundLight2};
`;
export const Toggle = styled( motion.div )<{isOn:boolean, isDisabled?:boolean}>`
  position: absolute;
  background:${( { theme: { colors }, isOn, isDisabled } ) => ( isDisabled
    ? colors.borderLight
    : isOn
      ? colors.primary
      : colors.secondary
  )};
  height: 20px;
  width: 26.5px;
  border-radius: 30px;
  cursor:${( { isDisabled } ) => ( isDisabled
    ? 'auto'
    : 'pointer' )};
  ${Container}:hover &{
    transform:scale(${( { isDisabled } ) => ( isDisabled
    ? 1
    : 1.1 )});
  }
`;
const variants: Variants = {
  on: {
    right: -4,
    left: 'unset'
  },
  off: {
    left: -4,
    right: 'unset'
  }
};
export interface Props {
  isOn: boolean;
  onClick( isOn: boolean ): void;
  className?: string;
  disabled?:boolean;
}
export function OnOffSwitch ( {
  isOn, onClick, className, disabled
}: Props ) {

  return <Container
    className={className}
    onClick={() => {

      if ( !disabled ) {

        onClick( !isOn );

      }

    }}
  >
    <Toggle
      data-testid="onOffToggle"
      variants={variants}
      isOn={isOn}
      isDisabled={disabled}
      animate={isOn
        ? 'on'
        : 'off'
      }
    />
  </Container>;

}
