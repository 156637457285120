import React, { ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { InputLabel as _InputLabel } from '~/shared/components/styled';

const Root = styled.div`
  display: flex;
  flex-direction: inherit;
  font-family: inherit;
  align-items: center;
`;

const InputLabel = styled(_InputLabel)`
  padding-bottom: 5px;
`;

export const SwitchButton = styled.label<{ on: string; off: string }>`
  ${({ theme }) => theme.typing.textSmallerSemiBold};
  color: ${({ theme }) => theme.colors.primary};
  border: solid 1px ${({ theme }) => theme.colors.primary};
  border-radius: 25px;
  display: flex;
  cursor: pointer;
  &::before {
    border-radius: 25px;
    content: ${({ on }) => on};
    display: block;
    padding: 8px 16px;
  }
  input:checked ~ &::before {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
  &::after {
    border-radius: 25px;
    content: ${({ off }) => off};
    display: block;
    padding: 8px 16px;
  }
  input:not(:checked) ~ &::after {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
`;

interface Props extends ComponentPropsWithoutRef<'input'> {
  on: string;
  off: string;
  label?: string;
}

const Toggle = ({ on, off, label, className, onChange, ...rest }: Props) => {
  return (
    <Root className={className}>
      <InputLabel>{label}</InputLabel>
      <input hidden id={`toggle-type-switch-${className}`} type="checkbox" {...rest} />
      <SwitchButton
        data-testid={`toggle-switch-${className}`}
        on={`'${on}'`}
        off={`'${off}'`}
        htmlFor={`toggle-type-switch-${className}`}
        onClick={
          // @ts-ignore
          (e) => onChange?.({ target: { checked: !rest.checked } })
        }
      />
    </Root>
  );
};

export default Toggle;
