import { QueryCache } from 'react-query';
import { errorMessage } from '~/shared/utils';

const FiveMinutes = 1000 * 60 * 5;


export const queryCache = new QueryCache( {
  defaultConfig: {
    queries: {
      staleTime: FiveMinutes,
    },
    mutations: {
      onError: ( error, _, rollback ) => {

        errorMessage( 'an error has occured' );
        console.error( ( ( error as unknown ) as Error ).message );
        if ( rollback ) {

          ( rollback as () => void )();

        }

      },
    },
  },
} );
