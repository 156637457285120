import { ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ProfileRank } from '~/shared/services/api';
import { useRTL } from '../hooks';
import { withHebrew } from '../utils/language';
import { SecureImg } from './SecureImg';

type ContainerProps = {
  borderWidth: number;
  size: number;
  borderColor?: string;
};
type ImageContainerProps = {
  borderWidth: number;
  containerSize: number;
  backgroundColor: string;
  borderColor?: string;
};
type ImageBorderContainerProps = {
  rankBorderProps: any;
  points: number;
  containerSize: number;
  borderColor?: string;
  highlightBorderColor?: string;
  hideProgress?: boolean;
};
type RankContainerProps = { paddingLeft: number; paddingBottom: number };
type ImageProps = { imgSrc?: string };

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  background-color: transparent;
  /* pointer-events: none; */
  font-family: inherit;
`;

const TextContainer = styled.div<{ rtl: boolean }>`
  padding: 0;
  margin: 0;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: inherit;
  align-items: flex-start;
  ${( { rtl } ) => withHebrew( rtl )}/* @media (max-width:560px){
    display:none;
  }  */
`;

const ImageBorderContainer = styled.span<ImageBorderContainerProps>`
  background-image: linear-gradient(
      ${( { rankBorderProps } ) => `${rankBorderProps.degOff}`}deg,
      ${( { rankBorderProps } ) => `${rankBorderProps.colorTransparent}`} 50%,
      transparent 50%
    ),
    linear-gradient(
      ${( { rankBorderProps } ) => `${rankBorderProps.degOn}`}deg,
      #1a364e 50%,
      #bec4c4 50%
    );
  ${( { hideProgress } ) => ( hideProgress
    ? 'background-image:unset'
    : undefined )};
  background-color: #1a364e;

  min-width: ${( { containerSize } ) => `${containerSize}px`};
  max-width: ${( { containerSize } ) => `${containerSize}px`};
  min-height: ${( { containerSize } ) => `${containerSize}px`};
  max-height: ${( { containerSize } ) => `${containerSize}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: scaleX(-1);
`;

const ImageContainer = styled.span<ImageContainerProps>`
  min-width: ${( { containerSize, borderWidth } ) => `${containerSize - ( borderWidth * 2 )}px`};
  max-width: ${( { containerSize, borderWidth } ) => `${containerSize - ( borderWidth * 2 )}px`};
  min-height: ${( { containerSize, borderWidth } ) => `${containerSize - ( borderWidth * 2 )}px`};
  max-height: ${( { containerSize, borderWidth } ) => `${containerSize - ( borderWidth * 2 )}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  padding: ${( { borderWidth } ) => `${borderWidth}px`};
  background-color: ${( { backgroundColor, theme } ) => backgroundColor || theme.colors.white};
  transform: scaleX(-1);
`;

const RankContainer = styled.span<RankContainerProps>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 50%;
  margin-left: ${( { paddingLeft } ) => `calc(50% + ${paddingLeft}px)`};
  margin-bottom: ${( { paddingBottom } ) => `${paddingBottom}px`};
  background-color: transparent;
  font-family: inherit;
  z-index:${( { theme } ) => theme.typing.zIndexes.overOthers};
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const RankName = styled.span`
  color: #0c2a4c;
  font-size: 16px;
  font-weight: bold;
`;

const RankPoints = styled.span`
  color: #0c2a4c;
  font-family: inherit;
  font-size: ${( { theme } ) => theme.typing.fontSizes.small};
  font-weight: normal;
`;

const Img = styled( SecureImg )<ContainerProps>`
  max-width: 100%;
  width: ${( { size } ) => `${size}px`};
  height: ${( { size } ) => `${size}px`};
  object-fit: contain;
  border-radius: 50%;
  /* background-image: radial-gradient(${( { theme } ) => theme.colors.primary} 5%, white 70%); */
  border: 0.01px solid white;
`;

const AvatarRank = ( {
  hideProgress,
  rank,
  className,
  ...props
}: { rank?: ProfileRank; hideProgress?: boolean } & ContainerProps &
  ImageBorderContainerProps &
  ImageContainerProps &
  RankContainerProps &
  ImageProps &
  ComponentPropsWithoutRef<'span'> ) => {

  const { t } = useTranslation();
  const rtl = useRTL();
  return (
    <Container>
      <RankContainer className={className} {...props}>
        {props.imgSrc && (
          <ImageBorderContainer {...props} hideProgress={hideProgress}>
            <ImageContainer {...props}>
              <Img
                {...props}
                src={props.imgSrc}
                mediaId={undefined}
                alt=""
              />
            </ImageContainer>
          </ImageBorderContainer>
        )}
        {rank && (
          <TextContainer rtl={rtl}>
            <RankName>{rank && rank.name}</RankName>
            <RankPoints>{`${rank && rank.all_points} ${t(
              'Avatar Rank.Points'
            )}`}</RankPoints>
          </TextContainer>
        )}
      </RankContainer>
    </Container>
  );

};

export default AvatarRank;
