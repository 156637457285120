import React from 'react';
import styled from 'styled-components';
import { motion, } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,

  /*
   * FacebookMessengerShareButton,
   * FacebookMessengerIcon,
   */
  HatenaShareButton,
  HatenaIcon,
  InstapaperShareButton,
  InstapaperIcon,
  LineShareButton,
  LineIcon,
  LinkedinShareButton,
  LinkedinIcon,
  LivejournalShareButton,
  LivejournalIcon,
  MailruShareButton,
  MailruIcon,
  OKShareButton,
  OKIcon,
  PinterestShareButton,
  PinterestIcon,
  PocketShareButton,
  PocketIcon,
  RedditShareButton,
  RedditIcon,
  TelegramShareButton,
  TelegramIcon,
  TumblrShareButton,
  TumblrIcon,
  TwitterShareButton,
  TwitterIcon,
  ViberShareButton,
  ViberIcon,
  VKShareButton,
  VKIcon,
  WhatsappShareButton,
  WhatsappIcon,
  WorkplaceShareButton,
  WorkplaceIcon,
} from 'react-share';

import { ReactComponent as LinkIcon } from '~/shared/images/link.svg';
import CloseButton from './CloseButton';
import { config } from '../config';
import { toast } from 'react-toastify';
import { copyToClipboard } from '../utils';


const Root = styled( motion.div )`
  position: absolute;
  bottom:0;
  z-index:10;
  background:white;
  width:60vw;
  font-family: Arimo;
  left:50%;
  border-radius:20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    width:80vw;
    /* left:10vw; */
  }
`;
const Container = styled.div`
  background: #efefef;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat( 5, 1fr);
  row-gap: 10px;
  width: 90%; 
  padding:10px;
  margin: 20px auto;
`;
const ButtonContainer = styled.div`
  position: relative;
  justify-self: center;
  
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    /* width: 20%; */
    /* height:auto; */
  }
`;
const TargetButton = styled.button`
  width: 55px;
  height: 55px;
  transition: .2s;
  justify-self: center;
  align-self: center;
  :hover,:focus{
    transform:scale(1.2) ;
    filter: brightness(1.1);
    outline: 0;
  }
  svg{
    width:100%;
    height:100%;
  }
  @media (max-width: 430px) {
    width: 45px;
    height: 45px;

  }

`;
const Title = styled.header`
  padding: 30px;
  font-family: inherit;
  background-color: ${( { theme } ) => theme.colors.backgroundLight2};
  color: ${( { theme } ) => theme.colors.textDark};
  ${( { theme } ) => theme.typing.textSmallBold};
  width: 100%;
  text-transform: capitalize;
`;


type ButtonType= { // :
  button: React.ForwardRefExoticComponent<any>;
  buttonName:string,
  props?: ( header:string, body:string )=>any, // generate props that fit the releavant button
  Icon:typeof FacebookIcon
};

const targets: ButtonType[] = [
  { // Whatsapp
    button: WhatsappShareButton,
    Icon: WhatsappIcon,
    buttonName: 'Whatsapp-web',
    props: ( header, body ) => ( {
      title: body
    } )
  },
  { // Facebook
    button: FacebookShareButton,
    Icon: FacebookIcon,
    buttonName: 'Facebook',
    props: () => ( {
      quote: 'Have you seen this on Chimu?',
      hashtag: '#chimu',
    } )
  },
  { // Twitter
    button: TwitterShareButton,
    Icon: TwitterIcon,
    buttonName: 'Twitter',
    props: ( header ) => ( {
      title: header,
      hashtags: [ 'chimu' ] // do not include #
    } )
  },
  { // Linkedin
    button: LinkedinShareButton,
    Icon: LinkedinIcon,
    buttonName: 'Linkedin',
    props: ( header, body ) => ( {
      title: header,
      summary: body,
      source: config.baseApiUrl.replace(
        'api.',
        ''
      )
    } )
  },
  { // Viber
    button: ViberShareButton,
    Icon: ViberIcon,
    buttonName: 'Viber',
    props: ( header, ) => ( {
      title: header
    } )
  },
  { // Telegram
    button: TelegramShareButton,
    Icon: TelegramIcon,
    buttonName: 'Telegram',
    props: ( header, ) => ( {
      title: header
    } )
  },
  { // Hatena
    button: HatenaShareButton,
    Icon: HatenaIcon,
    buttonName: 'Hatena',
    props: ( header, ) => ( {
      title: header
    } )
  },
  { // Instapaper
    button: InstapaperShareButton,
    Icon: InstapaperIcon,
    buttonName: 'Instapaper',
    props: ( header, body ) => ( {
      title: header,
      description: body
    } )
  },
  { // Line
    button: LineShareButton,
    Icon: LineIcon,
    buttonName: 'Line',
    props: ( header, ) => ( {
      title: header,
    } )
  },
  { // Livejournal
    button: LivejournalShareButton,
    Icon: LivejournalIcon,
    buttonName: 'Livejournal',
    props: ( header, body ) => ( {
      title: header,
      description: body
    } )
  },
  { // Mailru
    button: MailruShareButton,
    Icon: MailruIcon,
    buttonName: 'Mailru',
    props: ( header, body ) => ( {
      title: header,
      description: body,

      /** {string}: An absolute link to the image that will be shared */
      // imageUrl : ''
    } )
  },
  { // OK
    button: OKShareButton,
    Icon: OKIcon,
    buttonName: 'OK',
    props: ( header, body ) => ( {
      title: header,
      description: body,

      /** {string}: An absolute link to the image that will be shared */
      // image
      /** {string}: An absolute link to the image that will be shared */
      // image :''
    } )
  },
  { // Pinterest
    button: PinterestShareButton,
    Icon: PinterestIcon,
    buttonName: 'Pinterest',
    props: ( header, body ) => ( {
      title: header,
      description: body,

      /** {String}: An absolute link to the image that will be shared */
      media: `${config.baseUrl}/icon.jpg`
    } )
  },
  { // Pocket
    button: PocketShareButton,
    Icon: PocketIcon,
    buttonName: 'Pocket',
    props: ( header, ) => ( {
      title: header,
    } )
  },
  { // Reddit
    button: RedditShareButton,
    Icon: RedditIcon,
    buttonName: 'Reddit',
    props: ( header, ) => ( {
      title: header,
    } )
  },
  { // Tumblr
    button: TumblrShareButton,
    Icon: TumblrIcon,
    buttonName: 'Tumblr',
    props: ( header, body ) => ( {
      title: header,
      caption: body,
      tags: [ 'Chimu' ]
    } )
  },
  { // VK
    button: VKShareButton,
    Icon: VKIcon,
    buttonName: 'VK',
    props: ( header ) => ( {
      title: header,

      /** {String}: An absolute link to the image that will be shared */
      // image:'https://media-exp1.licdn.com/dms/image/C4E0BAQHKpeu22VcoBA/company-logo_200_200/0/1565591369240?e=1640217600&v=beta&t=K07eaUEyILhjnB763q2EG5V-k2nRooweCHP86O8FvO8'
    } )
  },
  { // Workplace
    button: WorkplaceShareButton,
    Icon: WorkplaceIcon,
    buttonName: 'Workplace',
    props: ( header, body ) => ( {
      quote: body,
      hashtag: '#Chimu'
    } )
  },
  { // Email
    button: EmailShareButton,
    Icon: EmailIcon,
    buttonName: 'Email',
    props: ( header, body ) => ( {
      subject: header,
      body,
      separator: ' ',
    } )
  },

];

export function SharePopup ( {
  header, close, body, url
}:{
  url: string;
  header: string;
  body:string;
  close():void
} ) {

  const { t } = useTranslation();
  return ( <Root
    initial={{
      y: '100%',
      x: '-50%',
      // boxShadow:'rgba(.7,.7,.7,0.0) 1px 1px 1px 40px '
      boxShadow: 'rgba(0,0,0,0.7) 1px 1px 1px 150vh ',
    }}
    exit={{
      y: '150%',
      x: '-50%',
      boxShadow: 'rgba(0,0,0,0) 1px 1px 1px 150vh ',
    }}
    animate={{
      y: '-30vh',
      x: '-50%',
      boxShadow: 'rgba(0,0,0,0.7) 1px 1px 1px 150vh ',
    }}
  >
    <CloseButton onClick={close} />
    <Title> {t( 'Post.Buttons.Share' )} </Title>
    <Container>
      <ButtonContainer>
        <TargetButton title={t( 'copy link' )}
          onClick={() => {

            copyToClipboard( url );
            toast.info(
              t( 'link copied' ),
              {
                autoClose: 1500,
                position: 'bottom-center',
              }
            );

          }}
        >
          <LinkIcon />
        </TargetButton>
      </ButtonContainer>
      {targets.map( ( {
        button, Icon, buttonName, props
      } ) => <ButtonContainer title={buttonName}>
        <TargetButton as={button} url={url} {...props?.(
          header,
          body
        )}>
          <Icon
            borderRadius={10}
          />
        </TargetButton>
      </ButtonContainer> )}
    </Container>
  </Root>
  );

}
