import { ComponentPropsWithRef, useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { fetchMediaContent } from '../services/api/api';

const PlaceholderImage = styled.img`
  position: absolute;
  width: 100%;
  background: white;
  height: 100%;
  left:0;
  top:0;
  object-fit: contain;
`;
export type Props = ComponentPropsWithRef<'img'> & {

  /**
   * pass an image url to display while the src is being loaded from s3
   */
  placeholder?:string;
  mediaId:number|undefined;
};


export const SecureImg = ( {
  src, placeholder, mediaId, ...rest
}: Props ) => {

  // const {source,error} = useMediaFetching(src)
  const [ loaded, setLoaded ] = useState( false );
  const [ imageError, setImageError ] = useState( false );
  const hourAndHalf = 1.5 * 60 * 60 * 1000;

  const { data } = useQuery(
    `media-item ${mediaId}`,
    async () => {

      const base64 = await fetchMediaContent( mediaId as number );
      return `data:image/png;base64, ${base64}`;

    },
    {
      enabled: !!mediaId && imageError,
      staleTime: hourAndHalf,
      onSuccess () {

        setImageError( false );

      }
    }
  );
  return ( <>
    <img
      src={ data || src }
      // alt={src}
      onLoad={ () => setLoaded( true ) }
      onError={async ( ) => {

        setImageError( true );

      }}

      /*
       * hidden={!loaded}
       * title={String(source)+'\n'+ placeholder}
       * alt={error ? src :''}
       */
      {...rest}
    />
    {placeholder && !loaded && <PlaceholderImage
      src={placeholder}
      // className={rest.className}
    />}
  </> );

};
export const SecureVideo = ( {
  src, onClick, ...rest
}: ComponentPropsWithRef<'video'> & {src:string} ) => (
  <>
    {/* {isLoading &&
        <LoaderWrapper >
          <StandardSpinner/>
          <span data-loading={src}>
            loading video 0%
          </span>
        </LoaderWrapper>
      } */}
    <video
      {...rest}

    >
      {<source
        src={src}

      />}
      {/* <source
            src={src}
          /> */}
    </video>
  </>
);


