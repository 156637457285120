
import styled from 'styled-components';
import { useQuery } from 'react-query';

import { StageProps } from './JoinCommunity';
import { CommunityQuestionsValues } from './types';
import { AnswerOnboardingTrivia } from './AnswerOnboardingTrivia';

import {
  StandardSpinner
} from '~/shared/components/Spinner';
import { createCommunityUserRequest } from '~/shared/services/api/api';
import { useEffect, useRef, useState } from 'react';
import { FadingBG, useMenu } from '~/shared/hooks/useMenu';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router';
import _CloseButton from '~/shared/components/CloseButton';
import { useOutsideClick } from '~/shared/hooks';
import { useTranslation } from 'react-i18next';
import { LoaderWrapper } from '~/app/components/Auth/styled';

const InfoOverlay = styled( motion.div )`
  position: absolute;
  background: rgba(255,255,255,0.9);
  top: 80px;
  left: 50%;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid ${( { theme } ) => theme.colors.secondary};
  border-radius: 10px;
  padding: 10px;
  gap:20px;
  width: 80vw;
  height: 80vh;
  aspect-ratio: 1;
  padding: 20px;
  b{
    color: ${( { theme } ) => theme.colors.primary};
  }
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    flex-direction: column;
  }
`;
const CloseButton = styled( _CloseButton )`
  top:5px;
  right: 5px;
`;

const InfoButton = styled( motion.button )<{seenExplanation:boolean}>`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-weight: 800;
  font-size: 20px;
  background: white;
  border: 3px solid ${( { theme } ) => theme.colors.primary};;
  background: ${( { theme } ) => theme.colors.secondary};
  color: ${( { theme } ) => theme.colors.primary};
  margin: 2px calc(50% - 17.5px); 

  box-shadow: 0 0 0 0 ${( { theme } ) => theme.colors.primary};
  transform: scale(1);
  animation: pulse 2s infinite;
  ${( { seenExplanation } ) => seenExplanation && `

    animation: unset;
    background: white;
    opacity: .6;
  `}
  :hover{
    opacity: 1;
  }
  @keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(62, 186, 211, 1);
  }

  70% {
    box-shadow: 0 0 0 9px rgba(62, 186, 211, .2);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(62, 186, 211, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(62, 186, 211, 0);
  }
}
`;

const Avatar = styled.img`
  height: 100px;
  width: 100px;
  object-fit: contain;
`;

type Props = Pick<StageProps<CommunityQuestionsValues>, 'values' | 'setFieldValue' | 'setUserId' | 'userId' | 'submitForm'>&{
  hideInfoButton?:boolean
};

export const CommunityQuestions = (props :Props) => {
  const {
    values,
    setFieldValue,
    setUserId,
    userId,
    submitForm,
    hideInfoButton,
  } = props;
  const { t } = useTranslation();
  const { state } = useLocation<{community?:{image:string, name:string}}>();
  const { community } = values;
  const { InMenu } = useMenu();
  const { data } = useQuery( `onboarding community ${community}`, async () => {
    const onBoardingData = await createCommunityUserRequest(community as number);
    const { user_id, on_boarding: {
      questions: communityQuestions,
      community_name: name
    }} = onBoardingData;
    return { name, questions: communityQuestions, userId: user_id};
  },
  { staleTime: Infinity});
  const popupRef = useRef<HTMLDivElement>( null );
  const [ showExplanation, setShowExplanation ] = useState( false );
  const [ seenExplanation, setSeenExplanation ] = useState( false );
  useEffect( () => data && setUserId(data.userId), [data]);
  useOutsideClick( popupRef, () => setShowExplanation(false), showExplanation, true);
  const explanation = t( 'join.explanation' )?.split( '@name' );
  if (!data)
    return <LoaderWrapper><StandardSpinner/></LoaderWrapper>;
  return <>
    <InMenu isShowing={showExplanation}>
      <FadingBG>
        <InfoOverlay
          ref={popupRef}
          initial={{y: '-100%', x: '-50%', opacity: 0}}
          animate={{opacity: 1, x: '-50%', y: 0}}
          exit={{y: '-100%', x: '-50%', opacity: 0}}
        >
          <CloseButton onClick={() => setShowExplanation( false )}/>
          <Avatar src={state?.community?.image}/>
          <p style={{ whiteSpace: 'pre-wrap'}}>
            {explanation?.[0]}
            <b>{data.name}</b>
            {explanation?.[1]}
          </p>
        </InfoOverlay>
      </FadingBG>
    </InMenu>
    {!hideInfoButton
      && <InfoButton
        type="button"
        seenExplanation={seenExplanation}
        onClick={() => {
          setShowExplanation( true );
          setSeenExplanation( true );
        }}
      >
        !
      </InfoButton>}
    <AnswerOnboardingTrivia
      adminId={userId as number}
      communityId={community as number}
      saveAnswers={( answers ) => setFieldValue( 'answers', answers)}
      onFinish={submitForm}
      style={{
        minHeight: '100%',
        maxHeight: 'calc(80vh - 60px)',
        padding: '0',
      }}
    />
  </>;
};


