import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';
import { queryCache } from '~/app/components/queryCache';

import { api } from '~/shared/services/api';
import { UserCommunity } from '~/shared/services/api/types';
import { Pagination } from '../services/api/utilityTypes';
import { FCMChatPayload } from './FCMHandlers/useFCMPushChatMessages';
import useMyUserQuery from './useMyUserQuery';

export function incrementUnreadMessages ( payload?: FCMChatPayload | undefined ) : void {

  queryCache.setQueryData<UserCommunity[] | undefined>(
    [ 'user communities',
      /* suggested:*/ false ],
    ( communities ) => {

      if ( !communities ) {

        return communities;

      }
      const newData = [ ...communities ];
      const relevantCommunity = newData.find(
        ( { id } ) => id === payload?.data.community_id
      );
      if ( !relevantCommunity ) {

        return communities;

      }
      relevantCommunity.unread_count += 1;
      return newData;

    }
  );

}
interface CommunityPagination extends Pagination {
  communities: UserCommunity[],
  page?:number
}
type UserCommunitiesBag = {
  communities?: UserCommunity[];
  reftechCommunities: ( ) => Promise<CommunityPagination[]|void>;
  error: unknown;
  isLoading: boolean;
  isFetching: boolean;
  canFetchMore?: boolean;
  fetchMore():void;
};

/**
 *
 * @param {boolean} [suggested=false] - whether to fetch suggested or already-joined communities (default false)
 * @param {boolean} [paginated=true] - whether to paginate results (default true)
 * @returns {UserCommunitiesBag} UserCommunitiesBag
 */
export const useUserCommunities = ( suggested = false, paginated = true ): UserCommunitiesBag => {

  const { user } = useMyUserQuery();
  const {
    data: communities,
    error,
    isLoading,
    isFetching,
    refetch: reftechCommunities,
    fetchMore,
    canFetchMore
  } = useInfiniteQuery<CommunityPagination>(
    [
      'user communities',
      suggested,
      paginated
    ],
    async ( ...args ) => {

      let pageNumber = args[args.length - 1];
      if ( paginated ) {

        pageNumber ||= 1;

      }

      return api.fetchUserCommunities(
        suggested,
        pageNumber
      );

    },
    {
      enabled: !!user,
      getFetchMore: ( { page, has_more_pages } ) => {

        if ( has_more_pages && page ) {

          const newPageNumber = page + 1;
          return newPageNumber;

        }

      },
    }
  );
  const flatMapped = useMemo(
    () => ( communities?.flatMap( ( x ) => x.communities ) as ( UserCommunity[] )|undefined ),
    [ communities ]
  );
  return {
    communities: flatMapped,
    reftechCommunities,
    fetchMore,
    error,
    isLoading,
    isFetching,
    canFetchMore
  };

};
