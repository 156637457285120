import { QueryConfig, useQuery } from 'react-query';

import { api } from '~/shared/services/api';
import { CommunityMasterData } from '~/shared/services/api/types';
import useCurrentCommunity from './useCurrentCommunity';

interface useMasterDataProps {
  options?: QueryConfig<CommunityMasterData, unknown>;
}

type MasterData = Partial<CommunityMasterData>&{
  refetch():void;
  isLoading:boolean;
};

export const useMasterData = ( props?: useMasterDataProps ): MasterData => {

  const { currentCommunity } = useCurrentCommunity();
  const { data: masterData, refetch, isLoading } = useQuery(
    [ 'masterData',
      currentCommunity?.id ],
    api.fetchMasterData,
    {
      cacheTime: Infinity,
      enabled: !!currentCommunity,
      ...props?.options,
    }
  );

  return { ...masterData,
    refetch,
    isLoading };

};

export default useMasterData;
