import React, { Component, ErrorInfo, ReactNode } from 'react';

import styled from 'styled-components';
import { chimuLogoSvg } from '~/shared/images';

const Root = styled.div`
  min-height: 50vh;
  min-width: 45vw;
  background-color: white;
  position: relative;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:20px;
  font-family: Arimo;
`;
const Title = styled.h2`
  white-space: pre-wrap;
  direction: ltr;
  color: ${( { theme } ) => theme.colors?.pea};
  width: 60%;
`;
const Logo = styled( chimuLogoSvg )`
  left: 44%;
  bottom: 5%;
  height: 36%;
  width: auto;
  path:first-child{
    stroke: white;
    stroke-width: 0.7px;
  }
`;

export function ErrorPage ( { children }:{children?:React.ReactChild} ) {

  return <Root>
    <Logo viewBox="0 0 95 68" />
    <Title>
      {children}
    </Title>
  </Root>;

}
interface Props {
  children: ReactNode;
  nameToLog?:string
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {

  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError ( _: Error ): State {

    // Update state so the next render will show the fallback UI.
    return { hasError: true };

  }

  public componentDidCatch ( error: Error, errorInfo: ErrorInfo ) {

    console.log( `Uncaught error at ${this.props.nameToLog || 'unspecified'}:` );
    console.error(
      error,
      errorInfo
    );

    // console.trace(error)

  }

  public render () {

    if ( this.state.hasError ) {

      return <ErrorPage>
        <span>
          An error has occurred.Please try again.<br />
          In case of repeated issues, please contact us at <a href="mailto:support@chimu.io">support@chimu.io</a>
        </span>
      </ErrorPage>;

    }

    return this.props.children;

  }

}

export function withErrorBoundary ( nameToLog:string, Element:( props:any )=>JSX.Element ) {

  return ( props:any ) => <ErrorBoundary nameToLog={nameToLog}>
    <Element {...props}/>
  </ErrorBoundary>;

}

export default ErrorBoundary;

