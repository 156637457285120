import { useReducer } from 'react';

type ChangeAction = {type:'next'|'prev'}
type SetAction = {type:'set', value:number}
type PagingAction = SetAction|ChangeAction

export function useDataPaging<T> ( data:T[] = [], startOnPage = 0 ) {

  const total = data.length;

  const [ page, dispatch ] = useReducer(
    ( currentPage:number, action:PagingAction ) => {

      switch ( action.type ) {

        case 'prev': {

          const prev = currentPage - 1;
          return prev >= 0
            ? prev
            : 0;

        }
        case 'next': {

          const next = currentPage + 1;
          return next < total
            ? next
            : total - 1;

        }
        case 'set': {

          const { value } = action;
          if ( value >= 0 && value < total ) {

            return value;

          }
          throw new Error( `Set value out of paging range:${value}` );

        }
        default:
          throw new Error( `no action type ${action}` );

      }

    },
    startOnPage,
  );
  const current = data[page];
  function next () {

    dispatch( { type: 'next' } );

  }
  function prev () {

    dispatch( { type: 'prev' } );

  }
  function setPage ( value:number ) {

    dispatch( { type: 'set',
      value } );

  }
  function goToFirst ( ) {

    dispatch( { type: 'set',
      value: 0 } );

  }
  function goToLast ( ) {

    dispatch( { type: 'set',
      value: data.length - 1 } );

  }
  const isFirstPage = page === 0;
  const isLastPage = page === total - 1;
  return {
    data,
    total,
    page,
    current,
    next,
    prev,
    setPage,
    isFirstPage,
    isLastPage,
    goToFirst,
    goToLast
  };

}
