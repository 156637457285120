/* eslint-disable  no-use-before-define, camelcase */
import { AnswerType } from '~/join-community/Fields';
import { Locale } from './Locale';

export interface Validate {
  is_valid: boolean
}

/**
 * Form of Response of upload api
 */
export interface MediaResponse {
  type: number;
  path: string;
  // path_signed: string;
  name: string;
  thumbnail: string | null;
}

export type NotificationTypes =
  | 'chat_hub'
  | 'chat_group'
  | 'user'
  | 'private_message'
  | 'poll'
  | 'private_message'
  | 'media'
  | 'challenge'
  | 'post'
  | 'event'
  | 'rank';

export type NotificationPopupTypes = 'private_message' | 'challenge' | 'rank';

export interface NotificationsPagination {
  not_seen_notifications: number;
  last_notification_date: string;
  notifications: NotificationI[];
}
export interface range {
  entity_id: number;
  entity_type: string;
  length: number;
  offset: number;
}

export interface ArbitraryData {
  comment_id?: number;
  parent_id?: number;
  personal?: boolean;
  network_user_id?: number;
  points?: number;
}

export interface NotificationI {
  id: string;
  template: string;
  image: string;
  community_id: number;
  network_user_id: number;
  object_type: NotificationTypes;
  follow_link_admin: string;
  created: string;
  pretty_created?: string;
  auth_user_id: number;
  object_id: number;
  follow_link_user: string;
  seen: boolean;
  arbitrary_data: ArbitraryData; // related to an object inside, like a comment in a post
  ranges?: range[];
}

export interface NotificationPopupI {

  /** string template to inject data into and display */
  template: string;
  image: string;
  community_id: number;
  network_user_id: number;
  object_type: NotificationPopupTypes;
  created: string;
  auth_user_id: number;
  object_id: number;
  arbitrary_data: ArbitraryData;
  pretty_created?: string;
  ranges?: range[];
}


export interface FeedPagination {
  cells: FeedCellRef[];
  challenges: Challenge[];
  network_opportunities: NetworkOpportunity[];
  events: any[];
  members: CommunityMemberItem[];
  sponsors: any[];
  video: Video[];
  communities: Community[];
  member_count: number;
  media: Media[];
  articles: Article[];
  polls: Poll[];
  last_cell_date: string;
  not_seen_notifications: number;
  rewards: Reward[];
  announcements:ManagerUpdate[];
}

type FeedMasterData = Pick<
  FeedPagination,
  | 'challenges'
  | 'network_opportunities'
  | 'articles'
  | 'polls'
  | 'video'
  | 'media'
  | 'events'
  | 'members'
  | 'rewards'
  | 'announcements'
>;

export interface DashboardData {
  challenges: any[];
  community_name: string;
  generated_posts: NetworkOpportunity[];
  members: any;
  posts_count: number;
  last_week_post_count: number;
  video: Video[];
  posts_one_day_old: any[];
  communities: Community[];
  repository_articels: any[];
  scraper_data: any;
  tips: any;
  your_challenge: any;
}

export interface MapData {
  nodes: any[];
  edges: any[];
}
export interface UserMapData {
  image: string;
  fullname: string;
  country: string;
  cv: string;
  join_date: string;
  last_login: string;
  role: UserRole;
  rank: Rank | null;
}

export const feedCellMapping = {
  post: 'network_opportunities' as keyof FeedMasterData,
  poll: 'polls' as keyof FeedMasterData,
  challenge: 'challenge' as keyof FeedMasterData,
  media: 'medias' as keyof FeedMasterData,
  video: 'videos' as keyof FeedMasterData,
  article: 'articles' as keyof FeedMasterData,
  event: 'events' as keyof FeedMasterData,
  member: 'members' as keyof FeedMasterData,
  reward: 'rewards' as keyof FeedMasterData,
  announcement: 'announcements' as keyof FeedMasterData,
};

export type FeedCellType = keyof typeof feedCellMapping;

type _Event = any;

export interface FeedCellRef {
  type: FeedCellType;
  id?: number;
}

export type FeedCell = FeedCellRef &
  (
    | { type: 'post'; data: NetworkOpportunity }
    | { type: 'event'; data: _Event }
    | { type: 'video'; data: Video }
    | { type: 'media'; data: Media }
    | { type: 'poll'; data: Poll }
    | { type: 'article'; data: Article }
    | { type: 'challenge'; data: Challenge }
    | { type: 'member'; data: UserRef }
    | { type: 'reward'; data: Reward }
  );

// TODO - member user type is different - only id and full_name
export interface UserRef {
  network_id: number;
  full_name: string;
  image?: string;
  current_role?: string;
  id?: number;
  task_status?: SubTaskStatus;
}

export interface AdminRef extends UserRef {
  auth_user_id: number;
  communities: CommunityRef[];
  rank: Rank | null;
}

export interface NewMember {
  id: number;
  network_id: number;
  created: string;
  full_name: string;
  image: string;
  current_role: string;
  saved: boolean;
  cv: string;
}

export interface ChallengeStatus {
  id: number;
  status_text: string;
  time_left: string;
  minutes_to_complete: number;
  percent_who_completed: number;
}

export interface ChallengeType {
  id: number;
  name: string;
  personal: boolean;
  type: 'poll' | 'media' | 'post' | 'event';
}

export interface ChallengeSubTask {
  id: number;
  name: string;
  points: number;
  completed: boolean;
  user: null | UserRef;
}
export interface MyChallenge {
  id: number;
  start_time: string;
  end_time: string;
  name: string;
  description: string;
  points: number;
  is_active: boolean;
  active: boolean;
  accepted: boolean;
  declined: boolean;
  completed: boolean;
  challenge_type: ChallengeType;
  challenge_id: number;
  participants: {
    count: number;
    users: {
      current_role?: string;
      full_name: string;
      image: string;
      network_id: number;
      tasks: {
        id: number;
        name: string;
        completed: boolean;
      }[];
    }[];
  };
}
export interface Challenge extends MyChallenge {
  sub_tasks: ChallengeSubTask[];
  media: MediaRef[];
}


export interface ChallengesData {
  data: MyChallenge[];
  has_more_pages: boolean;
}
export interface MediaMessages extends CommentsData {
  page: number;
}

export interface MediaPagination {
  has_more_pages: boolean;
  community_media: DataCenterMedia[];
  media_paths: unknown[];
}
export interface MediaSearchItem {
  community_id: number;
  cover_photo: string | null;
  created: Date;
  deleted: false;
  downloads: number;
  hide_from_feed: boolean;
  id: number;
  is_active: boolean;
  is_folder: boolean;
  manager_name: string;
  media_name: string;
  media_path: string;
  media_type: number;
  name: string;
  parent_id: number;
  posted_by: Omit<UserRef, 'current_role'>;
}
export interface MediaSearchPagination {
  found: number;
  has_more_pages: boolean;
  medias: MediaSearchItem[];
  total_pages: number;
}

export interface MediaMessages {
  page: number;
  has_more_pages: boolean;
  users: UserRef[];
  messages: Comment[];
}
export interface MediaFolders extends MediaPagination {
  has_more_pages: false;
  community_media: DataCenterFolder[];
}

export enum MediaType {
  CollabDoc=-1,
  Photo,
  Video,
  Audio,
  PDF,
  MediaLink,
}
export interface MediaRef {
  id: number;
  media_path: string;
  ref_id: number;
  ref_type: number;
  media_type: MediaType;
  downloads: number;
  name: string;
  cover_photo?: string;

  /**
   * present for pdf files, may be missing for files uploaded before this update
   */
  thumbnail: string|null;
  created: string;
  web_media: boolean;
  description: string|null;

  /*
   * url: string|null;
   */
}
export interface DataCenterMedia extends MediaRef{
  parent_id: number;
  parent_type?: number;
  tags: { id: number; name: string; }[];
  downloads: number;
  posted_by: number;
  owner: UserRef;
  comments_count: number;
  public: boolean;
  order?: number;
  media_name?: undefined;
  is_folder: boolean;
  saved: boolean;
  unread_count: number;
}

export interface MediaItemData extends Pick<DataCenterMedia, 'id' |
  'media_type' |
  'name' |
  'media_path' |
  'posted_by' |
  'owner' |
  'public' |
  'thumbnail' |
  'cover_photo' |
  'is_folder' |
  'created'
> {
  comments_count?: DataCenterMedia['comments_count'];
  downloads?: DataCenterMedia['downloads'];
}
export interface Media {
  id: number;
  created: string;
  pretty_created: string;
  media_path: string;
  media_type: MediaType;
  downloads: number;
  name: string;
  posted_by: UserRef;
  saved: boolean;
  public: boolean;
}
export interface MediaLink extends DataCenterMedia{
  url: string;
  description: string;
}

export interface DataCenterFolder extends DataCenterMedia {
  is_folder: true;
}

export type FeedItemDetails = {
  feedItemId: number;
  feedItemType: keyof typeof feedCellMapping;
};

export interface VerboseFeedItem {
  id: number;
  group_domain: string;
  created: string;
  pretty_created: string;
  group_title: string;
  group_descriptions: string;
  like_count: number;
  comments_count: number;
  view_count: number;
  liked: boolean;
  media: MediaRef[];
  background_color: number[];
  manager: UserRef;
  users: number[];
  cell_type: FeedCellType;
  description: string;
  image: string;
  members_count: number;
  pinned: boolean;
  published_at: string;
  rating_count: number;
  source_image: string;
  title: string;
  unread_count: number;
  url: string;
  your_rating?: any;
}

export interface NetworkOpportunity {
  id: number;
  group_domain: string;
  created: string;
  pretty_created: string;
  group_title: string;
  group_descriptions: string;
  like_count: number;
  is_updated: boolean;
  comments_count: number;
  view_count: number;
  liked: boolean;
  media: MediaRef[];
  background_color: number[];
  manager: UserRef;
  users: number[];
  saved: boolean;
  ranges: TagRange[];
  url?: string;
  as_system_user?: boolean;
  media_from_repo?: MediaResponse[];
  public: boolean;
}

export interface CommunityEventRef {
  background_color?: number[];
  end_date: string;
  event_title?: string;
  title?: string;
  id: number;
  img_link: string;
  last_date_of_registration: string;
  manager: UserRef;
  start_date: string;
  participants: UserRef[];
  description: string;
  attending?: boolean;
  link?: string;
  online: boolean;
  create_conference?: boolean;
  location?: string;
  media?: MediaRef[];
  saved: boolean;
  members: number[];
  public: boolean;
}

export interface CommunityEvent {
  background_color?: number[];
  media?: MediaRef[];
  attending: boolean;
  chat_group_id: any;
  communities: CommunityRef[];
  created: string;
  description: string;
  end_date: string;
  event_group: number;
  event_rate: {
    rating: number;
    reviews: number;
  };
  id: number;
  img_link: string;
  last_date_of_registration: string;
  link?: string;
  location?: string;
  manager: UserRef;
  online: boolean;
  open_event: boolean;
  participants: UserRef[];
  start_date: string;
  tags: any[];
  title: string;
  users_event: {
    user: number;
    current_role?: string;
    full_name: string;
    image?: string;
    partner: boolean;
    rank: Rank | null;
  }[];
  saved: boolean;
  members: number[];
  public: boolean;
}
export interface AllEvents {
  content: CommunityEvent[];
  sum: {
    past_events: number;
    upcoming_events: number;
    active_members: number;
    suspended_members: number;
  };
}
export interface Video {
  id: number;
  url: string;
  created: string;
  pretty_created: string;
  published_at: string;
  pretty_published_at: string;
  description: string;
  title: string;
  manager: UserRef;
  saved: boolean;
  group_domain: string;
  view_count?: number;
  comments_count?: number;
  public: boolean;
}

export interface CommunityRef {
  id: number;
  community_name: string;
  title: string;
  img: string;
  logo: string;
  members_count: number;
}

/*
 * export interface Community extends CommunityRef {
 *   is_current: boolean;
 *   status: string;
 *   managers_ids: number[];
 *   avg_post_count_per_day: number;
 *   members: Array<{ current_role?: string; full_name: string; image: string; network_id: number }>;
 *   unread_count: number,
 * }
 */

export interface RatingOption {
  id: number;
  text: string;
  image: string;
}

export type ratingCount = {
  count: number;
  image: string;
};

export interface AllRatings {
  offending: ratingCount;
  inaccurate: ratingCount;
  relevant: ratingCount;
  important: ratingCount;
  enlightening: ratingCount;
}

export interface Article {
  id: number;
  created: string;
  pretty_created: string;
  published_at: string;
  pretty_published_at: string;
  title: string;
  description: string;
  your_rating?: any;
  url: string;
  image: string;
  manager: UserRef;
  rating_options: RatingOption[];
  background_color: number[];
  source_image: string;
  group_domain: string;
  comments_count: number;
  view_count: number;
  rating_count: number;
  all_ratings: AllRatings;
  saved: boolean;
  public: boolean;
}

export interface Reward {
  id: number;
  created: string;
  pretty_created: string;
  message: string;
  message_plaintext: string;
  sender: UserRef;
  user: UserRef;
  receivers_count: number;
  amount: number;
  group_domain: string;
  comments_count: number;
  add_ons_count: number;
  is_an_add_on: boolean;
  saved: boolean;
}

export interface RewardPayload {
  message: string | null;
  message_plaintext: string | null;
  users: number[];
  amount: number;
}

export interface RewardsData {
  rewards: Reward[];
  count: number;
  has_more_pages: boolean;
}

export interface ClaimedItem {
    id: number;
    name: string;
    created?: string;
    image: string;
    description: string;
    value: number;
    cost: number;
    category: string;
    disclosure: string;
    product_id: string;
}

export interface ClaimRedeemData {
  rewards: ClaimedItem[];
  count: number;
  has_more_pages: boolean;
}
export interface Publisher {
  name: string;

  /** sic, error in api  */
  last_scrape_articles: number;
  total_number_of_articles: number;
  image: string;
  last_day_articles: number;
  repository_articles: number;
}

export interface SystemArticle {
  id: number;
  already_posted: boolean;
  publisher: string;
  media: MediaResponse[];
  is_video: boolean;
  created: string;
  pretty_created: string;
  published_at: string;
  pretty_published_at: string;
  title: string;
  description: string|null;
  your_rating?: any;
  url: string;
  manager: UserRef;
  rating_options: RatingOption[];
  background_color: number[];
  source_image: string;
  group_domain: string;
  comments_count: number;
  view_count: number;
  rating_count: number;
  all_ratings: AllRatings;
  saved: boolean;
  key?: string | number;
  icon?: string;
  refetch?: () => void;
  onPost?: () => void;
}
export interface SystemArticlesData {
  available_publishers: Publisher[];
  available_tags: string[];
  articles_found: number;
  articles_in_repository: number;
  articles: SystemArticle[];
}
export interface ManagerUpdate {
  id: number;
  created: string;
  description: string;
  url?: string;
  media?: MediaRef[];
  manager: UserRef;
  pinned:boolean;
}

export interface ManagerUpdateCreationPayload extends Pick<ManagerUpdate, 'description'> {
  plaintext: string;
  media?: MediaResponse[]
}

export interface PollOption {
  id: number;
  text: string;
  num_votes: number;
  percent: number;
}

export interface PollVotePayload {
  your_vote: number;
  total_votes: number;
  options: PollOption[];
}

export interface Poll {
  id: number;
  created: string;
  pretty_created: string;
  title: string;
  your_votes?: number[];
  total_votes: number;
  manager: UserRef;
  multiselect: boolean;
  options: PollOption[];
  comments_count: number;
  saved: boolean;
  members: number[];
  public: boolean;
}

export interface PollMembers {
  has_more_pages: boolean;
  count: number;
  members: number[];
}

export interface PostMembers {
  has_more_pages: boolean;
  count: number;
  members: number[];
}

export interface MediaMembers {
  has_more_pages: boolean;
  count: number;
  members: number[];
}

export interface MediaMembersPayload {
  public: boolean;
  users: number[];
}

export interface EventMembers {
  has_more_pages: boolean;
  count: number;
  members: number[];
}

export interface Role {
  id: number;
  current_role: string;
  another_role: string;
  seniority_current_position: string;
  seniority_in_another_position: string;
  previous_role: string;
  seniority_previous_job: string;
  previous_role_2: string;
}

export interface Address {
  id: number;
  city: string;
  country: string;
}

export interface Skill2 {
  en: string;
  he: string;
}

export interface Skill {
  id: number;
  skill: Skill2;
  name: string;
  image?: any;
}

export interface TargetPopulation {
  id: number;
  population: number;
}

export interface Rank {
  name: string;
  image: string;
}

export interface Rank2 extends Rank {
  id: number;
  image: string;
  percent: number;
  date_updated: string;
  points: number;
}

export interface RankData extends Rank {
  id: number;
  image: string;
  grey_image: string;
  points: number;
  percent_of_current: number;
}

export interface CommunityMemberItem {
  id: number;
  network_id: number;
  created: string;
  full_name:string;
  image:string;
  current_role:string;
  cv:string;
  saved: boolean;
  rank?: {
    name: string;
    image:string;
  } | null;
}

export interface CommunitySuggestion {
  about: string;
  already_member: boolean;
  community_name: string;
  id: number;
  img: string;
  logo: string;
  preferred_locale:Locale;
}
export interface CommunityBase {
  id: number;
  community_name: string;
  title: string;
  img: string;
  logo: string;
  admin: AdminRef;
  about: string;
  profile_percent?: any;
  community_chat_domain?: any;
  preferred_locale: Locale;
  is_current: boolean;
  members_count: number;
  managers_ids: number[];
  config: {
    basic_walk_through_enabled: boolean;
  }
}
export interface CommunityCreationResponse extends Pick<CommunityBase, 'preferred_locale'|'about'|'logo'|'img'|'title'|'community_name'|'id'>{
  admin_id: number;
  type: string;
  public: boolean;

}
export type CommunityStatus =
  | null // no action taken
  | 'invited' // can onBoard
  | 'join_request' // started onboarding but didnt finish
  | 'pending'; // sent onboarding, awaiting confirmation

export interface UserCommunity extends CommunityBase {
  is_in_community: number;
  status: CommunityStatus;
  avg_post_count_per_day: number;
  language?: string;

  /** unread messages */
  unread_count:number;
  not_seen_notifications:number
  not_seen_posts_count: number;
}
export interface Community extends CommunityBase {
  status: string;
  members_count: number;
  unread_count: number;
  avg_post_count_per_day: number;
  members: CommunityMember[];
  total_count?: number;
}
export interface joinCommunityAnswer {
  id: number;
  name?: string;
  en: string;
  order: number;
  image?: string;
  object_type?: number;
  stats?:{
    percentage:number;
    occurrences:number;
  }
}

export type QuestionType =
  | 'predefined_one_selection'
  | 'predefined_multi_select'
  | 'open'
  | 'expertises'
  | 'things_to_learn'
  | 'interests';

/**
 * question for display in onboarding to user
 */
export interface joinCommunityQuestion {
  id: number;
  order: number;
  optional: boolean;
  question: string;
  type: {
    type_name: QuestionType;
  };
  answers: joinCommunityAnswer[];
}

export interface onBoradingCommunityPayload {
  user_id: number;
  on_boarding: {
    scheme: {
      id: number;
      locale: string;
      scheme: string;
    };
    questions: joinCommunityQuestion[];
    community_name: string;
  };
}

export type UserLabel = {
  color: string;
  id: number;
  name: string;
};
interface UserRole {
  current_role: string;
  current_company: string;
}


export interface UserBase {
  id: number;
  network_id: number;
  full_name: string;
  first_name: string;
  last_name: string;
  image: string;
  phone_number: string;
  rank: Rank2 | null;
  finished_on_boarding: boolean;
  labels?: {
    id: number;
    user: number;
    label: UserLabel;
  };
}
export enum CommunityType{
  Regular = 1,
  Event
}
export interface User extends UserBase {
  current_role?: string;
  current_community: Community & {
    public: boolean,
    type: CommunityType
  };
  shareing_profile: boolean;
  email: string;
  gender: string;
  linkedin_link: string;
  facebook_link: string;
  program: string;
  sector?: any;
  role: Role;
  address?: Address;
  skills: Skill[];
  knowledge_area_israel: any[];
  leading_moves: string;
  target_population: TargetPopulation[];
  other_general_info?: any;
  relations: any[];
  pending_communities: CommunityBase[];
  join_request_communities: Community[];
  is_favorite: boolean;
  relationship: number;
  birth_day: string;
  rewards?: {
    available_to_distribute: number;
    claimed: number;
  };
}
export interface CommunityMember extends Omit<UserBase, 'finished_on_boarding'> {
  role: UserRole | null;
}

export interface ProfileAddress {
  id: number;
  postal_code?: string;
  street?: string;
  city: string;
  country: string;
}

export interface ProfileRank {
  image: string;
  name: string;
  all_points: number;
}

export interface ListItem {
  id: number;
  name: string;
  image: string;
  willing_to_learn?: boolean;
}

export interface ProfileWorkExperience {
  id: number;
  employer: string;
  position: string;
  employment_start: string;
  employment_end: string;
}

export interface UserProfile {
  id: number;
  image: string;
  full_name: string;
  rank: ProfileRank | null;
  current_role?: string;
  community_name: string;
  cv: string;
  email: string;
  phone_number: string;
  address: ProfileAddress;
  work_experience: ProfileWorkExperience[];
  expertises: Expertise[];
  interests: Interest[];
  birth_day: string;
  profile_config: {
    hide_phone_number: boolean;
    hide_email: boolean;
  }
}

export interface UserRanking {
  user: UserRef & {
    rank: {
      name: string;
      points: number;
      points_till_next_rank: number;
    };
  };
  ranks: RankData[] | null;
}

export interface UpdatedUserProfile {
  id: number;
  image: string;
  full_name: string;
  rank: ProfileRank | null;
  current_role?: string;
  community_name: string;
  cv: string;
  email: string;
  phone_number: string;
  address: ProfileAddress;
  work_experience: ProfileWorkExperience[];
  expertises: number[];
  interests: number[];
  profile_config: {
    hide_phone_number: boolean;
    hide_email: boolean;
  }
}

export interface Sponsor {
  id: number;
  logo_path: string;
  link: string;
  description: string;
  community_id: number;
}


export interface Interest {
  id: number;
  name: string;
  image: string;
}
export interface Expertise extends Interest {
  willing_to_learn?: boolean;
}
export interface PredefindFilter {
  id: number;
  filter_name: string;
  color: string;
  position: number;
}

export type SubTaskStatus =
  | 'Commit'
  | 'In Progress'
  | 'Pending 3rd Party'
  | 'Delayed'
  | 'Complete';

export type GroupChatType = {
  id: number;
  icon_path: string;
  name: string;
};

export interface CommunityMasterData {
  rating_options: RatingOption[];
  media_types: { [key: string]: number };
  interests: Interest[];
  expertises: Interest[];
  event_default_images: string[];
  bucket_locations: string[];
  message_types: { [key: string]: number };
  wall_filter_types: { [key: string]: string };
  filter_types_mobile: {
    key: string;
    value: string;
  }[];
  chat_hub_types: GroupChatType[];
  user_labels: UserLabel[];
  organizations: string[];
  countries: string[];
  user_communities: Community[];
  pending_communities: Community[];
  join_request_communities: Community[];
  invited_communities: Community[];
  not_seen_notifications: number;
  sub_task_statuses: SubTaskStatus[];
  predefined_filters: PredefindFilter[];
  community_predefined_filters: PredefindFilter[];
  community_onboarding_questions_filters: {
    answers: PredefindFilter[];
    filter_name: string;
    id: number;
    filter_enabled: boolean;
  };
}

export interface Filters {
  interests: Interest[];
  expertises: Interest[];
  countries: string[];
  user_labels: UserLabel[];

  /** custom user created filters  */
  predefined_filters: PredefindFilter[];

  /** custom commnity specific (manager created) filters  */
  community_predefined_filters: PredefindFilter[];

  /** filters based on the community onboarding questions */
  community_onboarding_questions_filters: {
    answers: PredefindFilter[];
    filter_name: string;
    id: number;
    filter_enabled: boolean;
  }[];
}

export interface UploadedFile {
  type: number;
  path: string;
  name: string;
}

export interface PostCreationPayload {
  communities: number[];
  description?: string;
  group_title?: string;
  media?: MediaResponse[];
  name: number;
  title?: string;
  users?: number[];
  plaintext: string;
  ranges?: TagRange[];
  as_system_user?: boolean;
}

export interface PostUpdatePayload {
  description?: string;
  plaintext?: string;
  title?: string;
  image_paths?: ( Omit<MediaResponse, 'name'> & { name?: string } )[];
  group_domain: string;
  users?: number[];
  ranges?:TagRange[]
}

export interface LikePayload {
  group_domain: string;
  like: boolean;
}

export interface CommentPayload {
  target_id: string | number;
  plaintext:string | null;
  text: string;
  type: number;
  is_group: boolean;
  ranges: TagRange[];
  parent_id?: number;
  media_name?: string | null;
}

export interface Comment extends CommentPayload {
  id: number;
  sender_id: number;
  created: string;
  children_count: number;
  parent_id: number;
  parent_message?: ( Comment & { sender_name?: string } ) | null;
  is_updated: boolean;
}

export interface CommentsData {
  users: UserRef[];
  messages: Comment[];
  has_more_pages: boolean;
}
export interface GroupMessages extends CommentsData {
  messages: Comment[];

  /*
   * users: Array<{
   *   network_id: number;
   *   full_name: string;
   *   image?: string;
   *   current_role?: string;
   *   communities: Array<CommunityRef>;
   *   rank: Rank;
   * }>;
   */
  users: UserRef[];
  has_more_pages: boolean;
}
export interface MediaMessages extends CommentsData {
  page: number;
}

export interface EventPayload {
  community_id?: number;
  manager_id?: number;
  description: string;
  end_date: string;
  img_link?: string | null;
  last_date_of_registration: string;
  location?: string;
  start_date: string;
  title: string;
  users: number[];
  online: boolean;
  create_conference: boolean;
  link?: string;
  media: MediaResponse[];
  members: number[];
  public: boolean;
}

export interface CreateFilterPayload {
  filter_name: string;
  user_ids: number[];
  color?: string;
  position?: number;
}

export interface MessageUser {
  network_id: number;
  auth_user_id?: number;
  full_name: string;
  image: string;
  current_role?: string;
  rank?: {
    name: string;
    image: string;
  } | null;

  /*
   * communities: {
   *   id: number;
   *   community_name: string;
   *   title: string;
   *   img: string;
   *   logo: string;
   *   members_count: number;
   * }[];
   */
  last_message_date: string;
  last_message?: Message & {
    unread_count: number;
  };
}

export interface Message {
  created: string;
  sender_id: number;
  target_id: string;
  text: string;
  type: number;
  is_group: boolean;
}
export interface ChatGroup {
  background_color: number[];
  comments_count: number;
  communities: CommunityRef[];
  created: string;
  group_descriptions: string;
  group_domain: string;
  group_title: string;
  group_type_id: {
    id: number;
    name: string;
  };
  id: number;
  is_popular: number;
  like_count: number;
  liked: boolean;
  manager: MessageUser;
  manager_id: number;
  media: unknown[];
  member_ids: number[];
  members: {
    current_role?: string;
    full_name: string;
    image: string;
    network_id: number;
  }[];
  members_count: number;
  tags: unknown[];
  unread_count: number;
  view_count: number;
}

export interface Hub {
  chat_group: ChatGroup;
  created: string;
  id: number;
  last_message_date: string|null;

  /** message count */
  messages: number;
  tags: unknown[];
  type: {
    icon_path: string;
    id: number;
    name: string;
  };
  last_message: Message & {
    unread_count: number;
  } | undefined;
}

export interface UserChats {
  messages: ( Message & {
    unread_count: number;
  } )[];
  users: MessageUser[];
  hubs: Hub[];
}

/*
 * export interface PrivateUserMessages {
 *   messages: Array<Message>;
 *   users: Array<MessageUser>;
 * }
 */

export type ChatMessagePayload = CommentPayload

export interface ChatGroupCreatePayload {
  name: string;
  title: string;
  users: number[];
  community_id: number;
  hub_type_id: number;
  public: boolean;
}

export interface FetchMembersPayload {
  page?: number;
  search?: string;
  location?: string;
  roles?: string;
  expertise?: string;
  interests?: string;
  predefined_filters?: string;
  community_predefined_filters?: string;
  onboarding_answers?: string;
  exclude_me?: boolean;
}


export interface SuggestedCommunity {
  about: string;
  community_name: string;
  id: number;
  img?: string;
  logo: string;
  title: string;
  members_count: number;
  avg_post_count_per_day: number;
  members: {
    current_role?: string;
    full_name: string;
    image: string;
    network_id: number;
  }[];
  status: CommunityStatus;
}

export interface TagRange {
  entity_type: string; // type of database entity related to this tag ('user')
  entity_id: number; // PK of the 'entity_type' i.e. user's Id
  offset: number;
  length: number;
}

export interface FileData {
  file?: File;
  preview?: string;
  id: string | number;
  type?: string;
  media_type?: number;
  media_path?: string;
  name?: string;
}

export interface UserRegistationPayload {
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
}
export interface UserRegistationResponse {
  id: number;
  network_id: number;
  full_name: string;
  first_name: string;
  last_name: string;
  image: string;
  current_role: string;
  is_admin: boolean;
  finished_on_boarding: boolean;
}
export type Invitation = {
  manager: boolean;
  email: string;
  full_name: string;
};
export interface CommunityCreationPayload {
  token: string;
  preferred_locale: string;
  admin_id: number;
  community_name: string;
  about: string;
  public: boolean;
  img: string;
  logo: string;
  topics: number[];
  type:CommunityType;
}
export type QuestionTypeName = 'open'|
'predefined_multi_select'|
'interests'|
'expertises'|
'predefined_one_selection'|
'things_to_learn'


export type OnboardingQuestionType = {
  id: number;
  type_name: QuestionTypeName;
  adapted_name: string;
};

/**
 * Onboarding answers as they come from the backend for the editor
 */
export type OnboardingQuestionAnswer = {
  id: number;
  order?: number;
  languages: {
    en?: string;
    he?: string;
  } | null;
  leads_to:OnboardingQuestion[]|null
};

/**
 * Onboardinq questions as they come from the backend for the editor
 */
export interface OnboardingQuestion {
  id: number;
  order: number;
  optional: boolean;
  languages: {
    en: string;
    he: string;
  };
  text: string;
  type: OnboardingQuestionType;
  answers: OnboardingQuestionAnswer[];
  community_preferred_language: string;
}

/**
 * Answer as it should be sent to server for onboarding creation
 */
export type AnswerForQuestionCreation = Omit<
  OnboardingQuestionAnswer,
  'id'|
  'leads_to'
> & {
  id: number|undefined;
  leads_to:QuestionForOnboardingCreation[]|null;
};

/**
 * Question as it should be sent to server for onboarding creation
 */
export type QuestionForOnboardingCreation = Omit<OnboardingQuestion, 'answers'|'id'> & {
  answers: AnswerForQuestionCreation[];
  id:number|undefined
};

/**
 * Structure used for onboarding creation api
 */
export interface OnboardingQuestionsPayload {
  questions: QuestionForOnboardingCreation[];
}
type StatsObject = {
  percentage:number;
  occurrences:number;
}

/**
 * Answer as received in question to display to onboarding user
 */
export type AnswerForOnboaringQuestion = {
  id: number;
  order: number;
  en: string;
  he: string;
  stats: StatsObject|null;
}

/**
 * Question as received to display to onboarding user
 */
export type QuestionForOnboarding = Omit<OnboardingQuestion, 'answers'>&{
  answers: AnswerForOnboaringQuestion[]
}

/**
 * Answer for submission by user
 */
export type OnboardingAnswer = {
  question_id: number;
  answer: AnswerType;
};

export interface OnboardingData {
  full_name: string;
  role: string;
  phone_number: string; // "+49123",
  location: string;
  photo: string; // "property/default_user_icons/1.png",
  onboarding_answers: OnboardingAnswer[];
}

export type QuestionId = Omit<string, 'USER_FULL_NAME' | 'USER_LOCATION' | 'USER_ROLE'> | 'USER_FULL_NAME' | 'USER_LOCATION' | 'USER_ROLE';
export type SchemeLine = {
  text: string;
  optional: boolean;
  question_id?: QuestionId;
};
export interface OnboardingScheme {
  id: number;
  languages: {
    description: string;
    en: SchemeLine[];
    he: SchemeLine[];
  };
}
export type OnboardingSchemePayload = Omit<OnboardingScheme, 'id'>;

export interface ScrappedArticle {
  description: string;
  id: string;
  image: string;
  published_at: string;
  source: string;
  source_image: string;
  title: string;
  url: string;
  topic: string;
}

export interface OnboardingPayload {
  full_name: string;
  id: number;
  photo: string;
  role: string;
  phone_number: string;
  location: string;
  onboarding_answers: OnboardingAnswer[];
  bio?: string,
}
interface ExpertiseInterestBase {
  image: string;
  languages: {
    en?: string;
    he?: string;
  };
}
export interface ExpertisePayload extends ExpertiseInterestBase {
  object_type: 1;
}
export interface InterestPayload extends ExpertiseInterestBase {
  object_type: 2;
}
export interface ExpertiseItem extends ExpertiseInterestBase {
  object_type: 1;
  id: number;
}
export interface InterestItem extends ExpertiseInterestBase {
  object_type: 2;
  id: number;
}
export interface CommunityTopic {
  id: number;
  pretty_name: string;
  languages: {
    en: string;
    he: string;
  };
}
export type ArticleSource = {
  id: number;
  name: string;
  image: string;
  total_number_of_articles: number;
  last_day_articles: number;
};

export type CommunityConfig = {
  auto_generate_posts: boolean;
  topics: CommunityTopic[];
  sources: ArticleSource[];
  invitation_mail_template: string;
  basic_walk_through_enabled: boolean;
  show_member_cells: boolean;
};
export type CommunityConfigPayload = {
  auto_generate_posts: boolean;
  show_member_cells: boolean;
  topics: number[];
  sources: number[];
};


export interface CommunitySettings {
  id: number;
  community_name: string;
  logo: string;
  about: string;
  public: boolean;
  preferred_locale: Locale;
  type: CommunityType;
}
export interface CompleteCommunitySettings extends CommunitySettings {
  config: CommunityConfig;
}
export interface CommunitySettingsPayload extends CommunitySettings {
  config: CommunityConfigPayload;
}
export interface InviteNewMemberPayload {
  community_id: number;
  first_name: string;
  last_name: string;
  mail: string;
  message?: string;
  phone_number?: string;
}
export interface UserOnboardingAnswer {
  id: string;
  question_text: string;
  answers: ( string | number )[];
}

export interface UserStatistics {
  profile_strength: number;
  activity_score: number;
  networking_score: number;
  challenge_points: number;
}
export interface UserAdminProfile {
  birth_day?: any;
  network_id: number;
  full_name: string;
  image: string;
  gender?: any;
  phone_number: string;
  cv: string;
  leading_moves: string;
  program?: any;
  linkedin_link: string;
  facebook_link: string;
  other_phone_number: string;
  user: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
  };
  role: Role;
  address?: any;
  skills: any[];
  target_population: any[];
  personal_education: any[];
  communities: Community[];
  profile_views: any[];
  rank: {
    id: number;
    image: string;
    rank_name: string;
    all_points: number;
    all_percent: string;
    profile_strength: string;
    active_points: number;
    active_percent: string;
    network_points: number;
    network_percent: string;
    date_of_update_rank: string;
    percent_to_next_rank: string;
    challenge_points: number;
    challenge_points_percent: string;
  }[];
  work_experience: any[];
  user_relationship: any[];
  user_statistics: UserStatistics;
  target_population_ids: number[];
  skills_ids: number[];
  labels: {
    id: number;
    label: {
      id: number;
      name: string;
      color: string;
      action: string;
    };
    user: number;
  };
  things_to_learn: any[];
  joining_reason: string;
  involvement_opportunity: string;
  manager_defined_fields: any[];
  onboarding_answers: UserOnboardingAnswer[];
}
export interface MinimalMemberInfo{
  network_id:number;
  full_name:string;
  image:string;
}

export interface IBioResponse {
  options: BioOptions
}

export interface BioOptions {
  professional: string,
  funny: string,
  sociable: string,
  serious: string,
}
