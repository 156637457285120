import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

import { ReactComponent as CloseSvg } from '~/shared/images/close.svg';
import { useRTL } from '~/shared/hooks';

/* eslint-disable-next-line  @typescript-eslint/no-unused-vars */
const CloseModalIcon = styled( ( { rtl, ...props }:ComponentPropsWithoutRef<'svg'>&{rtl:boolean} ) => <CloseSvg {...props}/> )<( {rtl?:boolean} )>`
  position: absolute;
  top: 20px;
  cursor: pointer;
  height: 20px;
  width: 20px;
  outline:none;
  overflow: visible;
  z-index:${( { theme } ) => theme.typing.zIndexes.overOthers};
  ${( { rtl } ) => ( rtl
    ? 'left'
    : 'right' )}:20px;
`;

function CloseButton ( { onClick, className }:ComponentPropsWithoutRef<'svg'> ) {

  const rtl = useRTL();
  return (
    <>
      <CloseModalIcon
        id="close-modal-icon"
        aria-label="Close"
        rtl={rtl}
        className={className}
        onClick={( e ) => {

          onClick?.( e );

        }}
      />
    </>
  );

}
export default CloseButton;
