import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { fetchDefaultImages } from '~/shared/services/api/api';
import { useQuery } from 'react-query';
import { ErrorMessage } from 'formik';
import { ReactComponent as EmptyUser } from '~/shared/images/empty-user.svg';
import ImageUploader, { Label } from '~/feed/components/UserProfile/ImageUploader';
import { SecureImg } from '~/shared/components';
import { CloudUploadSvg } from '~/shared/images';
import { ReactComponent as UploadSvg } from '~/shared/images/file-upload.svg';
import { useOutsideClick } from '~/shared/hooks';
import { useTranslation } from 'react-i18next';
import { Title } from '~/app/components/simpleAuth/styled';
import { motion } from 'framer-motion';

const Root = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
  align-items: flex-end;
`;
const ImageContainer = styled.button.attrs( { type: 'button' } )<{hasImage:boolean}>`
  position: relative;
  height: 45px;
  width: 45px;
  min-height: 57px;
  min-width: 57px;
  border-radius: 50%;
  border: 4px solid;
  border-color: #eee;
  transition:.2s;
  background: ${( { hasImage, theme } ) => hasImage && `radial-gradient(
    circle at bottom,
    ${theme.colors.primary} 
    10%,
    ${theme.colors.secondary}
    60%
    )`};
  :focus{
    border-color: ${( { theme } ) => theme.colors.primary};
    outline: unset;
    path{
      transition:.2s;
      fill: ${( { theme } ) => theme.colors.primary};
    }
  }
  
`;

const Profile = styled( SecureImg )`
  height: 100%;
  border-radius:50%;
`;
const Placeholder = styled( Profile )<{hasImage:boolean}>`
  width: ${( { hasImage } ) => ( hasImage
    ? '100%'
    : '140%' )};
  height: auto;
  aspect-ratio: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: ${( { hasImage } ) => ( hasImage
    ? 'contain'
    : 'cover' )};
`;
const Option = styled.span`
  display:flex;
  flex-direction:row;
  align-items:center;
  position: relative;
  gap:12px;
  width:40px;
  height:40px;
`;

const UploadIcon = styled( UploadSvg ).attrs( { viewBox: '-5 -5 25 25' } )`
  position: absolute;
  top: calc(50% + 13px);
  left: calc(50% + 20px);
  background: #FFFFFF;
  height: 23px;
  width: 23px;
  border: 2px solid ${( { theme } ) => theme.colors.primary};
  border-radius: 50%;
  transform: translate(-50%,-50%);
  path{
    fill:${( { theme } ) => theme.colors.primary};
  }
  .language-he &{
    left:unset;
    transform: translate(50%,-50%);
  }
`;

const UploaderLabel = styled( Label )`
  position: unset;
  display: flex;
  align-items: center;
  width:100%;
  padding: 1rem 0.5rem ;
  margin: 3px;
  color:${( { theme } ) => theme.colors.textLight};
`;

const CloudIcon = styled( CloudUploadSvg )`
  margin-inline: .5rem;
  path {
    fill: ${( { theme } ) => theme.colors.primary};
  }
  *:focus>&,*:hover>&{
    transform: scale(1.2);
  }
`;

interface ImageSelectorProps {
  image?: string;
  isBlob:boolean;
  setFieldValue: ( field: 'image', value: string, shouldValidate?: boolean | undefined ) => void;
  onImageToUpload( evt?: React.ChangeEvent<HTMLInputElement> ):void,
  onBlur?( e: React.ChangeEvent<Element> ):void;
  error?:string;
 }
export function ProfileImageSelector ( {
  image,
  isBlob,
  setFieldValue,
  onImageToUpload,
  onBlur
}: ImageSelectorProps ) {

  const { t } = useTranslation();
  const { data: images } =
    useQuery('defaultProfileImages', fetchDefaultImages, {});
  const imageOptions = images?.avatars.map( ( img ) => ( {
    value: img
  } ) );
  const [ isMenuOpen, setMenuOpen ] = useState( false );
  const menuRef = useRef<HTMLDivElement>( null );
  useOutsideClick( menuRef, () => setMenuOpen( false ), isMenuOpen);
  const imageSelection = !isBlob && imageOptions?.find(
    ( opt ) => image && opt.value.includes( image as string )
  );
  function onSelectImage(option: {value: string} | null) {
    if (!option)
      return;
    const { value } = option;
    // eslint-disable-next-line prefer-named-capture-group
    const idRegex = /\/property\/default_user_icons\/(\d+).png\?/;
    const res = ( value as string ).match( idRegex );
    const [ , id ] = ( res as RegExpMatchArray );
    // clear uploaded image if exists
    onImageToUpload( undefined );
    setFieldValue('image', `property/default_user_icons/${id}.png`);
    setMenuOpen( false );
  }
  useEffect(() => images && onSelectImage({value: images.avatars[0]}), [images])
  return <>
    <Root>
      <motion.div animate={!imageSelection && !image ? {
        scale: [1, 1.1, 1, 1.1, 1],
        animationDirection: 'alternate',
        transition: {duration: 1, repeat: Infinity, repeatDelay: 0.6, delay: 2}
      } : {}}>
        <ImageContainer
          tabIndex={0}
          name="image"
          onBlur={onBlur}
          autoFocus
          onClick={() => setMenuOpen( true )}
          hasImage={!!( image?.length )}
        >
          {imageSelection || image
            ? <Placeholder
              hasImage={!!( image?.length )}
              mediaId={undefined}
              src={( imageSelection ? imageSelection.value : image )}/>
            : <EmptyUser height={49}/>}
          <UploadIcon height={12.55}/>
        </ImageContainer>
      </motion.div>
      <ErrorMessage name="image">
        {t('join.errors.image')}
      </ErrorMessage>
      <div
        ref={menuRef}
        style={{
          display: isMenuOpen ? 'flex' : 'none',
          position: 'absolute',
          borderRadius: 10,
          backgroundColor: '#fff',
          boxShadow: '0px 2px 8px 4px rgba(0, 0, 0, 0.12)',
          width: '100%',
          alignItems: 'start',
          flexDirection: 'column',
          zIndex: 2,
          overflow: 'hidden auto',
          top: 0,
          maxHeight: '65vh',
          maxWidth: 480,
          insetInlineStart: -20,
          left: 0,
        }}>
        <ImageUploader
          onChange={( e ) => {
            onImageToUpload?.( e as any );
            setMenuOpen( false );
          }}
          // no need for it to display image, used only for upload image upload function
          imgSrc={undefined}
          tabIndex={0}
          borderWidth={5}
          size={115}
          borderColor="#CCC"
          accept="image/jpeg,image/png,image/gif,image/jpg"
          UploadLabel={UploaderLabel}
          Icon={CloudIcon}
        />
        <Title style={{paddingLeft: 15}}>{t( 'join.imageSelection' )}</Title>
        <Select<{ value: string; }> // only used for selection menu
          options={imageOptions}
          tabIndex="0"
          menuIsOpen={true}
          isSearchable={false}
          openMenuOnClick={true}
          isMulti={false}
          tabSelectsValue={false}
          classNamePrefix="image-select"
          value={image
            ? imageOptions?.find(
              ( opt ) => opt.value.includes( image as string )
            )
            : undefined}
          styles={{
            container ( defaultStyle ) {
              return {
                ...defaultStyle,
                width: '100%',
                ':focus-within': {outline: 'blue solid 2px'}
              };
            },
            control: () => ({ opacity: 0, height: 0}),
            menu ( defaultStyle ) {
              return {
                ...defaultStyle,
                position: 'relative',
                top: 'unset',
                boxShadow: 'unset',
              };
            },
            menuList ( defaultStyle ) {
              return {
                ...defaultStyle,
                display: 'flex',
                flexDirection: 'row',
                // maxWidth: '400px',
                maxHeight: '300px',
                flexWrap: 'wrap',
              };
            },
            option: (defaultStyle ) => ({ ...defaultStyle, width: '70px'}),
          }}
          onChange={onSelectImage}
          formatOptionLabel={( option ) =>
            <Option>
              <Profile src={option.value} mediaId={undefined}/>
            </Option>}
        />
      </div>
    </Root>
  </>;
}
