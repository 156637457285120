import random from 'lodash/random';

import { useMasterData } from './useMasterData';

const useEventRandomImage = (): { randomImage?: string } => {

  const masterData = useMasterData();
  const defaultImages = masterData?.event_default_images;
  const idx = random(
    0,
    ( defaultImages?.length || 1 ) - 1
  );
  return { randomImage: defaultImages?.[idx] };

};

export default useEventRandomImage;
