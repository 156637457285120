import styled from 'styled-components';

import { Buttons } from './styled';
import { QuestionField, AnswerType, QuestionFieldProps } from './Fields';
import {
  QuestionForOnboarding, QuestionType
} from '~/shared/services/api';
import { CSSProperties, useEffect, useState } from 'react';

const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr 60px;
  grid-row-gap: 5px;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
`;
const StyledField = styled( QuestionField )`
  height: auto;
  max-height: unset;
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    padding: 5px;
    margin-bottom: 15px;
  }
`;
const OptionalLabel = styled.span`
color: red;
white-space: nowrap;
`;
const Header = styled.h2`
  margin: 0;
  padding: 15px 0;
`;
const Explanation = styled.h4`
  margin: 0;
  padding: 5px 0;
`;

export function isAnswered ( type:QuestionType, answer:AnswerType ):boolean {
  if ( !answer ) {
    return false;
  }
  try {
    switch ( type ) {
      case 'open':
        return Boolean( ( answer as string ).length );
      case 'predefined_one_selection':
        return answer !== undefined;
      default:
        return Boolean( ( answer as number[] ).length );
    }
  } catch ( error ) {
    console.error( error );
    return false;
  }
}

function toggleValueInArray<T> ( array:T[], value:T ):T[] {
  const set = new Set( array );
  if ( set.has( value ) )
    set.delete( value );
  else
    set.add( value );
  return Array.from( set );
}

function assignChangeValue ( prevValue: AnswerType, newValue: string|number ):AnswerType {
  let value : AnswerType = '';
  switch ( typeof prevValue ) {
    case 'undefined':
      value = newValue as number;
      break;
    case 'number':
      value = newValue as number;
      break;
    case 'string':
      value = newValue as string;
      break;
    default:
      value = toggleValueInArray(prevValue as number[], newValue as number);
  }
  return value;
}

type QuestionProps = {
  question: QuestionForOnboarding;

  /**
   * current state of selected answer
   */
  selectedAnswer: AnswerType;

  /**
   * next question is being loaded
   *
   */
   isLoadingNext?:boolean;

   /**
    * function to be called after answering a question
    */
  onClickNext: ( answer:AnswerType ) => void;
  onClickBack: () => void;

  /**
   * isLoadingNext is included automatically for this, no need to manually include
   */
  disableNextButton?:boolean;
  disableBackButton?:boolean;

  /**
   * Should the next button be a 'submit' button
   */
  nextIsFinish:boolean;

  /**
   * Number of the question in the list
   */
  questionNumber: number;

  /**
   * Number of questions in the list
   */
  total?: number;
  style?:CSSProperties;

};
export function TriviaQuestion ( {
  question,
  disableBackButton,
  onClickBack,
  disableNextButton,
  onClickNext,
  questionNumber,
  total,
  selectedAnswer,
  nextIsFinish,
  isLoadingNext,
  style

}: QuestionProps ) {
  const [ answer, setAnswer ] = useState<AnswerType>( selectedAnswer );
  useEffect( () => {
    setAnswer( selectedAnswer );
    return () => setAnswer( undefined );
  }, [ question ]);
  const {
    type: { type_name: type },
    text: questionText,
    optional
  } = question;
  const answerIsValid: boolean = optional || isAnswered(type, answer);
  const props: QuestionFieldProps = {
    selected: answer,
    options: question.answers,
    handleChange: ( value: string | number ) => {
      const newAnswer = assignChangeValue(answer, value);
      setAnswer(newAnswer);
    },
    type: question.type.type_name,
    optional: question.optional,
    prefferedLang: question.community_preferred_language as 'en'|'he'
  };
  return <Container style={style}>
    <Header>
      {questionText}
    </Header>
    <Explanation>
      {type !== 'open' && (
        type === 'predefined_one_selection'
          ? 'Select one answer '
          : 'Select at least one answer '
      )}
      {optional && <OptionalLabel>( Optional )</OptionalLabel>}
    </Explanation>
    <StyledField {...props} />
    <Buttons
      style={{position: 'relative', bottom: 0, padding: 'unset', marginInline: 'auto', width: '95%'}}
      prevActive={!disableBackButton}
      prev={!disableBackButton ? onClickBack : undefined}
      isSubmit={nextIsFinish}
      nextActive={!isLoadingNext && ( answerIsValid && !disableNextButton )}
      next={async ( e ) => {
        e.preventDefault();
        e.stopPropagation();
        onClickNext( answer );
      }}
    >
      <b style={{ marginInline: 10 }}>
        { `${questionNumber + 1}${total ? `/${total}` : ''}`}
      </b>
    </Buttons>
  </Container>;
}
