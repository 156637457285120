/* eslint-disable no-useless-escape */
/* eslint-disable require-unicode-regexp */
import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { Formik, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';

import {
  Section as _Section,
  Column as _Column,
  EmptyButton as _Button,
} from './styled';
import { MailSvg } from '../images';

import { api } from '~/shared/services/api';
import { useRecaptcha } from '~/shared/hooks/useRecaptcha';
import { config } from '~/shared/config';


const Root = styled( _Section )`
  background: ${( { theme } ) => theme.colors.landingBackgroundDark};
  padding: 70px 10px;
  gap: 148px;
  justify-content:center;
  align-items:flex-start;
  @media ${( { theme } ) => theme.typing.mediaRules.untilMedium} {
    gap: 20px;
  }
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    align-items: center;
    padding: 50px 30px;
    flex-direction: column;
  }
`;
const Column = styled( _Column )`
  max-width: 564px;
  background: ${( { theme } ) => theme.colors.landingBackgroundDark};
  position:relative;
  width:100%;
  padding:unset;
  gap: 20px;
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    max-width: unset;
    gap: 20px;
  }

`;
const Headline = styled.span`
  font-size: 34px;
  font-weight: 800;
  line-height: 1.24;
  letter-spacing: -0.68px;
  color: ${( { theme } ) => theme.colors.primary};
`;
const Content = styled.p`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.48px;
  color: rgba(255, 255, 255, 0.9);
  margin: 0;
  padding-top: 12px;
`;
const Mail = styled.a`
  position:relative;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.22;
  letter-spacing: -0.36px;
  color: rgba(255, 255, 255, 0.9);
  padding-left: 43px;
  width:fit-content;
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
  }
`;
const MailIcon = styled( MailSvg )`
  position:absolute;
  left: 0;
  bottom: 9px;
  
`;
const Form = styled.form`
  max-width: 590px;
  width: min( 700px, 100%);
  display: grid;
  grid-column-gap: 40px;
  grid-row-gap: 28px;
  grid-template-rows: 46px 46px 120px 50px ; 
  grid-template-areas: 
  "name company"
  "email phone"
  "message message"
  "send send";
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    display:flex;
    flex-direction : column;
  }
`;
const inputStyle = css`
  padding: 2px 12px;
  border-radius: 8px;
  border: none;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
  color: ${( { theme } ) => theme.colors.white};
  font-size: 18px;

  ::placeholder {
    color: rgba(255, 255, 255, .8)
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  }
`;
const Input = styled( Field )`
  height:46px;
  ${inputStyle}
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    width:100%;
  }
`;
const Text = styled.textarea`
  ${inputStyle}
  width:100%;
  resize: none;
`;
const Error = styled( ErrorMessage ).attrs( { component: 'span' } )`
  position: absolute;
  left: 0;
  bottom: -20px;
  font-size: 16px;
`;
const Button = styled( _Button )`
  color: white;
  border-color:${( { theme } ) => theme.colors.primary};
  transition: .2s;
  padding: 15px 54px;
  grid-area: send;
  height: 50px;
  width:fit-content;
  margin-left:auto;
  background-color:transparent;
  :not(:disabled){
    background-color: ${( { theme } ) => theme.colors.primary};
    :hover, :focus{ 
      filter: brightness(1.25)
    }
  }
`;

type FieldProps = {
  onChange( e:React.ChangeEvent<any> ):void;
  name:string;
  placeholder:string;
  value:string;
  required?:boolean;
}
const fields = [
  {
    name: 'name',
    placeholder: 'Full name',
    required: true
  },
  {
    name: 'company',
    placeholder: 'Company/Organization',
    required: true,
  },
  {
    name: 'email',
    placeholder: 'Email',
    required: true,
  },
  {
    name: 'phone',
    placeholder: 'Phone',
    required: true,
  }
];

const FormField = ( props:FieldProps ) => (
  <span style={{ position: 'relative',
    gridArea: props.name }}>
    <Input
      {...props}
    />
    <Error name={props.name}/>
  </span>
);
export function ContactForm ( { platformName = 'Chimu' }:{platformName?:string} ) {

  // const { executeRecaptcha } = useRecaptcha();
  const toastId = useRef<string>( null );
  return <Root id="contact">
    <Column>
      <Headline>
        Would you like to know more & be a part of the difference we are making?
      </Headline>
      <Content>
        Join {platformName} and upgrade your collaboration today
      </Content>
      <Mail href="mailto:info@chimu.io">
        <MailIcon/>
        Info@chimu.io
      </Mail>
    </Column>
    <Formik
      initialValues={{
        name: '',
        email: '',
        company: '',
        phone: '',
        message: '',
      }}
      // eslint-disable-next-line consistent-return
      onSubmit={async ( values, helpers ) => {

        const {
          company: organization,
          email,
          name: full_name,
          message
        } = values;
        // const token = await executeRecaptcha( 'sendForm' );
        const token = 'await executeRecaptcha( \'sendForm\' );';
        if ( !token ) {

          return toast.error( 'an error has occured, please try again' );

        }
        const data :Parameters<typeof api.contactUs>[0] = {
          captcha: token,
          email,
          full_name,
          message,
          organization
        };
        api.contactUs( data )
        .then( () => {

          toast.update(
 toastId.current!,
 {
   autoClose: 5000,
   type: toast.TYPE.SUCCESS,
   render: `your message was sent.
            thanks!`,
   closeButton: true,
   position: toast.POSITION.BOTTOM_LEFT,
 }
          );
          helpers.resetForm();
          helpers.setStatus( 'great' );

        } )
        .catch( () => {

          toast.error( 'an error has occured, please try again' );

        } );

      }}
      isInitialValid={false}
      initialErrors={{}}
      validate={( values ) => {

        const errors:{[key:string]:string} = {};
        if ( !( /\@/ ).test( values.email ) ) {

          errors.email = 'Invalid email';

        }
        for ( const name in values ) {

          // @ts-ignore
          if ( !values[name].length ) {

            errors[name] = 'Required';

          }

        }
        return errors;

      }}
    >
      {( {
        values, isValid, handleChange, handleSubmit, isSubmitting,
      } ) => (
        <Form onSubmit={handleSubmit}>

          {fields.map( ( field ) => (
            <FormField
              {...field}
              key={field.name}
              onChange={handleChange}
              value={
              // @ts-ignore
                values[field.name]
              }
            />
          ) )}
          <span style={{ height: 'auto',
            position: 'relative',
            gridArea: 'message' }}>
            < Text
              name="message"
              placeholder="Message"
              value={ values.message }
              onChange={ handleChange }
              rows={4}
              required
            />
            < Error
              name="message"
            />
          </span>
          <Button
            type="submit"
            disabled={!isValid || isSubmitting}
          >
            Send
          </Button>
        </Form>
      )}
    </Formik>

  </Root>;

}
