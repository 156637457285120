/**
 * Just a placeholder, will be replaced by NormalModuleReplacementPlugin at @see {webpack.config.ts}
 */
// @ts-expect-error: config dependant
import { default as LandingComponent } from './__PROJECT__';

export type LandingType = () => JSX.Element

export const Landing :LandingType = LandingComponent;

