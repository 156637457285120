import React, { useEffect, useRef } from 'react';
import styled, { CSSProperties, StyledComponent } from 'styled-components';
import { CSSObject } from '@emotion/serialize';

import Select, { MenuPlacement, StylesConfig } from 'react-select';
import { useTranslation } from 'react-i18next';


import { PhonePrefix, PHONE_PREFIXES } from '~/shared/utils/countryData';

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  && {
    direction: ltr;
  }
`;
const Option = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;
const Flag = styled.span`
  min-height: 16px;
  min-width: 22px;
  margin-right: 5px;
`;

interface CommonProps {
  location: string;
  setLocationValue: ( prefix: string, location: string ) => void;
  tabIndex?: number;
}

interface StyleOptions extends Omit<StylesConfig<PhonePrefix, false>,
  'control' |
  'container' |
  'valueContainer' |
  'menu' |
  'menuList' |
  'indicatorSeparator' |
  'indicatorsContainer' |
  'option'
> {
  control?: CSSObject;
  container?: CSSObject;
  valueContainer?: CSSObject;
  menu?: CSSObject;
  menuList?: CSSObject;
  indicatorSeparator?: CSSObject;
  indicatorsContainer?: CSSObject;
  option?: CSSProperties;
}

interface Props extends CommonProps {
  phone?: string;
  setPhoneValue?: (phone: string) => void;
  showDialCode?: boolean;

  /**
   * input to use as phone input
   */
  Input?: StyledComponent<'input', any>;
  location: string;
  SelectMenuPlacement?: MenuPlacement;
  setLocationValue: ( prefix: string, location: string ) => void;
  selectStyles?: StyleOptions;
  className?: string;
}

/**
 * can be used without Phone parameters to select country only
 * @param {Props} param0
 * @returns
 */
export function PhoneAndCountryInput ( {
  phone,
  location,
  setLocationValue,
  setPhoneValue,
  Input,
  selectStyles = {},
  showDialCode = true,
  SelectMenuPlacement,
  className,
}: Props ) {

  const {
    t,
    i18n
  } = useTranslation();
  useEffect( () => { // @ts-expect-error
    import( /* WebpackChunkName: "flags-css" */'flag-icon-css/css/flag-icon.min.css' );
  }, [] );
  const locations = i18n.language === 'he'
    ? PHONE_PREFIXES.sort( ({he}, {he: he2} ) => (he > he2 ? 1 : -1))
    : PHONE_PREFIXES;
  const selectedValue = locations.find( ( loc ) => loc.name === location );
  useEffect(() => {
    if (phone?.includes(selectedValue?.dial_code as string)) {
      setLocationValue(selectedValue?.dial_code as string, selectedValue?.name as string)
      setPhoneValue?.(phone?.split(selectedValue?.dial_code as string)[1])
    }
  }, [phone])
  const {
    control,
    container,
    valueContainer,
    menu,
    menuList,
    indicatorSeparator,
    indicatorsContainer,
    option: optionStyles,
    ...restOfStyles
  } = selectStyles;
  const ref = useRef(null)
  const handleFocus = () => {
    // @ts-ignore
    setTimeout(() => ref.current.scrollIntoView( {
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    } ), 50);
  };

  return <Box
    className={className}
  >
    <Select
      tabIndex="0"
      tabSelectsValue={false}
      placeholder={t( 'Create community.Profile.select country' )}
      options={locations}
      value={selectedValue}
      onFocus={handleFocus}
      onChange={( opt ) =>
        opt && setLocationValue(opt?.dial_code, opt?.[i18n.language === 'he' ? 'he' : 'name'])}
      isOptionSelected={( loc ) => loc.name === location || loc.he === location}
      styles={{
        control: ( defStyles ) => ( {
          ...defStyles,
          border: 'none',
          borderRadius: '0',
          borderBottom: '2px solid #d8d8d8',
          height: 50,
          ...control,
          '& input': {
            caretColor: 'transparent',
          },
        } ),
        container: ( defStyles ) => ( {
          ...defStyles,
          width: '50%',
          overflow: 'visible',
          fontSize: '12px',
          ...container
        } ),
        valueContainer: ( defStyles ) => ( {
          ...defStyles,
          width: '50px',
          padding: '5px',
          // oveflow: 'visible'
          ...valueContainer
        } ),
        menu: ( defStyles ) => ( {
          ...defStyles,
          ...menu,
          minWidth: '300px',
          maxHeight: '150px',
          textAlign: 'left',
          // oveflow: 'visible'
        } ),
        menuList: ( defStyles ) => ( {
          ...defStyles,
          ...menuList,
          maxHeight: '150px',
        } ),
        indicatorSeparator: () => ( {
          display: 'none',
          ...indicatorSeparator
        } ),
        // @ts-ignore
        indicatorsContainer: ( defStyles ) => ( {
          ...defStyles,
          div: {
            padding: 0,
            ...indicatorsContainer
          }
        } ),
        option: ( defStyles ) => ( {
          ...defStyles,
        } ),
        ...restOfStyles
      }}
      filterOption={( { data: { he, label: en } }, search ) => (
        he.toLocaleLowerCase().includes( search.toLocaleLowerCase() ) ||
        en.toLocaleLowerCase().includes( search.toLocaleLowerCase() )
      )}
      formatOptionLabel={( opt ) => <Option
        style={optionStyles}
      >
        <Flag className={`flag-icon flag-icon-${opt.code.toLowerCase()}`} />
        {showDialCode && `(${opt.dial_code})`} { opt[i18n.language === 'he' ? 'he' : 'name']}
      </Option>}
      menuPlacement={SelectMenuPlacement}
    />
    { !!Input && <Input
      tabIndex={0}
      value={phone}
      name="phone"
      type="tel"
      ref={ref}
      onFocus={handleFocus}
      placeholder="555 555 1234"
      onChange={( e ) => {
        setPhoneValue?.(e.target.value.replace(/[^0-9()]/g, ''));
      }}/>}
  </Box>;

}
