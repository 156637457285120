import {
  ListInfoBox, ContentInfoBox, ContentTitle, ListItemContainer, ListItemText
} from './styles';

import {
  ListItem,
} from '~/shared/services/api';
import { useRTL } from '~/shared/hooks';

const List = ( {
  header,
  items,
}: {
  header: string;
  items: ListItem[];
} ) => {

  const rtl = useRTL();

  return (
    <ListInfoBox rtl={rtl}>
      <ContentInfoBox
      >
        <ContentTitle>{header}</ContentTitle>
        {!!items?.length &&
          items.map( ( item: ListItem, index: number ) => (
            <ListItemContainer key={index}>
              <ListItemText>{item.name}</ListItemText>
            </ListItemContainer>
          ) )
        }
      </ContentInfoBox>
    </ListInfoBox>
  );

};

export default List;
