import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  CardTitle
} from './styled';
import { ReactComponent as EditIcon } from '~/shared/images/new-message.svg';
import { CodeBoxProps, CodeBox } from './CodeBox';

const Disclaimer = styled.div`
  font-family: inherit;
  padding: 10px;
  margin: 10px 0;
  max-width: 420px;
  border-radius: 10px;
  text-align: center;
  color: ${( { theme } ) => theme.colors.textMedium};

  a {
    color: ${( { theme } ) => theme.colors.primary};
  }
`;
const Email = styled.b`
  position: relative;

`;
const EditButton = styled.button`
  position: absolute;
  right: -5px;
  transform: translateX(100%);
  color: inherit;
  :hover {
    color: ${( { theme } ) => theme.colors.primary};  
  }
  path{
    fill: currentColor;
  }
`;

export interface Props extends CodeBoxProps, Omit<React.ComponentPropsWithoutRef<'div'>, 'onSubmit'> {
  email: string;
  backToEmail: () => void;
  isLoading?: boolean;
  askTerms?: boolean;
}

export function LoginCodeBox ( {
  email, backToEmail, sendCodeToMail, onSubmit, style, ...rest
}: Props ) {
  const { t } = useTranslation();
  const disclaimerTexts = t( 'Login.Code.recapthca' ).split( '|' );
  return (
    <>
      <Card style={style} {...rest}>
        <CardContent>
          <CardTitle>
            {t( 'Login.Code.text' )}
            <br />
            <Email>
              {email || 'no email provided'}
              <EditButton
                title={t( 'Login.Code.edit' )}
                onClick={backToEmail}
                type="button"
              >
                <EditIcon height={19} />
              </EditButton>
            </Email>
          </CardTitle>
          <CodeBox
            onSubmit={onSubmit}
            sendCodeToMail={sendCodeToMail} />
        </CardContent>
        <Disclaimer>
          {disclaimerTexts[0]}
          <a href="https://policies.google.com/privacy">
            {' '}
            {t( 'Login.Code.privacy' )}
          </a>
          {disclaimerTexts[1]}
          <a href="https://policies.google.com/terms">
            {' '}
            {t( 'Login.Code.terms' )}
          </a>
          {disclaimerTexts[2]}.
        </Disclaimer>
      </Card>
    </>
  );

}
