import { i18n } from "i18next"

export function withHebrew(rtl?:boolean){
  return `
  font-family:${rtl?"Arimo":""};
  direction:${rtl?"rtl":"ltr"};
  font-stretch:"normal";
  `
}

export function isHebrew(i18n: i18n){
  return i18n.language === "he";
}

export function isSharedByTheSystem(name: string) {
  return name === "Shared by the system";
}