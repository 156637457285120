import React, { ReactNode, Suspense } from 'react';
import {
  Redirect,
  Route, Switch, useLocation
} from 'react-router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';

import AuthPage from './Auth';

import Landing from '~/configurables/Landing/LandingPage';
import { ContainedSpinner } from '~/shared/components/Spinner';
import { RecaptchaProvider } from '~/shared/hooks/useRecaptcha';
import { validateToken } from '~/shared/services/api/api';
import { config } from '~/shared/config';
import { useQuery } from 'react-query';
import { ErrorPage } from '~/shared/components/ErrorBoundary';
import { BaseButton } from '~/shared/components/Button';
import { AccountBlockedPage } from './recovery/AccountBlocked';
import { RecoverySuccessPage } from './recovery/RecoverySuccess';
import { RecoveryFailedPage } from './recovery/RecoveryFailed';
import { AccountDeletingPage } from './recovery/DeletingAccount';
import { SimpleAuth } from './simpleAuth/SimpleAuth';
import { simpleAuthPath } from './simpleAuth/simpleAuthPath';
import { QRPage } from './simpleAuth/QrPage';
import { useRTL } from '~/shared/hooks';
import { JoinCommunityPage } from '~/join-community';
import { joinWizardPath } from '~/join-community/JoinCommunity';

const CreateCommunityPage = React.lazy( () => import( /* WebpackChunkName: "create-community" */'~/create-community' ) );
const AuthenticatedShell = React.lazy( () => import( /* WebpackChunkName: "AuthenticatedShell" */'./AuthenticatedShell' ) );


const FullScreen = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${( { theme } ) => theme.colors.backgroundLight};
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: Montserrat;
  &.language-he{
    font-family: Arimo;
    direction:rtl;
    font-stretch:normal;
  }
`;

const siteKey = config.recaptchaKey;
export function ShellContextProviders ( { children }:{children?:ReactNode} ) {

  return <RecaptchaProvider siteKey={siteKey}>
    {children}
  </RecaptchaProvider>;

}

export default function Shell () {

  const { i18n } = useTranslation();

  const location = useLocation();
  const isMobile = location.search.includes( 'mobile' );
  const rtl = useRTL();

  const {
    data: validToken,
    refetch: refetchToken,
    isLoading: tokenLoading,
    isError: tokenError
  } = useQuery<boolean>( 'token', async () => validateToken( isMobile ),
    {
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );
  if (!window.location.href.includes('localhost'))
    document.cookie = 'trustnet_auth=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;';
  if (tokenLoading)
    return <ContainedSpinner data={'invalidToken'}/>;
  if ( tokenError )
    return <ErrorPage>
      <span style={{ textAlign: 'center', display: 'block', lineHeight: 1.6 }}>
          Seems there is a problem connecting to the server <br/>
        <BaseButton onClick={() => refetchToken()}> retry </BaseButton><br/>
          In case of repeated issues, please contact us at <a href="mailto:support@chimu.io">support@chimu.io</a>
      </span>
    </ErrorPage>;
  return (
    <FullScreen className={`language-${i18n.language}`}>
      <ShellContextProviders>

        <Suspense fallback={<ContainedSpinner/>} >
          <Switch>
            <Route exact path="/recover/success" ><RecoverySuccessPage/></Route>
            <Route exact path="/account/deleting" ><AccountDeletingPage/></Route>
            <Route exact path="/recover/failed" ><RecoveryFailedPage/></Route>
            <Route exact path="/auth/blocked" ><AccountBlockedPage/></Route>
            <Route exact path={simpleAuthPath} >
              <SimpleAuth
                refetchToken={refetchToken}
                validToken={validToken as boolean}
              />
            </Route>
            <Route exact path="/auth/qr" >
              <QRPage/>
            </Route>
            <Route exact path="/auth" ><AuthPage refetchToken={refetchToken} validToken={validToken as boolean} /></Route>
            <Route exact path="/create" component={CreateCommunityPage}/>
            <Route exact path={joinWizardPath} render={() =>
              <JoinCommunityPage validToken={validToken as boolean} refetchToken={refetchToken}/>}/>
            <Route exact path="/landing" >
              <Redirect to="/"/>
            </Route>
            {validToken && <Route>
              <AuthenticatedShell validToken={validToken} />
            </Route>}
            { /**
               * When not logged in: the "/" route leads to landing, all other non-specified routes should route through the auth page.
               * when logged in any routes should go into authenticated shell, and there if non-standard it should go to feed
               */}
            <Route path="/" component={Landing}/>
            <Route path="/*" >
              <AuthPage
                refetchToken={refetchToken}
                validToken={validToken as boolean}
              />
            </Route>
          </Switch>
        </Suspense>
      </ShellContextProviders>
      <ToastContainer
        limit={3}
        position={'bottom-left'}
        bodyStyle={{
          direction: rtl
            ? 'rtl'
            : 'ltr'
        }}
      />
    </FullScreen>
  );

}
