import { Suspense, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';


import Shell from './Shell';

import { GlobalStyle } from '~/shared/theme';
import { ContainedSpinner } from '~/shared/components/Spinner';
import ErrorBoundary from '~/shared/components/ErrorBoundary';
import { intializeFireBase } from '~/firebaseInit';
import { AppContextProviders } from './AppContextProviders';

export default function App () {

  useEffect(
    intializeFireBase,
    []
  );
  return (
    <Router>
      <ErrorBoundary nameToLog={'App'}>
        <AppContextProviders>
          <GlobalStyle />
          <Suspense fallback={<ContainedSpinner data={'App suspense'}/>}>
            <Shell/>
          </Suspense>
        </AppContextProviders>
      </ErrorBoundary>
    </Router>
  );

}
