
export { default as Alon } from './Alon.jpg';
export { default as Dovi } from './Dovi.jpg';
export { default as Filip } from './Filip.jpg';
export { default as Matías } from './Matías.jpg';
export { default as Stefan } from './Stefan.jpg';
export { default as Ariel } from './Ariel.jpg';
export { default as David } from './David.jpg';
export { default as Marijo } from './Marijo.jpg';
export { default as Meir } from './Meir.jpg';
export { default as Yasmin } from './Yasmin.jpg';
export { default as Tamara } from './Tamara.png';
