import { useEffect } from 'react';
import { errorMessage } from '../../utils';
import { FCMTypes, FCMPayLoad } from './FCMTypes';

export interface FCMChatPayload extends FCMPayLoad{
  data:{
    push_message_type: '1';
    push_type:string;
    body:string;
    community_id:number;
    from_id:string;
    is_from_web:string;
    object_id:string;
    sender_id:string;
    title:string;
  }
}
function isChat ( payload:FCMPayLoad ) : payload is FCMChatPayload {

  return Number( payload.data.push_message_type ) === FCMTypes.Chat;

}

/**
 * provide a function to be executed upon receiving a chat message, and a dependency array to determine when the function has changed.
 * includes cleanup.
 * @param param0
 * @returns
 */
export default function useFCMPushChatMessageEffect ( effect: ( payload?: FCMChatPayload ) => void, deps:any[] = [] ) {

  const onEvent = ( event: MessageEvent<any> ) => {

    const payload = event.data;
    console.log(
      'noticed a new message from the Chat listener with type',
      payload.data.push_message_type
    );
    try {

      if ( isChat( payload ) ) {

        effect( payload );

      }

    } catch ( err ) {

      console.log( err );
      errorMessage( 'unable to recieve new messages' );

    }

  };

  const onPushChatMessagesListener = () => navigator?.serviceWorker?.addEventListener(
    'message',
    onEvent
  );
  const removePushChatMessageListener = () => navigator?.serviceWorker?.removeEventListener(
    'message',
    onEvent
  );

  useEffect(
    () => {

      onPushChatMessagesListener();

      return removePushChatMessageListener;

    },
    deps
  );

}
