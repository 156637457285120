import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';
import { SecureImg, Props as SecureImgProps } from './SecureImg';

import { icon } from '~/shared/images/logo';

type ContainerProps = {
  borderWidth?: number;
  size?: number|string;
  mobileSize?:number;
  borderColor?: string;
};
type ImageProps = { imgSrc?: string, alt?:string };

const Container = styled.span<ContainerProps>`
  ${( { size } ) => {

    const value = typeof size === 'string'
      ? size
      : `${size}px`;
    return `
    min-width: ${value};
    max-width: ${value};
    min-height: ${value};
    max-height: ${value};`;

  }}
  display: block;
  border-radius: 50%;
  overflow: hidden;
  padding: ${( { borderWidth } ) => `${borderWidth}px`};
  background-color: ${( { borderColor, theme } ) => borderColor || theme.colors.white};
  position: relative;
  @media ${( { theme } ) => theme.typing.mediaRules.mobile} {
    min-width: ${( { size, mobileSize } ) => `${mobileSize || size}px`};
    max-width: ${( { size, mobileSize } ) => `${mobileSize || size}px`};
    min-height: ${( { size, mobileSize } ) => `${mobileSize || size}px`};
    max-height: ${( { size, mobileSize } ) => `${mobileSize || size}px`};
  }
`;

// eslint-disable-next-line
const Img = styled( ( {size, borderWidth, mobileSize, ...rest}:ContainerProps & SecureImgProps ) => <SecureImg {...rest}/> )<{ size: number|string; borderWidth: number, mobileSize?:number }>`
  max-width: 100%;
  object-fit: cover;
  border-radius: 50%;
  ${( { size, borderWidth } ) => {

    const value = typeof size === 'string'
      ? size
      : `${size}px`;
    return `
    width: calc( ${value} - ${borderWidth * 2}px );
    height: calc( ${value} - ${borderWidth * 2}px); `;

  }}
   @media ${( { theme } ) => theme.typing.mediaRules.mobile} {
     ${( { borderWidth, ...props } ) => {

    const size = props.mobileSize || props.size;
    const value = typeof size === 'string'
      ? size
      : `${size}px`;
    return `
       width: calc( ${value} - ${borderWidth * 2}px );
       height: calc( ${value} - ${borderWidth * 2}px );
     `;

  }}
  }
`;

export const Avatar = ( {
  imgSrc,
  children,
  alt,
  size = 40,
  borderWidth = 0,
  ...rest
}: ContainerProps & ImageProps & ComponentPropsWithoutRef<'span'> ) => (
  <Container borderWidth={borderWidth} size={size} {...rest}>
    <Img
      src={imgSrc}
      size={size}
      mobileSize={rest.mobileSize}
      borderWidth={borderWidth}
      placeholder={icon}
      alt={alt}
      mediaId={undefined}
    />
    {children}
  </Container>
);

export default Avatar;
