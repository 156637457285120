import { useQuery } from 'react-query';
import { fetchBasicCommunityInfo } from '~/shared/services/api/api';

export function useSimpleCommunityData(id: number) {

  return useQuery(
    ['basicCommunity',
      id],
    () => fetchBasicCommunityInfo(id),
    {
      enabled: id
    }
  );

}
