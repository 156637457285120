export * from './backgrounds'
export * from './graphs'
export * from './sponsors'
export {multiScreen} from './multiscreen'
export {ReactComponent as ChimuLogoSvg} from './chimu_logo_complete.svg'
export {ReactComponent as cooperationSvg } from './cooperation.svg'
export {ReactComponent as missionSvg } from './mission.svg'
export {ReactComponent as teamSvg } from './team.svg'
export {ReactComponent as MailSvg } from './mail.svg'
export {ReactComponent as playSvg } from './play.svg'

export {default as chimuTotem } from './chimu-illustration2.png'
// export {default as chimuVid } from './chimuvid.mp4'

export * from './slides'
