import { StageProps } from '~/join-community/JoinCommunity';
import { CommunityQuestionsValues } from '~/join-community/types';
import { Buttons } from '~/join-community/styled';
import { TextArea as _TextArea } from '~/shared/components';
import styled from 'styled-components';
import { useMutation, useQuery } from 'react-query';
import { StandardSpinner } from '~/shared/components/Spinner';
import React, { useEffect, useState } from 'react';
import { LoaderWrapper } from '~/app/components/Auth/styled';
import { BaseButton } from '~/shared/components/Button';
import { RefreshSvg } from '~/shared/images';
import { Header } from '~/app/components/recovery/styled';
import { useTranslation } from 'react-i18next';
import { getBioOptions, updateBioOptions } from '~/shared/services/api/api';
import { BioOptions } from '~/shared/services/api';

const TextArea = styled(_TextArea)`
  margin: 9px 0 0;
  padding: 0 0 16px 0;
  margin-bottom: 12px;
  min-height: 300px;
  font-size: 1.2rem;
  font-weight: 400;
  width: 100%;
  color: ${( { theme } ) => theme.colors.textDark4};
  background-color: unset;
  &:focus {
    outline: none;
  }
`;

const ButtonsArea = styled.div`
  display: flex;
  & button {
    padding: 10px;
    margin-bottom: 10px;
    font-size: 0.55rem;
    border: none;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    border-radius: 0;
    &:hover {
      background-color: #0056b3;
    }
    &:nth-child(1) {
      border-radius: 5px 0 0 5px;
      background: #14248A;
    }
    &:nth-child(2) {
      background: #3849B5
    }
    &:nth-child(3) {
      background: #4153C6;
    }
    &:nth-child(4) {
      background: #6E7FEA;
      border-radius: 0 5px 5px 0;
    }
    &:nth-child(5) {
      background: #6E7FEA;
      border-radius: 5px;
      margin-left: 10px;
      padding: 5px;
      svg {
        height: 20px;
        width: 25px;
      }
    }
  }
  &.language-he {
    & button {
      &:nth-child(1) {
        border-radius: 0 5px 5px 0;
      }
      &:nth-child(4) {
        border-radius: 5px 0 0 5px;
      }
      &:nth-child(5) {
        margin-left: unset;
        margin-right: 10px;
      }
    }
  }
`;

type Props = Pick<StageProps<CommunityQuestionsValues>, 'next' >&{
  userId: number | undefined,
  removeCookies?: boolean,
};

export const EditBio = (props: Props) => {
  const { next, userId, removeCookies} = props;
  const { t, i18n } = useTranslation();
  const {data: bios, isLoading, isFetching, refetch: refetchGpt} = useQuery(
    'openai-bio', getBioOptions.bind(null, userId as number),
    {retry: 3, cacheTime: Infinity, refetchOnWindowFocus: false});
  const [bio, setBio] = useState<string>()
  useEffect(() => bios && setBio(bios.professional), [bios])
  const [updateBio] = useMutation(
    () => updateBioOptions(userId as number, bio as string, removeCookies || false), {
      onSuccess: () => next(),
    })
  const onClickHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    const option = (e.target as HTMLElement).getAttribute('data-value') as keyof BioOptions
    setBio(bios![option])
  }
  return <div>
    <Header>
      {t('join.stageHeader.bio')}
    </Header>
    {(isLoading || isFetching)
      ? <LoaderWrapper><StandardSpinner/></LoaderWrapper>
      : <>
        <ButtonsArea className={`language-${i18n.language}`}>
          {bios && Object.keys(bios).map(key =>
            <BaseButton className="category-button" key={key} onClick={onClickHandler} data-value={key}>
              {t(`join.bio.${key}`)}
            </BaseButton>)}
          <BaseButton onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            refetchGpt()
          }}>
            <RefreshSvg/>
          </BaseButton>
        </ButtonsArea>
        <TextArea
          tabIndex={0}
          value={bio}
          name="bio"
          onChange={(e) => {
            e.preventDefault();
            e.stopPropagation()
            setBio(e.target.value)
          }}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}/>
        <Buttons
          next={() => updateBio()}
          prevActive={false}
          nextActive/>
      </>}
  </div>
}
