import React from 'react';
import styled from 'styled-components'

import { Column, SectionTitle, Button as _Button } from "./styled";
import { ContentColumn as _ContentColumn, SlideContent as _SlideContent, SlideTitle } from "./WhyChimu";

import { 
  collaboration,
  content,
  gamification,
  insights,
  multipleInnerCommunities,
  newsFeed,
  simplifiedOnboarding,
  dataCenter,
  eventCalendars,
 } from "../images/slides";
import {SlideShow} from '~/shared/components/SlideShow'


const Root = styled(Column)`
  position:relative;
  width:100%;
  gap:30px;
  align-items:center;
  background:${({theme})=>theme.colors.white};
  padding: 103px;
  @media ${ ({theme}) => theme.typing.mediaRules.untilSmall} {
    padding: 20px;
    padding-bottom: 100px;
    gap:20px;
  
  }
` 
const Headline = styled.h2`
  color:black;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-align:center;
  line-height: 1;
`
const SlideContent = styled(_SlideContent)`
  color:black;
`
const ContentColumn = styled(_ContentColumn)`
  width:100%;
  padding:0;
`
const Button = styled(_Button)`
  color:white;
  background: ${({theme})=>theme.colors.primary};
  width:fit-content;
`
const Img = styled.img`
  width:auto;
  max-width:min( 60% );
  height:auto;
  object-fit: contain;
  @media ${ ({theme}) => theme.typing.mediaRules.untilSmall} {
    max-width: 100%;
    max-height: 350px;
  }
`

const title = 'how we do it'

type SlideProps = {
  title:string;
  content:string;
  picture: any

}

const slides:SlideProps[] = [
  {
    title:'News feed',
    content:'This module allows members to quickly and easily see what’s going on in your community',
    picture:newsFeed,
  },
  {
    title:'Content',
    content:'Comprehensive content management and simple moderation',
    picture:content
  },
  {
    title:'Simplified Onboarding',
    content:'Quickly transfer your groups to a community environment',
    picture:simplifiedOnboarding
  },
  {
    title:'Gamification',
    content:'Badging, points-tracking, challenges, and polls keep your members engaged and sharing',
    picture:gamification
  },
  {
    title:'Data center',
    content:'Community’s resource gallery gives you the space to provide materials that your members need',
    picture:dataCenter
  },
  {
    title:'Insights',
    content:'Drive and measure engagement via a powerful administration dashboard',
    picture:insights
  },
  {
    title:'Event Calendars',
    content:'Share details with your audience about upcoming virtual and in-person events',
    picture:eventCalendars
  },
  {
    title:'Collaboration',
    content:'With rich posts, member profiles and direct messaging, create a community where real connections take place',
    picture:collaboration
  },
  {
    title:'Multi & Inner Communities',
    content:'Enable your members to engage with different communities and discuss under your brand',
    picture:multipleInnerCommunities
  },
]
function Slide(props:{slide:SlideProps}) {
  const {slide} = props
  return <>
    <ContentColumn>
      <SlideTitle>{slide.title}</SlideTitle>
      <SlideContent>{slide.content}</SlideContent>
    </ContentColumn>
    <Img src={slide.picture} alt={slide.title} />
  </>
}

export function HowWeDoIt() {
  return <Root id='howdo'>
    <SectionTitle> {title} </SectionTitle>
    <Headline>We build a <b>hybrid</b> workplace for organizations</Headline>
    <SlideShow slideCount={slides.length} >
      {slides.map(( slide, i )=><Slide key={slide.title+i} slide={slide} />)}
    </SlideShow>
  </Root>
}
