
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  InfoBox,
  ContentInfoBox,
  ContentTitle,
  ContentDescription,
} from './styles';

import {
  TextArea as _TextArea,
  TextInput as _TextInput,
} from '~/shared/components';
import { useRTL } from '~/shared/hooks';
import { PhoneAndCountryInput } from '~/shared/components/formControls/PhoneAndCountryInput';

const TextArea = styled( _TextArea )<( {numOfLines?:number} )>`
  /* white-space: pre; */
  margin: 9px 0 0;
  padding: 0 0 16px 0;
  margin-bottom: 12px;
  line-height:  18px;
  height:  ${( { numOfLines } ) => ( numOfLines
    ? `${( 21 * numOfLines )}`
    : '21' )}px;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  color: ${( { theme } ) => theme.colors.textDark4};
  background-color: unset;
  &:focus {
    outline: none;
  }
`;

const TextInput = styled( _TextInput )`
  line-height: 38px;
  padding: 0 0 8px 0;
  margin-bottom: 15px;
  border-width: 1px;
  input{
    font-size:16px;
  }
  color: ${( { theme } ) => theme.colors.textDark4};
`;

type FieldEditorProps = {
  isEdit: boolean;
  placeholder?: string;
  value?: string;
  setValue: ( value: string ) => void;
  fieldTitle: string;
  numOfLines?: number;
};

function FieldEditor ( {
  isEdit, placeholder, value = '', setValue, fieldTitle, numOfLines
}:FieldEditorProps ) {

  return <>
    <ContentTitle>{fieldTitle}</ContentTitle>
    {isEdit
      ? <TextArea
        numOfLines={numOfLines}
        placeholder={placeholder}
        value={value}
        onChange={( e ) => setValue( e.target.value )}
      />
      : <div
        style={{
          whiteSpace: 'pre-wrap',
          marginBlockEnd: 10
        }}
      >
        {value}
      </div>
    }
  </>;

}

type LocationEditorProps = {
  city: string;
  country: string;
  isEdit: boolean;
  TEXTAREA_PLACEHOLDER: string;
  setCity: ( e: any ) => void;
  setCountry: ( e: any ) => void;
};

function LocationEditor ( {
  city, country, isEdit, TEXTAREA_PLACEHOLDER, setCity, setCountry
}: LocationEditorProps ) {

  const { t } = useTranslation();
  const someDataExists = city || country;
  return <>
    { ( someDataExists || isEdit ) && (
      <ContentTitle>
        {t( 'Popups.Update profile.Home address.Title' )}
      </ContentTitle>
    )}
    {isEdit
      ? (
        <>
          <TextInput
            placeholder={`${t(
              'Popups.Update profile.Home address.City'
            )} - ${TEXTAREA_PLACEHOLDER}`}
            value={city || ''}
            onChange={( e ) => setCity( e.target.value )} />
          <PhoneAndCountryInput
            showDialCode={false}
            location={country}
            setLocationValue={( prefix, location ) => setCountry( location )} />
        </>
      )
      : someDataExists && (
        <ContentDescription>
          {[ country,
            city ].filter( ( x ) => !!x ).join( ', ' )}

        </ContentDescription>
      )}
  </>;

}

const GeneralInformation = ( {
  isEdit,
  name,
  setName,
  currentRole,
  setCurrentRole,
  about,
  setAbout,
  city,
  setCity,
  country,
  setCountry,
  TEXTAREA_PLACEHOLDER,
}: {
  isEdit: boolean;
  currentRole?: string;
  setCurrentRole: ( e: any ) => void;
  name: string;
  setName: ( e: any ) => void;
  about: string;
  setAbout: ( e: any ) => void;
  city: string;
  setCity: ( e: any ) => void;
  country: string;
  setCountry: ( e: any ) => void;
  TEXTAREA_PLACEHOLDER: string;
} ) => {

  const { t } = useTranslation();
  const rtl = useRTL();
  return (
    <InfoBox rtl={rtl}>
      <ContentInfoBox>
        {isEdit &&
          <FieldEditor // Name
            fieldTitle={t( 'Popups.Update profile.Name.Title' )}
            isEdit={isEdit}
            placeholder={`${t( 'Popups.Update profile.Name.Title' )} - ${TEXTAREA_PLACEHOLDER}`}
            value={name}
            setValue={setName}
          />
        }
        {isEdit &&
          <FieldEditor // Role
            fieldTitle={t( 'Popups.Update profile.Current role.Title' )}
            isEdit={isEdit}
            placeholder={`${t( 'Popups.Update profile.Current role.Title' )} - ${TEXTAREA_PLACEHOLDER}`}
            value={currentRole}
            setValue={setCurrentRole}
          />
        }
        <FieldEditor // About
          fieldTitle={t( 'Popups.Update profile.About me.Title' )}
          numOfLines={5}
          isEdit={isEdit}
          placeholder={`${t( 'Popups.Update profile.About me.Title' )} - ${TEXTAREA_PLACEHOLDER}`}
          value={about}
          setValue={setAbout}
        />
        {/* <button title={JSON.stringify({city,country},null,2)}/> */}
        <LocationEditor
          city={city}
          country={country}
          isEdit={isEdit}
          TEXTAREA_PLACEHOLDER={TEXTAREA_PLACEHOLDER}
          setCity={setCity}
          setCountry={setCountry}
        />

      </ContentInfoBox>
    </InfoBox>
  );

};

export default GeneralInformation;
