import { useCallback, useState } from 'react';

import { MediaResponse } from '~/shared/services/api';
import { useMasterData } from '~/shared/hooks/useMasterData';
import { uploadMedia } from '../services/api/api';

export type FileData = Array<{ file: File; type: string }>;

export type FileUploading = {
  upload: ( data: FileData, props?:{
    location?: string;
    onItemSent?( alreadyFinished:number ):void;
  } ) => Promise<Array<MediaResponse>>;
  isUploading: boolean;
};

const useFilesUploading = ( itemType?: string, onProgress?:( progressEvent:{loaded:number, total:number } )=>void ): FileUploading => {

  const { media_types, bucket_locations } = useMasterData();
  const location = itemType
    ? bucket_locations?.find( ( item ) => item.includes( itemType ) )
    : undefined;
  const [ isUploading, setUploading ] = useState( false );
  const upload = useCallback(
    async ( fileData: FileData, { onItemSent }:{location?: string, onItemSent?( alreadyFinished:number ):void} = {} ) => {

      if ( !media_types ) {

        throw new Error( 'master_data query must be called before attempting this' );

      }
      try {

        setUploading( true );
        const result = [];
        for ( const { file, type } of fileData ) {

          let fileType = 3;
          if ( type.toLowerCase().startsWith( 'image' ) ) {

            fileType = media_types.Photo;

          }
          if ( type.toLowerCase().startsWith( 'video' ) ) {

            fileType = media_types.Video;

          }
          if ( type.toLowerCase().startsWith( 'audio' ) ) {

            fileType = media_types.Audio;

          }
          if ( media_types && fileType === -1 ) {

            throw new Error( `File ${file.name} has unsupported file type ${type ?? ''}` );

          }
          const formData = new FormData();
          formData.append(
            'payload',
            file as Blob
          );
          formData.append(
            'type',
            String( fileType )

          );
          formData.append(
            'location',
            location || bucket_locations?.find( ( item ) => item.includes( location as string ) ) || 'posts/'
          );
          const response = await uploadMedia(
            formData,
            onProgress
          );
          result.push( response );
          onItemSent?.( result.length );

        }
        return result;

      } catch ( error ) {

        throw new Error( error as any );

      } finally {

        setUploading( false );

      }

    },
    [
      media_types,
      bucket_locations,
      setUploading,
      location
    ]
  );

  return { upload,
    isUploading };

};

export default useFilesUploading;
