import React, { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

import { BaseButton, feedActionStyles } from '~/shared/components/styled';
import { useRTL } from '../hooks';
import { withHebrew } from '../utils/language';

const Button = styled(BaseButton)<({rtl?:boolean})>`
  padding: 5px 5px 5px 30px;
  position: relative;
  ${feedActionStyles}
  ${({rtl})=>withHebrew(rtl)}
  svg{
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }
  @media ${ ({theme}) => theme.typing.mediaRules.mobile} {
    font-weight:bold;
  }
`;

const ActionButton = ({
  label,
  Icon,
  isActive,
  className,
  ...rest
}: {
  label: string;
  Icon: React.FunctionComponent;
  isActive?: boolean;
} & ComponentPropsWithoutRef<'button'>) => {
  const rtl = useRTL()
  return (
    <Button rtl ={rtl} className={`${className} ${isActive ? 'active' : ''}`} {...rest}>
      <Icon />
      {label}
    </Button>
  );
};

export default ActionButton;
