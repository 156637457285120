import styled from 'styled-components';
import { Button } from '~/shared/components/styled';

export const ButtonsWrapper = styled.div`
  font-family:inherit;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px;
  width:100%;
`;
export const ConfirmButton = styled( Button )`
  /* font-family:inherit; */
  background-color: ${( { theme } ) => theme.colors.textMedium2};
  color: ${( { theme } ) => theme.colors.white};
  padding: 10px 25px;
  border-radius: 20px;
  &:not(:disabled){
    background-color: ${( { theme } ) => theme.colors.primary};
  }
`;
export const CancelButton = styled( Button )`
  color: ${( { theme } ) => theme.colors.textMedium2};
  /* @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    display:none;
  } */
`;
