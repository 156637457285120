import {
  createContext, useContext, useEffect, useState
} from 'react';

/*
 * const isReady = () => typeof window !== 'undefined' &&
 *   typeof ( window as any ).grecaptcha !== 'undefined' &&
 *   typeof ( window as any ).grecaptcha.execute !== 'undefined';
 */

type Unready= {
  executeRecaptcha: undefined;
  ready: false;
}
type Ready={
  executeRecaptcha( action:string ):Promise<string>;
  ready: true;
}
type Recaptcha = Ready | Unready
const RecaptchaContext = createContext<Recaptcha|undefined>( undefined );


declare global {
  interface Window {
    grecaptcha?: {
      enterprise:{
        execute( siteKey:string, payload:{action:string} ):Promise<string>;
        getResponse():unknown;
        ready():unknown;
        render():unknown;
        reset():unknown;

      }
    }
  }
}

export const RecaptchaProvider = (
  { children, siteKey }:{children:React.ReactNode, siteKey:string}
) => {

  const [ ready, setReady ] = useState( false );

  useEffect(
    () => {

      const script = document.createElement( 'script' );
      script.src = `https://www.recaptcha.net/recaptcha/enterprise.js?render=${siteKey}`;
      script.id = 'grecaptcha';
      script.onload = () => {

        setReady( true );

      };
      document.body.appendChild( script );

    },
    []
  );
  if ( !ready ) {

    return <RecaptchaContext.Provider value={{ ready,
      executeRecaptcha: undefined }}>
      {children}
    </RecaptchaContext.Provider>;

  }
  const executeRecaptcha = async ( action:string ) => {

    if ( !ready ) {

      throw new Error( 'executeRecaptcha was used before the captcha was loaded' );

    }
    const token :string = await ( window.grecaptcha as NonNullable<Window['grecaptcha']> ).enterprise.execute(
      siteKey,
      { action }
    );
    return token;


  };
  return <RecaptchaContext.Provider value={{ ready,
    executeRecaptcha }}>
    {children}
  </RecaptchaContext.Provider>;

};
export const useRecaptcha = (): Recaptcha => {

  const context = useContext( RecaptchaContext );
  if ( context === undefined ) {

    throw new Error( 'useRecaptcha must be used within a RecaptchaProvider' );

  }
  return context;

};
