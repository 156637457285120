import React, { useState, useEffect, ComponentPropsWithoutRef, ReactNodeArray  } from 'react';
import styled from 'styled-components';


const SlideShowContainer = styled.section`
  position:relative;
  display: flex;
  flex-direction: row;
  height: 650px;
  padding: 0 200px ;
  margin-top:20px;
  width:100%;
  height: 520px;
  @media ${ ({theme}) => theme.typing.mediaRules.untilBig} {
    height: 420px;
    padding: 20px 100px ;
  }
  @media ${ ({theme}) => theme.typing.mediaRules.untilMedium} {
    height: 450px;
    padding: 0 60px ;
  }
  @media ${ ({theme}) => theme.typing.mediaRules.untilSmall} {
    padding: 0 20px;
  }
`
const ArrowButton = styled.button`
  background:none;
  height:100%;
  width:50px;
  display:flex;
  justify-content:center;
  align-items:center;
`
const Arrow = styled.span`
  background:none;
  height:34px;
  width:34px;
  transform: rotate(45deg);
  border-radius: 1px;
  border: solid 3px ${({theme})=>theme.colors.primary};
  border-right:none;
  border-top:none;
  &.right{
    transform: rotate(-135deg);
  }
`

const SlideContainer = styled.div`
  position:relative;
  display: flex;
  flex-direction: row;
  justify-content:center;
  align-items:center;
  width:calc( 100% - 100px);
  height:100%;
  max-height:calc( 100% - 20px);
`
const Slide = styled.article`
  position:absolute;
  opacity:0;
  display:flex;
  align-items:center;
  justify-content:center;
  transition: .6s ease;
  width:calc(100% - 100px);
  max-height:100%;
  left: 50%;
  &.active{
    opacity:1;
  }
  transform: translateX(-50%);
  @media ${ ({theme}) => theme.typing.mediaRules.untilMedium} {
    flex-direction:column
  }
  @media ${ ({theme}) => theme.typing.mediaRules.untilSmall} {
    width:100%;
  }
`
const DotContainer = styled.div`
  position:absolute;
  bottom: -30px;
  left:50%;
  transform:translateX(-50%);
  display:flex;
  justify-content:center;
  gap:20px;
  @media ${ ({theme}) => theme.typing.mediaRules.untilSmall} {
    bottom: -60px;
  }
`
const Dot = styled.button`
  width: 16px;
  height: 16px;
  border-radius:50%;
  background-color: ${({theme})=>theme.colors.white};
  transition:.3s;
  box-shadow: 0 2px 10px 0 rgba(22, 31, 56, 0.26);
  &.active{
    background-color: ${({theme})=>theme.colors.primary};
    cursor:pointer;
  }
`
type Props = ComponentPropsWithoutRef<'section'> & {
  children:ReactNodeArray;
  slideCount:number;
  timer?:number;
} 

const BackButton = (props:ComponentPropsWithoutRef<'button'>) => <ArrowButton {...props}><Arrow/></ArrowButton>
const NextButton = (props:ComponentPropsWithoutRef<'button'>) => <ArrowButton {...props}><Arrow className={'right'}/></ArrowButton>


export function SlideShow({children,slideCount,timer=6000}:Props){
  
  const [slideNumber,setSlideNumber] = useState<number>(0)
  const [count,setCount] = useState<boolean>(true)
  
  function nextSlide(){
    setSlideNumber(num => num < slideCount-1 ? num+1 : 0)
  } 
  function prevSlide(){
    setSlideNumber(num => num > 0 ? num-1 : slideCount-1)
  }
  useEffect(function(){
    if(count){
      const slideTimer = setInterval( nextSlide, timer )
      return function(){ clearInterval(slideTimer)}
    }
  },[count])
  return (
    <SlideShowContainer
      onMouseEnter={()=>setCount(false)}
      onMouseLeave={()=>setCount(true)}
    >
      <BackButton onClick={prevSlide}/>
        <SlideContainer>
          {children.map( ( child, i )=> <Slide key={'slide'+i} className={slideNumber === i? 'active':''}> {child} </Slide>)}
        </SlideContainer>
      <NextButton onClick={nextSlide}/>
      <DotContainer>
        {Array(slideCount).fill(1).map((_,i)=>(
          <Dot 
            key={'dot'+i}
            className={i==slideNumber ? 'active':''}
            onClick={()=>setSlideNumber(i)}
          />)
        )}
      </DotContainer>
    </SlideShowContainer>
  ) 
}