import styled from 'styled-components';

import { ReactComponent as BlockedSvg } from '../images/blocked.svg';

export const Root = styled.div`
  text-align: center;
  padding: 15%;
`;
export const Header = styled.h3`
  font-size: 24px;
  font-weight: 800;
  text-align: center;
`;
export const Message = styled.p`
font-size: 16px;
font-weight: 500;
text-align: center;
color: rgb(143, 161, 188);
white-space: pre-wrap;
`;

export const BlockedIcon = styled( BlockedSvg )`
  ellipse{
    fill: ${( { theme } ) => theme.colors.secondary};
    stroke: ${( { theme } ) => theme.colors.secondary};
  }
`;
