import React, { useState, useEffect, ComponentPropsWithoutRef,  } from 'react';
import styled from 'styled-components';

import { 
  Section as _Section, 
  Column as _Column, 
  SectionTitle, 
} from "./styled";
import { barGraphSvg,whyUsBackground, LineGraph } from '../images'

import {SlideShow} from '~/shared/components/SlideShow'

const Column = styled(_Column)`
  background-color: var(--backgroundDark);
  position:relative;
  width:100%;
  padding: 92px 0;
  padding-top: 107px;
  background-image: url(${whyUsBackground});
`
const Headline = styled.span`
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.72;
  letter-spacing: normal;
  width: fit-content;
  margin:14px auto;
  text-align:center;
  @media ${ ({theme}) => theme.typing.mediaRules.untilSmall} {
    line-height: 1;

  }
`
export const ContentColumn = styled(_Column)`
  margin: auto 0;
  padding: 0;
  padding-inline-start: 20px;
  padding-inline-end: 10px; 
`
export const SlideTitle = styled.h5`
  margin:0;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  color:${({theme})=>theme.colors.primary};
`
export const SlideContent = styled.p`
  margin: none;
  color:white;
  max-width:500px;
  
  line-height: 26px;
  font-weight:500;
  font-size:${({theme})=>theme.typing.fontSizes.large};
`
const BarGraphIcon = styled(barGraphSvg).attrs( ()=>({viewBox:"0 0 697 453"}) )`
  max-height: 100%;
  max-width: 360px;
  `
const LineGraphIcon = styled.img.attrs( ()=>({src: LineGraph}) )`
  object-fit:fill;
  height: 254px;
  width: 370px;
`


type SlideProps = {
  title:string;
  content:string;
  picture: any

}

const slides:SlideProps[] = [
  {
    title:'Great minds don’t think alike.',
    content:'Great minds grow when people from diverse backgrounds and experiences share their knowledge and ideas. We create communities that  firmly believe in lifelong learning.',
    picture: LineGraphIcon
  },
  {
    title:'Collaborate with Chimu',
    content:'Our goal is to help you learn from each other, to define best practice and become opinion leaders in your community field. Most of all, we want to share our knowledge and creativity to have great ideas together.',
    picture: BarGraphIcon
  },
]

function Slide(props:{slide:SlideProps}) {
  const {slide} = props
  return <>
    <slide.picture />
    <ContentColumn>
      <SlideTitle>{slide.title}</SlideTitle>
      <SlideContent>{slide.content}</SlideContent>
    </ContentColumn>
  </>
}

export function WhyChimu() {
  return <Column id='whyus' >
    <SectionTitle >why chimu</SectionTitle>
    <Headline>
      We build <b>TRUST</b> in a zero trust world
    </Headline>
    <SlideShow slideCount={slides.length} >
      {slides.map(( slide, i )=><Slide key={slide.title+i} slide={slide} />)}
    </SlideShow>    
  </Column>
}
