import { ChatUser } from './types';

import { Hub } from '~/shared/services/api';
import { LinkProps } from 'react-router-dom';

export const isUser = ( item?: ChatUser | Hub ): item is ChatUser => !!item && 'network_id' in item;
export const isHub = ( item?: ChatUser | Hub ): item is Hub => !!item && 'chat_group' in item;

export type ChatIdentifier = ['hub' | 'user', number] | [undefined, undefined];

export function linkToChat ( data?:{
  isHub: boolean;
  id: number;
  withMessage?: string;
} ):LinkProps {

  return { to: { pathname: '/chat',
    state: { currentChat: data
      ? [ data.isHub
        ? 'hub'
        : 'user',
      data.id ]
      : undefined,
    withMessage: data?.withMessage } } };

}

export function getChatId ( chat: ChatUser | Hub ):number {

  const isChatUser = isUser( chat );

  return isChatUser
    ? chat.network_id

    /** using chat group id becuase that what you get in resposne when a chat group is created */
    : chat.chat_group.id;

}


export function getChatIdentifier ( chat: ChatUser | Hub ):ChatIdentifier {

  const isChatUser = isUser( chat );
  const chatId = getChatId( chat );
  const chatType: ChatIdentifier[0] = isChatUser
    ? 'user'
    : 'hub';
  return [ chatType,
    chatId ];

}
