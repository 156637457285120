import { useQuery } from 'react-query';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import {
  ListInfoBox, ContentInfoBox, ContentTitle
} from './styles';

import {
  api, Interest, Expertise, CommunityMasterData
} from '~/shared/services/api';
import { useCurrentCommunity, useRTL } from '~/shared/hooks';
import { fetchCommunityMasterData } from '~/shared/services/api/api';

type OptionType = {
  value: number;
  label: string;
};

const ClearIndicator = ( props: any ) => {

  const { t } = useTranslation();

  const {
    children = <div>{t( 'Popups.Placeholders.Clear all' )}</div>,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref} style={getStyles(
      'clearIndicator',
      props
    )}>
      <div style={{ padding: '0px 5px' }}>{children}</div>
    </div>
  );

};

const ClearIndicatorStyles = ( base: any, state: any ) => ( {
  ...base,
  cursor: 'pointer',
  color: state.isFocused
    ? '#0a2138'
    : '#a1a1a1',
} );


function toOptions ( list?: ( Interest|Expertise )[] ) : OptionType[] {

  return list?.map( ( item ) => ( {
    value: item.id,
    label: item.name
  } ) ) || [];

}

const List = ( {
  listType,
  header,
  items,
  onChange,
}: {
  listType: string;
  header: string;
  items: number[];
  onChange: ( list:( Interest|Expertise )[] ) => void;
} ) => {

  const { t } = useTranslation();
  const { currentCommunity } = useCurrentCommunity();
  const id = currentCommunity?.id;

  const { data } = useQuery<CommunityMasterData>(
    `community ${id}`,
    async (): Promise<CommunityMasterData> => fetchCommunityMasterData(
      id || 0
    ),
    {
      enabled: currentCommunity
    }
  );

  const rtl = useRTL();

  const fetchedOptions = ( listType === 'Expertise'
    ? data?.expertises
    : data?.interests ) || [];

  const options = toOptions(
    fetchedOptions
  );


  return (
    <ListInfoBox rtl={rtl}>
      <ContentInfoBox>
        <ContentTitle>{header}</ContentTitle>
        <Select
          isClearable={false}
          closeMenuOnSelect={false}
          components={{ ClearIndicator }}
          styles={{ clearIndicator: ClearIndicatorStyles }}
          isMulti={true}
          options={options}
          theme={( theme ) => ( {
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: '#f5f5f5',
              primary: '#e7eaf0',
            },
          } )}
          placeholder={t( 'Actions.select' )}
          hideSelectedOptions={true}
          value={options.filter( ( option ) => items.includes( option.value ) )}
          onChange={( selection, meta ) => {

            // selection:{label: string,value: number}[]
            if ( meta.action === 'clear' ) {

              return onChange( [] );

            }

            /*
             * if(a.action === 'remove-value'){
             *   console.log(selection);
             */

            /*
             *   return onChange('tags','')
             * }
             */
            const selectionIds = selection.map( ( item ) => item.value );
            const newSelection = fetchedOptions.filter( ( option ) => selectionIds.includes( option.id ) );
            onChange( newSelection );

            /*
             * if(a.action == 'select-option'){
             * return onChange('tags','')
             * }
             */

          }}
          isOptionSelected={( option ) => items.includes( option.value ) }
          maxMenuHeight={100}
        />
      </ContentInfoBox>
    </ListInfoBox>
  );

};

export default List;
