import { api } from '~/shared/services/api';
import { downloadFile } from '~/shared/utils';
import useFeedMutation from './useFeedMutation';

type Props = {
  url: string;
  mediaId: number;
  isModal: boolean ;
  name?: string;
  noApiCall?:boolean;
};

const useDownloading = ( {
  url,
  mediaId,
  isModal,
  name,
  noApiCall = false
}:Props ) => {

  const [ download, { isLoading } ] = useFeedMutation( {
    fn: async ( ) => {

      if ( !noApiCall ) {

        await api.downloadMedia( mediaId ).catch( console.error );

      }
      if ( url ) {

        downloadFile(
          url,
          name || 'something'
        );

      }

    },
    optimisticOptions: {
      feedItemId: mediaId,
      feedItemType: 'media',
    },
    isModal,
  } );

  /*
   * useEffect(() => {
   *   if (source) {
   *     downloadFile(source, name||'something.txt');
   *   }
   *   // if (data && keys) {
   *   //   transformAndFetch(url, keys!.key, keys!.secret).then(link=>{
   *   //     if(link){
   *   //     }
   */

  /*
   *   //   })
   *   // }
   * }, [source]);
   */

  return {
    download,
    isLoading,
  };

};

export default useDownloading;
