import React, { ComponentPropsWithRef } from 'react';
import { ReactComponent as PlusSvg } from '~/shared/images/plus-circle.svg';
import styled, { StyledComponent, DefaultTheme } from 'styled-components';
import theme from '../theme';
import { Button } from '~/shared/components/styled';

export const JoinButton = styled( Button )`
  border: none;
  outline: none;
  background-color: ${theme.colors.primary};
  color: white;
  font-size: ${theme.typing.fontSizes.smaller};
  font-weight: ${theme.typing.fontWeights.bold};
  margin-top: 14px;
  padding: 7px 44px;
  border-radius: 10px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  :disabled {
    cursor: auto;
  }
`;

export const PlusIcon = styled( PlusSvg ).attrs( () => ( { width: 19,
  height: 19, } ) )`
  margin: 0 8px 0 8px;
  object-fit: contain;
  background-color: ${( { color } ) => color};
  min-width: 19px;
`;

type IconType = typeof PlusIcon | StyledComponent<React.FunctionComponent<React.SVGProps<SVGSVGElement>>, DefaultTheme, {}, never>

const JoinCommunityButton = ( {
  title,
  Icon,
  ...rest
}: ComponentPropsWithRef<'button'> & {title: string, Icon?: IconType} ) => (
  <JoinButton {...rest}>
    { Icon && <Icon/>}
    {title}
  </JoinButton>
);

export default JoinCommunityButton;
