import { CSSProperties, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { AnswerType } from './Fields';
import { TriviaQuestion } from './TriviaQuestion';
import {
  joinCommunityQuestion, QuestionForOnboarding,
} from '~/shared/services/api';
import { ContainedSpinner } from '~/shared/components/Spinner';
import { sendSingleAnswer, getFirstQuestion} from '~/shared/services/api/api';
import { IQaMap } from '~/join-community/JoinCommunity';

function initialAnswerMapper ( question:Pick<joinCommunityQuestion, 'type'> ):AnswerType {
  switch ( question.type.type_name ) {
    case 'predefined_one_selection':
      return undefined;
      break;
    case 'open':
      return '';
      break;
    default:
      return [] as number[];
      break;
  }
}
export type IOnboardingAnswers = {
  [question_id: number]: AnswerType;
}

type AnswerOnboardingProps = {
  communityId: number;
  saveAnswers: ( answers: IOnboardingAnswers ) => void;
  onFinish(): void;
  adminId: number;
  style?:CSSProperties;
};
export function AnswerOnboardingTrivia ( {
  saveAnswers, communityId, adminId, onFinish, style
}: AnswerOnboardingProps ) {
  const [ page, setPage ] = useState( 0 );
  const [ isLastPage, setIsLastPage ] = useState( false );
  const qaMap = useRef(new Map() as IQaMap)
  const [ saveAnswerAndGetNext, { isLoading, data: currentQuestion } ] = useMutation(
    async ( { questionId, answer }: { questionId: number; answer?: AnswerType; } ) => {
      if (questionId === 0) {
        const firstQuestion = await getFirstQuestion(adminId, communityId);
        qaMap.current.set(page, {
          question: firstQuestion,
          answer: initialAnswerMapper(firstQuestion)
        });
        return firstQuestion
      }
      const { next } = await sendSingleAnswer(adminId, questionId, answer);
      if (next) {
        const newPage = page + 1;
        setPage(newPage);
        qaMap.current.set(newPage, {
          question: next,
          answer: initialAnswerMapper(next)
        });
        return next;
      } else {
        setIsLastPage(true );
        const answers: (Parameters<typeof saveAnswers>)[0] = {};
        qaMap.current.forEach(( {question, answer }) => {
          answers[question.id] = answer;
        });
        saveAnswers(answers);
        onFinish()
        return qaMap.current.get(page)?.question
      }
    }
  );
  useEffect(() => {
    saveAnswerAndGetNext({questionId: 0})
  }, [])
  const prevQuestion = () => {
    if (isLastPage)
      return setIsLastPage(false);
    setPage( page - 1 );
  }
  const nextQuestion = async ( answer: AnswerType ) => {
    qaMap.current.set(page, {question: currentQuestion!, answer});
    await saveAnswerAndGetNext( {questionId: currentQuestion?.id as number, answer})
  };
  const currentAnswer = qaMap.current.get(page)?.answer as AnswerType;
  if (!currentQuestion)
    return <ContainedSpinner />;
  return <TriviaQuestion
    isLoadingNext={isLoading}
    /*
     * Disabled due to a BE bug
     * disableBackButton={page === 0}
     */
    disableBackButton
    // @ts-ignore
    question={currentQuestion as QuestionForOnboarding}
    selectedAnswer={currentAnswer}
    nextIsFinish={isLastPage}
    onClickBack={prevQuestion}
    onClickNext={nextQuestion}
    questionNumber={page}
    style={style}
  />;
}
