import styled from 'styled-components';
import Select from 'react-select';
import { Buttons } from './styled';
import { StageProps } from './JoinCommunity';
import { CommunityValues } from './types';
import { StandardSpinner } from '~/shared/components/Spinner';
import { SecureImg } from '~/shared/components';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { logo } from '~/configurables/logo';
import colors from '~/shared/theme/colors';
import { logOut } from '~/shared/services/api/api';
import { LoaderWrapper } from '~/app/components/Auth/styled';

const Option = styled.span`
  display:flex;
  flex-direction:row;
  align-items:center;
  gap:12px;
`;
const Logo = styled( SecureImg )`
  width:25px;
`;

const Header = styled.h4`
  font-weight: 700;
  font-size: 14px;
  line-height: 47px;
  color: ${( { theme } ) => theme.colors.textDark3};
`;
export const SelectWrapper = styled.div`
  padding-top:20px;
  max-width:400px;
  input {
    caret-color: transparent;
  }
`;

type CommunityOptions = {value:number, label:string, src:string}

export const SelectCommunity = ( {
  values, setFieldValue, next, prev,
}:Pick<StageProps<CommunityValues>, 'values'| 'setFieldValue'| 'next'| 'prev'> ) => {
  const location = useLocation<{community?:{image:string, name:string}}>();
  const queryParams = new URLSearchParams(location.search);
  const {community, communities} = values;
  const { t, i18n } = useTranslation();
  // Const data = [ph,p1,p2]
  function handleNext ( communityId:number ) {
    const selectedCommunity = communities?.find(
      (comm ) => comm.id === communityId);
    if (selectedCommunity) {
      i18n.changeLanguage(selectedCommunity.preferred_locale);
      location.state = {
        ...location.state,
        community: { name: selectedCommunity.community_name, image: selectedCommunity.logo}
      };
    }
    next();
  }
  useEffect(() => {
    if (community !== undefined || !communities)
      return;
    const preselectedCommunityId = Number(queryParams.get('community_id'));
    // if community not already set, is pre-selected and exists in list; will set it and skip this stage
    if (preselectedCommunityId) {
      const preselectedCommunity = preselectedCommunityId && communities?.find(
        (comm ) => comm.id === preselectedCommunityId);
      if (preselectedCommunity) {
        setFieldValue('community', preselectedCommunityId);
        return handleNext( preselectedCommunityId );
      }
    }
    // if only one community available; will set it and skip this stage
    if (communities?.length === 1) {
      const [ { id: onlyCommunityId } ] = communities;
      setFieldValue('community', onlyCommunityId);
      return handleNext( onlyCommunityId );
    }
  }, [communities]);
  const suggestedCommunities = communities
  ?.filter(comm => !comm.already_member)
  .map(({ community_name, id, logo, about, }) =>
    ({label: community_name, value: id, src: logo, about}));

  if (!suggestedCommunities)
    return <LoaderWrapper><StandardSpinner/></LoaderWrapper>;
  if (suggestedCommunities?.length === 0) {
    return <div>
      <img src={logo} height={100} width="auto"/>
      <h3> Seems you've already got them all...</h3>
      No more communities are available for you to join. <br/>
      <Link to="/auth" style={{color: colors.primary}}
      >Go back home</Link>
    </div>;
  }
  const value = suggestedCommunities?.find( ( opt ) => opt.value === values.community );
  return <>
    <SelectWrapper>
      <Select<CommunityOptions>
        options={ suggestedCommunities}
        autoFocus
        isMulti={false}
        tabSelectsValue={false}
        value={value}
        placeholder={`${t('join.selectCommunity')}...`}
        defaultValue={suggestedCommunities?.find( ( opt ) => opt.value === values.community )}
        styles={{placeholder: () => ({color: 'black', fontWeight: 'bold'})}}
        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        onChange={(option) => setFieldValue( 'community', option?.value, false)}
        formatOptionLabel={( option ) =>
          <Option>
            <Logo src={option.src} mediaId={undefined}/>
            {option.label}
          </Option>}
      />
    </SelectWrapper>
    {value?.about && <>
      <Header>
      About the community
      </Header>
      <p>
        {value.about}
      </p>
    </>}
    <Buttons
      next={() => handleNext( values.community as number )}
      prevActive={true}
      nextActive={ ( values.community !== undefined ) }
      prev={() => {
        if ( location?.state?.community )
          location.state.community = undefined;
        prev();
        logOut();
      }}
    />
  </>;

};
