
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { Success } from './images';
import { useTranslation } from 'react-i18next';

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SuccessIcon = styled.div`
  width: 100px;
  height: 100px;
  animation: pulsate 1.2s ease-out ;
  margin-block: 1rem;
  background-size:contain;
  background-image:url(${Success});
  @media (prefers-reduced-motion) {
    animation-name: dissolve;
  }
  @keyframes pulsate {
    0% {
      opacity:0;
      transform: scale(.2) rotateY(-180deg);
    }
    55% {
      transform: scale(1.2) rotateY(0deg);
    }
    65% {
      transform: scale(1.2) rotatez(-30deg);
    }
    75% {
      transform: scale(1.2) rotatez(30deg);
    }
    85% {
      transform: scale(1.2) rotatez(0deg);
    }
    100% {
      transform: scale(1) rotateY(0deg);
    }
  }
  @keyframes dissolve {
    from {
      opacity:0;
    }
  }
`;
const Title = styled.h3`
  font-size: 24px;
  font-weight: 800;
  text-align: center;
`;
const Content = styled.p`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color:${( { theme } ) => theme.colors.textMedium};
`;
const Button = styled( Link )`
  padding: 11px 27px;
  border-radius: 20px;
  background-color: ${( { theme } ) => theme.colors.primary};
  color: ${( { theme } ) => theme.colors.white};
  font-size: 14px;
  font-weight: 800;
  text-decoration: none;
  margin-top: 20px;
  :hover, :focus {
    background-color: #269FB8;
    outline: 1px solid blue;
  } 
`;
type Props = {
  onGoToCommunity?(): void;
  hideButton?:boolean;
};

export const SuccessPage = ( { onGoToCommunity, hideButton = false }:Props ) => {
  const { t } = useTranslation();
  const { state } = useLocation<{invitedByManager?:boolean}>();
  const invitedByManager = state?.invitedByManager;
  return <Root>
    <Title>
      {t( 'join.congrats' )} {!invitedByManager
        ? t( 'join.uninvited' )
        : t( 'join.invited' )}
    </Title>
    <SuccessIcon />
    {/* {!invitedByManager &&
      <Content>
        {t( 'join.sentByEmail' )}
      </Content>
    } */}
    {!hideButton && ( onGoToCommunity
      ? <Button
        as="button"
        type="button"
        onClick={onGoToCommunity}
      >
        {invitedByManager
          ? t( 'join.toCommunity' )
          : t( 'join.toLanding' )
        }
      </Button>
      : <Button
        to={invitedByManager
          ? '/feed'
          : '/auth'
        }
      >
        {invitedByManager
          ? t( 'join.toCommunity' )
          : t( 'join.toLanding' )
        }
      </Button> )
    }
  </Root>;

};
