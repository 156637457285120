import React,{useState} from 'react' 
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {CheckBox} from './index'
import { BaseButton } from './styled';

import { Button as _Button } from '~/shared/components/styled';

const OptionsButton = styled(BaseButton)<({open:boolean})>`
  width: 10px;
  height: 10px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.textDark};
  border-right: 2px solid ${({ theme }) => theme.colors.textDark};
  transform: rotate(${({open})=>open?45:-135}deg);
  /* margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px; */
  /* vertical-align:${({open})=>open?'middle':''}; */
  margin:10px;
  vertical-align:middle;
  transition: .2s;
`;
const Selection = styled.section<({open:boolean})>`
>*{
  border-bottom: solid 1px rgba(112, 132, 154, 0.3);
  padding: 17px 0 ;
  font-family:inherit;
}
font-family:inherit;
height:fit-content;
max-height:${({open})=>open?'500vw':0};
overflow:hidden;
transition:.2s;

`
const Header = styled.header<({open:boolean})>`
  font-family:inherit;
  padding-top: 10px ;
  display:flex;
  /* padding-bottom: 15px ; */
  justify-content:space-between;
  min-width:fit-content;
  width:${({open})=>open?'100%':'0'};
  transition:.2s;
`;

const Heading = styled.h3<({active?:boolean})>`
&::first-letter{
  text-transform: uppercase;
}

font-family:inherit;
/* font-weight:bold; */
margin:0;
color: ${({ theme,active }) => active?theme.colors.primary:theme.colors.textDark};
/* ${({ theme }) => theme.typing.fontSizes.normal}; */
font-size:19px;
`;

const Buttons = styled.span<({open:boolean})>`
font-weight:200;
font-size:15px;
border:none;
display:flex;
overflow:hidden;
transition:.1s;
width:${({open})=>open?'55%':'0'};
justify-content:flex-end;
/* width:100%; */
padding:2px 0;
button{
  align-self:bottom;
  margin:0 3px;
}
`
const Button = styled(_Button).attrs({ hollow: true })`
  font-size: ${({ theme }) => theme.typing.fontSizes.smaller};
  padding: 11px 0px;
  padding:0;
  font-weight:100;
  [disabled]{
color: ${({ theme }) => theme.colors.textDark};
  color: ${({ theme }) => theme.colors.textLight2};
  }
`;

interface IProps{
  clear?:()=>void;
  selectAll?:()=>void;
  title:string;
  active:boolean;
}
function CheckBoxGroup({ title, active, clear,selectAll,children}:IProps&React.ComponentPropsWithoutRef<'section'>) {
  const [isOpen,setOpen] = useState(false)
  const { t } = useTranslation()

  return <section style={{fontFamily:'inherit'}}>
    <Header open={isOpen} >
      <Heading onClick={()=>{setOpen(!isOpen)}} active={active}>
        {title}
      </Heading>
      <Buttons open={isOpen} onClick={e=>{e.stopPropagation()}}>
          {selectAll&&<Button onClick={selectAll}>
            {t(`Community.Filter.Select All`)}
          </Button>}
          {clear&&<Button onClick={clear}>
            {t(`Community.Filter.Clear`)}
          </Button>}
      </Buttons> 
      <OptionsButton open={isOpen} onClick={()=>{setOpen(!isOpen)}} />
    </Header>
    {/* <h3 style={{fontFamily:'inherit'}} onClick={()=>{setOpen(!isOpen)}}>
      {title}
      <OptionsButton open={isOpen} />
    </h3> */}
    <Selection open={isOpen}>
      {children}
    </Selection>
  </section>
} 
export default CheckBoxGroup; 