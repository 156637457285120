export { ReactComponent as FacebookSvg } from './facebook-icon.svg';
export { ReactComponent as LinkedinSvg } from './linkedin-icon.svg';
export { ReactComponent as MailSvg } from './mail-icon.svg';
export { default as bg } from './powerup-bg.svg';
export { default as globe } from './smart-globe.webp';
export { default as supGlobe } from './superGlobe.webp';
export { default as supGlobe2 } from './superGlobex2.webp';
export { default as building } from './building.webp';
export { default as computers } from './computersBG.webp';
export { default as screens } from './screens.webp';
export { default as people } from './portraits';
export * as teampics from './team';
export * as features from './features';
export * as icons from './icons';
