import styled from 'styled-components';
import { Button } from '~/shared/components/styled';
import { authBG } from '../images';


export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${( { theme } ) => theme.colors.white};
  background-image: url(${authBG});
  background-repeat: no-repeat;
  background-size: cover;
  background-position:bottom left;
  padding-top: 10px;
  padding-right: 30px;
  padding-left: 30px;
  height: 100%;

  @media ${( { theme } ) => theme.typing.mediaRules.mobile} {
  }
`;

export const LogoImage = styled.img`
  cursor:pointer;
  transition:.2s;
  min-height: 70px;
  max-height: 70px;
  &.enter{
    animation: enter .8s 1 ease-in-out;
  }
  :not(.enter):hover{
    animation: wiggle .8s infinite ease-in-out;
  }
  @keyframes wiggle {
    0% {
    }
    50% {
      transform: scale(1.03);
      filter:brightness(1.08);
    }
    100% {
    }
  }
  @keyframes enter {
    0% {
      transform: translateY(-150px);
      opacity:.2;
    }
    100% {
    }
  }
`;

export const Greeting = styled.p`
  font-family:inherit;
  font-size: 2rem;
  font-weight: 1000;
  text-align: center;
  color: ${( { theme } ) => theme.colors.secondary};
`;
export const ChimuLogo = styled.img.attrs( () => ( { viewBox: '0 0 95 68' } ) )`
  height: 60px;
  margin-bottom:10px;
`;

export const Card = styled.div`
  border-radius: 10px;
  text-align: center;
  background: ${( { theme } ) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family:inherit;
  @media ${( { theme } ) => theme.typing.mediaRules.mobile} {
    width: 100%;
    max-width: 450px;
  }
`;

export const CardContent = styled.div`
  padding: 10px 20px 0px 20px;
  font-family:inherit;
`;

export const CardTitle = styled.p`
  color: ${( { theme } ) => theme.colors.textDark3};
  font-size: lamp(10px, 15vw, 21px);
  text-align: center;
  font-weight: 1000;
  margin-left: 5%; 
  margin-right: 5%;
  font-family:inherit; 
`;

export const CardFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* gap: 10px; */
  padding: 20px 10px;
  font-family:inherit;
`;
export const VerifyButton = styled( Button )`
  font-family:inherit;
  height: 64px;
  width: 100%;
  border-radius: 13px;
  color: ${( { theme } ) => theme.colors.white};
  background-color: ${( { theme } ) => theme.colors.primary};
  ${( { theme } ) => theme.typing.textLargeBold};
  @media ${( { theme } ) => theme.typing.mediaRules.mobile} {
    height: 40px;
    ${( { theme } ) => theme.typing.textSmallBold};
  }
  :not(:disabled):focus{
    filter:brightness(1.2)
  }
  ::first-letter{
    text-transform: uppercase;
  }
`;

export const FooterButton = styled( Button )`
  color: ${( { theme } ) => theme.colors.textMedium};
  font-size: ${( { theme } ) => theme.typing.fontSizes.small};
  :focus{
    filter: brightness(1.2)
  }
`;

