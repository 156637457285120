import { initializeApp } from 'firebase/app';
import { fetchCollabToken } from './shared/services/api/collab';


const firebaseConfig = {
  apiKey: 'AIzaSyBQcYEMCXghDsatHz8_J82wpWLn4vsci_U',
  authDomain: 'chimu-adddd.firebaseapp.com',
  databaseURL: 'https://chimu-adddd.firebaseio.com',
  projectId: 'chimu-adddd',
  storageBucket: 'chimu-adddd.appspot.com',
  messagingSenderId: '208426130312',
  appId: '1:208426130312:web:c275e6e2522b926eb0b83d'
};

export const intializeFireBase = () => {

  try {

    /*
     * if (!("Notification" in window)) {
     *   errorMessage(`This browser does not support for realtime notifications,
     *    some features may not work as intended`);
     *   return false
     * }
     */
    initializeApp( firebaseConfig );

  } catch ( err ) {

    // errorMessage("This is probably an error related to your broswer. Please try to enable your browser's notifications or change to Chrome");
    console.error( err );

  }

};

// intializeFireBase();

/*
 * export const db = firebase.firestore();
 * export const auth = firebase.auth();
 */

// export const signIn = async ( userId:number ) => {

//   try {

/*
 *     const token = await fetchCollabToken( userId );
 *     const creds = await auth.signInWithCustomToken( token );
 *     return creds;
 */

//   } catch ( e ) {

//     console.error( e );

//   }

// };
