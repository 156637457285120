import { useInfiniteQuery } from 'react-query';
import useCurrentCommunity from './useCurrentCommunity';

import {
  User,
  api,
  Community,
  CommunityMember,
  FetchMembersPayload,
} from '~/shared/services/api';

const usePagingCommunityMembers = (
  payload?: FetchMembersPayload
): {
  members: Community['members'];
  total_count?: number;
  members_basic?: {
    [id:number]:string
  };
  isLoading: boolean;
  isFetching: boolean;
  error?: Error;
  refetch?: any;
  fetchMore?: any;
  canFetchMore?: any;
} => {

  const { currentCommunity } = useCurrentCommunity();

  const fetchMembersPage = async (
    key: string,
    communityId: number,
    payload: FetchMembersPayload,
    page = 1
  ): Promise<{
    members?: Community['members'];
    total_count?: number;
    members_basic?: Array<any>;
    page?: number;
  }> => {

    const retData = await api
    .fetchCommunityMembers(
      communityId,
      {
        ...payload,
        exclude_me: true,
        page,
      }
    )
    .then( ( data ) => {

      const sortedMembers = data.members.sort( ( a, b ) => {

        if ( a.full_name.toLowerCase() < b.full_name.toLowerCase() ) {

          return -1;

        }
        if ( a.full_name.toLowerCase() > b.full_name.toLowerCase() ) {

          return 1;

        }
        return 0;

      } );
      return { ...data,
        members: sortedMembers };

    } );
    const { members, total_count, members_basic } = retData;

    return {
      members,
      total_count,
      members_basic,
      page: page + 1,
    };

  };

  const {
    data,
    error,
    isLoading,
    isFetching,
    refetch,
    fetchMore,
    canFetchMore,
  } = useInfiniteQuery(
    [
      'communityMembers',
      currentCommunity?.id,
      payload
    ],
    fetchMembersPage,
    {
      getFetchMore: ( lastPage ) => lastPage?.page,
      refetchOnWindowFocus: false,
    }
  );

  const paginatedData = [] as Community['members'];
  data?.forEach( ( page ) => {

    page?.members?.forEach( ( member: CommunityMember ) => {

      paginatedData.push( member );

    } );

  } );

  return {
    members: paginatedData,
    total_count:
      data && data[data.length - 1]
        ? data[data.length - 1]?.total_count
        : 0,
    members_basic:
      data && data[data.length - 1]
        ? data[data.length - 1]?.members_basic
        : [],
    error: error as Error,
    isLoading,
    isFetching,
    refetch,
    fetchMore,
    canFetchMore,
  };

};

export default usePagingCommunityMembers;
