import { default as person1 } from './person-1.webp';
import { default as person2 } from './person-2.webp';
import { default as person3 } from './person-3.webp';
import { default as person4 } from './person-4.webp';
import { default as person5 } from './person-5.webp';
import { default as person6 } from './person-6.webp';
import { default as person7 } from './person-7.webp';
import { default as person8 } from './person-8.webp';
import { default as person9 } from './person-9.webp';
import { default as person10 } from './person-10.webp';
import { default as person11 } from './person-11.webp';
import { default as person12 } from './person-12.webp';
import { default as person13 } from './person-13.webp';
import { default as person14 } from './person-14.webp';
import { default as person15 } from './person-15.webp';
import { default as person16 } from './person-16.webp';
import { default as person17 } from './person-17.webp';
import { default as person18 } from './person-18.webp';
export default [
  person1,
  person2,
  person3,
  person4,
  person5,
  person6,
  person7,
  person8,
  person9,
  person10,
  person11,
  person12,
  person13,
  person14,
  person15,
  person16,
  person17,
  person18
];
