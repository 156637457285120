import styled from 'styled-components';
import {
  ibm,
  allianz,
  cisco,
  siemens,
  atos,
  portland,
  cotPng,
  aes
} from '../images/sponsors';
import {
  MFsvg,
  MhiSvg,
  MunichSvg

} from './logos';

import { Marquee as _Marquee } from '~/shared/components/Marquee';

const Root = styled.section`
  background:${( { theme } ) => theme.colors.white};
  width:100%;
  padding: 29px 0;

`;
const Title = styled.h4`
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: ${( { theme } ) => theme.colors.textMedium};
  margin: 0 auto;
`;
const Marquee = styled( _Marquee )`
  width: min( 100%, 1564px);
  margin: 0 auto;
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content:center;
  gap:51px;
  height:100px;
`;
const Img = styled.img`
  /* max-height:50px; */
  max-width: 100%;
  margin: auto;
  max-height:65px;
  &.Atos{
    /* filter:saturate(0.1) brightness(2.3);   */
  }
  &.Portland{
    /* filter:saturate(0.02) brightness(6.9);   */
  }
`;

const images = [
  {
    name: 'Allianz',
    src: allianz,
  },
  {
    name: 'siemens',
    src: siemens,
  },
  {
    name: 'Cisco',
    src: cisco,
  },
  {
    name: 'IBM',
    src: ibm,
  },
  {
    name: 'Atos',
    src: atos,
  },
  {
    name: 'Portland',
    src: portland,
  },
  {
    name: 'Charter of Trust',
    src: cotPng,
  },
  {
    name: 'AES',
    src: aes,
  },
];

type Props = {
  TitleElement?: typeof Title;
  className?: string;
};

export const LogoCarousel = ( { TitleElement = Title, className }:Props ) => <Root
  className={className}
>
  <TitleElement >Our Partners</TitleElement>
  <Marquee>
    {
      images.map( ( { name, src }, i ) => (
        <Img
          src={src}
          className={name}
          alt={name}
          title={name}
          key={i + name}
        />
      ) )
    }
    {/* <CoT  viewBox="0 0 115 62" style={{maxWidth:'100%',margin:'auto'}}/> */}
    <MFsvg style={{ maxWidth: '100%',
      margin: 'auto' }} viewBox="0 0 222 45" />
    <MunichSvg/>
    <MhiSvg/>
  </Marquee>

</Root>;
