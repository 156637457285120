import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { simpleLogin } from '~/shared/services/api/api';
import { icon } from '~/configurables/logo';
import { useSimpleCommunityData } from './useSimpleCommunityData';
import { useCurrentCommunity, useMyUserQuery, useUserCommunities } from '~/shared/hooks';
import { User } from '~/shared/services/api';
import { Logo, Title, Powered } from './styled';
import { VerifyButton } from '../Auth/styled';
import styled from 'styled-components';
import { Root } from './QrPage';
import { Link } from 'react-router-dom';

const Main = styled.main`
  height: 100%;
  width: 100%;
  padding: 20px;
  text-align: center;
  h2{
    text-align:inherit;
  }
`;
const Button = styled( VerifyButton )`
  margin-top: 50px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 200px;
  max-width: 300px;
  height: 70px;
  font-size: 20px;
`;

type Props = {
  executeRecaptcha: ( action: string ) => Promise<string>;
  communityId: string;
};
export function MemberAlreadyButton ( { communityId, executeRecaptcha }: Props ) {

  const { t, i18n } = useTranslation();
  const history = useHistory();

  const { user, } = useMyUserQuery();
  const { communities } = useUserCommunities();
  const { changeCommunity } = useCurrentCommunity();
  const { data } = useSimpleCommunityData( Number( communityId ) );

  return <Root
    className={`language-${i18n.language}`}
  >
    <Logo
      src={data?.img || icon} />
    <Main>
      <Title>
        {t( 'simpleAuth' )}
      </Title>
      <Button
        onClick={async () => {

          if ( communities?.some( ( comm ) => comm.id === Number( communityId ) ) ) {

            changeCommunity( Number( communityId ) );
            history.push( '/' );

          }
          try {

            const captcha = await executeRecaptcha( 'submit' );
            if ( !captcha ) {

              throw new Error( 'could not get captcha' );

            }
            const { email, first_name, last_name } = user as User;
            await simpleLogin( {
              email,
              firstName: first_name,
              lastName: last_name,
              captcha,
              communityId: Number( communityId as string )
            } ).catch( ( e ) => {

              throw e;

            } );
            history.push( '/' );

          } catch ( err ) {

            console.error( err );
            throw err;

          }

        }}
      >
        Click to Join
      </Button>
      <Link to="/"
        style={{
          color: '#ccc',
        }}
      >
        here by mistake? go back
      </Link>
    </Main>
    <Powered />
  </Root>;


}
