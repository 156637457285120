// this config file is automatically generated when building/running and should not be edited directly
import { IAppConfig } from './configInterface';
export const config :IAppConfig = {
  "title": "Chimu",
  "baseImageUrl": "https://ams-storage.hostwinds.net",
  "bucket": "chimu-dev",
  "baseApiUrl": "https://api.dev.chimu.io",
  "baseUrl": "https://dev.chimu.io",
  "loginUrl": "/auth",
  "adminConsoleUrl": "https://admin.dev.chimu.io",
  "onBoardingUrl": "https://onboarding.dev.chimu.io",
  "clientId": "j73YBBy4G0bjAclO0zFZ0dv87fajq1cdA4FYjKiC",
  "recaptchaKey": "6LdIdM4aAAAAACP03VOAGIQsfDAg2x8O78BcZ949",
  "hasSuggested": true,
  "allowedEmails":null
}
;
