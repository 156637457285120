import { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

import {
  AudioSvg, VideoSvg, PdfSvg, ImageSvg
} from '~/shared/images';

const Container = styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  background:${( { theme } ) => theme.colors.backgroundMedium};
`;
const PdfIcon = styled( PdfSvg )`
  path{
    fill:white; 
  }
`;
const AudioIcon = styled( AudioSvg )`
  g{
    fill:white; 
  }
`;

const icons = [
  ImageSvg,
  VideoSvg,
  AudioIcon,
  PdfIcon
];

function MediaPlaceholder ( { type, children, ...rest }:{type:number}&ComponentPropsWithoutRef<'div'> ) {

  const Problem = () => <span title={`type- ${String( type )}`}> problem </span>;
  const Icon = icons[type - 2] || Problem;
  return (
    <Container {...rest}>
      <Icon/>
      {children}
    </Container>
  );

}
export default MediaPlaceholder;
