import styled from 'styled-components';

import {
  Column as _Column,
  Button as _Button
} from './styled';

import { getStarted } from '../images';
import { Link } from 'react-router-dom';


const Column = styled( _Column )`
  width:100%;
  min-height: 350px;
  padding: 78px 0;
  padding-top: 106px;

  align-items:center;
  gap: 25px;

  background-size:104.5% ;
  background-position: bottom right;
  background-repeat: no-repeat;
  object-fit:fill;
  background-color: ${( { theme } ) => theme.colors.primary};
  background-image: url(${getStarted});
  @media ${( { theme } ) => theme.typing.mediaRules.untilBig} {
    min-height: unset;
  }
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    padding:50px
  }
`;
const Headline = styled.span`
  max-width:916px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  line-height: 1.54;
  color:${( { theme } ) => theme.colors.white};
`;
const Button = styled( _Button )`
  background: ${( { theme } ) => theme.colors.landingBackgroundDark};
  color: ${( { theme } ) => theme.colors.white};
  text-decoration: unset;
`;


export function GetStarted () {
  return <Column>
    <Headline>
      Our goal is to make our companies, our members and, ultimately,
      <br/>
      our world more knowledgeable.
      <br/>
      <span style={{ fontSize: '30px' }}>Together we can make a difference </span>
    </Headline>
    <Button
      as={Link}
      to={'/auth'}>
      Get started ➜
    </Button>
  </Column>;

}
