
export interface FCMPayLoad {
  collapseKey: any;
  data: {
    push_message_type:string;
    [key:string]:any;
  };
  from: string;
  notification: any;
}

export enum FCMTypes {
  Chat = 1,
  Notification,
  Comment,
  FeedItem,
  PollComment,
  MediaComment,
  CommunityChanged,
  Rank
}
