import React, { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';
import { Scrollbar } from '../styled';

const _TextArea = styled.textarea`
  margin-top: 10px;
  ${Scrollbar}
  border: none;
  resize: none;
  width: 100%;
  height: 100px;
  padding: 15px;
  font-family: inherit;
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.borderLight}`};
  color: ${({ theme }) => theme.colors.textDark};
  background-color: ${({ theme }) => theme.colors.backgroundLight3};
  font-size: ${({ theme }) => theme.typing.fontSizes.small};
  font-weight: ${({ theme }) => theme.typing.fontWeights.normal};
`;

const TextArea = (props: ComponentPropsWithoutRef<'textarea'>) => <_TextArea {...props} />;

export default TextArea;
