import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Formik, FormikErrors, } from 'formik';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router';

import * as backgrounds from './images';
import { Registration } from './Registration';
import { PersonalDetails } from './PersonalDetails';
import { CommunityQuestions } from './CommunityQuestions';
import { SuccessPage } from './Success';
import { FormValues, OnboardingData } from './types';
import { queryCache } from '../app/components/queryCache';

import { logo } from '~/shared/images';
import {
  CommunitySuggestion, OnboardingQuestion,
  QuestionForOnboarding
} from '~/shared/services/api';
import { onBoardUserFirstTime } from '~/shared/services/api/api';
import { MenuProvider } from '~/shared/hooks/useMenu';
import { useTranslation } from 'react-i18next';
import { SelectCommunity } from './SelectCommunity';
import { AnswerType } from '~/join-community/Fields';
import { EditBio } from '~/join-community/EditBio';

const Root = styled.div`
  background-position: 92px 101px ,left bottom, right;
  background-size: 94px , contain, cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  padding: 0;
  background-color: #e1e8fd;
  --bg: url( ${backgrounds.group5});
  &.s0{
    --bg: url( ${backgrounds.group1});
  }
  &.s1{
    --bg: url( ${backgrounds.group2});
  }
  &.s2{
    --bg: url( ${backgrounds.group3});
  }
  &.s3{
    --bg: url( ${backgrounds.group4});
  }
  background-image: 
    url(${logo}), 
    var(--bg), 
    linear-gradient( 
      to top, 
      #e1e8fd 28%,
      #e1e8fd 28%,
      #e1e8fd 40%,
      #e1e8fd
    )
  ;
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    padding:0;
  }
`;
const Main = styled.main<{hasHeader:boolean}>`
  position:relative;
  width: 55%;
  min-width:560px;
  height: 100vh;
  overflow: auto;
  background-color:white;
  padding: 4rem 2rem 2rem 2rem;
/*   display: grid;
  grid-template-rows: 75px ${( { hasHeader } ) => ( hasHeader
    ? 'auto'
    : '' )} 1fr;
  grid-row-gap: 30px; */
  display: flex;
  flex-direction: column;
  
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    width: 100%;
    min-width: unset;
    padding: 60px 30px 30px 30px;
    margin: 0 auto;
    grid-template-rows: auto ${( { hasHeader } ) => ( hasHeader
    ? 'auto'
    : '' )} 1fr;
    grid-row-gap: 10px;

  }
`;
const Progress = styled.nav<{ current: number, stepSize: number }>`
  width: 100%; 
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 400px;
  margin-inline: auto;
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    //margin-bottom: 30px;
  }
  .language-he &{
    background-image:linear-gradient(
    to left,
    ${( { theme } ) => theme.colors.pea} 0,
    ${( { theme } ) => theme.colors.pea} ${( { current, stepSize } ) => current - stepSize + ( stepSize / 3 )}%,
    #D9D9D9 ${( { current } ) => current}%,
    #D9D9D9 100%
  ); 
  }
`;
const PageNumber = styled.span<{ number: number, current: number, name?: string }>`
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: white;
  //background-color: #E5E5E6;
  text-align: center;
  font-weight: 800;
  z-index: 1;
/*   box-shadow: 0 0 0px 5px ${( { theme, number, current } ) => {
    if ( current > number )
      return theme.colors.pea;
    return '#9BABC577';
  }}; */
  
  // Changes for rhombus shape
  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background: ${( { theme, number, current } ) => {
    if ( number > current )
      return '#D9D9D9';
    else {
      return {
        0: 'linear-gradient(135deg, #5D79DF 50%, #6987F3 50%)',
        1: 'linear-gradient(135deg, #24B0CD 50%, #3FBAD3 50%)',
        2: 'linear-gradient(135deg, #A2DBD9 50%, #BEE9E8 50%)',
        3: 'linear-gradient(135deg, #CAD2EE 50%, #E1E7FD 50%)',
        4: 'linear-gradient(135deg, #CAD2EE 50%, #E1E7FD 50%)',
      }[number]
    }
  }};
    transform: translate(-50%, -50%) rotate(45deg);
    z-index: -1;
  }
  div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    transform: translate(-50%, -50%) rotate(45deg);
    //background: antiquewhite;
    box-shadow: 0 0 10px 1px rgba(0,0,0,0.2);
    z-index: -2;
    border-radius: 5px;
  }
`;

const Header = styled.h1`
  font-size: 34px;
  font-weight: 800;
  text-transform: capitalize; 
  width: 100%;
  max-width: 500px;
  margin-inline: auto;
  color:${( { theme } ) => theme.colors.textDark3};
`;

const stages = [ {
  name: 'registration',
  component: Registration,
  stageTitle: 'account'
}, {
  name: 'community selection',
  component: SelectCommunity,
  stageTitle: 'community'
}, {
  name: 'Personal details',
  component: PersonalDetails,
  stageTitle: 'personal'
}, {
  component: CommunityQuestions,
  stageTitle: 'professional'
}, {
  component: EditBio,
  stageTitle: 'bio'
}, {
  name: '',
  component: SuccessPage,
  stageTitle: 'final'
},
];

export const joinWizardPath = '/join';

export type IQaMap = Map<number, { question: QuestionForOnboarding | OnboardingQuestion; answer?: AnswerType; }>

export type StageProps<T>={
  values:T;
  handleChange( e:React.ChangeEvent ):void;
  handleBlur( e:React.ChangeEvent ):void;
  setUserId: React.Dispatch<React.SetStateAction<number | undefined>>;
  userId: number | undefined;
  setValues ( values: React.SetStateAction<FormValues> ):void;
  submitForm():void;
  setFieldValue(
      field: keyof FormValues,
      value: any,
      shouldValidate?: boolean | undefined
  ):void;
  next():void;
  prev():void;
  errors:FormikErrors<T>;
}

interface JoinCommunityPageProps {
  refetchToken(): void,
  validToken: boolean,
}

export const JoinCommunityPage = ( {refetchToken, validToken}: JoinCommunityPageProps) => {
  const [ stageNumber, setStageNumber ] = useState( 0);
  const urlLocation = useLocation<{invitedByManager?:boolean, community?:{image:string, name:string}}|undefined>();
  const currentStage = stages[stageNumber];
  const [ userId, setUserId ] = useState<number>();
  const { t } = useTranslation();
  useEffect(() => {
    /* redirect to the internal join community */
    if (validToken)
      window.location.href = window.location.href.replace(joinWizardPath, '/feed')
  }, []);
  const next = () => (stageNumber < stages.length) && setStageNumber(stageNumber + 1);
  const prev = () => (stageNumber > 0) && setStageNumber(stageNumber - 1);
  const initialValues:FormValues = {
    firstName: '',
    lastName: '',
    role: '',
    prefix: '',
    location: '',
    phone: '',
    community: undefined,
    image: '',
    answers: {},
  };
  return (
    <MenuProvider
      style={{top: 0, height: '100%'}}>
      <Root className={`s${stageNumber}`}>
        <Main hasHeader={!!currentStage.name}>
          <div>
            {stageNumber < 5
              && <Progress
                current={Number( ( stageNumber / ( stages.length - 1 ) * 100 ).toFixed( 0 ) )}
                stepSize={Number( ( 1 / ( stages.length - 1 ) * 100 ).toFixed( 0 ) )}>
                {stages.slice(0, 4).map( ( stage, i ) => {
                  const num = i + 1;
                  return <PageNumber
                    title={stage.stageTitle}
                    key={`${stage.stageTitle}${i}`}
                    number={i}
                    current={stageNumber}
                    name={t( `join.stageName.${stage.stageTitle}` )}>
                    <div /> {num}
                  </PageNumber>;
                })}
              </Progress>}
          </div>
          {currentStage.name &&
            <Header>
              {t( `join.stageHeader.${currentStage.stageTitle}` )}
            </Header>}
          <Formik<FormValues>
            initialValues={initialValues}
            onSubmit={async ( values ) => {
              if (userId) {
                const {
                  firstName,
                  lastName,
                  role,
                  image,
                  answers,
                  location,
                  prefix,
                  phone: number,
                  community: communityId,
                } = values;
                const community = queryCache
                .getQueryData<CommunitySuggestion[]>(
                  'suggestedCommunities'
                )?.find( ( { id } ) => id === communityId );
                const full_name = `${firstName} ${lastName}`;
                const phone = `${prefix}${number.replace(/\s/, '')}`;
                const onboarding_answers:OnboardingData['onboarding_answers'] = [];
                Object.entries(answers).forEach(([ key, value]) => {
                  onboarding_answers.push({question_id: Number(key), answer: value!});
                });
                const data: OnboardingData = {
                  full_name,
                  location,
                  onboarding_answers,
                  phone_number: phone,
                  photo: image!,
                  role,
                };
                try {
                  const { invited_by_manager: invitedByManager } =
                    await onBoardUserFirstTime(userId, data, community?.preferred_locale || 'en');
                  if (urlLocation.state)
                    urlLocation.state.invitedByManager = invitedByManager;
                  else
                    urlLocation.state = { invitedByManager };
                  await refetchToken();
                  setStageNumber(stageNumber + 1);
                } catch ( e ) {
                  console.error( e );
                  toast.error( e as string );
                }
              } else {
                console.error( 'missing user id' );
              }
            }}
            validate={( { image } ) => {
              const errors :FormikErrors<FormValues> = {};
              if (!image)
                errors.image = 'problem right here';
              return errors;
            }}>
            {( {
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
              setValues,
              submitForm,
              errors
            } ) => (
              <form
                onSubmit={handleSubmit}
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: '500px',
                  marginInline: 'auto',
                  flex: 1,
                }}
              >
                <currentStage.component
                  values={values}
                  userId={userId}
                  setUserId={setUserId}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setValues={setValues}
                  submitForm={submitForm}
                  setFieldValue={setFieldValue}
                  next={next}
                  prev={prev}
                  errors={errors}
                  hideInfoButton
                />
              </form>
            )}
          </Formik>
        </Main>
      </Root>
    </MenuProvider>
  );
};
