import styled from 'styled-components';
import { BaseButton, Scrollbar } from '~/shared/components/styled';
import { withHebrew } from '~/shared/utils/language';


export const PollOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  max-height: 300px;
  height: 200px;
  overflow: auto;
  padding: 10px;
  ${Scrollbar}
`;

export const PollOptionCountFill = styled.span<( { rtl: boolean; count: number; } ) >`
  position: absolute;
  top: 0;
  font-family: inherit;
  ${( { rtl } ) => ( rtl
    ? 'right:0'
    : 'left: 0;' )};
  bottom: 0;
  width: ${( { count } ) => `${count}%`};
  opacity: 0.1;
  transition: .2s;
  background-color: ${( { theme } ) => theme.colors.primary};
`;

export const PollOptionButton = styled( BaseButton ) <{ isActive: boolean; rtl: boolean; }>`
  min-height: 46px;
  padding: 0 15px;
  border-radius: 10px;
  display: flex;
  ${( { rtl } ) => rtl && withHebrew( rtl )};
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  font-family: inherit;
  border: ${( { theme, isActive } ) => ( isActive
    ? `2px solid ${theme.colors.primary}`
    : `1px solid ${theme.colors.borderLight}` )};
  box-sizing: border-box;
  &:disabled {
    cursor: default;
  }
`;

export const PollOptionTextLeft = styled.span`
  color: ${( { theme } ) => theme.colors.textMedium2};
  font-family: inherit;
  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    font-size: ${( { theme } ) => theme.typing.fontSizes.small};
  }
`;

export const PollOptionTextRight = styled.span`
  color: ${( { theme } ) => theme.colors.primary};
  font-family: inherit;
  font-weight: ${( { theme } ) => theme.typing.fontWeights.bold};
`;
