
export const getFileType = ( file: File ): string => {

  const { type } = file;
  return type === 'application/pdf'
    ? 'PDF'
    : type[0].toUpperCase() + type.slice(
      1,
      type.indexOf( '/' )
    );

};
export const getFileTypeId = ( file: File ): 0 | 1 | 2 | 3 => {

  switch ( getFileType( file ) ) {

    case 'image':
      return 0;
    case 'video':
      return 1;
    case 'audio':
      return 2;
    default:
      return 3;

  }

};

const serialRegex = /\_TMSCHIMU\_\d+/g;
export function removeChimuSerial ( name = '.' ) {

  return name.replaceAll(
    serialRegex,
    ''
  );

}

export const downloadPDF = ( path: string ) => {

  window.open( path );

};

export const convertFileToUrl = ( file: File ): string => URL.createObjectURL( file );

export const downloadFile = ( url: string, name: string ): void => {

  // const moddedUrl = config.baseImageUrl + '/' + url.replace(config.baseImageUrl,config.bucket)
  const link = document.createElement( 'a' );
  link.href = url;
  // .replace('/thumbnail','');
  link.download = removeChimuSerial( name );
  link.target = '_blank';
  document.body.appendChild( link );
  link.click();
  document.body.removeChild( link );

};
export function getNameFromUrl ( url: string ) {

  const slash = url.lastIndexOf( '/' );
  const name = url.slice( slash + 1 );
  return name;

}

export function separateNameAndSuffix ( name = '.' ):{
  filename:string,
  suffix:string
} {

  const lastdot = name.lastIndexOf( '.' );

  if ( lastdot === -1 ) {

    return {
      filename: name,
      suffix: ''
    };

  }
  const filename = name.slice(
    0,
    lastdot
  );
  const suffix = name.slice( lastdot + 1 );
  return { filename,
    suffix };

}
export function isPDF ( filename:string ):boolean {

  const { suffix } = separateNameAndSuffix( filename );
  return suffix === 'pdf';

}
