import { useMemo } from 'react';
import { useMutation } from 'react-query';
import { queryCache } from '~/app/components/queryCache';
import { api, User } from '~/shared/services/api';

import { useMyUserQuery } from './useMyUserQuery';

const useCurrentCommunity = (): { currentCommunity?: User['current_community']; changeCommunity:( id:number )=>void } => {

  const { user, refetch } = useMyUserQuery();

  const currentCommunity = useMemo(
    () => user?.current_community
    , [ user ]
  );
  const [ changeCommunity ] = useMutation(
    async ( id: number ) => {

      api.changeCurrentCommunity( id ).then( () => setTimeout(
        () => {

          refetch();

        },
        500
      ) );

    },
    {
      onSuccess: async () => {

        // await refetch()

        queryCache.invalidateQueries();
        setTimeout(
          () => {

            refetch();

          },
          500
        );

      },
    }
  );
  return { currentCommunity,
    changeCommunity };

};

export default useCurrentCommunity;
