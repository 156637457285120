import { ComponentPropsWithRef } from 'react';
import styled from 'styled-components';
import { ReactComponent as CheckSvg } from '~/shared/images/new-checkmark.svg';

export const CheckBoxRoot = styled.div``;

const CheckIcon = styled( CheckSvg )`
  position: relative;
  transform: translateX(4px) translateY(-3px);
  display: none;
`;
const CheckBox = styled.label`
  position: relative;
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  cursor: pointer;
  margin: 0 5px; 
  color: white;
  background-color: white;
  border: 1px solid #D8D8D8;
`;

const CheckInput = styled.input`
  opacity: 0;
  position: absolute;
  &:focus ~ ${CheckBox} {
    outline: 1px solid blue;
    border-color: ${( { theme } ) => theme.colors.primary};
    border-width: 2px;
  }
  &:disabled ~ ${CheckBox} {
    background-color: ${( { theme } ) => theme.colors.secondary};
  }
  &:checked ~ ${CheckBox} ${CheckIcon} {
    display: initial;
  }
`;

const Label = styled.label`
  display:flex;
  align-items:center;
  font-family:inherit;
  font-size:smaller;
  cursor:pointer;
`;
interface IProps{
  inputId?:string;
  checked?:boolean;
  onCheck?: ( isChecked:boolean )=>void;
  label?:string;
  name?:string;
  disabled?: boolean;
}

export default function Checkbox ( {
  checked,
  onCheck,
  label,
  name,
  disabled,
  inputId,
  ...rest
}:IProps & Omit<ComponentPropsWithRef<'div'>, 'onChange'> ) {

  return (
    <CheckBoxRoot {...rest}>
      <Label>
        <CheckInput
          disabled={disabled}
          name={name}
          type="checkbox"
          id={inputId}
          onChange={( evt ) => !disabled && onCheck?.( evt.target.checked )}
          checked={!disabled
            ? checked
            : true}
        />
        <CheckBox
          onClick={() => onCheck?.( !checked )}
          // htmlFor={inputId}
        >
          { !disabled
            ? <CheckIcon data-testid="checked" />
            : 'X'}
        </CheckBox>
        {label}
      </Label>
    </CheckBoxRoot>
  );

}
