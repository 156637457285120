import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import emailImage from '~/shared/images/email/group10.png';
import { mainItemBackground } from '../../../app/components/Auth/CodeBox';

const EmailIconAndInputWrapper = styled.div`
  direction:ltr;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 13px;
  background: ${mainItemBackground};
  margin: 0 23px;
  width: 100%;
  color: rgba(132 67 33 0.4);
  margin-bottom:10px;
  
  @media ${( { theme } ) => theme.typing.mediaRules.mobile} {
    height: 40px;
  }
`;
const EmailIconWrapper = styled.img`
  margin: 0 1px 0 12px;
`;
const EmailInput = styled.input`
  display: block;
  margin-left: 10px;
  background: ${mainItemBackground};
  border: none;
  font-size: ${( { theme } ) => theme.typing.fontSizes.large};
  font-weight: ${( { theme } ) => theme.typing.fontWeights.semiBold};
  width: 100%;
  direction: ltr;
  &:focus {
    outline: none;
  }

  @media ${( { theme } ) => theme.typing.mediaRules.mobile} {
    /* ${( { theme } ) => theme.typing.textSmallerSemiBold}; */
    /* font-size: 14px; */
    font-size: ${( { theme } ) => theme.typing.fontSizes.normal};
    padding: 0px;
    margin-left: 10px;
  }
`;
interface MailInputProps extends React.ComponentPropsWithoutRef<'input'>{
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  InputComponent?: typeof EmailInput
  hideIcon?:boolean;
}

export function MailInput ( {
  email,
  setEmail,
  InputComponent,
  hideIcon,
  className,
  ...rest
}: MailInputProps ) {

  const { t } = useTranslation();
  const Input = InputComponent || EmailInput;
  return <EmailIconAndInputWrapper
    className={className}
  >
    {!hideIcon && <EmailIconWrapper src={emailImage} />}
    <Input
      value={email}
      onChange={( event ) => setEmail( event.target.value )}
      type="email"
      placeholder={t( 'Login.Email.email' )}
      autoFocus={true}
      {...rest}
    />
  </EmailIconAndInputWrapper>;

}
