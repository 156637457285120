const colors = {
  primary: '#3ebad3',
  secondary: '#0d3750',
  backgroundGrey: '#E6E6E6',
  white: '#ffffff',
  pea: '#bfc329',
  lightRed: '#f87676',
  giveaway: '#f3b203',

  borderLight: '#e7eaf0',

  textLighter: '#c1c7d3',
  textLight: '#a1a1a1',
  textLight2: '#9EA2A4',
  textMedium: '#8fa1bc',
  textMedium2: '#70849a',
  textMedium3: 'rgba(10, 33, 56, 0.5)',
  textMedium4: '#888e95',
  textDark: '#0a2138',
  textDark2: '#153664',
  textDark3: '#0b2541',
  textDark4: '#0e2441',
  textDark5: '#162e57',
  textDark6: '#122f57',
  textLink: '#0a7fff',

  backgroundLight: '#f5f5f5',
  backgroundLight2: '#E6E6E6',
  backgroundLight3: 'rgb(232 238 244 / 47%)',
  backgroundDark: '#082d43',
  backgroundMedium: '#c6cbce',
  landingBackgroundDark: '#161f38',

  divider: 'rgba(67, 85, 103, 0.15)',

  hoverLight: '#eee',

  WorkExperienceEmployer: '#122f57',
  WorkExperienceRole: '#11294c',
  WorkExperienceYears: '#8a9ab0',
};

export default colors;
