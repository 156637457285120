import {RankPercent} from '~/shared/components/AvatarRankSimple'

export const rankPercentHelper = (points: number, isPercent?:boolean) : RankPercent => {
  
  const rankBorderPercent = isPercent? points : (points % 500) / 5;

  let rankBorderObj: RankPercent = {
    colorTransparent: '#bec4c4',
    degOn: 90,
    degOff: 90 - rankBorderPercent * 3.6,
  };
  if (rankBorderPercent >= 25 && rankBorderPercent < 50) {
    rankBorderObj = {
      ...rankBorderObj,
      degOff: -((rankBorderPercent - 25) * 3.6),
    };
  } else if (rankBorderPercent >= 50 && rankBorderPercent < 75) {
    rankBorderObj = {
      colorTransparent: '#1a364e',
      degOn: 90 - (rankBorderPercent - 50) * 3.6,
      degOff: 90,
    };
  } else if (rankBorderPercent >= 75 && rankBorderPercent < 100) {
    rankBorderObj = {
      colorTransparent: '#1a364e',
      degOn: -((rankBorderPercent - 75) * 3.6),
      degOff: 90,
    };
  } else if (rankBorderPercent >= 100) {
    rankBorderObj = {
      colorTransparent: '#1a364e',
      degOn: -87,
      degOff: 93,
    };
  }
  return rankBorderObj;
};
