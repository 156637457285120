import { AnimatePresence, motion } from 'framer-motion';
import React, {
  ComponentPropsWithoutRef, createContext, CSSProperties, useContext, useMemo, useRef
} from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { Scrollbar } from '../components/styled';

const menuContainerId = 'menu-container';
const MenuContainer = styled.div.attrs( { id: menuContainerId } )`
  position: absolute;
  /* background: #a60b0bb3; */
  width: 100%;
  height: calc(100% - 160px);
  top: 80px;
  & > *{
    z-index: 5;
  }
`;
export const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 20px;
  inset-inline-end: 24px;
  font-family: inherit;
  display: flex;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  max-width: 80%;
  border-radius:10px;
  background: white;
  ${Scrollbar}

  box-shadow: 0 0 0 1000px #222223bf;

  @media ${( { theme } ) => theme.typing.mediaRules.untilSmall} {
    padding: 20px;
    width: 100%;
  }
`;
export function FadingBG ( { children, className, style }:ComponentPropsWithoutRef<'div'> ) {

  return <motion.div
    style={{
      position: 'relative',
      background: '#000000aa',
      width: '100%',
      height: '100%',
      ...style
    }}
    exit={{
      opacity: 0
    }}
    className={className}
    children={children}
  />;

}
interface UseMenu {
  InMenu( props:{children:JSX.Element, isShowing:boolean} ):JSX.Element,
  menuContainer:()=>( Element|null ),
}

const MenuContext = createContext<UseMenu|undefined>( undefined );
type MenuProviderProps = {
  children: React.ReactNode;
  style?: CSSProperties
};

export const MenuProvider = (
  {
    children,
    style
  }:MenuProviderProps
) => {

  const containerRef = useRef( null );

  const useMenu :UseMenu = useMemo(
    () => ( {
      InMenu ( { children, isShowing } ) {

        return containerRef.current
          ? createPortal(
            <AnimatePresence exitBeforeEnter >
              {isShowing && children}
            </AnimatePresence>,
            containerRef.current
          )
          : <></>;

      },
      menuContainer: () => containerRef.current
    } ),
    [ ]
  );
  return <MenuContext.Provider value={useMenu}>

    <MenuContainer ref={containerRef} style={style}/>
    {children}
  </MenuContext.Provider>;

};

/**
 * Used to place menus and overlays on layer above content
 */
export const useMenu = (): UseMenu => {

  const context = useContext( MenuContext );
  if ( context === undefined ) {

    throw new Error( 'useMenu must be used within a MenuProvider' );

  }
  return context;

};
