import { QueryConfig, useQuery } from 'react-query';

import useCurrentCommunity from './useCurrentCommunity';

import {
  Community, CommunityMember
} from '~/shared/services/api';
import { RefetchOptions } from 'react-query/types/core/query';
import { fetchCommunityMembers } from '../services/api/api';
import { FetchMembersPayload } from '../services/api/types';


interface CommunityMembersQuery {
  communityId?: number;
  members?: Community['members'];
  isLoading: boolean;
  error?: Error;
  queryKey: [string, number | undefined, FetchMembersPayload|undefined];
  refetch: ( options?: RefetchOptions | undefined ) => Promise<CommunityMember[] | undefined>;
}

const useCommunityMembers = ( params1?: FetchMembersPayload, { enabled = true, retry = false, ...config }:QueryConfig<CommunityMember[]> = {} ): CommunityMembersQuery => {

  const { currentCommunity } = useCurrentCommunity();
  const communityId = currentCommunity?.id;
  const params:FetchMembersPayload = {
    page: undefined,
    search: undefined,
    location: undefined,
    roles: undefined,
    expertise: undefined,
    interests: undefined,
    predefined_filters: undefined,
    community_predefined_filters: undefined,
    onboarding_answers: undefined,
    exclude_me: false,
    ...params1,
  };
  const queryKey:CommunityMembersQuery['queryKey'] = [
    'communityMembers',
    communityId,
    params
  ];
  const {
    data, error, refetch, ...rest
  } = useQuery(
    queryKey,
    () => fetchCommunityMembers(
      communityId as number,
      params
    )
    .then( ( { members } ) => members ),
    {
      enabled: !!communityId && enabled,
      retry,
      ...config
    }
  );
  return {
    communityId,
    members: data,
    error: error as Error,
    refetch,
    queryKey,
    ...rest,
  };

};
export function useCommunityMembersExcludeMe ( params?:Omit<FetchMembersPayload, 'exclude_me'> ) {

  return useCommunityMembers( {
    exclude_me: true,
    ...params
  } );

}
export default useCommunityMembers;
