import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { postCTR } from '~/shared/services/api/api';

const sendPostCTR = async (id: number) => {
  try {
    await postCTR(id);
  } catch (err) {
    console.error(err);
  }
};

const ClampLines = styled.p<{ lines?: number }>`
  margin: 0;
  overflow: hidden;
  max-height: 4.9em;
  -webkit-line-clamp: ${({ lines }) => lines || 3};
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;
const ExternalLink = styled.a.attrs({ target: '_blank' })`
  margin-top: 6px;
  white-space: normal;
  word-wrap: break-word;
  width: fit-content;
  color: ${({ theme }) => theme.colors.primary};
  &:focus {
    color: blue;
  }
  @media ${({ theme }) => theme.typing.mediaRules.untilMedium} {
    font-size: ${({ theme }) => theme.typing.fontSizes.small};
  }
`;

export const ReadMoreLink = (props: {
  id: number;
  url: string;
  onClick?: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <ExternalLink
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
        sendPostCTR(props.id);
      }}
      href={props.url}
    >
      {t('Post.Read more')}
    </ExternalLink>
  );
};

export default ClampLines;
