import React from 'react';
import styled from 'styled-components';

import { withHebrew } from '../../utils/language';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap:15px;
  width: 400px;
  max-width: 50vw;
  align-items: center;
  font-family:inherit;
  @media ${ ({theme}) => theme.typing.mediaRules.untilSmall} {
    max-width: 100%;
  }
`;