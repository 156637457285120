import React, { useReducer, useState } from 'react';
import styled from 'styled-components';
import { ActionButton, Controls as PDFControls } from '../PDFPreview';
import { SecureImg } from '../SecureImg';
import { Scrollbar } from '../styled';
import { UndoSvg } from '~/shared/images';
import { ReactComponent as RotateSvg } from '~/shared/images/rotate-cw.svg';

const Container = styled.div`
  max-width: 80vw;
  max-height:80vh;
  width: min(80vh,80vw);
  height: min(80vh,80vW);
  overflow: auto;
  /* DO NOT SET "display:flex", as it hides parts of images when zoomed in */
  ${Scrollbar}
`;
const Image = styled( SecureImg )`
  padding:20px 0;
  /* is a fix for an issue on ios app */
  max-width: unset;
  display: block;
  margin: auto;
`;
const Controls = styled( PDFControls )`
  bottom: 40px;
  :hover{
    opacity: 1;
  }
`;
const Button = styled( ActionButton )`
  font-size: 30px;
  height: 15px;
  display: flex;
  align-items: center;
`;
const ArrowIcon = styled( UndoSvg )`
  height: 16px;
  width: 18px;
  path{
    fill: white !important;
  }
  :first-of-type{
    transform: translateX(2px) rotate( -115deg );
  }
  :nth-of-type(2){
    transform: translateX(-2px) rotate( 70deg );
  }
`;
const RotateIcon = styled( RotateSvg )`
  path{
    fill:white;
  }
`;

type rotateAction = 'clockwise'|'counterClockwise'|'reset'
type Props = {
  mediaId: number | undefined;
} & React.ComponentPropsWithoutRef<'img'>;

export function ViewImagePopup ( props:Props ) {

  const [ zoom, setZoom ] = useState( 1 );
  const [ rotation, dispatch ] = useReducer(
    ( currentRotation:number, action:rotateAction ) => {

      switch ( action ) {

        case 'clockwise':
          return ( currentRotation + 90 ) % 360;

        case 'counterClockwise':
          return ( ( currentRotation - 90 ) + 360 ) % 360;

        default:
          return 0;

      }


    },
    0
  );

  function reset () {

    setZoom( 1 );
    dispatch( 'reset' );


  }
  function zoomIn () {

    setZoom( zoom + 0.1 );

  }
  function zoomOut () {

    setZoom( zoom - 0.1 );

  }
  return <Container>
    <Image
      style={{
        height: `${100 * zoom}%`,
        transform: `rotate(${rotation}deg)`
      }}
      {...props}
    />
    <Controls>
      <Button
        onClick={ () => dispatch( 'counterClockwise' ) }
      >
        <RotateIcon
          height={20}
          width={20}
          style={{
            transform: 'scaleX(-1)'
          }}

        />
      </Button>
      <Button
        onClick={ zoomOut }
      >
        -
      </Button>
      <Button
        title="reset"
        onClick={ reset }
      >
        <ArrowIcon/>
        <ArrowIcon/>
      </Button>
      <Button
        onClick={ zoomIn }
      >
        +
      </Button>
      <Button
        onClick={ () => dispatch( 'clockwise' ) }
      >
        <RotateIcon/>
      </Button>
    </Controls>

  </Container>;

}
