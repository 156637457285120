import { useTranslation } from 'react-i18next';


import {
  Root, Header, Message
} from './styled';

import { ReactComponent as Icon } from '../images/account-deleted.svg';


export function AccountDeletingPage () {

  const { t } = useTranslation();
  return <Root>
    <Icon/>
    <Header>
      {t( 'Deleting account.title' )}
    </Header>
    <Message>
      {t( 'Deleting account.text' )}
    </Message>
  </Root>;

}
