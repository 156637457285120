import { FlattenInterpolation, ThemeProps, DefaultTheme } from 'styled-components';
export { default as GlobalStyle } from './GlobalStyle';

export type ThemedCssType = FlattenInterpolation<ThemeProps<DefaultTheme>>;

export interface StyledPropsWithCss {
  readonly styles?: ThemedCssType;
}

import colors from './colors';
import * as typing from './typing';

const theme : { colors:typeof colors, typing:typeof typing,rtl?:'rtl'|'ltr'}= {
  colors,
  typing,

};

export default theme;
