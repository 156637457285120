import React from 'react';
import {useTranslation} from 'react-i18next'
import { getTimeFromNow } from '../utils';

const useTranslatedPrettyTime = () => {
  const {t,i18n} = useTranslation()
  const getPrettyTime = (dateStr: string) => t(`Dates.${getTimeFromNow(dateStr)}`);
  
  return getPrettyTime
};

export default useTranslatedPrettyTime;
