
import styled from 'styled-components';

import { Input, Buttons } from './styled';
import { StageProps } from './JoinCommunity';
import { FormValues } from './types';

import { PhoneAndCountryInput } from '~/shared/components/formControls/PhoneAndCountryInput';
import { ProfileImageSelector } from './ProfileImageSelector';
import { useEffect, useState } from 'react';
import { isFileImage } from '~/feed/components/UserProfile/UserProfile';
import { errorMessage } from '~/shared/utils';
import { uploadMedia } from '~/shared/services/api/api';
import theme from '~/shared/theme';
import { useTranslation } from 'react-i18next';

const PhoneInputBox = styled( Input )`
  width: 100%;
  margin: 0;
  padding-inline-start: 10px;
  direction: ltr;
`;
const PhoneAndCountry = styled( PhoneAndCountryInput )`
  display:grid;
  grid-template-columns: auto 1fr;
  gap:0;
  margin-bottom: 30px;
`;

const NamesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;

`;
const textColor = 'rgb(152, 161, 169)';
export const PersonalDetails = ( {
  values, handleChange, handleBlur, next, prev, setFieldValue
}:StageProps<FormValues> ) => {
  const { t } = useTranslation();
  const [ blob, setBlob ] = useState<string>( '' );
  const [ blobObj, setBlobObj ] = useState<any>( {} );
  useEffect( () => blobObj && URL.revokeObjectURL(blobObj.url), [blobObj]);
  const firstNameValid = Boolean( values.firstName?.length );
  const roleValid = Boolean( values.role?.length );
  const lastNameValid = Boolean( values.lastName?.length );
  const phoneValid = ((/^[\s\d()-]*$/).test(values.phone)) && Boolean(values.prefix);
  const onImageChange = (evt: React.ChangeEvent<HTMLInputElement> | undefined ) => {
    if ( evt === undefined) {
      setBlob( '' );
      setBlobObj( {} );
      return;
    }
    const file = evt.target?.files?.[0];
    if (!file)
      return
    if (!isFileImage(file.type)) {
      const error = 'Unsupported file type';
      errorMessage(((error as unknown) as Error).message);
      throw new Error('Unsupported file type');
    }
    const fileBlob = URL.createObjectURL( file );
    setBlob( fileBlob );
    setBlobObj( {file, preview: fileBlob, type: 'Photo'});
  }
  type FileData = { file: File; type: string };

  const upload = async ( fileData: FileData ) => {
    try {
      const result = [];
      const { file } = fileData;
      const formData = new FormData();
      formData.append('payload', file as Blob);
      formData.append('type', '0');
      formData.append('location', 'user-icon/');
      const response = await uploadMedia(formData);
      result.push(response);
      return result;
    } catch (error) {
      throw new Error( error as any );
    }
  };
  function onNextClick () {
    if (blob !== '') {
      upload( blobObj as FileData )
      .then((mediaResponse) =>
        mediaResponse[0] && setFieldValue('image', mediaResponse[0].path))
    }
    next();
  }
  return <>
    <ProfileImageSelector
      image={blob || values.image}
      isBlob={!!blob}
      setFieldValue={setFieldValue}
      onImageToUpload={onImageChange}
      onBlur={handleBlur}/>
    <br/>
    <NamesContainer>
      <Input
        tabIndex={0}
        value={values.firstName}
        name="firstName"
        placeholder={t( 'Labels.First Name' )}
        onChange={handleChange}
        onBlur={handleBlur}/>
      <Input
        tabIndex={0}
        value={values.lastName}
        name="lastName"
        placeholder={t( 'Labels.Last Name' )}
        onChange={handleChange}
        onBlur={handleBlur}/>
    </NamesContainer>
    <Input // Role
      tabIndex={0}
      value={values.role}
      name="role"
      placeholder={t( 'Labels.Role' )}
      onChange={handleChange}
      onBlur={handleBlur}/>
    <PhoneAndCountry
      phone={values.phone}
      location={values.location}
      setPhoneValue={( phone ) => setFieldValue('phone', phone)}
      setLocationValue={( prefix, location ) => {
        setFieldValue('prefix', prefix);
        setFieldValue('location', location);
      }}
      Input={PhoneInputBox}
      selectStyles={{
        container: {
          background: '#fff',
          height: 45,
          borderBottom: '2px solid #D8D8D8',
          width: values.location ? 56 : 200,
          transition: '.2s',
          ':hover': {
            borderBottomColor: '#b3b3b3',
          },
          ':hover,:focus': {
            width: 200,
          }
        },
        placeholder: ( defStyles ) => ( {
          ...defStyles,
          color: 'rgba(10,33,56,0.5);',
          fontWeight: 400,
          fontSize: 15.5,
          marginLeft: 6
        } ),
        valueContainer: {padding: 'unset'},
        singleValue: ( def ) => ( {
          ...def,
          '>span': {
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: '100%',
            color: '#000'
          }
        }),
        control: {
          color: textColor,
          border: 'unset',
          borderBottom: 'unset'
        },
        indicatorsContainer: {
          color: textColor,
        },
        menuList: {
          fontSize: theme.typing.fontSizes.large,
          fontWeight: theme.typing.fontWeights.semiBold,
          color: 'rgba(10,33,56)',
        },
        option: {
          fontWeight: 400,
          fontSize: 15.5,
          marginLeft: 6,
        },
      }}
      SelectMenuPlacement="auto"/>
    <Buttons
      next={onNextClick}
      prev={values.communities?.length ? () => prev() : undefined}
      prevActive={true}
      nextActive={firstNameValid && lastNameValid && roleValid && phoneValid
        && !!( values.image || blob )}/>
  </>;
};


