import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import _Avatar from './Avatar';
import { Line as _Line, Button } from './styled';
import _PostMediaContent from './PostMediaContent';
import ClampLines from './ClampLines';

import {
  useRTL,
  useFeedMutation,
  useMyUserQuery,
  useModal,
  useCurrentCommunity,
  useMasterData,
} from '~/shared/hooks';
import { withHebrew } from '~/shared/utils/language';
import { SystemArticle, Article } from '~/shared/services/api';
import { createSystemPost } from '~/shared/services/api/api';

const Root = styled.div<{ rtl: boolean }>`
  ${( { rtl } ) => withHebrew( rtl )};
  position: relative;
  width: 300px;
  border: 1px solid rgb(62, 186, 211);
  /* min-width: 40px;  */
  /* max-height: 345px;  */
  /* min-height: 40px;  */
  display: flex;
  flex-direction: column;
  height: 363px;
  font-family: Montserrat, sans-serif;
  border-radius: 10px;
  box-shadow: 0 17px 25px 0 rgb(10 33 56 / 12%);
  overflow: hidden;
  @media (max-width: 580px) {
    overflow-x: hidden;
    width: 80vw;
  }
  @media (max-width: 400px) {
    width: 72vw;
    overflow-x: hidden;
  }
`;

const Header = styled.div`
  height: 69px;
  /* min-height: 5px;  */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 25px;
  margin-left: 20px;
  padding-top: 10px;
  @media (max-width: 400px) {
    margin-right: 0px;
    margin-left: 0px;
    justify-content: flex-start;
  }
`;

const IconTitle = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 5px;
  padding-left: 5px;
  @media (max-width: 400px) {
    padding-right: 0px;
    padding-left: 0px;
  }
`;

const TitleDate = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 2px;
`;

const IsPublished = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  padding: 4px 5px;
  border-radius: 6px;
  border: 1px solid ${( { theme } ) => theme.colors.lightRed};
  color: ${( { theme } ) => theme.colors.lightRed};
  margin-left: auto;
`;

const Avatar = styled( _Avatar ).attrs( ( { theme } ) => ( {
  borderWidth: 3,
  borderColor: theme.colors.secondary,
  size: 40,
  width: '46px',
  height: '46px',
} ) )``;

const TitleLabel = styled.span`
  font-family: inherit;
  font-weight: ${( { theme } ) => theme.typing.fontWeights.bold};
  text-align: center;
  font-size: ${( { theme } ) => theme.typing.fontSizes.smallMobile};
  line-height: 1.36;
  color: ${( { theme } ) => theme.colors.textDark6};
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 115px;
`;

const DateLabel = styled.span`
  font-family: inherit;
  font-weight: ${( { theme } ) => theme.typing.fontWeights.bold};
  font-size: 11px;
  text-align: center;
  color: ${( { theme } ) => theme.colors.textMedium4};
  text-align: start;
`;

const Line = styled( _Line )`
  width: 100%;
  align-self: center;
`;

const ArticleContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Montserrat, sans-serif;
  overflow-y: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 500;
`;

const Description = styled( ClampLines )`
  height: 69px;
  width: 95%;
  padding: 5px 15px;
`;

const ExternalLink = styled.a.attrs( { target: '_blank' } )`
  margin-top: 6px;
  white-space: normal;
  word-wrap: break-word;
  height: 69px;
  overflow: hidden;
  width: 95%;
  padding: 5px 15px;
  direction: ltr;
  color: ${( { theme } ) => theme.colors.primary};

  @media ${( { theme } ) => theme.typing.mediaRules.untilMedium} {
    font-size: ${( { theme } ) => theme.typing.fontSizes.small};
  }
`;

const PostMediaContent = styled( _PostMediaContent )`
  margin-top: 15px;
  overflow: hidden;
  margin-bottom: 15px;
  cursor: pointer;
  height: 138px;
  display: flex;
  align-items: center;
  width: 100%;
  max-height: 100%;
  cursor: pointer;
  border-radius: 40px;
  padding: 3px 15px;
  object-fit: cover;
`;

const PublishButton = styled( Button )`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat, sans-serif;
  text-align: center;
  color: ${( { theme } ) => theme.colors.white};
  background-color: ${( { theme } ) => theme.colors.primary};
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  padding: 4px 7px;
  border-radius: 6px;
`;

const PublishContainer = styled.div`
@media (max-width: 400px) {
  width: max-content;
}
`;

const ArticleCard = ( articleItem: SystemArticle ) => {

  const {
    publisher,
    id,
    published_at,
    source_image,
    already_posted,
    title,
    description,
    created,
    url,
    media,
    key,
    icon,
    refetch,
    onPost,
    ...rest
  } = articleItem;
  const rtl = useRTL();
  const { t, i18n } = useTranslation();

  const currentDate = new Date( published_at );
  const displayDate =
    `${currentDate.getDate()
    }-${
      currentDate.getMonth() + 1
    }-${
      currentDate.getFullYear()
    } ${
      currentDate.toTimeString().slice(
        0,
        5
      )}`;

  const sendPostCTR = async () => {};

  const onViewArticle = () => {};

  const iconURL = icon
    ? icon
    : source_image;
  return (
    <Root rtl={rtl} key={key} >
      <Header>
        <IconTitle>
          <Avatar imgSrc={iconURL} />
          <TitleDate>
            <TitleLabel>{publisher}</TitleLabel>
            <DateLabel>{displayDate}</DateLabel>
          </TitleDate>
        </IconTitle>
        <PublishContainer>
          {already_posted
            ? (
              <IsPublished>{t( 'Popups.Buttons.Published' )}</IsPublished>
            )
            : (
              <PublishButton onClick={onPost}>
                {t( 'Popups.Buttons.Publish' )}
              </PublishButton>
            )}
        </PublishContainer>
      </Header>
      <Line />
      <ArticleContainer>
        <Description id={publisher + url}>{title}</Description>
        <ExternalLink onClick={sendPostCTR} href={url}>
          {url}
        </ExternalLink>
        {!!media?.[0] && <PostMediaContent
          mediaId={Number( id )}
          onClick={onViewArticle}
          url={media[0].path || source_image}
          type="Photo"
          name={media[0].name}
        />}
      </ArticleContainer>
    </Root>
  );

};

export default ArticleCard;
