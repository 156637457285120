import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { CodeBox, CodeInput } from '~/app/components/Auth/CodeBox';
import { Props } from '~/app/components/Auth/LoginCodeBox';
import {
  Card,
} from '~/app/components/Auth/styled';
import { AcceptBox } from '~/shared/components/formControls/TermsBox';
import { useState } from 'react';
import { Button } from '~/join-community/styled';

const Container = styled( Card )`
  text-align: start;
  align-items: start;
`;
const Header = styled.h3`
  font-size: 14px;
  font-weight: 500;
  text-align: start;
  margin: 0;
  color: ${( { theme } ) => theme.colors.textLight};
`;
const StyledCodeBox = styled( CodeBox )`
  padding: 0;
  align-items: start;
  &>div:first-child{
    padding: 0;
    width: min-content;
  }
`;
const StyledCodeInput = styled( CodeInput )`
  border-radius: unset;
  border-bottom: #D8D8D8 solid 2px;
  background-color: white;
  color: ${( { theme } ) => theme.colors.textDark3};
  padding: 7.5px 0;
  font-size: 22px;
  &:first-child{
    margin-inline-start: 0; 
  }
  :focus{
    border-bottom-color: ${( { theme } ) => theme.colors.primary};
  }
`;
const ButtonsContainer = styled.div`
  font-size: 12px;
  font-weight: 400;
  text-align: start;
  color: ${( { theme } ) => theme.colors.textDark3};
  margin-top: 1rem;
`;
const TextButton = styled.button.attrs( { type: 'button' } )`
  font-size: 12px;
  font-weight: 600;
  text-align: start;
  color: ${( { theme } ) => theme.colors.textDark3};
  cursor: pointer;
  :disabled{
    cursor: wait;
    opacity: 0.6;
  }
`;
const Disclaimer = styled.div`
  font-family: inherit;
  margin: 35px 0;
  max-width: 420px;
  text-align: start;
  font-size: 12px;
  color: ${( { theme } ) => theme.colors.textMedium};
  a {
    color: ${( { theme } ) => theme.colors.primary};
  }
`;

const CustomButton = styled(Button)`
  grid-area: next;
  width: fit-content;
  padding: 8px 20px;
`;

export function RegisterCodeBox ( {
  isLoading, backToEmail, sendCodeToMail, onSubmit, askTerms, style, ...rest
}: Props ) {

  const { t } = useTranslation();
  const disclaimerTexts = t( 'Login.Code.recapthca' ).split( '|' );
  const [code, setCode] = useState<string>();
  const submit = async (userCode: string) => {
    if (askTerms)
      setCode(userCode)
    else
      await onSubmit(userCode)
  }
  return (
    <>
      <Container style={style} {...rest}>
        <Header>
          {t( 'Login.Code.text' )}
        </Header>
        <StyledCodeBox
          onSubmit={submit}
          sendCodeToMail={sendCodeToMail}
          InputComponent={StyledCodeInput}
          ButtonComponent={() => <></>}
        />
        <br/>
        {askTerms &&
          <AcceptBox
            style={{position: 'relative', bottom: 'unset', width: '100%'}}
            valid={!isLoading && !!code}
            accept={() => onSubmit(code!)}
            acceptText={t('Actions.submit')}
            CustomButton={CustomButton}
            tabIndex={2}/>
        }
        <ButtonsContainer>
          {t( 'join.resendCaption' )}
          <br/>
          <TextButton onClick={sendCodeToMail} disabled={isLoading}>
            {t( 'join.resend' )}&nbsp;
          </TextButton>
          {t( 'join.or' )}&nbsp;
          <TextButton onClick={backToEmail}>
            {t( 'join.edit' )}
          </TextButton>
        </ButtonsContainer>
        <Disclaimer>
          {disclaimerTexts[0]}
          <a href="https://policies.google.com/privacy">
            {' '}
            {t( 'Login.Code.privacy' )}
          </a>
          {disclaimerTexts[1]}
          <a href="https://policies.google.com/terms">
            {' '}
            {t( 'Login.Code.terms' )}
          </a>
          {disclaimerTexts[2]}.
        </Disclaimer>
      </Container>
    </>
  );

}
