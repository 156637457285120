import styled from 'styled-components';
import { motion } from 'framer-motion';
import { chimuLogo } from '~/shared/images';
// Import GreyChimuLogo from '~/shared/images/chimu-logo-grey.png';
import { ReactComponent as chimuTitle } from '~/shared/images/chimu-title.svg';

const PoweredByWrapper = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
`;

const LogoImg = styled( motion.img )`
  height: 40px;
  object-fit: contain;
  width: 40px;
  filter: saturate(0) brightness(1.45);
  transition: filter .4s;
  ${PoweredByWrapper}:hover &{
    filter: saturate(1);
  }
`;

const TitleImg = styled( chimuTitle )`
  margin-left: 5px;
  width: auto;
  path{
    fill: ${( { theme } ) => theme.colors.borderLight};

    ${PoweredByWrapper}:hover &{
      fill: ${( { theme } ) => theme.colors.primary};
    }
  }
`;

const TitleText = styled.span`
  color: ${( { theme } ) => theme.colors.white};
  font-weight: ${( { theme } ) => theme.typing.fontWeights.bold};
  font-size: 8.2px;
  padding-top: 3px;
`;

const TitleWrapper = styled.div`
  position: relative;
  top: -7px;
  display: flex;
  align-items:center;
  gap:3px;
`;
const chimuLink = 'https://chimu.io';
export function PoweredByLogo ( { className }:{className?:string} ) {

  return (
    <PoweredByWrapper href={chimuLink} target="_blank" className={className}
      as={( motion.a )}
      whileHover={{
        scale: 1.2
      }}
    >
      <LogoImg
        src={chimuLogo}
        animate={{
          scale: [
            1,
            1.1,
            1,
            1.1,
            1
          ],
          animationDirection: 'alternate',
          transition: {
            duration: 0.6,
            repeat: Infinity,
            repeatDelay: 0.6
          }
        }}
      />
      <TitleWrapper>
        <TitleText>Powered by</TitleText>
        <TitleImg
          height={20}
          width={'unset'}
        />
      </TitleWrapper>
    </PoweredByWrapper>
  );

}
